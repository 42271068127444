import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts'
import { Tooltip } from 'antd';

import Style from './BottomSider.module.css'
import usePublic from '../usePublic/usePublic'
// import CesiumContext from '../../common/CesiumContext'
// import noDataSvg from './image/nodata.svg'
import TitleCom from '../usePublic/TitleCom/TitleCom'
// import Axios from '../../common/Axios';
// import UserContext from '../../common/UserContext'
import close1 from '../../common/image/close2.png'
import open1 from '../../common/image/open2.png'
import Region from '../Echarts/Region'
import Time from '../Echarts/Time'

export default function BottomSider({ bottomFireData, viewer }) {

    const { sortArr, deleteEntityByName, addBoundary } = usePublic({ viewer })//取重


    const [chartdata, setchartdata] = useState(bottomFireData)

    useEffect(() => {
        if (bottomFireData && bottomFireData.length > 0) {
            setchartdata(bottomFireData)
        } else {
            setchartdata([])
        }
    }, [bottomFireData])


    // 左侧卫星类型——饼图
    useEffect(() => {
        if (chartdata && chartdata.length > 0) {
            const bingWarp = document.getElementById('OneEcharts');
            if (bingWarp) {
                const color = ['#00ffff', '#006ced', '#ffe000', '#ffa800', '#00cfff', '#ff5b00', '#ff3000']
                const newChartdata = sortArr(chartdata, 'satelliteType')
                const newData = []
                let i = 0
                for (const key in newChartdata) {

                    newData.push({
                        name: key,
                        value: newChartdata[key].length,
                        info: newChartdata[key],
                        itemStyle: {
                            borderWidth: 1,
                            shadowBlur: 25,
                            borderRadius: 75,
                            borderColor: color[i],
                            shadowColor: color[i],
                            color: color[i],
                        },
                        label: {
                            color: color[i]
                        }
                    })

                    if (i < color.length - 1) {
                        i++
                    } else {
                        i = 0
                    }
                }

                // 指定图表的配置项和数据
                let option = {
                    color: color,
                    title: {
                        text: '数据源',
                        top: 'middle',
                        textAlign: "center",
                        left: "49%",
                        textStyle: {
                            color: '#fff'
                        },
                        fontSize: 16,
                        fontWeight: '400'
                    },
                    legend: {
                        type: 'scroll',
                        pageIconColor: "rgba(255,255,255,0.8)", //翻页按钮颜色
                        pageIconInactiveColor: 'rgba(255,255,255,0.2)', //翻页按钮不激活时（即翻页到头时）的颜色。
                        pageTextStyle: { //翻页文字设置
                            color: 'rgba(255,255,255,0.8)'
                        },
                        // orient: 'vertical',
                        // right: 10,
                        // top: 20,
                        // bottom: 20,
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    tooltip: {
                        show: false
                    },
                    toolbox: {
                        show: false
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    series: [{
                        type: 'pie',
                        radius: ['40%', '48%'],
                        itemStyle: {
                            label: {
                                show: true,
                                position: 'outside',
                                formatter: function (params) {
                                    if (params.name !== '') {
                                        return '传感器：' + params.name;
                                    } else {
                                        return '';
                                    }
                                },
                            },
                            labelLine: {
                                length: 20,
                                length2: 20,
                                show: true,
                                color: '#fff'
                            },
                        },
                        label: {
                            show: true,
                            position: 'outside',
                            formatter: function (params) {
                                return `${params.name}\n\n${params.value}处`;
                            },
                        },
                        data: newData
                    }]
                }
                const bingChart = echarts.init(bingWarp);
                bingChart.setOption(option);
                window.addEventListener("resize", () => {
                    bingChart.resize();
                });
            }
        }
    }, [chartdata, sortArr])

    // echarts图hover
    function hoverBoundary(code, dd) {
        if (viewer) {
            // 重新绘制区县边界
            let isTrue = false
            // 该市县的边界的是否已经存在
            var entitys = viewer.entities._entities._array;
            let length = entitys.length
            for (var f = length - 1; f >= 0; f--) {
                if (entitys[f]._name && entitys[f]._name.indexOf("hoverBoundary") > -1 && entitys[f]._name === "hoverBoundary" + code) {
                    // 已存在
                    isTrue = true;
                } else if (entitys[f]._name && entitys[f]._name.indexOf("hoverBoundary") > -1 && entitys[f]._name !== "hoverBoundary" + code) {
                    viewer.entities.remove(entitys[f]);//删除
                }
            }
            deleteEntityByName('selectCity')//删除历史火点查询select产生的实体
            // 本来有则删除，本来无则添加
            if (isTrue === false) {
                // 更新天气
                //code区县代码，取出前四位
                // const cityCode = code.substring(0, 4) + '00'
                // cesiumContext.setweatherCode(cityCode)
                // 添加边界
                // addBoundary(3, code, true, "hoverBoundary" + code, '#1E90FF')
                switch (dd) {
                    // case 'proCode':
                    //     addBoundary(1, code, true, "hoverBoundary" + code, '#1E90FF')
                    //     break;
                    case 'cityCode':
                        addBoundary(2, code, true, "hoverBoundary" + code, '#1E90FF')
                        break;
                    case 'countyCode':
                        addBoundary(3, code, true, "hoverBoundary" + code, '#1E90FF')
                        break;
                    case 'townCode':
                        addBoundary(4, code, true, "hoverBoundary" + code, '#1E90FF')
                        break;
                    default:
                }
            } else {
                // cesiumContext.setweatherCode()//天气恢复
                deleteEntityByName("hoverBoundary" + code)//删除历史火点查询select产生的实体
            }
        }
    }

    function closeBottom() {
        // setIsOpen(V => !V)
        const hisRight = document.getElementById('BottomWrapperID')
        hisRight.style.transform = isOpen ? 'translateY(calc(100% + 20px))' : 'translateY(0)'
        let time = setInterval(() => {
            setIsOpen(V => !V)
            clearInterval(time)
        }, 900)
    }
    const [isOpen, setIsOpen] = useState(true)//开关
    return (
        <>
            <div className={Style.BottomWrapper} id='BottomWrapperID'
                style={{
                    // transform: isOpen ? 'translateY(0)' : 'translateY(calc(100% + 20px))',
                    transition: '1s'
                }}>

                <p className={Style.closeWrapper} onClick={closeBottom} >
                    <Tooltip title={isOpen ? '收起' : '展开'} placement="top">  <img src={isOpen ? close1 : open1} alt=''></img>
                    </Tooltip>
                </p>


                {/* 数据源统计 */}
                <div>
                    <div><TitleCom title='数据源统计' type={2} /></div>
                    {
                        chartdata && chartdata.length > 0 ?
                            <div>
                                <div id='OneEcharts' className={Style.oneEchartsWrapper}></div>
                            </div>
                            : <div className={Style.noDatap}>
                                <p >暂无火点</p>
                            </div>
                    }
                </div>
                {/* 区域统计 */}
                <div>
                    <div><TitleCom title='区域统计' type={2} /></div>
                    {
                        chartdata && chartdata.length > 0 ?
                            <div>
                                <Region dataSource={chartdata} hoverBoundary={hoverBoundary} />
                            </div>
                            : <div className={Style.noDatap}>
                                <p >暂无火点</p>
                            </div>
                    }
                </div>

                <div>
                    <div><TitleCom title='时间统计' type={2} /></div>
                    <Time dataSource={chartdata} />
                    {/* {
                        bobao && bobao.length > 0 ?
                            <div className={Style.listWra} id='par'>
                                <div id='chil' className={Style.listWrachil}>
                                    {
                                        bobao.map((item, index) => <div key={index} className={index % 2 === 1 ? Style.listlist : Style.listlistDan} >
                                            <div>{item.firePointAddress}</div>
                                            <div>{item.satelliteTime}</div>
                                        </div>)
                                    }
                                </div>
                            </div> :
                            <div className={Style.noDatap}>
                                <p >暂无火点</p>
                            </div>
                    } */}
                </div>
            </div>
        </>

    )
}