import React, { useEffect, useContext, useState } from 'react';
import * as Cesium from 'cesium';

import Style from './CesiumTool.module.css';
import usePublic from '../usePublic/usePublic';
// import CesiumContext from '../../common/CesiumContext';
import { Button, Menu, Dropdown, Tooltip } from 'antd';

import satellite_name from './orbitingSatellite.json'
import location from '../../common/image/location.png';

import zoomLarge from '../../common/image/zoomLarge.png';
import zoomSmall from '../../common/image/zoomSmall.png';
import layer from '../../common/image/layer.png';
import rangeSvg from '../../common/image/rangeBlue.png';
import rangeBlueSvg from '../../common/image/range.png';
import areaSvg from '../../common/image/areaBlue.png';
import areaBlueSvg from '../../common/image/area.png';
import removeSvg from '../../common/image/remove.png';
import orbitSvg from '../../common/image/orbit3.png';
import orbitBlue from '../../common/image/orbit3Blue.png';
import UserContext from '../../common/UserContext';

import zhujiBlue from '../../common/image/zhujiBlue.png';
import zhuji from '../../common/image/zhuji.png';

// import zoomLarge from './image/zoomLarge.svg';
// import zoomSmall from './image/zoomSmall.svg';
// import location from './image/location.svg';
// import layer from './image/layer.svg';
// import rangeSvg from './image/range.svg'
// import rangeBlueSvg from './image/rangeBlue.svg'
// import areaSvg from './image/area.svg'
// import areaBlueSvg from './image/areaBlue.svg'
// import removeSvg from './image/remove.svg'
// import orbitSvg from './image/orbit3.svg'
// import orbitBlue from './image/orbit3Blue.svg'

let handler
export default function CesiumTool({ viewer }) {
    const userContext = useContext(UserContext)
    // const cesiumContext = useContext(CesiumContext);
    // let viewer = cesiumContext.viewer
    const { addBoundary } = usePublic({ viewer })
    const [orbitWeather, setOrbitWeather] = useState(false)//卫星轨道
    const [rangeWeather, setRangeWeather] = useState(false)
    const [areaWeather, setAreaWeather] = useState(false)
    const [zhujiWeather, setZhujiWeather] = useState(false)

    function drawOrbitFor(value) {
        setOrbitWeather(value)
        if (value) {
            viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(108.924043, 34.753503, 45000000),
                duration: 2,
            });
            satellite_name.forEach((item, index) => {
                for (let i = 0; i < item.totalCount; i++) {
                    drawOrbit(index, i)
                }
            })
        } else {
            resetPostion()
            // viewer.dataSources.removeAll();

            // satellite_name.forEach(item => {
            //     for (let i = 0; i < item.totalCount; i++) {
            //         let id = item.children[i].code
            //         viewer.entities.removeById(id)
            //         for (let l = 0; l < viewer.dataSources.length; l++) {
            //             if (viewer.dataSources.get(l).name.indexOf(id) !== -1) {
            //                 viewer.dataSources.remove(viewer.dataSources.get(l));
            //                 break;
            //             }
            //         }
            //     }
            // })

            //layerName是加载时给geojson添加的属性
            const dataSources = viewer.dataSources._dataSources
            for (let k = dataSources.length - 1; k >= 0; k--) {
                // if (dataSources[k].name.indexOf('geojsonDataSource') > -1) {
                if (dataSources[k].hhname && dataSources[k].hhname === 'guidao') {
                    viewer.dataSources.remove(dataSources[k])
                }
            }


            // satellite_name.forEach(item => {
            //     // 倒叙遍历
            //     for (let i = item.totalCount.length; i > 0; i--) {
            //         let id = item.children[i].code
            //         viewer.entities.removeById(id)
            //         for (let l = 0; l < viewer.dataSources.length; l++) {
            //             if (viewer.dataSources.get(l).name.indexOf(id) !== -1) {
            //                 viewer.dataSources.remove(viewer.dataSources.get(l));
            //                 break;
            //             }
            //         }
            //     }
            // })
        }
    }

    function drawOrbit(obj, i) {
        // let satellite = null;
        // let property;
        // let clock = viewer.clock;
        let name = satellite_name[obj].children[i].code
        let path = require("./czml/" + name + ".czml")
        // let path = satePath[name]
        // viewer.dataSources.add(Cesium.CzmlDataSource.load(path)).then(function (dataSource) {
        Cesium.CzmlDataSource.load(path).then(function (dataSource) {
            dataSource.hhname = 'guidao'
            // 以前以为以下是控制卫星运动的，没想到去掉卫星也运动 ，czml神奇
            // satellite = dataSource.entities.getById("Satellite/" + name);
            // property = new Cesium.SampledPositionProperty();//获取位置
            // for (let ind = 0; ind < 292; ind++) {
            //     let time = Cesium.JulianDate.addSeconds(clock.startTime, 300 * ind, new Cesium.JulianDate());//朱利安日期（天数，秒数，时间标准）
            //     let position = satellite.position.getValue(time);
            //     let cartographic = viewer.scene.globe.ellipsoid.cartesianToCartographic(position);
            //     let lat = Cesium.Math.toDegrees(cartographic.latitude),//弧度转为度
            //         lng = Cesium.Math.toDegrees(cartographic.longitude),
            //         hei = cartographic.height / 1.9;
            //     property.addSample(time, Cesium.Cartesian3.fromDegrees(lng, lat, hei));
            // }
            viewer.dataSources.add(dataSource)


            // if (satellite_name[obj].children[i].orbitalType === "太阳同步轨道") {
            //     let cylinderEntity = viewer.entities.add({
            //         id: satellite_name[obj].children[i].code,
            //         cylinder: {
            //             HeightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            //             length: 600000,
            //             topRadius: 0,//顶部半径
            //             bottomRadius: 600000 / 4,//底部半径
            //             material: Cesium.Color.RED.withAlpha(.4),
            //             outline: !0,
            //             numberOfVerticalLines: 0,
            //             outlineColor: Cesium.Color.RED.withAlpha(.8)
            //         }
            //     });
            //     cylinderEntity.position = property;
            //     cylinderEntity.position.setInterpolationOptions({ //设定位置的插值算法
            //         interpolationDegree: 10,
            //         interpolationAlgorithm: Cesium.LagrangePolynomialApproximation
            //     });
            // }
        })
    }

    const menu1 = (
        <Menu>
            <Menu.Item key='0' onClick={() => { ChangeMap(0) }}>卫星图</Menu.Item>
            <Menu.Item key='1' onClick={() => { ChangeMap(1, true) }}>矢量图</Menu.Item>
            <Menu.Item key='2' onClick={() => { ChangeMap(2) }}>地形图</Menu.Item>
            <Menu.Item key='3' onClick={() => { ChangeMap(3) }}>acrgis影像图</Menu.Item>
            <Menu.Item key='4' onClick={() => { ChangeMap(4) }}>高德影像图</Menu.Item>
        </Menu>
    );

    //#region 
    //Arcgis
    // const dd = {
    //     AG_image: new Cesium.ProviderViewModel({
    //         name: "acrgis影像图",
    //         tooltip: "acrgis影像图",
    //         // iconUrl: imageNote,
    //         creationFunction: function () {
    //             let esri = new Cesium.ArcGisMapServerImageryProvider({
    //                 url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
    //                 tileMatrixSetID: "GoogleMapsCompatible",
    //                 style: "default",
    //                 layer: "tdtImgAnnoLayer",
    //             });
    //             return esri;
    //         }
    //     }),
    //     AG_vector: new Cesium.ProviderViewModel({
    //         name: "acrgis影像图",
    //         tooltip: "acrgis影像图",
    //         // iconUrl: imageNote,
    //         creationFunction: function () {
    //             let esri = new Cesium.ArcGisMapServerImageryProvider({
    //                 url: 'http://cache1.arcgisonline.cn/arcgis/rest/services/ChinaOnlineCommunity/MapServer',
    //                 tileMatrixSetID: "GoogleMapsCompatible",
    //                 style: "default",
    //                 layer: "tdtImgAnnoLayer",
    //             });
    //             return esri;
    //         }
    //     }),
    //     AG_black: new Cesium.ProviderViewModel({
    //         name: "acrgis影像图",
    //         tooltip: "acrgis影像图",
    //         // iconUrl: imageNote,
    //         creationFunction: function () {
    //             let esri = new Cesium.ArcGisMapServerImageryProvider({
    //                 url: 'http://cache1.arcgisonline.cn/arcgis/rest/services/ChinaOnlineStreetPurplishBlue/MapServer',
    //                 tileMatrixSetID: "GoogleMapsCompatible",
    //                 style: "default",
    //                 layer: "tdtImgAnnoLayer",
    //             });
    //             return esri;
    //         }
    //     }),


    // }
    // //高德
    // const ff = {
    //     GD_image: new Cesium.ProviderViewModel({
    //         name: "高德影像图",
    //         tooltip: "高德影像图",
    //         // iconUrl: imageNote,
    //         creationFunction: function () {
    //             let esri = new Cesium.UrlTemplateImageryProvider({
    //                 url: 'https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
    //                 tileMatrixSetID: "GoogleMapsCompatible",
    //                 style: "default",
    //                 layer: "tdtImgAnnoLayer",
    //             });
    //             return esri;
    //         }
    //     }),
    //     GD_line: new Cesium.ProviderViewModel({
    //         name: "高德街道图",
    //         tooltip: "高德街道图",
    //         // iconUrl: imageNote,
    //         creationFunction: function () {
    //             let esri = new Cesium.UrlTemplateImageryProvider({
    //                 url: 'http://webrd02.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}',
    //                 tileMatrixSetID: "GoogleMapsCompatible",
    //                 style: "default",
    //                 layer: "tdtImgAnnoLayer",
    //             });
    //             return esri;
    //         }
    //     })
    // }
    //#endregion

    function ChangeMap(key, isZhuJI) {
        // 删除其他图层
        removeLayer('baseLayerNation')
        // removeLayer('zhujiLayer')
        // 是否移除注记图层
        if (!isZhuJI) {
            // 添加注记图层
            let zhujiLayer = new Cesium.WebMapTileServiceImageryProvider({
                url: "http://t0.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=5956e6519f2bb0ae8e57bc834298c9f1",
                layer: "tdtImgBasicLayer",
                style: "default",
                format: "image/jpeg",
                tileMatrixSetID: "GoogleMapsCompatible",
                show: false
            })
            zhujiLayer.baseLayerName = 'zhujiLayer'
            //注记图层
            // viewer.imageryLayers.addImageryProvider(zhujiLayer);//注记图层
        }

        let map = ' ';
        let layer = null
        switch (key) {
            case 0:
                map = new Cesium.WebMapTileServiceImageryProvider({
                    // url: 'https://t0.tianditu.com/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=a3dfb742357fb07dfca6a7ec78d89359',
                    url: 'https://t0.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=5956e6519f2bb0ae8e57bc834298c9f1',
                    tileMatrixSetID: "GoogleMapsCompatible",
                    style: "default",
                    layer: "img",
                    maximumLevel: 18,
                })
                map.baseLayerName = 'baseLayerNation'
                layer = viewer.imageryLayers.addImageryProvider(map);
                viewer.imageryLayers.lowerToBottom(layer);//将图层移到最底层
                break;
            case 1:
                map = new Cesium.UrlTemplateImageryProvider({
                    url: 'https://webrd02.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}',
                    tileMatrixSetID: "GoogleMapsCompatible",
                    style: "default",
                    layer: "SDPubMap",
                });
                map.baseLayerName = 'baseLayerNation'
                layer = viewer.imageryLayers.addImageryProvider(map);
                viewer.imageryLayers.lowerToBottom(layer);//将图层移到最底层
                break;
            case 2:
                map = new Cesium.WebMapTileServiceImageryProvider({
                    url: 'https://t0.tianditu.gov.cn/ter_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=ter&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=7711a24780452f03bb7c02fba98183b9',
                    tileMatrixSetID: "GoogleMapsCompatible",
                    style: "default",
                    layer: "tdtImgAnnoLayer",
                    maximumLevel: 18,
                });
                map.baseLayerName = 'baseLayerNation'
                layer = viewer.imageryLayers.addImageryProvider(map);
                viewer.imageryLayers.lowerToBottom(layer);//将图层移到最底层
                break;
            case 3:
                map = new Cesium.ArcGisMapServerImageryProvider({
                    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
                    tileMatrixSetID: "GoogleMapsCompatible",
                    style: "default",
                    layer: "tdtImgAnnoLayer",
                });
                map.baseLayerName = 'baseLayerNation'
                layer = viewer.imageryLayers.addImageryProvider(map);
                viewer.imageryLayers.lowerToBottom(layer);//将图层移到最底层
                break;
            case 4:
                map = new Cesium.UrlTemplateImageryProvider({
                    url: 'https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
                    tileMatrixSetID: "GoogleMapsCompatible",
                    style: "default",
                    layer: "tdtImgAnnoLayer",
                });
                map.baseLayerName = 'baseLayerNation'
                layer = viewer.imageryLayers.addImageryProvider(map);
                viewer.imageryLayers.lowerToBottom(layer);//将图层移到最底层
                break;
            default:
                break;
        }
    }

    // 移除图层
    function removeLayer(baseLayerName) {
        // 倒叙遍历，customName是自定义的属性
        const layers = viewer.imageryLayers._layers
        for (let f = layers.length - 1; f >= 0; f--) {
            if (layers[f]._imageryProvider.baseLayerName && layers[f]._imageryProvider.baseLayerName === baseLayerName) {
                viewer.imageryLayers.remove(layers[f])
            }
        }
    }

    const deleteRangeArea = () => {
        let RangeArea = viewer.entities._entities._array
        for (let i = 0; i < RangeArea.length; i++) {
            if (RangeArea[i]._name === "_range") {
                viewer.entities.remove(RangeArea[i]);
                i--;
            }
        }
    }
    // 测距
    function range(value) {

        // if (value) {
        // area(false)
        // deleteRangeArea()
        // 取消双击事件-追踪该位置
        viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

        handler = new Cesium.ScreenSpaceEventHandler(viewer.scene._imageryLayerCollection);
        var positions = [];
        var poly = null;
        var distance = 0;
        var cartesian = null;
        var labelPt;

        //鼠标移动事件
        handler.setInputAction(function (movement) {
            let ray = viewer.camera.getPickRay(movement.endPosition);
            cartesian = viewer.scene.globe.pick(ray, viewer.scene);
            if (!Cesium.defined(cartesian)) //跳出地球时异常
                return;
            if (positions.length >= 2) {
                if (!Cesium.defined(poly)) {
                    //移动时路径绘制
                    poly = new PolyLinePrimitive(positions);
                } else {
                    positions.pop();
                    positions.push(cartesian);
                }
            }
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

        //鼠标左键点击事件
        handler.setInputAction(function (movement) {
            let ray = viewer.camera.getPickRay(movement.position);
            cartesian = viewer.scene.globe.pick(ray, viewer.scene);
            if (!Cesium.defined(cartesian)) //跳出地球时异常
                return;
            if (positions.length === 0) {
                positions.push(cartesian.clone());
            }
            positions.push(cartesian);
            //记录鼠标单击时的节点位置，异步计算贴地距离
            labelPt = positions[positions.length - 1];
            if (positions.length > 2) {
                getSpaceDistance(positions);
            } else if (positions.length === 2) {
                //在三维场景中添加Label
                viewer.entities.add({
                    name: '_range',
                    id: "range",
                    position: labelPt,
                    point: {
                        pixelSize: 5,
                        color: Cesium.Color.RED,
                        outlineColor: Cesium.Color.WHITE,
                        outlineWidth: 1,
                    },
                    label: {
                        text: '起 点',
                        // font: 'normal 8px SimHei',
                        // fillColor: Cesium.Color.BLUE,
                        font: '16px sans-serif',
                        fillColor: Cesium.Color.WHITE,
                        showBackground: true,
                        backgroundColor: Cesium.Color.fromCssColorString('rgba(0, 32, 64, 0.8)'),//背景颜色
                        //showBackground:true,
                        style: Cesium.LabelStyle.FILL,     //label样式 FILL、OUTLINE、FILL_AND_OUTLINE
                        outlineWidth: 2,
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,//水平位置
                        pixelOffset: new Cesium.Cartesian2(0, -10),
                    }
                });
            }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

        //鼠标左键双击事件
        handler.setInputAction(function (movement) {
            let ray = viewer.camera.getPickRay(movement.position);
            cartesian = viewer.scene.globe.pick(ray, viewer.scene);
            if (!Cesium.defined(cartesian)) //跳出地球时异常
                return;
            if (positions.length === 0) {
                positions.push(cartesian.clone());
            }
            positions.push(cartesian);
            //记录鼠标单击时的节点位置，异步计算贴地距离
            labelPt = positions[positions.length - 1];
            if (positions.length > 2) {
                getSpaceDistance(positions);
            } else if (positions.length === 2) {
                //在三维场景中添加Label
                viewer.entities.add({
                    name: '_range',
                    //id:"LEFT_DOUBLE_CLICK",
                    position: labelPt,
                    point: {
                        pixelSize: 5,
                        color: Cesium.Color.RED,
                        outlineColor: Cesium.Color.WHITE,
                        outlineWidth: 2,
                    }
                });
            }
            //关闭事件句柄
            if (handler) {
                handler.destroy();
                handler = null
            }
            setRangeWeather(false)//结束
        }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

        var PolyLinePrimitive = (function () {
            function _(positions) {
                this.options = {
                    name: '_range',
                    polyline: {
                        show: true,
                        positions: [],
                        material: Cesium.Color.ORANGERED,
                        width: 3,
                        clampToGround: true
                    }
                };
                this.positions = positions;
                this._init();
            }

            _.prototype._init = function () {
                var _self = this;
                var _update = function () {
                    return _self.positions;
                };
                //实时更新polyline.positions
                this.options.polyline.positions = new Cesium.CallbackProperty(_update, false);
                viewer.entities.add(this.options);
            };

            return _;
        })();

        //空间两点距离计算函数
        function getSpaceDistance(positions) {
            //只计算最后一截，与前面累加
            //因move和鼠标左击事件，最后两个点坐标重复
            var i = positions.length - 3;
            var point1cartographic = Cesium.Cartographic.fromCartesian(positions[i]);
            var point2cartographic = Cesium.Cartographic.fromCartesian(positions[i + 1]);
            getTerrainDistance(point1cartographic, point2cartographic);
        }

        //贴地距离计算函数
        function getTerrainDistance(point1cartographic, point2cartographic) {
            var geodesic = new Cesium.EllipsoidGeodesic();
            geodesic.setEndPoints(point1cartographic, point2cartographic);
            var s = geodesic.surfaceDistance;
            var cartoPts = [point1cartographic];
            for (var jj = 1000; jj < s; jj += 1000) {　　//分段采样计算距离
                var cartoPt = geodesic.interpolateUsingSurfaceDistance(jj);
                cartoPts.push(cartoPt);
            }
            cartoPts.push(point2cartographic);
            //返回两点之间的距离
            var promise = Cesium.sampleTerrain(viewer.terrainProvider, 2, cartoPts);

            Cesium.when(promise, function (updatedPositions) {
                // positions height have been updated.
                // updatedPositions is just a reference to positions.
                for (var jj = 0; jj < updatedPositions.length - 1; jj++) {
                    var geoD = new Cesium.EllipsoidGeodesic();
                    geoD.setEndPoints(updatedPositions[jj], updatedPositions[jj + 1]);
                    var innerS = geoD.surfaceDistance;
                    innerS = Math.sqrt(Math.pow(innerS, 2) + Math.pow(updatedPositions[jj + 1].height - updatedPositions[jj].height, 2));
                    distance += innerS;
                }

                //在三维场景中添加Label
                var textDisance = distance.toFixed(2) + "米";
                if (distance > 10000)
                    textDisance = (distance / 1000.0).toFixed(2) + "千米";
                viewer.entities.add({
                    name: '_range',
                    position: labelPt,
                    point: {
                        pixelSize: 4,
                        color: Cesium.Color.RED,
                        outlineColor: Cesium.Color.WHITE,
                        outlineWidth: 2,
                    },
                    label: {
                        text: textDisance,
                        font: '16px sans-serif',
                        fillColor: Cesium.Color.WHITE,
                        showBackground: true,
                        backgroundColor: Cesium.Color.fromCssColorString('rgba(0, 32, 64, 0.8)'),//背景颜色
                        // style: Cesium.LabelStyle.FILL,
                        outlineWidth: 2,
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,//水平位置
                        pixelOffset: new Cesium.Cartesian2(0, -10),
                    }
                });
            });
        }
        // } else {
        //     deleteRangeArea()
        //     viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);//停止监听移动事件
        //     viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);//停止监听移动事件
        // }

    }

    function rangeHandle() {
        setAreaWeather(false)//关闭测距
        if (rangeWeather) {
            deleteRangeArea()
            if (handler) {
                handler.destroy();
                handler = null
            }
        } else {
            range()
        }
        setRangeWeather(v => !v)
    }

    // 测面
    function area(value) {
        // if (value) {
        // range(false)
        // 取消双击事件-追踪该位置
        viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

        handler = new Cesium.ScreenSpaceEventHandler(viewer.scene._imageryLayerCollection);
        var positions = [];
        var tempPoints = [];
        var polygon = null;
        var cartesian = null;

        //鼠标移动事件
        handler.setInputAction(function (movement) {
            let ray = viewer.camera.getPickRay(movement.endPosition);
            cartesian = viewer.scene.globe.pick(ray, viewer.scene);
            if (!Cesium.defined(cartesian)) //跳出地球时异常
                return;
            if (positions.length >= 2) {
                if (!Cesium.defined(polygon)) {
                    polygon = new PolygonPrimitive(positions);
                } else {
                    positions.pop();
                    positions.push(cartesian);
                }
            }
        }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

        //鼠标左键点击事件
        handler.setInputAction(function (movement) {
            let ray = viewer.camera.getPickRay(movement.position);
            cartesian = viewer.scene.globe.pick(ray, viewer.scene);
            if (!Cesium.defined(cartesian)) //跳出地球时异常
                return;
            if (positions.length === 0) {
                positions.push(cartesian.clone());
            }
            positions.push(cartesian);
            //在三维场景中添加点
            var cartographic = Cesium.Cartographic.fromCartesian(positions[positions.length - 1]);
            var longitudeString = Cesium.Math.toDegrees(cartographic.longitude);
            var latitudeString = Cesium.Math.toDegrees(cartographic.latitude);
            var heightString = cartographic.height;
            tempPoints.push({ lon: longitudeString, lat: latitudeString, hei: heightString });
            viewer.entities.add({
                name: '_range',
                position: positions[positions.length - 1],
                point: {
                    pixelSize: 4,
                    color: Cesium.Color.RED,
                    outlineColor: Cesium.Color.WHITE,
                    outlineWidth: 2,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                }
            });
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);


        //鼠标左键双击事件
        handler.setInputAction(function (movement) {
            let ray = viewer.camera.getPickRay(movement.position);
            cartesian = viewer.scene.globe.pick(ray, viewer.scene);
            if (!Cesium.defined(cartesian)) //跳出地球时异常
                return;
            if (positions.length === 0) {
                positions.push(cartesian.clone());
            }
            positions.push(cartesian);
            //在三维场景中添加点
            var cartographic = Cesium.Cartographic.fromCartesian(positions[positions.length - 1]);
            var longitudeString = Cesium.Math.toDegrees(cartographic.longitude);
            var latitudeString = Cesium.Math.toDegrees(cartographic.latitude);
            var heightString = cartographic.height;
            tempPoints.push({ lon: longitudeString, lat: latitudeString, hei: heightString });
            viewer.entities.add({
                name: '_range',
                position: positions[positions.length - 1],
                point: {
                    pixelSize: 4,
                    color: Cesium.Color.RED,
                    outlineColor: Cesium.Color.WHITE,
                    outlineWidth: 2,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
                }
            });
            if (handler) {
                handler.destroy();
                handler = null
            }
            setAreaWeather(false)//结束

            var textArea = getArea(tempPoints) + "平方千米";
            viewer.entities.add({
                name: '_range',
                position: positions[positions.length - 1],
                label: {
                    text: textArea,
                    font: '18px sans-serif',
                    fillColor: Cesium.Color.WHITE,
                    showBackground: true,
                    backgroundColor: Cesium.Color.fromCssColorString('rgba(0, 32, 64, 0.8)'),//背景颜色
                    // style: Cesium.LabelStyle.FILL,
                    outlineWidth: 3,
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    horizontalOrigin: Cesium.HorizontalOrigin.LEFT,//水平位置
                    pixelOffset: new Cesium.Cartesian2(0, -10),
                }
            });
        }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

        var radiansPerDegree = Math.PI / 180.0;//角度转化为弧度(rad) 
        var degreesPerRadian = 180.0 / Math.PI;//弧度转化为角度

        //计算多边形面积
        function getArea(points) {

            var res = 0;
            //拆分三角曲面

            for (var i = 0; i < points.length - 2; i++) {
                var j = (i + 1) % points.length;
                var k = (i + 2) % points.length;
                var totalAngle = Angle(points[i], points[j], points[k]);


                var dis_temp1 = distance(positions[i], positions[j]);
                var dis_temp2 = distance(positions[j], positions[k]);
                res += dis_temp1 * dis_temp2 * Math.abs(Math.sin(totalAngle));
            }


            return (res / 1000000.0).toFixed(4);
        }

        /*角度*/
        function Angle(p1, p2, p3) {
            var bearing21 = Bearing(p2, p1);
            var bearing23 = Bearing(p2, p3);
            var angle = bearing21 - bearing23;
            if (angle < 0) {
                angle += 360;
            }
            return angle;
        }
        /*方向*/
        function Bearing(from, to) {
            var lat1 = from.lat * radiansPerDegree;
            var lon1 = from.lon * radiansPerDegree;
            var lat2 = to.lat * radiansPerDegree;
            var lon2 = to.lon * radiansPerDegree;
            var angle = -Math.atan2(Math.sin(lon1 - lon2) * Math.cos(lat2), Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2));
            if (angle < 0) {
                angle += Math.PI * 2.0;
            }
            angle = angle * degreesPerRadian;//角度
            return angle;
        }

        var PolygonPrimitive = (function () {
            function _(positions) {
                this.options = {
                    name: '_range',
                    polygon: {
                        hierarchy: [],
                        // perPositionHeight : true,
                        material: Cesium.Color.GREEN.withAlpha(0.5),
                        // heightReference:20000
                    }
                };

                this.hierarchy = { positions };
                this._init();
            }

            _.prototype._init = function () {
                var _self = this;
                var _update = function () {
                    return _self.hierarchy;
                };
                //实时更新polygon.hierarchy
                this.options.polygon.hierarchy = new Cesium.CallbackProperty(_update, false);
                viewer.entities.add(this.options);
            };

            return _;
        })();

        /*两点间距离*/
        function distance(point1, point2) {
            var point1cartographic = Cesium.Cartographic.fromCartesian(point1);
            var point2cartographic = Cesium.Cartographic.fromCartesian(point2);
            /**根据经纬度计算出距离**/
            var geodesic = new Cesium.EllipsoidGeodesic();
            geodesic.setEndPoints(point1cartographic, point2cartographic);
            var s = geodesic.surfaceDistance;
            //返回两点之间的距离
            s = Math.sqrt(Math.pow(s, 2) + Math.pow(point2cartographic.height - point1cartographic.height, 2));
            return s;
        }
        // } else {
        //     deleteRangeArea()
        //     viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);//停止监听移动事件
        //     viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);//停止监听移动事件
        // }
    }
    function areaHandle() {
        setRangeWeather(false)//关闭测距
        if (areaWeather) {
            deleteRangeArea()
            if (handler) {
                handler.destroy();
                handler = null
            }
        } else {
            area()
        }
        setAreaWeather(v => !v)
    }
    // 重定位
    function resetPostion() {
        const code = userContext.userInfo.countyCode
        if (code) {
            // const data = localStorage.getItem('countyLevel')
            const data = userContext.userInfo.countyLevel
            switch (data) {
                case '管理员':
                    addBoundary(1, code, true, "所在省", '#FA8072')
                    break;
                case '省级':
                    addBoundary(1, code, false, "所在省", '#FA8072')
                    break;
                case '市级':
                    addBoundary(2, code, false, "所在市", '#FA8072')
                    break;
                case '县级':
                    addBoundary(3, code, false, "所在县", '#FA8072')
                    break;
                case '街道级':
                    // 截取前6位
                    // addBoundary(3, code.slice(0, 6), true, "所在街道", '#FA8072')
                    addBoundary(4, code, true, "所在街道", '#FA8072')
                    break;
                default:
            }
        }
    }

    // 注记图层切换
    function zhujiHandle() {
        let zhujiLayer = new Cesium.WebMapTileServiceImageryProvider({
            url: "http://t0.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=5956e6519f2bb0ae8e57bc834298c9f1",
            layer: "tdtImgBasicLayer",
            style: "default",
            format: "image/jpeg",
            tileMatrixSetID: "GoogleMapsCompatible",
            show: false
        })
        zhujiLayer.baseLayerName = 'zhujiLayer'
        if (zhujiWeather) {
            removeLayer('zhujiLayer')
        } else {
            viewer.imageryLayers.addImageryProvider(zhujiLayer);//注记图层
        }
        setZhujiWeather(v => !v)
    }

    useEffect(() => {
        if (viewer) {
            zhujiHandle() //进入页面就添加注记
        }
        return () => {
            if (viewer) {
                // viewer.destroy()
            }
        }
        // eslint-disable-next-line
    }, [viewer])
    return (
        <div className={Style.toolWrapper} id='cesiumToolWrapper'>
            <Tooltip title="重定位">
                <Button type="text" className={Style.locationButton} onClick={() => resetPostion()} icon={<img src={location} alt='' className={Style.toolImg}></img>}></Button>
            </Tooltip>
            <Tooltip title="放大">
                <Button type="text" className={Style.locationButton} onClick={() => viewer.camera.zoomIn(10000)} icon={<img src={zoomLarge} alt='' className={Style.toolImg}></img>}></Button>
            </Tooltip>
            <Tooltip title="缩小">
                <Button type="text" className={Style.locationButton} onClick={() => viewer.camera.zoomOut(10000)} icon={<img src={zoomSmall} alt='' className={Style.toolImg}></img>}></Button>
            </Tooltip>
            <Dropdown overlay={menu1} placement="bottom" arrow>
                <Tooltip title="图层">
                    <Button type="text" className={Style.locationButton} icon={<img src={layer} alt='' className={Style.toolImg}></img>}></Button>
                </Tooltip>
            </Dropdown>
            <Tooltip title="测距">
                <Button type="text" className={Style.locationButton} style={{ backgroundColor: rangeWeather ? 'rgb(76, 130, 177)' : 'transparent' }} onClick={() => rangeHandle()} icon={<img src={rangeWeather ? rangeBlueSvg : rangeSvg} alt='' className={Style.toolImg}></img>}></Button>
            </Tooltip>
            <Tooltip title="测面">
                <Button type="text" className={Style.locationButton} style={{ backgroundColor: areaWeather ? 'rgb(76, 130, 177)' : 'transparent' }} onClick={() => areaHandle()} icon={<img src={areaWeather ? areaBlueSvg : areaSvg} alt='' className={Style.toolImg}></img>}></Button>
            </Tooltip>
            <Tooltip title="清除">
                <Button type="text" className={Style.locationButton} onClick={() => deleteRangeArea()} icon={<img src={removeSvg} alt='' className={Style.toolImg}></img>}></Button>
            </Tooltip>
            <Tooltip title="轨道">
                <Button type="text" className={Style.locationButton}
                    onClick={() => drawOrbitFor(!orbitWeather)}
                    icon={<img src={orbitWeather ? orbitBlue : orbitSvg}
                        alt='' className={Style.toolImg}></img>}></Button>
            </Tooltip>
            <Tooltip title="注记">
                <Button type="text" className={Style.locationButton} style={{ backgroundColor: zhujiWeather ? 'rgb(76, 130, 177)' : 'transparent' }}
                    onClick={() => zhujiHandle()}
                    icon={<img src={zhujiWeather ? zhuji : zhujiBlue}
                        alt='' className={Style.toolImg}></img>}></Button>
            </Tooltip>
            {/* <Tooltip title="轨道">
                <Button type="text" className={Style.locationButton} onClick={() => drawOrbitFors()}
                    icon={<img src={orbitSvg} alt='' className={Style.toolImg}></img>}>
                </Button>
            </Tooltip> */}
        </div>
    )

};
