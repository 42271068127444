import React from 'react';
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Space } from 'antd'

// import { PauseCircleOutlined } from '@ant-design/icons';
import Style from './Main.module.css';
// import MainMap from '../MainMap/MainMap';
// import RightSide from '../RightSide/RightSide';
// import BottomSider from '../BottomSider/BottomSider';
import Header from './Header';
// import CesiumContext from '../../common/CesiumContext';
import RealTimeFire from '../RealTimeFire/RealTimeFire'
import HistoryFire from '../HistoryFire/HistoryFire'
// import UserManager from '../UserManager/UserManager';
// import UserManager from '../RouterCom/RouterCom';
// import usePublic from '../usePublic/usePublic';
// import CesiumTool from '../CesiumTool/CesiumTool'
dayjs.locale('zh-cn')

export default function Main({ setUserInfo, userInfo }) {

    //#region  添加网站水印
    // useEffect(() => {
    //   watermark({ "watermark_txt": "青岛星科瑞升" });
    //   function watermark(settingText) {
    //     //默认设置
    //     var defaultSettings = {
    //       watermark_txt: settingText,
    //       watermark_x: 20, //水印起始位置x轴坐标
    //       watermark_y: 20, //水印起始位置Y轴坐标
    //       watermark_rows: 20, //水印行数
    //       watermark_cols: 20, //水印列数
    //       watermark_x_space: 100, //水印x轴间隔
    //       watermark_y_space: 50, //水印y轴间隔
    //       watermark_color: '#aaa', //水印字体颜色
    //       watermark_alpha: 0.9, //水印透明度
    //       watermark_fontsize: '15px', //水印字体大小
    //       watermark_font: '微软雅黑', //水印字体
    //       watermark_width: 210, //水印宽度
    //       watermark_height: 80, //水印长度
    //       watermark_angle: 20 //水印倾斜度数
    //     };
    //     if (arguments.length === 1 && typeof arguments[0] === "object") {
    //       var src = arguments[0] || {};
    //       for (var key in src) {
    //         if (src[key] && defaultSettings[key] && src[key] === defaultSettings[key]) continue;
    //         else if (src[key]) defaultSettings[key] = src[key];
    //       }
    //     }
    //     var oTemp = document.createDocumentFragment();
    //     //获取页面最大宽度
    //     var page_width = Math.max(document.body.scrollWidth, document.body.clientWidth);
    //     // var cutWidth = page_width * 0.0150;
    //     // var page_width = page_width - cutWidth;
    //     //获取页面最大高度
    //     var page_height = Math.max(document.body.scrollHeight, document.body.clientHeight) + 450;
    //     page_height = Math.max(page_height, window.innerHeight - 30);
    //     //如果将水印列数设置为0，或水印列数设置过大，超过页面最大宽度，则重新计算水印列数和水印x轴间隔
    //     if (defaultSettings.watermark_cols === 0 || (parseInt(defaultSettings.watermark_x + defaultSettings.watermark_width * defaultSettings.watermark_cols + defaultSettings.watermark_x_space * (defaultSettings.watermark_cols - 1)) > page_width)) {
    //       defaultSettings.watermark_cols = parseInt((page_width - defaultSettings.watermark_x + defaultSettings.watermark_x_space) / (defaultSettings.watermark_width + defaultSettings.watermark_x_space));
    //       defaultSettings.watermark_x_space = parseInt((page_width - defaultSettings.watermark_x - defaultSettings.watermark_width * defaultSettings.watermark_cols) / (defaultSettings.watermark_cols - 1));
    //     }
    //     //如果将水印行数设置为0，或水印行数设置过大，超过页面最大长度，则重新计算水印行数和水印y轴间隔
    //     if (defaultSettings.watermark_rows === 0 || (parseInt(defaultSettings.watermark_y + defaultSettings.watermark_height * defaultSettings.watermark_rows + defaultSettings.watermark_y_space * (defaultSettings.watermark_rows - 1)) > page_height)) {
    //       defaultSettings.watermark_rows = parseInt((defaultSettings.watermark_y_space + page_height - defaultSettings.watermark_y) / (defaultSettings.watermark_height + defaultSettings.watermark_y_space));
    //       defaultSettings.watermark_y_space = parseInt(((page_height - defaultSettings.watermark_y) - defaultSettings.watermark_height * defaultSettings.watermark_rows) / (defaultSettings.watermark_rows - 1));
    //     }
    //     var x;
    //     var y;
    //     for (var i = 0; i < defaultSettings.watermark_rows; i++) {
    //       y = defaultSettings.watermark_y + (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i;
    //       for (var j = 0; j < defaultSettings.watermark_cols; j++) {
    //         x = defaultSettings.watermark_x + (defaultSettings.watermark_width + defaultSettings.watermark_x_space) * j;
    //         var mask_div = document.createElement('div');
    //         mask_div.id = 'mask_div' + i + j;
    //         mask_div.className = 'mask_div';
    //         mask_div.appendChild(document.createTextNode(defaultSettings.watermark_txt));
    //         //设置水印div倾斜显示
    //         mask_div.style.webkitTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
    //         mask_div.style.MozTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
    //         mask_div.style.msTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
    //         mask_div.style.OTransform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
    //         mask_div.style.transform = "rotate(-" + defaultSettings.watermark_angle + "deg)";
    //         mask_div.style.visibility = "";
    //         mask_div.style.position = "absolute";
    //         mask_div.style.left = x + 'px';
    //         mask_div.style.top = y + 'px';
    //         mask_div.style.overflow = "hidden";
    //         mask_div.style.zIndex = "9999";
    //         //让水印不遮挡页面的点击事件
    //         mask_div.style.pointerEvents = 'none';
    //         mask_div.style.opacity = defaultSettings.watermark_alpha;
    //         mask_div.style.fontSize = defaultSettings.watermark_fontsize;
    //         mask_div.style.fontFamily = defaultSettings.watermark_font;
    //         mask_div.style.color = defaultSettings.watermark_color;
    //         mask_div.style.textAlign = "center";
    //         mask_div.style.width = defaultSettings.watermark_width + 'px';
    //         mask_div.style.height = defaultSettings.watermark_height + 'px';
    //         mask_div.style.display = "block";
    //         oTemp.appendChild(mask_div);
    //       };
    //     };
    //     document.body.appendChild(oTemp);
    //   }
    // }, [])
    //#endregion


    // const [viewer, setViewer] = useState()
    // const [fireClicked, setfireClicked] = useState(false)
    // const [bottomFireData, setBottomFireData] = useState()
    // 天气情况
    // const [weatherCode, setweatherCode] = useState()
    return (
        // <CesiumContext.Provider value={{
        //     viewer, setViewer,//地图
        //     // fireClicked, setfireClicked,//火点信息卡片打开关闭
        //     // bottomFireData, setBottomFireData, //底部栏信息
        //     // weatherCode, setweatherCode//天气
        // }}>
        <Router basename='/'>
            <div className={Style.mainWrapper}>
                <Header setUserInfo={setUserInfo} />
                {/* <div style={{ width: '100vw', height: '100vh' }}>
                    <MainMap />
                    <RightSide userInfo={userInfo} />
                    <BottomSider />
                    <CesiumTool />
                    <div className={Style.bottomCopyright}>
                        <a href='https://www.star-rising.com' target="_blank" rel="noopener noreferrer" style={{ color: '#94EBFE' }}>Copyright © {new Date().getFullYear()} 青岛星科瑞升信息科技有限公司 版权所有</a>
                    </div>
                </div> */}
                <Switch>
                    {/* 历史火情 */}
                    <Route path="/HistoryFire" component={HistoryFire} />
                    {/* 实时火情 */}
                    <Route path="/" component={RealTimeFire} />
                </Switch>

                <div className={Style.LoginFooter}>
                    <Space>
                        <a href='https://www.star-rising.com' target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Copyright © {new Date().getFullYear()} 青岛星科瑞升信息科技有限公司 版权所有</a>
                    </Space>
                </div>
            </div>
        </Router>
        // </CesiumContext.Provider>
    );
}
