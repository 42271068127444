// 卫星影像数据管理地图
import React, { useEffect, useContext, useState } from 'react';
import * as Cesium from 'cesium';
// import Cookies from 'universal-cookie';
import { parse } from 'wellknown'//stringify：geojson转wkt  //parse：wkt转geojson

// import CesiumContext from '../../common/CesiumContext'
import usePublic from '../usePublic/usePublic'
// import FireInfoCard from '../FireInfoCard/FireInfoCard'
import Style from './MainMap.module.css'
import UserContext from '../../common/UserContext'
import FireClick from '../FireClick/FireClick'
import Axios from '../../common/Axios';
// let viewer;
// const cookies = new Cookies();

export default function MainMap({ setViewer }) {
    const userContext = useContext(UserContext)
    // const cesiumContext = useContext(CesiumContext);
    const [viewer, setviewer] = useState()
    const { deleteEntityByName } = usePublic({ viewer })
    // 天地图
    // let basemap = new Cesium.WebMapTileServiceImageryProvider({
    //     url:
    //         'http://{s}.tianditu.gov.cn/img_c/wmts?service=wmts&request=GetTile&version=1.0.0' +
    //         '&LAYER=img&tileMatrixSet=c&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}' +
    //         '&style=default&format=tiles&tk=31091965e263284a501a723b8909f405',
    //     layer: 'tdtCva',
    //     style: 'default',
    //     format: 'tiles',
    //     tileMatrixSetID: 'c',
    //     subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
    //     tilingScheme: new Cesium.GeographicTilingScheme(),
    //     tileMatrixLabels: [
    //         '1',
    //         '2',
    //         '3',
    //         '4',
    //         '5',
    //         '6',
    //         '7',
    //         '8',
    //         '9',
    //         '10',
    //         '11',
    //         '12',
    //         '13',
    //         '14',
    //         '15',
    //         '16',
    //         '17',
    //         '18',
    //         '19'
    //     ],
    //     maximumLevel: 18
    // })

    // arcgis
    let basemap = new Cesium.ArcGisMapServerImageryProvider({
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
        tileMatrixSetID: "GoogleMapsCompatible",
        style: "default",
        layer: "tdtImgAnnoLayer",
    });
    // let basemap = new Cesium.ArcGisMapServerImageryProvider({
    //     url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
    //     tileMatrixSetID: "GoogleMapsCompatible",
    //     style: "default",
    //     layer: "tdtImgAnnoLayer",
    // });
    // let basemap = new Cesium.UrlTemplateImageryProvider({
    //     url: 'https://webst02.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}',
    //     tileMatrixSetID: "GoogleMapsCompatible",
    //     style: "default",
    //     layer: "tdtImgAnnoLayer",
    // })
    basemap.baseLayerName = 'baseLayerNation'

    // 初始化地图
    useEffect(
        () => {
            Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiYzcwZmJmNi02MzQ5LTQ1MGEtODgzMy0yZTZiZGExY2MzMGMiLCJpZCI6MTAxOTgsInNjb3BlcyI6WyJhc2wiLCJhc3IiLCJnYyJdLCJpYXQiOjE1ODEzMDgxNTR9.NeJU4yfUi-SnLrNDOBYsQgmiSh3B4axCTi1m84Qw99c';
            let viewerr = new Cesium.Viewer('cesiumContainer', {
                baseLayerPicker: false,
                shouldAnimate: true,//自动播放
                infoBox: false,//信息框
                animation: false,//“动画”窗口小部件提供用于播放，暂停和倒退的按钮，以及当前时间和日期，并由“飞梭环”包围，用于控制动画的速度。cesium示例地图左下角那个
                timeline: false,//时间轴是用于显示和控制当前场景时间的小部件。
                geocoder: false,//查找，用于查找地址和地标，以及将照相机悬挂到它们。使用铯离子进行地址解析。,cesium示例地图右上角的放大镜
                homeButton: false,//主页按钮
                navigationHelpButton: false,//导航帮助按钮
                selectionIndicator: false,//原生选择绿框
                fullscreenButton: false,//右下角的全屏按钮
                terrainProvider: Cesium.createWorldTerrain(),
                // terrainProvider: Cesium.EllipsoidTerrainProvider({}),//关闭地形
                sceneModePicker: false, //是否显示投影方式控件
                // contextOptions: {
                //     webgl: {
                //         alpha: true,
                //     }
                // },
                imageryProvider: basemap,
                // terrainProvider: new Cesium.CesiumTerrainProvider({
                //     url: 'https://tiles1.geovisearth.com/base/v1/terrain?token=0a83e9d93378d3d3e5273f0f5402ef30ea12b9d7595dddff3a2ada74b5d069e2',
                // }),
            });

            setviewer(viewerr)
            // 添加注记图层
            let zhujiLayer = new Cesium.WebMapTileServiceImageryProvider({
                url: "http://t0.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=5956e6519f2bb0ae8e57bc834298c9f1",
                layer: "tdtImgBasicLayer",
                style: "default",
                format: "image/jpeg",
                tileMatrixSetID: "GoogleMapsCompatible",
                show: false
            })
            zhujiLayer.baseLayerName = 'zhujiLayer'
            //注记图层
            // viewer.imageryLayers.addImageryProvider(zhujiLayer);//注记图层

            // cesiumContext.setViewer(viewer)
            if (setViewer) {
                setViewer(viewerr)
            }
            // 关闭双击entity，该事件会放大视图，entity居中显示，且鼠标左键失去移动功能，鼠标滚轮失去作用
            viewerr.screenSpaceEventHandler.setInputAction(function () { }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

            // 高程遮挡属性，设置该属性为true之后，标绘将位于地形的顶部；如果设为false（默认值），那么标绘将位于平面上。
            // 缺陷：开启该属性有可能在切换图层时会引发标绘消失的bug。
            viewerr.scene.globe.depthTestAgainstTerrain = false;

            // 解决天地图过暗
            viewerr.scene.highDynamicRange = false;
            // 去除版权信息
            viewerr.cesiumWidget.creditContainer.style.display = "none";

            // 最小缩放高度 米
            viewerr.scene.screenSpaceCameraController.minimumZoomDistance = 600;
            // viewer.scene.screenSpaceCameraController.minimumZoomDistance = 150;//相机的高度的最小值
            // viewer.scene.screenSpaceCameraController.maximumZoomDistance = 6000000; //相机高度的最大值
            // eslint-disable-next-line react-hooks/exhaustive-deps
            let handler = new Cesium.ScreenSpaceEventHandler(viewerr.scene._imageryLayerCollection);
            handler.setInputAction(function (movement) {
                let ellipsoid = viewerr.scene.globe.ellipsoid;//得到当前三维场景的椭球体
                let cartesian_two = viewerr.camera.pickEllipsoid(movement.endPosition, ellipsoid);//当前世界坐标笛卡尔积
                if (cartesian_two) {
                    //获取实时经纬度
                    let cartographic_two = ellipsoid.cartesianToCartographic(cartesian_two);//将笛卡尔坐标转换为地理坐标
                    //将弧度转为度的十进制度表示
                    let longitudeString_two = Cesium.Math.toDegrees(cartographic_two.longitude);
                    let latitudeString_two = Cesium.Math.toDegrees(cartographic_two.latitude);

                    // 东西经，南北纬
                    let jing = 'W'
                    let wei = 'S'
                    if (longitudeString_two > 0) {
                        jing = 'E'
                    }
                    if (latitudeString_two > 0) {
                        wei = 'N'
                    }
                    let address_lon = document.getElementById("address_lon")
                    let address_lat = document.getElementById("address_lat")
                    if (address_lon && address_lat) {
                        address_lon.innerHTML = `${longitudeString_two.toFixed(4)} °${jing}`;
                        address_lat.innerHTML = `${latitudeString_two.toFixed(4)}  °${wei}`;
                        address_lon.style.marginLeft = '2em'
                        address_lat.style.marginRight = '2em'
                    }
                }
            }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
            // lisenClick()
            // eslint-disable-next-line
        }, []
    )

    // useEffect(() => {
    //     if (addBoundary) {
    //         postion()
    //     }
    //     // eslint-disable-next-line
    // }, [addBoundary])

    // function postion() {
    //     // const code = localStorage.getItem('countyCode')
    //     const code = userContext.userInfo.countyCode
    //     if (code) {
    //         // const data = localStorage.getItem('countyLevel')
    //         const data = userContext.userInfo.countyLevel
    //         switch (data) {
    //             case '管理员':
    //                 addBoundary(1, code, true, "所在省", '#FA8072')
    //                 break;
    //             case '省级':
    //                 addBoundary(1, code, true, "所在省", '#FA8072')
    //                 break;
    //             case '市级':
    //                 addBoundary(2, code, true, "所在市", '#FA8072')
    //                 break;
    //             case '县级':
    //                 addBoundary(3, code, true, "所在县", '#FA8072')
    //                 break;
    //             case '街道级':
    //                 // 截取前6位
    //                 // addBoundary(3, code.slice(0, 6), true, "所在街道", '#FA8072')
    //                 addBoundary(4, code, true, "所在街道", '#FA8072')
    //                 break;
    //             default:
    //         }
    //     }
    // }

    // const [fireClicked, setFireClicked] = useState(false)//fireCard是否出现
    // const [firedatas, setFiredatas] = useState([]);//fireCard内容

    // // 火苗点击事件
    // useEffect(() => {
    //     if (viewer) {
    //         var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    //         handler.setInputAction(function (movement) {
    //             //点击实体
    //             let pick = viewer.scene.pick(movement.position);
    //             if (Cesium.defined(pick) && pick && pick.id) {
    //                 // 由于添加了聚合的原因，有点被聚合后，点击会获得多个信息，判断一下如果被聚合，则显示第一个火点的信息
    //                 if (pick.id instanceof Array) {//数组
    //                     // 找出一个是fireInfo的火点
    //                     // const index = pick.id.find(item => item._name && item._name.indexOf('fireInfo') > -1)
    //                     // getFireInfo(index)
    //                     // 筛选出是fireInfo的火点
    //                     const index = pick.id.filter(item => item._name && item._name.indexOf('fireInfo') > -1)
    //                     getFireInfo(index)
    //                 } else {
    //                     //判断当前点击是否为firePoint
    //                     if (pick.id._name && pick.id._name.indexOf('fireInfo') > -1) {
    //                         // 根据火点编码获取火点信息
    //                         getFireInfo([pick.id])
    //                     } else {
    //                         setFireClicked(false)
    //                         addListens(false)
    //                     }
    //                 }
    //             } else {
    //                 setFireClicked(false)
    //                 addListens(false)
    //             }
    //         }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    //     }
    //     return () => {
    //         !handler || handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [viewer])

    // function lisenClick(){
    //     if (viewer) {
    //         var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    //         handler.setInputAction(function (movement) {
    //             //点击实体
    //             let pick = viewer.scene.pick(movement.position);
    //             if (Cesium.defined(pick) && pick && pick.id) {
    //                 // 由于添加了聚合的原因，有点被聚合后，点击会获得多个信息，判断一下如果被聚合，则显示第一个火点的信息
    //                 if (pick.id instanceof Array) {//数组
    //                     // 找出一个是fireInfo的火点
    //                     // const index = pick.id.find(item => item._name && item._name.indexOf('fireInfo') > -1)
    //                     // getFireInfo(index)
    //                     // 筛选出是fireInfo的火点
    //                     const index = pick.id.filter(item => item._name && item._name.indexOf('fireInfo') > -1)
    //                     getFireInfo(index)
    //                 } else {
    //                     //判断当前点击是否为firePoint
    //                     if (pick.id._name && pick.id._name.indexOf('fireInfo') > -1) {
    //                         // 根据火点编码获取火点信息
    //                         getFireInfo([pick.id])
    //                     } else {
    //                         setFireClicked(false)
    //                         addListens(false)
    //                     }
    //                 }
    //             } else {
    //                 setFireClicked(false)
    //                 addListens(false)
    //             }
    //         }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    //     }
    // }
    // // 根据火点编码获取火点信息
    // function getFireInfo(value) {
    //     // setFiredatas(value._fireInfo)
    //     setFiredatas(value.map(item => item._fireInfo))
    //     setFireClicked(true)
    //     addListens(value['0'])
    //     // Axios({
    //     //     method: 'get',
    //     //     url: '/selectFirePointByCode',
    //     //     params: {
    //     //         fireCode: value._fireInfo.fireCode,
    //     //     },
    //     // }).then(req => {
    //     //     if (req.data.status === 0) {
    //     //         setFiredatas(req.data.data)
    //     //         setFireClicked(true)
    //     //         addListens(value)
    //     //     } else {
    //     //         message.error('获取火点信息bycode:', req.data.msg)
    //     //     }
    //     // }).catch(err => {
    //     //     console.error(err)
    //     //     message.error('网络错误')
    //     // })
    // }
    // const addListens = useCallback((value) => {
    //     if (value && value._fireInfo) {
    //         let htmlOverlay = document.getElementById("fireInfoCard");
    //         const a = function () {
    //             var position = Cesium.Cartesian3.fromDegrees(Number(value._fireInfo.longitude), Number(value._fireInfo.latitude));
    //             var canvasPosition = viewer.scene.cartesianToCanvasCoordinates(position);
    //             if (Cesium.defined(canvasPosition)) {
    //                 htmlOverlay.style.top = canvasPosition.y - 265 + 'px';
    //                 htmlOverlay.style.left = canvasPosition.x + 20 + 'px';
    //             }
    //         }
    //         viewer.scene.preRender.addEventListener(a, 'poplisten');
    //     } else {
    //         for (let i = 0; i < viewer.scene.preRender._listeners.length; i++) {
    //             let a = viewer.scene.preRender._listeners[i]
    //             viewer.scene.preRender.removeEventListener(a, 'poplisten')
    //             i--
    //         }
    //     }
    // }, [viewer])

    useEffect(() => {
        let userInfo = userContext.userInfo
        // 山东省，省级账号
        if (viewer && userInfo.countyCode === '370000') {
            cameraChange()
        } else {
            deleteEntityByName('xuxian')//删除历史火点查询select产生的实体
        }
        // eslint-disable-next-line
    }, [viewer])

    // 屏幕移动，若当前处于山东历史影像，则更改当前级别的山东历史影像
    function cameraChange() {
        /* 三维球转动添加监听事件 */
        // viewer.camera.changed.addEventListener(function (percentage) {
        viewer.scene.camera.moveEnd.addEventListener(function () {
            // 当前级别
            // const level = getZoomLevel(viewer.camera.positionCartographic.height)
            const level = viewer.camera.positionCartographic.height
            // 当前屏幕中心经纬度
            const lon = weather()
            // 获取图层信息
            getInfo(lon.lon, lon.lat, level)
        });
    }
    // 获取屏幕中心点坐标
    const weather = () => {
        if (viewer) {
            //获取或设置相机。
            // viewer.scene.camera.moveEnd.addEventListener(function () {
            let centerResult = viewer.camera.pickEllipsoid(
                new Cesium.Cartesian2(
                    viewer.canvas.clientWidth / 2,
                    viewer.canvas.clientHeight / 2
                )
            )
            let curPosition = Cesium.Ellipsoid.WGS84.cartesianToCartographic(centerResult)
            let curLongitude = (curPosition.longitude * 180) / Math.PI
            let curLatitude = (curPosition.latitude * 180) / Math.PI
            const lonlat = {
                lon: curLongitude,
                lat: curLatitude
            }
            return lonlat
            // })
        }
    }
    // 获取图层信息
    function getInfo(lon, lat, scale) {
        Axios({
            url: '/queryVectorBoundary',
            method: 'get',
            params: {
                lon: lon,
                lat: lat,
                scale: scale,
            },
        }).then(res => {
            deleteEntityByName('xuxian')//删除历史火点查询select产生的实体
            let myAdminCode = document.getElementById("myAdminCode")
            if (myAdminCode) {
                myAdminCode.style.display='none'
            }

            if (res.data.status === 'ok') {
                if (res.data.result.geometry) {
                    // wkt转geojson
                    const POLYGON = parse(res.data.result.geometry)
                    let datda = []
                    switch (POLYGON.type) {
                        case 'Polygon':
                            POLYGON.coordinates['0'].forEach(item => {
                                datda.push(item['0'])
                                datda.push(item['1'])
                            })
                            break;
                        case 'MultiPolygon':
                            POLYGON.coordinates['0'].forEach(item => {
                                item.forEach(it => {
                                    datda.push(it['0'])
                                    datda.push(it['1'])
                                })
                            })
                            break
                        default:
                    }

                    if (myAdminCode) {
                        myAdminCode.innerHTML = `${res.data.result.data}`;
                        myAdminCode.style.display='block'
                    }
                    // 添加边界
                    viewer.entities.add({
                        name: 'xuxian',
                        polyline: {
                            positions: Cesium.Cartesian3.fromDegreesArray(datda),
                            width: 2,
                            material: new Cesium.PolylineDashMaterialProperty({//虚线
                                // material: new Cesium.PolylineGlowMaterialProperty({//光晕
                                glowPower: 0.2,
                                color: Cesium.Color.fromCssColorString('#1E90FF'),//#FA8072
                            }),
                            // followSurface: false, //是否贴着地表
                            clampToGround: true,//贴地
                        },
                    })
                }

            }
        }).catch(err => { })
    }

    // function getZoomLevel(h) {
    //     if (h <= 100) { return 19 }
    //     else if (h <= 300) { return 18 }
    //     else if (h <= 660) { return 17 }
    //     else if (h <= 1300) { return 16 }
    //     else if (h <= 2600) { return 15 }
    //     else if (h <= 6400) { return 14 }
    //     else if (h <= 13200) { return 13 }
    //     else if (h <= 26000) { return 12 }
    //     else if (h <= 67985) { return 11 }
    //     else if (h <= 139780) { return 10 }
    //     else if (h <= 250600) { return 9 }
    //     else if (h <= 380000) { return 8 }
    //     else if (h <= 640000) { return 7 }
    //     else if (h <= 1280000) { return 6 }
    //     else if (h <= 2600000) { return 5 }
    //     else if (h <= 6100000) { return 4 }
    //     else if (h <= 11900000) { return 3 }
    //     else { return 2 }
    // }


    return (
        <div style={{ height: '100%' }} id='mainCesium'>
            <div id="cesiumContainer" style={{ height: '100%', width: '100%' }}></div>
            <div className={Style.addressBox}>
                <div id='address_lon' className={Style.address_lon}></div>
                <div id='address_lat' className={Style.address_lat}></div>
            </div>
            <div id='myAdminCode' className={Style.myAdminCodeWra}></div>
            {/* {fireClicked ? <FireInfoCard firedatas={firedatas} setFireClicked={setFireClicked} /> : null} */}
            <FireClick viewer={viewer} />
        </div>
    );
}

