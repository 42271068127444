import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import LoginControl from './components/Login/LoginControl'

import * as serviceWorker from './serviceWorker';
moment.locale('zh-cn')

ReactDOM.render(
    //   <ConfigProvider locale={zhCN}>
    //     <App />
    //   </ConfigProvider>,
    <ConfigProvider locale={zhCN}>
        <LoginControl />
    </ConfigProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
