// 按时间统计
import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts';
import dayjs from 'dayjs';

export default function Time({ dataSource, unit }) {
    const timeEcharts = useRef()
    useEffect(() => {
        console.log(dataSource,'天龙')
        if (dataSource && dataSource.length > 0) {
            console.log(dataSource)
            // if (true) {
            // let myChart = echarts.init(document.getElementById('zhuMy'))
            let ThreeEcharts = timeEcharts.current
            if (ThreeEcharts) {
                const color = ['#00ffff', '#006ced', '#ffe000', '#ffa800', '#00cfff', '#ff5b00', '#ff3000']
                const newchartdata = sortArr(dataSource, 'landType')
                // const xAxis = Object.keys(newchartdata)
                // const seriesData = Object.values(newchartdata)
                const xAxis = []
                const seriesData = []

                let i = 0
                for (const key in newchartdata) {
                    xAxis.push(key)
                    seriesData.push({
                        value: newchartdata[key].length,
                        itemStyle: {
                            borderColor: color[i],
                            shadowColor: color[i],
                            color: color[i],
                        },
                        label: {
                            color: color[i],
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            textStyle: { //数值样式
                                fontSize: 16
                            }
                        }
                    })
                    if (i < color.length) {
                        i++
                    } else {
                        i = 0
                    }
                }

                const option = {
                    xAxis: {
                        type: 'category',
                        data: xAxis,
                        axisLabel: {
                            show: true,
                            color: '#fff',
                            interval: 0,
                            rotate: 50,
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: '#fff',//左边线的颜色
                                width: '1'//坐标线的宽度
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        show: false
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    series: [{
                        data: seriesData,
                        type: 'bar',
                        barWidth: 15,
                        label: {
                            formatter: function (params) {
                                return params.value + '处'
                            },
                        }
                    }]
                };
                const ThreeChart = echarts.init(ThreeEcharts)
                ThreeChart.setOption(option)
                window.addEventListener("resize", () => {
                    ThreeChart.resize();
                });
            }
        }
        // eslint-disable-next-line
    }, [dataSource])
        // 数组，相同值的key
        function sortArr(arr, str) {
            const newArray = {}
            arr.forEach(item => {
                newArray[item[str]] = newArray[item[str]] ? newArray[item[str]] : []
                newArray[item[str]].push(item)
            })
            return newArray;
        }

    return (
        <div ref={timeEcharts} style={{ width: '100%', height: "100%" }}></div>
    )
}