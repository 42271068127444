// 卫星影像数据管理地图
import React, { useEffect, useCallback, useState } from 'react';
import * as Cesium from 'cesium';
// import Cookies from 'universal-cookie';

// import CesiumContext from '../../common/CesiumContext'
// import usePublic from '../usePublic/usePublic'
import FireInfoCard from '../FireInfoCard/FireInfoCard'
// import UserContext from '../../common/UserContext'

// const cookies = new Cookies();
let lisen = null
export default function FireClick({ viewer }) {

    const [fireClicked, setFireClicked] = useState(false)//fireCard是否出现
    const [firedatas, setFiredatas] = useState([]);//fireCard内容

    // // 火苗点击事件
    useEffect(() => {
        if (viewer) {
            var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            handler.setInputAction(function (movement) {
                //点击实体
                let pick = viewer.scene.pick(movement.position);
                if (Cesium.defined(pick) && pick && pick.id) {
                    // 由于添加了聚合的原因，有点被聚合后，点击会获得多个信息，判断一下如果被聚合，则显示第一个火点的信息
                    if (pick.id instanceof Array) {//数组
                        // 找出一个是fireInfo的火点
                        // const index = pick.id.find(item => item._name && item._name.indexOf('fireInfo') > -1)
                        // getFireInfo(index)
                        // 筛选出是fireInfo的火点
                        const index = pick.id.filter(item => item._name && item._name.indexOf('fireInfo') > -1)
                        getFireInfo(index)
                    } else {
                        //判断当前点击是否为firePoint
                        if (pick.id._name && pick.id._name.indexOf('fireInfo') > -1) {
                            // 根据火点编码获取火点信息
                            getFireInfo([pick.id])
                        } else {
                            setFireClicked(false)
                            addListens(false)
                        }
                    }
                } else {
                    setFireClicked(false)
                    addListens(false)
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        }
        return () => {
            !handler || handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)
            if (viewer) {
                !lisen || viewer.scene.preRender.removeEventListener(lisen, 'poplisten')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewer])

    // 根据火点编码获取火点信息
    function getFireInfo(value) {
        // setFiredatas(value._fireInfo)
        setFiredatas(value.map(item => item._fireInfo))
        setFireClicked(true)
        addListens(value['0'])

    }
    const addListens = useCallback((value) => {
        if (value && value._fireInfo) {
            let htmlOverlay = document.getElementById("fireInfoCard");
            lisen = function () {
                var position = Cesium.Cartesian3.fromDegrees(Number(value._fireInfo.longitude), Number(value._fireInfo.latitude));
                var canvasPosition = viewer.scene.cartesianToCanvasCoordinates(position);
                if (Cesium.defined(canvasPosition)) {
                    // htmlOverlay.style.top = canvasPosition.y - 265 + 'px';
                    // htmlOverlay.style.left = canvasPosition.x + 20 + 'px';
                    let width = document.body.offsetWidth
                    let x = 20
                    let y = 265
                    if (canvasPosition.y < 256) {
                        y = 0
                    } else {
                        y = -265
                    }
                    if (canvasPosition.x + 500 >= width) {
                        x = -490
                    } else {
                        x = 20
                    }
                  
                    htmlOverlay.style.top = canvasPosition.y + y + 'px';
                    htmlOverlay.style.left = canvasPosition.x + x + 'px';
                }
            }
            viewer.scene.preRender.addEventListener(lisen, 'poplisten');
        } else {
            for (let i = 0; i < viewer.scene.preRender._listeners.length; i++) {
                let a = viewer.scene.preRender._listeners[i]
                if (lisen) {
                    viewer.scene.preRender.removeEventListener(lisen, 'poplisten')
                    viewer.scene.preRender.removeEventListener(a, 'poplisten')
                }
                i--
            }
        }
    }, [viewer])
    function close() {
        if (lisen) {
            viewer.scene.preRender.removeEventListener(lisen, 'poplisten')
        }
    }



    return (
        <>
            {fireClicked ? <FireInfoCard firedatas={firedatas} setFireClicked={setFireClicked} closeListen={close} /> : null}
        </>
    );
}

