import React, { useEffect, useState, useContext } from 'react';

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';
import { BrowserRouter as Router, NavLink, useHistory } from "react-router-dom";

// import UserContext from '../../../common/UserContext';
import UserManager from './UserManager/UserManager'
// import FirePointAudit from './FirePointAudit/FirePointAudit'//火点审核
import NationalFirePoint from './NationalFirePoint/NationalFirePoint'//全国火点

import signout from '../Main/image/signout.png'
import myself from '../Main/image/myself.svg'
import Style from './RouterCom.module.css';
import UserContext from '../../common/UserContext'

import { Popover } from 'antd'

// import logo from '../Main/image/logo.png'
import Cookies from 'universal-cookie';
dayjs.locale('zh-cn')
const cookies = new Cookies();

export default function RouterCom({ setUserInfo }) {
    const userContext = useContext(UserContext)
    const history = useHistory()
    // 右上角时间
    // const [timeSurface, setTimeSurface] = useState(dayjs(new Date()).format('MM月DD日 hh:mm:ss'))
    // useEffect(() => {
    //     /* 定时器 间隔 */
    //     let time = setInterval(() => {
    //         setTimeSurface(dayjs(new Date()).format('MM月DD日 HH:mm:ss'))
    //     }, 1000);//1S请求一次
    //     return () => clearInterval(time)
    // }, [])
    const content = <div>
        <p className={Style.headerUserTitle1}>地区：{userContext.userInfo.info.countyName}</p>
        <span className={Style.headerUserTitle1}>账号有效期:{userContext.userInfo.info.overTime}</span>
    </div>

    const lingArry = [
        {
            title: '用户管理',
            url: '/',
            com: <UserManager />
        },
        // {
        //     title: '火点审核',
        //     url: '/FirePointAudit',
        //     com: <FirePointAudit />
        // },
        {
            title: '全国火点',
            url: '/NationalFirePoint',
            com: <NationalFirePoint />
        }
    ]

    const [selectLink, setSelectLink] = useState('/')//选中的
    useEffect(() => {
        const path = window.location.pathname.split('/').pop()
        if (lingArry.map(item => item.url).findIndex(item => item === '/' + path) > -1) {
            setSelectLink('/' + path)
        } else {
            setSelectLink('/')
        }
        // eslint-disable-next-line
    }, [])



    // 去前台
    function goHome() {
        window.location.href = '/'
        history.push('/')
    }

    return (
        <Router basename='/admin'>
            <div className={Style.main}>
                <div className={Style.headerWrapper}>
                    <div className={Style.headerTitleWrapper}>
                        {/* <a href='https://www.star-rising.com' target="_blank" rel="noopener noreferrer" >
                            <img src={logo} alt=''></img>
                        </a> */}
                        <span className={Style.headerTitle}> 全国火情播报系统</span>
                        {/* <span className={Style.headerTitle}>星科瑞升多源卫星全国火情监测系统软件</span> */}
                    </div>
                    <div className={Style.headerLinkStyle} >
                        {
                            lingArry.map((item, index) => <div key={index} onClick={() => setSelectLink(item.url)}
                                className={item.url === selectLink ? Style.selectLink : Style.selectLink_no}>
                                <NavLink to={item.url}>{item.title}</NavLink>
                            </div>)
                        }
                    </div>
                    <div className={Style.headerRight}>
                        {/* <span className={Style.headerTime}>{timeSurface}</span> */}
                        <div style={{ display: "flex" }}>
                            <img alt='' src={myself} className={Style.userIcon} />
                            <Popover placement="bottom" title={null} content={content} trigger="hover"  >
                                <span className={Style.headerUserTitle}>{userContext.userInfo.userName}</span>
                            </Popover>
                        </div>
                        <div onClick={goHome} className={Style.headerUserTitle} style={{ cursor: 'pointer' }}>去前台</div>
                        <img alt='' src={signout}
                            onClick={() => {
                                // localStorage.setItem('token', '');
                                // cookies.set('token', '');
                                // setUserInfo({});
                                // window.location.href = '/'

                                cookies.set('userName', '',);
                                cookies.set('auths', '',);
                                cookies.set('token', '',);
                                cookies.set('countyLevel', '',);
                                history.push('/')
                                userContext.setUserInfo({})
                            }}
                            className={Style.signoutIcon} />
                    </div>
                </div>
                <div>
                    {
                        lingArry[lingArry.findIndex(item => item.url === selectLink)].com
                    }
                </div>
            </div>
        </Router>
    );
}
