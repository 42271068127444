// RetrievePassword 找回密码
import React, { useState } from 'react'
import { Button, Form, Input, Steps, message } from 'antd'
// import axios from 'axios'
import { Link } from "react-router-dom"
import Style from './RetrievePassword.module.css'
import xiugaiSuccess from './image/xiugaiSuccess1.png'
import axios from 'axios'
import backVideo from './image/backVideoR.mp4'
import Rlogo from './image/logo.png'
let { getNationalFireAdress } = window.config;

export default function RetrievePassword() {
    let timer = null//计时器

    const { Step } = Steps;
    const [current, setCurrent] = useState(0);
    const [rememberIphone, setRememberIphone] = useState('');


    // 复杂密码正则校验
    function validatePassword(rule, value) {   //密码校验
        let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
        if (value) {
            if (value.length < 6 || value.length > 16) {
                return Promise.reject('密码长度错误！');
            }
            else {
                if (regex.test(value)) {
                    return Promise.resolve();
                }
                else {
                    return Promise.reject('密码必须是大小写字母或数字，至少包含两种');
                }
            }
        }
        else {
            return Promise.resolve();
        }
    }
    const [getCode, setGetCode] = useState(1);//是否获取动态码////1获取，2重新获取，3倒计时
    const [getCodeTime, setGetCodeTime] = useState(60);//？秒后重新获取动态码
    const [form] = Form.useForm()

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 24 },
    };
    function stepReturn(id) {
        switch (id) {
            case 0://手机验证
                return <div className={Style.caseWrapper}>
                    <Form onFinish={iphoneCheck} form={form} className={Style.formStyle}>
                        <Form.Item name='iphone' label='手机号' rules={[{ required: true, message: '请输入手机号!' }]}>
                            <Input className={Style.formInput}></Input>
                        </Form.Item>
                        {/* <Form.Item name='code' label='验证码' rules={[{ required: true, message: '请输入验证码!' }]}><Input></Input></Form.Item> */}
                        <Form.Item
                            name='verificationCode'
                            label='验证码'
                            rules={[
                                {
                                    required: true,
                                    message: '填写验证码!',
                                }
                            ]}
                        >
                            <Input placeholder="验证码"
                                className={Style.formInput}
                                addonAfter={
                                    //1获取，2重新获取，3倒计时
                                    getCode === 3 ?
                                        <p className={Style.getDynamicCodeNutton} >{getCodeTime}s后重新获取</p>
                                        :
                                        <p onClick={getDynamicCode} className={Style.getDynamicCodeNutton}>
                                            {getCode === 1 ?
                                                "获取验证码" : "重新获取"
                                            }
                                        </p>
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ textAlign: 'center' }}
                        > <Button type='primary' htmlType='submit'>确定</Button></Form.Item>
                    </Form>
                    <p className={Style.yesMima}>想起密码了 <Link to='/'>去登陆</Link></p>

                </div>
            case 1://修改密码
                return <div className={Style.caseWrapper}>
                    <Form onFinish={passwordChange} className={Style.formStyle} {...layout}>
                        <Form.Item
                            name="newPassword"
                            label="密码"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入密码!',
                                },
                                {
                                    validator: validatePassword,
                                }
                            ]}
                            hasFeedback
                        >
                            <Input.Password className={Style.formInput} placeholder="密码(6-16位，大小写字母或数字，至少包含两种)" />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            dependencies={['password']}
                            label='确认密码'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: '请再次输入密码!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('两次输入的密码不一致!');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password className={Style.formInput} placeholder="确认密码" />
                        </Form.Item>
                        <Form.Item
                            style={{ textAlign: 'center' }}
                        > <Button type='primary' htmlType='submit'>确定</Button></Form.Item>
                    </Form>
                    <p className={Style.yesMima}>想起密码了 <Link to='/'>去登陆</Link></p>
                </div>
            case 2://修改成功
                return <div>
                    <img src={xiugaiSuccess} alt='' className={Style.successImg}></img>
                    <p className={Style.yesMima}>修改成功 <Link to='/'>去登陆</Link></p>
                </div>
            default:
                break;
        }
    }


    // 获取动态码验证码
    function getDynamicCode() {
        const phone = form.getFieldValue('iphone')
        if (phone) {
            setGetCode(3);//1获取，2重新获取，3倒计时
            axios({
                method: 'get',
                url: getNationalFireAdress() + '/api/user/verificationCodeUpdate',
                params: { phone: phone }
            }).then(res => {
                if (res.data.status === 0) {
                    // 计时器
                    timer = setInterval(() => {
                        setGetCodeTime(v => {
                            if (v > 0) {
                                return v - 1;
                            } else {
                                clearInterval(timer);
                                setGetCode(2);//1获取，2重新获取，3倒计时
                                return 60;
                            }
                        });
                    }, 1000)//毫秒，1秒=1000毫秒
                } else {//其他等手机号错误的情况，更改为获取
                    message.error(res.data.data)
                    setGetCode(1);//1获取，2重新获取，3倒计时
                }
            }).catch(err => {
                console.log(err)
                message.error('网络错误')
            })

        } else {
            message.error('请先输入手机号')
        }
    }

    // 检查校验码
    function iphoneCheck(values) {
        // 
        axios({
            method: 'get',
            url: getNationalFireAdress() + '/api/user/getVerificationCode',
            params: {
                phone: values.iphone,
                verificationCode: values.verificationCode,
            }
        }).then(res => {
            if (res.data.status === 0) {
                // 记住手机号
                setRememberIphone(values.iphone)
                setCurrent(1)
                clearInterval(timer);
                setGetCode(2);//1获取，2重新获取，3倒计时

            } else {
                message.error(res.data.msg)
            }
        }).catch(err => {
            console.log(err)
            message.error('网络错误')
        })
    }

    // 修改密码
    function passwordChange(values) {
        axios({
            method: 'post',
            url: getNationalFireAdress() + '/api/user/userUnRememberPassword',
            data: {
                newPassword: values.newPassword,
                confirmPassword: values.confirmPassword,
                phone: rememberIphone
            }
        }).then(res => {
            if (res.data.status === 0) {
                setCurrent(2)
            } else {
                message.error(res.data.msg)
            }
        }).catch(err => {
            console.log(err)
            message.error('网络错误')
        })

    }


    return (
        <div className={Style.RetrievePasswordWrapper}>
            <video src={backVideo}
                id='backVideo'
                className={Style.videoWrappermy}
                autoPlay="autoplay"
                loop={true}
                // controls
                muted
            >
            </video>
            <img src={Rlogo} alt='' className={Style.logoImg}></img>
            <div className={Style.zhuWrapper}>
                <div className={Style.stepWrapper}>
                    <Steps current={current} id='passwordSteps'>
                        <Step title="手机号验证" />
                        <Step title="设置新密码" />
                        <Step title="修改成功" />
                    </Steps>
                </div>
                <div className={Style.operstionWrapper}>
                    {
                        stepReturn(current)
                    }
                </div>
            </div>
        </div>
    )
}