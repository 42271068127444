import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, Cascader, Checkbox } from 'antd'
import axios from 'axios'
import { Link } from "react-router-dom"
import Style from './Register.module.css'
import backVideo from './image/backVideoR.mp4'
import Rlogo from './image/logo.png'

let { getNationalFireAdress } = window.config;

export default function Register() {
    const [registerForm] = Form.useForm()
    let timer = null//计时器
    const [getCode, setGetCode] = useState(1);//是否获取动态码////1获取，2重新获取，3倒计时
    const [getCodeTime, setGetCodeTime] = useState(60);//？秒后重新获取动态码
    const [options, setOptions] = React.useState([]);

    // 初始化获取省级列表
    useEffect(() => {
        getProvinceList()
    }, [])
    // 获取省级列表
    function getProvinceList() {
        axios({
            method: 'get',
            url: getNationalFireAdress() + "/getProvinceList"
        }).then(res => {
            const data = res.data.data
            data.forEach(item => {
                item.isLeaf = false
                item.level = 1
            })
            setOptions(data)
        }).catch(err => { })
    }

    // 注册
    function submitHandle(values) {
        setGetCodeTime(-1)
        clearInterval(timer);
        setGetCode(1)
        const data = { ...values }
        delete data.submit
        delete data.confirm
        data.countyCode = countyCodeName[countyCodeName.length - 1].code
        data.countyName = countyCodeName[countyCodeName.length - 1].name
        delete data.code
        axios({
            method: 'post',
            url: getNationalFireAdress() + '/api/user/add',
            data: data
        }).then(res => {
            if (res.data.status === 0) {
                registerForm.resetFields()
                message.success('注册成功')
            } else {
                message.error(res.data.data)
            }
        }).catch(err => {
            console.log(err)
        })

    }

    function iphone(value) {
        return axios({
            method: 'get',
            url: getNationalFireAdress() + '/api/user/booleanUserName',
            params: {
                userName: value,
            },
            // timeout: 5000,
        }).then(function (response) {
            if (response.data.data.status === 0) {
                return true
            } else if (response.data.data.status === 1) {
                return false
            }
        }).catch(function (error) {
            console.log(error)
        })
    }

    //手机号校验
    function validatePhone(rule, value) {
        let regex = /^(13[0-9]|14[0-9]|15[0-9]|16[0-9]|17[0-9]|18[0-9]|19[0-9])\d{8}$/;
        if (value) {
            if (value.length !== 11) {
                return Promise.reject('手机号码长度为11位！');
            }
            else {
                if (regex.test(value)) {
                    // return Promise.resolve();
                    return iphone(value).then(function (existence) {
                        if (existence) {
                            return Promise.resolve();
                        } else {
                            return Promise.reject('该手机号已注册！')
                        }
                    })
                }
                else {
                    return Promise.reject('不是正确的手机号码！');
                }
            }
        }
        else {
            return Promise.resolve();
        }
    }

    // 复杂密码正则校验
    function validatePassword(rule, value) {   //密码校验
        let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
        if (value) {
            if (value.length < 6 || value.length > 16) {
                return Promise.reject('密码长度错误！');
            }
            else {
                if (regex.test(value)) {
                    return Promise.resolve();
                }
                else {
                    return Promise.reject('密码必须是大小写字母或数字，至少包含两种');
                }
            }
        }
        else {
            return Promise.resolve();
        }
    }

    // 获取动态码验证码
    function getDynamicCode() {
        const phone = registerForm.getFieldValue('userName')
        if (phone) {
            setGetCode(3);//1获取，2重新获取，3倒计时
            axios({
                method: 'get',
                url: getNationalFireAdress() + '/api/user/verificationCode',
                params: { phone: phone }
            }).then(res => {
                if (res.data.status === 0) {
                    // 计时器
                    timer = setInterval(() => {
                        setGetCodeTime(v => {
                            if (v > 0) {
                                return v - 1;
                            } else {
                                clearInterval(timer);
                                setGetCode(2);//1获取，2重新获取，3倒计时
                                return 60;
                            }
                        });
                    }, 1000)//毫秒，1秒=1000毫秒
                } else {
                    message.error(res.data.msg)
                }
            }).catch(err => {
                console.log(err)
                message.error('网络错误')
            })

        } else {
            message.error('请先输入手机号')
        }

    }
    const [countyCodeName, setCountyCodeName] = useState()
    function onChange(_, value) {
        setCountyCodeName(value)
    }

    // 根据省编号获取市列表  /getCityList   @RequestParam("provinceCode")
    function getCityList(code) {
        return new Promise(resolve => {
            axios({
                method: 'get',
                url: getNationalFireAdress() + "/getCityList",
                params: {
                    provinceCode: code
                }
            }).then(res => {
                const data = res.data.data
                data.forEach(item => {
                    item.isLeaf = false
                    item.level = 2
                })
                resolve(data)
            }).catch(err => { })
        })
    }

    // 根据市编号获取区县列表
    function getCountyList(code) {
        return new Promise(resolve => {
            axios({
                method: 'get',
                url: getNationalFireAdress() + "/getCountyList",
                params: {
                    cityCode: code
                }
            }).then(res => {
                const data = res.data.data
                data.forEach(item => {
                    item.isLeaf = false
                    item.level = 3
                })
                resolve(data)
            }).catch(err => { })
        })
    }

    // 根据区县编号获取街道列表
    function getStreetList(code) {
        return new Promise(resolve => {
            axios({
                method: 'get',
                url: getNationalFireAdress() + "/getStreetList",
                params: {
                    countyCode: code
                }
            }).then(res => {
                const data = res.data.data
                resolve(data)
            }).catch(err => { })
        })
    }

    // axios({
    //     method: 'get',
    //     url: getNationalFireAdress() + "/getStreetList",
    // }).then(res => {
    //     const data = res.data.data
    // }).catch(err => { })

    async function loadData(selectedOptions) {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        // targetOption.loading = true;//loading
        let chindren = []
        switch (targetOption.level) {
            case 1://获取市
                chindren = await getCityList(targetOption.code)
                break;
            case 2://获取区县
                chindren = await getCountyList(targetOption.code);
                break;
            case 3://获取街道
                chindren = await getStreetList(targetOption.code);
                break;
            default:
        }
        // targetOption.loading = false;
        targetOption.children = chindren
        setOptions([...options]);
    };

    return (
        <div className={Style.registerForm}>
            <video src={backVideo}
                id='backVideo'
                className={Style.videoWrappermy}
                autoPlay="autoplay"
                loop={true}
                // controls
                muted
            >
            </video>
            <img src={Rlogo} alt='' className={Style.logoImg}></img>
            <div className={Style.registerWrapper}>
                <p className={Style.registerTitleWrapper}>注册</p>
                <Form onFinish={submitHandle} form={registerForm} >
                    <Form.Item
                        name="userName"
                        // label="手机号"
                        rules={[
                            {
                                required: true,
                                message: '请输入你的手机号!',
                                whitespace: true,
                            },
                            {
                                validator: validatePhone,
                            }
                        ]}
                    >
                        <Input placeholder="手机号" className={Style.formInput} />
                    </Form.Item>
                    <Form.Item
                        // label="真实姓名"
                        name="reallyName"
                        rules={[
                            {
                                required: true,
                                message: '请输入你的真实姓名!',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input placeholder="真实姓名" className={Style.formInput} />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        // label="密码"
                        rules={[
                            {
                                required: true,
                                message: '密码!',
                            },
                            {
                                validator: validatePassword,
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password className={Style.formInput} placeholder="密码(6-16位，大小写字母或数字，至少包含两种)" />
                    </Form.Item>
                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '请再次输入密码!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('两次输入的密码不一致!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password className={Style.formInput} placeholder="确认密码" />
                    </Form.Item>
                    <Form.Item
                        name='verificationCode'
                        rules={[
                            {
                                required: true,
                                message: '填写验证码!',
                            }
                        ]}
                    >
                        <Input placeholder="验证码"
                            className={Style.formInput}
                            addonAfter={
                                //1获取，2重新获取，3倒计时
                                getCode === 3 ?
                                    <p className={Style.getDynamicCodeNutton} >{getCodeTime}s后重新获取</p>
                                    :
                                    <Button ghost onClick={getDynamicCode} className={Style.getDynamicCodeNutton}>
                                        {getCode === 1 ?
                                            "获取验证码" : "重新获取"
                                        }
                                    </Button>
                            }
                        />
                    </Form.Item>
                    <Form.Item name='code'
                        rules={[
                            {
                                required: true,
                                message: '请选择地区!',
                            }
                        ]}
                        // extra='省级以及市级账号(需激活)使用期限为5天，区县级以及街道级别使用期限为7天'
                        extra='注册省级以及市级账号(需激活)赠送5天vip，注册区县级以及街道级别账号赠送7天vip'
                    >
                        <Cascader placeholder='请选择区域' className={Style.formInput} options={options} loadData={loadData} onChange={onChange} changeOnSelect
                            fieldNames={{ label: "name", value: 'code' }} />
                        {/* <Cascader options={options} changeOnSelect onChange={onChange} /> */}
                    </Form.Item>
                    <div className={Style.yinsiWra}>
                        <div>
                            <Form.Item
                                name="agreement"
                                valuePropName="checked"
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            value ? Promise.resolve() : Promise.reject(new Error('请阅读并同意隐私协议')),
                                    },
                                ]}
                            >
                                <Checkbox>
                                    请阅读并同意 <a href='/UserAgreement.html' target="_blank" rel="noopener noreferrer">《隐私协议》</a>
                                </Checkbox>
                            </Form.Item>

                        </div>
                        <div>
                            已有账号？<Link to="/" style={{ color: "#ff8d35" }}>返回登录</Link>
                        </div>
                    </div>
                    <Form.Item
                        name='submit'
                        style={{ textAlign: "center" }}
                    >
                        <Button type="primary" htmlType="submit" className={Style.registerFormBtn}> 注   册 </Button>
                    </Form.Item>
                </Form>
            </div>
        </div >
    )
}
