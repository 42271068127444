import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, Link } from "react-router-dom";
import Cookies from 'universal-cookie';
import { message } from 'antd';
import axios from 'axios'

import Style from './LoginControl.module.css';


import Main from '../Main/Main';
import Login from './Login';
import UserContext from '../../common/UserContext'
import Register from './Register'
import RetrievePassword from './RetrievePassword'
import RouterCom from '../RouterCom/RouterCom'

let { getNationalFireAdress } = window.config;

const cookies = new Cookies();

export default function LoginControl() {
    const [userInfo, setUserInfo] = useState({
        userName: cookies.get('userName'),
        token: cookies.get('token'),
        auths: cookies.get('auths'),//账号权限
        countyLevel: cookies.get('countyLevel'),//账号级别（管理员，省级，市级。。。）
        countyCode: cookies.get('countyCode'),//账号的区划代码
        info: cookies.get('info'),
    })
    useEffect(() => {
        setUserInfo({
            userName: cookies.get('userName'),
            token: cookies.get('token'),
            auths: cookies.get('auths'),
            countyLevel: cookies.get('countyLevel'),
            countyCode: cookies.get('countyCode'),
            info: cookies.get('info'),
        })
    }, [])

    // const [otherJump, setOtherJump] = useState(false)
    useEffect(() => {
        const href = window.location.href
        // const href = 'http://192.168.2.215:3002/?secretKey=thisissecretKey&username=17854119839&password=qwe123'
        if (href.indexOf('secretKey') > -1 && href.indexOf('username') > -1 && href.indexOf('password') > -1) {
            const params = href.split('?').pop()
            const array = params.split('&')
            const canshu = {}
            array.forEach(item => {
                const keyValue = item.split('=')
                canshu[keyValue['0']] = keyValue['1']
            })
            if (canshu.username && canshu.password && canshu.secretKey) {
                if (canshu.secretKey === 'thisissecretKey') {
                    // 登录
                    axios({
                        method: 'post',
                        url: getNationalFireAdress() + '/api/login',
                        params: {
                            userName: canshu.username,
                            password: canshu.password,
                            remember: true,
                        },
                        // timeout: 5000,
                    }).then(function (response) {
                        if (response.data.status === 0) {
                            let result = response.data.data;
                            LoginCorrectly(result)
                        } else {
                            message.warn(response.data.data);
                        }
                    }).catch(function (error) {
                        message.warn('网络错误！');
                        console.log(error);
                    })

                }
            }
        }
        // eslint-disable-next-line
    }, [])

    function LoginCorrectly(result) {
        let strings = result.split(".");
        //解析，需要吧‘_’,'-'进行转换否则会无法解析
        const user = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))));
        updataInfo(user.aud, user.auths, result.token, result.user.accountType, result.user.countyCode, result.user)
        cookies.set('userName', user.aud,);
        cookies.set('auths', user.auths,);
        cookies.set('token', result.token,);
        cookies.set('countyLevel', result.user.accountType,);
        cookies.set('countyCode', result.user.countyCode,);
        cookies.set('info', result.user);
    }

    function updataInfo(userName, auths, token, countyLevel, countyCode, info) {
        setUserInfo({
            userName: userName,
            token: token,
            auths: auths,
            countyLevel: countyLevel,
            countyCode: countyCode,
            info: info
        })
    }

    return (
        <Router basename="/">
            <UserContext.Provider value={{ userInfo, setUserInfo, updataInfo }}>
                <Switch>
                    <Route path="/admin" render={() => <HouControl />} />
                    <Route exact path="/" render={() => <QianControl />} />
                    <Route path="*" render={() => <QianControl />} />
                    <Redirect from='/*' to="/" />
                </Switch>
            </UserContext.Provider>
        </Router>
    )
}

function QianControl() {
    const userContext = useContext(UserContext)
    const userInfo = userContext.userInfo
    return (
        <div>
            {
                userInfo.userName ?<Main />:
                    <Switch>
                        <Route path="/Register" render={() => <Register />} />
                        <Route path="/RetrievePassword">
                            <RetrievePassword />
                        </Route>
                        <Route exact path="/" render={() => <Login />} />
                        <Route path="*" render={() => <Login />} />
                        <Redirect from='/' to="/" />
                    </Switch>
            }
        </div>
    )
}



function HouControl() {
    const userContext = useContext(UserContext)
    const userInfo = userContext.userInfo
    return (
        <div>
            {
                userInfo.userName ?
                    (userInfo.auths === 'auth_administor' ? <RouterCom /> : <NoAuthor />)
                    :
                    <Switch>
                        <Route path="/Register" render={() => <Register />} />
                        <Route path="/RetrievePassword">
                            <RetrievePassword />
                        </Route>
                        <Route exact path="/" render={() => <Login />} />
                        <Route path="*" render={() => <Login />} />
                        <Redirect from='/' to="/" />
                    </Switch>
            }
        </div>
    )
}

function NoAuthor() {
    return (
        <div className={Style.mainDiv}>
            <div>
                <p>您没有权限进入后台管理系统</p>
                <Link to='/'>点击此处去前台</Link>
            </div>
        </div>
    )
}