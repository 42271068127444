import React, { useState, useEffect, useRef, useContext } from 'react';
import dayjs from 'dayjs'
import {
    Table, Button, Modal, Form, Input, message, Space, DatePicker, Tooltip, Checkbox, Cascader, Spin
} from 'antd'
import Highlighter from 'react-highlight-words';
import 'dayjs/locale/zh-cn';
import {
    SearchOutlined, WarningOutlined, DeleteOutlined, CloseCircleOutlined,
    CheckCircleOutlined, FieldTimeOutlined, CommentOutlined, UnlockOutlined,
    TagOutlined
} from '@ant-design/icons';
import Axios from '../../../common/Axios'
import UserContext from '../../../common/UserContext'
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';


import Style from './UserManager.module.css';
dayjs.locale('zh-cn')
export default function UserManager() {
    const { RangePicker } = DatePicker;
    const userContext = useContext(UserContext)
    const [dataSource, setDataSource] = useState()
    const [passVisible, setPassVisible] = useState(false);
    const [timeVisible, setTimeVisible] = useState(false);
    const [options, setOptions] = React.useState([]);
    const [ggContent, setGgContent] = useState('')//
    const [spinning, setSpinning] = useState(false)//表格加载loading

    const [passform] = Form.useForm()
    const [timeform] = Form.useForm()
    // const [accountTypeList, setAccountTypeList] = useState([])//保存账号级别list

    useEffect(() => {
        init()
        getGG()
        return () => { }
        // eslint-disable-next-line
    }, [])

    // 获取所有用户
    function init() {
        setSpinning(true)
        Axios({
            method: 'get',
            url: '/api/user/selectAllUser',
        }).then(function (response) {
            if (response.data.status === 0) {
                const data = []
                response.data.data.forEach(item => {
                    data.push({
                        ...item,
                        key: item.id,
                    })
                })
                // setAccountTypeList(Array.from(new Set(data.map(item => item.accountType))))//保存所有账号级别
                setDataSource(data)
            }
            setSpinning(false)
        }).catch(function (error) {
            setSpinning(false)
            console.log(error);
        })
    }

    // 获取公告
    function getGG() {

        Axios({
            method: 'get',
            url: '/queryNotice',
        }).then(function (res) {
            if (res.data.status === 0) {
                setGgContent(res.data.data.content)
            } else {
            }
        }).catch(function (error) {
        })
    }
    const searchInput = useRef()
    const [searchText, setsearchText] = useState('')
    const [searchedColumn, setsearchedColumn] = useState('')
    const getColumnSearchProps = (dataIndex, chineseType) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`请输入${chineseType ? chineseType : dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    > 查询 </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}> 重置 </Button>
                    {/* <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setsearchText(selectedKeys[0])
                            setsearchedColumn(dataIndex)
                        }}
                    > Filter </Button> */}
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: text =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();

        setsearchText(selectedKeys[0])
        setsearchedColumn(dataIndex)
    };
    const handleReset = clearFilters => {
        clearFilters();
        setsearchText('')
    };


    const columns = [
        {
            title: '用户名',
            dataIndex: 'reallyName',
            key: 'reallyName',
            align: "center",
            ...getColumnSearchProps('reallyName', '用户名'),
        },
        {
            title: '手机号',
            dataIndex: 'telephone',
            key: 'telephone',
            align: "center",
            ...getColumnSearchProps('telephone', '手机号'),
        },
        {
            title: '注册时间',
            dataIndex: 'addTime',
            key: 'addTime',
            align: "center",
            defaultSortOrder: 'descend',//默认排序方式
            sorter: (a, b) => { //排序
                const aString = a.addTime;
                const bString = b.addTime;
                const A = new Date(aString).getTime();
                const B = new Date(bString).getTime();
                return A - B;
            },
        },
        {
            title: 'vip过期时间',
            dataIndex: 'overTime',
            key: 'overTime',
            align: "center",
            sorter: (a, b) => { //排序
                const aString = a.overTime;
                const bString = b.overTime;
                const A = new Date(aString).getTime();
                const B = new Date(bString).getTime();
                return A - B;
            },
            render: (value, _) => {
                const A = new Date(value).getTime();
                const B = new Date().getTime();
                const data = A - B
                if (data && data < 0) {
                    return <span style={{ color: "rgb(253, 32, 34)" }}>{value}</span>
                } else {
                    return <span>{value}</span>
                }
            }
            // render: (value, _) => {
            //     if (value) {
            //         try {
            //             const vipTimeRangeJson = JSON.parse(value).list['0']
            //             return vipTimeRangeJson.startTime + vipTimeRangeJson.endTime
            //         } catch { }
            //     }
            // },
        },
        {
            title: '账号级别',
            dataIndex: 'accountType',
            key: 'accountType',
            align: "center",
            // filters: accountTypeList.map(item => {
            //     return {
            //         text: item,
            //         value: item
            //     }
            // }),
            filters: [
                {
                    text: '管理员',
                    value: '管理员',
                },
                {
                    text: '省级',
                    value: '省级',
                },
                {
                    text: '市级',
                    value: '市级',
                },
                {
                    text: '县级',
                    value: '县级',
                },
                {
                    text: '街道级',
                    value: '街道级',
                },
            ],
            onFilter: (value, record) => record.accountType.includes(value),
        },
        {
            title: '地级市',
            dataIndex: 'countyName',
            key: 'countyName',
            align: "center",
            ...getColumnSearchProps('countyName', '地级市'),
        },
        {
            title: '登录次数',
            dataIndex: 'loginNum',
            key: 'loginNum',
            align: "center",
            sorter: (a, b) => { //排序
                return a.loginNum - b.loginNum;
            },
        },
        {
            title: '最后登录时间',
            dataIndex: 'loginLastTime',
            key: 'loginLastTime',
            align: "center",
            sorter: (a, b) => { //排序
                const aString = a.loginLastTime;
                const bString = b.loginLastTime;
                const A = new Date(aString).getTime();
                const B = new Date(bString).getTime();
                return A - B;
            },
        },
        {
            title: '是否接收短信通知',
            dataIndex: 'receiveSms',
            key: 'receiveSms',
            align: "center",
            render: (value) => {
                if (value && value === 1) {
                    return <span style={{ color: 'green' }}>是</span>
                } else {
                    return <span style={{ color: '#777b77' }}>否</span>
                }
            },
            filters: [
                {
                    text: '接受',
                    value: 1,
                },
                {
                    text: '不接收',
                    value: 0,
                },
            ],
            onFilter: (value, record) => String(record.receiveSms).includes(value),
        },
        // {
        //     title: 'vip',
        //     dataIndex: 'vipLevel',
        //     key: 'vipLevel',
        //     align: "center",
        //     render: (_, record) => {
        //         // if (value && value > 0) {
        //         //     return <Badge count={value}>
        //         //         <SketchOutlined style={{ fontSize: '20px', color: 'gold' }} />
        //         //     </Badge>
        //         // }
        //         if (dayjs(record.overTime) > dayjs(new Date())) {
        //             return <Badge count={1}>
        //                 <SketchOutlined style={{ fontSize: '20px', color: 'gold' }} />
        //             </Badge>
        //         }
        //     },
        //     filters: [
        //         {
        //             text: 'vip',
        //             value: 1,
        //         },
        //         {
        //             text: '非vip',
        //             value: 0,
        //         },
        //     ],
        //     onFilter: (value, record) => {
        //         if (value === 1) {
        //             return record.vipLevel > 0
        //         } else {
        //             return String(record.receiveSms).includes(value)
        //         }
        //     },
        // },
        {
            title: '备注',
            dataIndex: 'remark',
            key: 'remark',
            align: "center",
            ...getColumnSearchProps('remark', '备注'),
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            align: "center",
            render: (_, record) => {
                if (record.accountType !== '管理员') {
                    return <div className={Style.tableOption}>
                        <Space>
                            {
                                record.activeFlag === 0 ?
                                    <Tooltip title='禁用账号'>
                                        <CloseCircleOutlined style={{ color: '#ff4d4f' }} onClick={() => disable(record)} />
                                    </Tooltip>
                                    :
                                    <Tooltip title='激活账号'>
                                        <CheckCircleOutlined style={{ color: '#999696' }} onClick={() => disable(record)} />
                                    </Tooltip>
                            }
                            {/* {
                                !record.vipLevel || record.vipLevel === 0 ?
                                    <Tooltip title='设为vip'>
                                        <SketchOutlined style={{ color: '#999696' }} onClick={() => openVip([record.id], record.reallyName)} />
                                    </Tooltip> :
                                    <Tooltip title='取消vip'>
                                        <SketchOutlined style={{ color: '#ff4d4f' }} onClick={() => cancelVip([record.id], record.reallyName)} />
                                    </Tooltip>
                            } */}
                            {
                                record.receiveSms === 1 ?
                                    <Tooltip title='取消短信通知'>
                                        <CommentOutlined style={{ color: '#ff4d4f' }} onClick={() => cancelMessage([record.id], record.reallyName)} />
                                    </Tooltip>
                                    :
                                    <Tooltip title='开启短信通知'>
                                        <CommentOutlined style={{ color: '#999696' }} onClick={() => openMessage([record.id], record.reallyName)} />
                                    </Tooltip>
                            }
                            {
                                record.telephone.indexOf('000000000') > -1 ? <>
                                </> :
                                    <>
                                        <Tooltip title='修改密码' > <UnlockOutlined onClick={() => { setPassVisible(true); passform.setFieldsValue({ id: record.id }) }} /></Tooltip>
                                        <Tooltip title='删除账号' > <DeleteOutlined onClick={() => deleteUser(record)} /></Tooltip>
                                    </>
                            }
                            <Tooltip title='修改vip过期时间' > <FieldTimeOutlined onClick={() => { setTimeVisible(true); timeform.setFieldsValue({ id: record.id }) }} /></Tooltip>
                            <Tooltip title='更改备注' > <TagOutlined onClick={() => { setupdataRemarkVisible(true); updataRemarkForm.setFieldsValue({ id: record.id, remark: record.remark }) }} /></Tooltip>
                            {/* <Tooltip title='更改区域' > <EnvironmentOutlined onClick={() => { setupdataRegionVisible(true); updataRegionForm.setFieldsValue({ id: record.id, countyName: record.countyName }) }} /></Tooltip> */}
                        </Space>
                    </div>
                }
            }
        },
    ]
    // 更改区域
    const [updataRegionVisible, setupdataRegionVisible] = useState(false)
    const [updataRegionForm] = Form.useForm()
    function updataRegion(values) {
    }
    function updataRegionCancel() {
        updataRegionForm.resetFields()
        setupdataRegionVisible(false)
    }

    // 更改备注
    const [updataRemarkVisible, setupdataRemarkVisible] = useState(false)
    const [updataRemarkForm] = Form.useForm()
    function updataRemark(values) {
        if (values.id === '批量') {
            confirm({
                title: `确定将 ${selectedRowKeys.length} 个用户，修改备注？`,
                icon: <WarningOutlined />,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    const info = {
                        "userIdArray": selectedRowKeys.join(','),
                        // "overTime": "",
                        "remark": values.remark
                    }
                    updataUserInfo(info)
                },
                onCancel() {
                },
            });
        } else {
            // Axios({
            //     method: 'post',
            //     url: '/api/user/updateremark',
            //     data: {
            //         id: values.id,
            //         remark: values.remark
            //     },
            // }).then(function (response) {
            //     if (response.data.status === 0) {
            //         message.success('成功')
            //         init()
            //         updataRemarkCancel()
            //     }
            // }).catch(function (error) {
            //     console.log(error);
            // })

            const info = {
                "userIdArray": String(values.id),
                // "overTime": "",
                "remark": values.remark
            }
            updataUserInfo(info)
        }
    }

    function updataRemarkCancel() {
        updataRemarkForm.resetFields()
        setupdataRemarkVisible(false)
    }


    // 开启短信通知
    function openMessage(data, msg) {
        confirm({
            title: `确定给 ${msg} 开通短信通知吗？`,
            icon: <WarningOutlined />,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                Axios({
                    method: 'post',
                    url: '/api/user/updatereceivesms',
                    data: {
                        ids: data.join(','),
                        receiveSms: 1
                    },
                }).then(function (response) {
                    if (response.data.status === 0) {
                        message.success('成功开启短信通知')
                        init()
                    }
                }).catch(function (error) {
                    console.log(error);
                })
            },
            onCancel() {
            },
        });
    }

    // 关闭短信通知
    function cancelMessage(data, msg) {
        confirm({
            title: `确定给 ${msg} 关闭短信通知吗？`,
            icon: <WarningOutlined />,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                Axios({
                    method: 'post',
                    url: '/api/user/updatereceivesms',
                    data: {
                        ids: data.join(','),
                        receiveSms: 0
                    },
                }).then(function (response) {
                    if (response.data.status === 0) {
                        message.success('成功关闭短信通知')
                        init()
                    }
                }).catch(function (error) {
                    console.log(error);
                })
            },
            onCancel() {
            },
        });

    }

    //#region 
    // // 批量设为vip
    // function openVip(data, msg) {
    //     confirm({
    //         title: `确定将 ${msg} 设为vip？`,
    //         icon: <WarningOutlined />,
    //         okText: '确定',
    //         okType: 'danger',
    //         cancelText: '取消',
    //         onOk() {
    //             Axios({
    //                 method: 'post',
    //                 url: '/api/user/updateviplevel',
    //                 data: {
    //                     ids: data.join(','),
    //                     vipLevel: 1
    //                 },
    //                 timeout: 5000,
    //             }).then(function (response) {
    //                 if (response.data.status === 0) {
    //                     message.success('设置vip成功')
    //                     init()
    //                 }
    //             }).catch(function (error) {
    //                 console.log(error);
    //             })
    //         },
    //         onCancel() {
    //         },
    //     });
    // }

    // // 取消vip
    // function cancelVip(data, msg) {
    //     confirm({
    //         title: `确定将 ${msg} 取消vip？`,
    //         icon: <WarningOutlined />,
    //         okText: '确定',
    //         okType: 'danger',
    //         cancelText: '取消',
    //         onOk() {
    //             Axios({
    //                 method: 'post',
    //                 url: '/api/user/updateviplevel',
    //                 data: {
    //                     ids: data.join(','),
    //                     vipLevel: 0
    //                 },
    //                 timeout: 5000,
    //             }).then(function (response) {
    //                 if (response.data.status === 0) {
    //                     message.success('已取消vip')
    //                     init()
    //                 }
    //             }).catch(function (error) {
    //                 console.log(error);
    //             })
    //         },
    //         onCancel() {
    //         },
    //     });
    // }
    //#endregion

    // 批量更改备注
    function updateMakers() {
        if (selectedRowKeys.length > 0) {
            setupdataRemarkVisible(true);
            updataRemarkForm.setFieldsValue({ id: '批量' })
        } else {
            message.error('请至少选择一个用户')
        }
    }
    // 批量更改过期时间
    function updateOverTimes() {
        if (selectedRowKeys.length > 0) {
            setTimeVisible(true);
            timeform.setFieldsValue({ id: '批量' })
        } else {
            message.error('请至少选择一个用户')
        }
    }
    // 批量修改用户信息（过期时间以及备注）
    function updataUserInfo(info) {
        Axios({
            method: 'post',
            url: '/api/user/updateSysUser',
            data: {
                ...info
            },
        }).then(function (response) {
            if (response.data.status === 0) {
                message.success('更改成功')
                init()
                setupdataRemarkVisible(false)
                setTimeVisible(false)//
            } else {
                message.error('修改失败')
            }
        }).catch(function (error) {
            console.log(error);
        })
    }
    const { confirm } = Modal
    // 删除账号
    function deleteUser(record) {
        confirm({
            title: `确定删除 ${record.reallyName} 吗？`,
            icon: <WarningOutlined />,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                Axios({
                    method: 'delete',
                    url: '/api/user/general/deletesysuser',
                    params: {
                        userName: record.telephone
                    },
                }).then(function (response) {
                    if (response.data.status === 0) {
                        message.success('删除成功')
                        init()
                    }
                }).catch(function (error) {
                    console.log(error);
                })
            },
            onCancel() {
            },
        });
    }

    // 禁用,激活
    function disable(record) {
        confirm({
            title: `确定${record.activeFlag === 1 ? '启用' : "禁用"} ${record.reallyName} 吗？`,
            icon: <WarningOutlined />,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                Axios({
                    method: 'post',
                    url: '/api/user/operateActiveFlag',
                    data: {
                        userId: record.id,
                        keepType: record.activeFlag === 0 ? '2' : '1',//1启用，2禁用
                    },
                }).then(function (response) {
                    if (response.data.status === 0) {
                        message.success('成功')
                        init()
                    }
                }).catch(function (error) {
                    console.log(error);
                })

            },
            onCancel() {
            },
        });
    }

    // 修改密码
    function updatePassword(values) {
        Axios({
            method: 'post',
            url: '/api/user/adminUpdatePassword',
            data: {
                userId: values.id,
                newPassword: values.password,
                confirmPassword: values.confirm,
            },
        }).then(function (response) {
            if (response.data.status === 0) {
                message.success('修改成功')
                setPassVisible(false)
                passform.resetFields()
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    // 修改过期时间
    function updateOverTime(values) {
        if (values.id === '批量') {
            confirm({
                title: `确定将 ${selectedRowKeys.length} 个用户，更改过期时间？`,
                icon: <WarningOutlined />,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    const info = {
                        "userIdArray": selectedRowKeys.join(','),
                        "overTime": values.overTime.format('YYYY-MM-DD HH:mm:ss'),
                        // "remark": ""
                    }
                    updataUserInfo(info)
                },
                onCancel() {
                },
            });
        } else {
            const info = {
                "userIdArray": String(values.id),
                "overTime": values.overTime.format('YYYY-MM-DD HH:mm:ss'),
                // "remark": ""
            }
            updataUserInfo(info)
            // Axios({
            //     method: 'post',
            //     url: '/api/user/updateOverTime',
            //     data: {
            //         userId: values.id,
            //         overTime: values.overTime.format('YYYY-MM-DD HH:mm:ss'),
            //     },
            // }).then(function (response) {
            //     if (response.data.status === 0) {
            //         message.success('修改成功')
            //         setTimeVisible(false);
            //         timeform.resetFields()
            //         init()
            //     }
            // }).catch(function (error) {
            //     console.log(error);
            // })

        }
    }

    // 复杂密码正则校验
    function validatePassword(rule, value) {   //密码校验
        let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
        if (value) {
            if (value.length < 6 || value.length > 16) {
                return Promise.reject('密码长度错误！');
            }
            else {
                if (regex.test(value)) {
                    return Promise.resolve();
                }
                else {
                    return Promise.reject('密码必须是大小写字母或数字，至少包含两种');
                }
            }
        }
        else {
            return Promise.resolve();
        }
    }
    let pagination = {
        showQuickJumper: false,//跳至**页
        showTotal: ((total) => {
            return `共 ${total} 位用户`;
        }),
        showSizeChanger: false,
    }

    const [selectedRowKeys, setSelectedRowKeys] = useState([])//选择框
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,//指定选定的值
        onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
        },
        // selections: [
        //     Table.SELECTION_ALL,//选择所有
        // ],
    };
    // 全选
    function selectAllChange(e) {
        if (e.target.checked) {
            setSelectedRowKeys(dataSource.map(item => item.id))
        } else {
            setSelectedRowKeys([])
        }
    }
    function uploadFireExcel() {
        Axios({
            method: 'get',
            url: '/download/vipuserfirepoint',
            responseType: 'blob',
        }).then(function (response) {
            if (response.data.type === "application/json") {
                var reader = new FileReader();
                reader.readAsText(response.data, 'utf-8');
                reader.onload = function () {
                    const data = JSON.parse(reader.result)
                    message.info(data.data)
                }
            } else {
                const disposition = response.headers['content-disposition'];
                let fileNameYuan = disposition.substring(disposition.indexOf('filename=') + 9, disposition.length);//截取filename
                // 解码
                let fileName = decodeURI(fileNameYuan)
                try {
                    let url = window.URL.createObjectURL(response.data);
                    let eleLink = document.createElement('a');
                    eleLink.href = url;
                    eleLink.download = `${fileName}`;
                    eleLink.click();
                } catch (error) {
                    console.error('download error!', error);
                }
            }
        }).catch(function (error) {
            console.log(error);
        })

    }


    // 初始化获取省级列表
    useEffect(() => {
        getProvinceList()
    }, [])
    // 获取省级列表
    function getProvinceList() {
        Axios({
            method: 'get',
            url: "/getProvinceList"
        }).then(res => {
            const data = res.data.data
            data.forEach(item => {
                item.isLeaf = false
                item.level = 1
            })
            setOptions(data)
        }).catch(err => { })
    }
    // const [countyCodeName, setCountyCodeName] = useState()
    function onChange(_, value) {
        // setCountyCodeName(value)
    }

    async function loadData(selectedOptions) {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        // targetOption.loading = true;//loading
        let chindren = []
        switch (targetOption.level) {
            case 1://获取市
                chindren = await getCityList(targetOption.code)
                break;
            case 2://获取区县
                chindren = await getCountyList(targetOption.code);
                break;
            case 3://获取街道
                chindren = await getStreetList(targetOption.code);
                break;
            default:
        }
        // targetOption.loading = false;
        targetOption.children = chindren
        setOptions([...options]);
    };
    // 根据省编号获取市列表  /getCityList   @RequestParam("provinceCode")
    function getCityList(code) {
        return new Promise(resolve => {
            Axios({
                method: 'get',
                url: "/getCityList",
                params: {
                    provinceCode: code
                }
            }).then(res => {
                const data = res.data.data
                data.forEach(item => {
                    item.isLeaf = false
                    item.level = 2
                })
                resolve(data)
            }).catch(err => { })
        })
    }

    // 根据市编号获取区县列表
    function getCountyList(code) {
        return new Promise(resolve => {
            Axios({
                method: 'get',
                url: "/getCountyList",
                params: {
                    cityCode: code
                }
            }).then(res => {
                const data = res.data.data
                data.forEach(item => {
                    item.isLeaf = false
                    item.level = 3
                })
                resolve(data)
            }).catch(err => { })
        })
    }

    // 根据区县编号获取街道列表
    function getStreetList(code) {
        return new Promise(resolve => {
            Axios({
                method: 'get',
                url: "/getStreetList",
                params: {
                    countyCode: code
                }
            }).then(res => {
                const data = res.data.data
                resolve(data)
            }).catch(err => { })
        })
    }

    const [visibleGG, setGisibleGG] = useState(false)
    const [formGG] = Form.useForm()

    // 打开公告编辑
    function gg() {
        setGisibleGG(true)
        formGG.setFieldsValue(
            {
                gg: ggContent
            }
        )
    }
    function onFinishGG(value) {
        confirm({
            title: `确定发布公告吗，将给所有用户展示公告信息`,
            icon: <WarningOutlined />,
            okText: '确定',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                Axios({
                    url: "/updateNotice",
                    method: 'post',
                    data: {
                        id: 1,
                        title: '', //修改后的标题（可不传）
                        content: value.gg, //修改后的内容（可不传）
                        author: '', //修改后的作者（可不传）
                        //   state:0, //修改后的状态 0：正常 1：禁用（可不传）
                    },
                }).then(res => {
                    if (res.data.status === 0) {
                        message.success('公告发布成功')
                        cancelGG()
                        getGG()
                    }
                }).catch(err => { })
            },
            onCancel() {
            },
        });
    }
    function cancelGG() {
        formGG.resetFields()
        setGisibleGG(false)
    }

    // 不能选择今天之前的时间
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };

    // 活跃度时间更改
    function rangePickerChange(e) {
        console.log('eE:::', e)
        if (e) {

        } else {
            init()
        }
    }
    return (
        <div className={Style.mainWrapper_use}>
            <div className={Style.topToolWrapper}>
                <Space>
                    <Button onClick={() => gg()} style={{ display: userContext.userInfo.auths === 'auth_administor' ? '' : "none" }}>公告</Button>
                    <Button onClick={() => uploadFireExcel()}>导出vip火点</Button>
                    <Button onClick={() => openMessage(selectedRowKeys, `${selectedRowKeys.length}个用户`)}>批量开启消息提醒</Button>
                    <Button onClick={() => cancelMessage(selectedRowKeys, `${selectedRowKeys.length}个用户`)}>批量关闭消息提醒</Button>
                    {/* <Button onClick={() => openVip(selectedRowKeys, `${selectedRowKeys.length}个用户`)}>批量开启vip</Button> */}
                    {/* <Button onClick={() => cancelVip(selectedRowKeys, `${selectedRowKeys.length}个用户`)}>批量关闭vip</Button> */}

                    <Button onClick={() => updateMakers()}>批量更改备注</Button>
                    <Button onClick={() => updateOverTimes()}>批量更改过期时间</Button>
                </Space>
                <div style={{marginTop:'20px'}}>
                    <span style={{color:"white"}}>活跃度统计：</span>
                    <RangePicker locale={locale} onChange={rangePickerChange} />
                </div>
            </div>
            <Spin spinning={spinning}>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    id='userManagerTable'
                    pagination={pagination}
                    rowSelection={{
                        ...rowSelection,
                    }}
                    title={() => <Checkbox style={{ color: 'white' }} onChange={selectAllChange}>全选</Checkbox>}
                />
            </Spin>
            {/*  */}
            {/* 修改密码 */}
            <Modal title='修改密码' footer={null} open={passVisible} onCancel={() => { setPassVisible(false); passform.resetFields() }}>
                <Form form={passform} onFinish={updatePassword}>
                    <Form.Item
                        name="id"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        // label="密码"
                        rules={[
                            {
                                required: true,
                                message: '请输入密码!',
                            },
                            {
                                validator: validatePassword,
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password placeholder="密码(6-16位，大小写字母或数字，至少包含两种)" />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '请再次输入密码!',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('两次输入的密码不一致!');
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="确认密码" />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Space>
                            <Button htmlType='submit'>修改</Button>
                            <Button onClick={() => { setPassVisible(false); passform.resetFields() }}>取消</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 修改过期时间 */}
            <Modal title='修改过期时间' open={timeVisible} footer={null} onCancel={() => { setTimeVisible(false); timeform.resetFields() }}>
                <Form form={timeform} onFinish={updateOverTime} >
                    <Form.Item
                        name="id"
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="overTime"
                        label='过期时间'
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: '请输入过期时间!',
                            },
                        ]}
                    >
                        <DatePicker showTime disabledDate={disabledDate} />
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Space>
                            <Button htmlType='submit'>修改</Button>
                            <Button onClick={() => { setTimeVisible(false); timeform.resetFields() }}>取消</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 修改备注 */}
            <Modal title='修改备注' footer={null} open={updataRemarkVisible}
                onCancel={() => updataRemarkCancel()}>
                <Form onFinish={updataRemark} form={updataRemarkForm}>
                    <Form.Item
                        name='remark'
                    >
                        <Input.TextArea ></Input.TextArea>
                    </Form.Item>
                    <Form.Item
                        name='id'
                        style={{ display: 'none' }}
                    >
                        <Input></Input>
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button htmlType='submit' type='primary'>确认</Button>
                            <Button onClick={() => updataRemarkCancel()}>取消</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            {/* 修改区域 */}
            <Modal title='修改区域' footer={null} open={updataRegionVisible}
                onCancel={() => updataRegionCancel()}>
                <Form onFinish={updataRegion} form={updataRegionForm}>
                    <Form.Item
                        name='id'
                        style={{ display: 'none' }}
                    >
                        <Input></Input>
                    </Form.Item>
                    <Form.Item
                        label='原区域'
                        name='countyName'
                    >
                        <Input disabled></Input>
                    </Form.Item>
                    <Form.Item
                        label='现区域'
                        name='countyNameNow'
                        rules={[
                            {
                                required: true,
                                message: '请选择地区!',
                            }
                        ]}
                        extra='省级以及市级账号(需激活)赠送5天vip，注册区县级以及街道级别账号赠送7天vip'
                    >
                        <Cascader className={Style.formInput} options={options} loadData={loadData} onChange={onChange} changeOnSelect
                            fieldNames={{ label: "name", value: 'code' }} />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button htmlType='submit' type='primary'>确认</Button>
                            <Button onClick={() => updataRegionCancel()}>取消</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title='公告' footer={null} open={visibleGG} onCancel={cancelGG}>
                <Form form={formGG} onFinish={onFinishGG}>
                    <Form.Item name='gg'
                        rules={[
                            {
                                required: true,
                                message: '请填写公告',
                            }
                        ]}>
                        <Input.TextArea></Input.TextArea>
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'right' }}>
                        <Space>
                            <Button type='primary' htmlType='submit'>确定</Button>
                            <Button onClick={cancelGG}>取消</Button>
                        </Space>
                    </Form.Item>
                </Form>

            </Modal>

        </div >
    );
}
