// 登录

import React, { useState, useRef, useEffect, useContext } from 'react';
import { Form, Input, Button, message, Spin, Space, Modal } from 'antd';
import axios from 'axios';
import Vcode from 'react-vcode'
import { UserOutlined, LockOutlined, SafetyCertificateOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"

import Style from './Login.module.css'
import Rlogo from './image/R0.webp'
import wechat from './image/wechat.webp'
import xiao from './image/xiao3.webp'
// import app from './image/c149e4f30338af64320cd82941fcbb0.png'
import app1 from './image/app2.png'
// import iphone from './image/iphone.png'
import Cookies from 'universal-cookie';
import backVideo from './image/backVideo.mp4'
import UserContext from '../../common/UserContext'

// import dayjs from 'dayjs';
// import { Vertify } from '@alex_xu/react-slider-vertify';
const cookies = new Cookies();
let { getNationalFireAdress } = window.config;

export default function Login(props) {
    const userContext = useContext(UserContext)
    const [form] = Form.useForm();
    const codeRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState()//自动生成的验证码值
    // 登录
    let handleSubmit = (values) => {
        // setVisible(true)//验证码
        setLoading(true)//loading
        axios({
            method: 'post',
            url: getNationalFireAdress() + '/api/login',
            params: {
                userName: values.userName,
                password: values.password,
                remember: values.remember,
            },
            timeout: 5000,
        }).then(function (response) {
            setLoading(false)
            if (response.data.status === 0) {
                let result = response.data.data;
                LoginCorrectly(result)
            } else {
                message.warn(response.data.data);
            }
        }).catch(function (error) {
            message.warn('网络错误！');
            setLoading(false)
            console.log(error);
        })
    };

    //#region 
    // function vertifySuccess() {
    //     document.getElementById('VertifyWraqpper').style.zIndex = -1
    //     // setVisible(false)
    //     const values = form.getFieldValue()
    //     setLoading(true)//loading
    //     axios({
    //         method: 'post',
    //         url: getNationalFireAdress() + '/api/login',
    //         params: {
    //             userName: values.userName,
    //             password: values.password,
    //             remember: values.remember,
    //         },
    //         timeout: 5000,
    //     }).then(function (response) {
    //         setLoading(false)
    //         if (response.data.status === 0) {
    //             let result = response.data.data;
    //             LoginCorrectly(result)
    //         } else {
    //             message.warn(response.data.data);
    //         }
    //     }).catch(function (error) {
    //         message.warn('网络错误！');
    //         setLoading(false)
    //         console.log(error);
    //     })
    // }
    //#endregion

    async function LoginCorrectly(result) {
        let strings = result.token.split(".");
        //解析，需要吧‘_’,'-'进行转换否则会无法解析
        var user = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))));

        // localStorage.setItem('token', result.token)
        // localStorage.setItem('username', user.aud)
        // localStorage.setItem('nickName', result.user.reallyName)
        // localStorage.setItem('auths', user.auths)
        // localStorage.setItem('countyCode', result.user.countyCode)
        // localStorage.setItem('countyName', result.user.countyName)
        // localStorage.setItem('overTime', result.user.overTime)
        // localStorage.setItem('countyLevel', result.user.accountType)

        // cookies.set('token', result.token)
        // cookies.set('auths', user.auths)

        // props.setUserInfo({
        //     token: result.token,
        //     auths: user.auths,
        //     username: user.aud,
        // })
        let code = result.user.countyCode ? result.user.countyCode : '370000'

        userContext.updataInfo(user.aud, user.auths, result.token, result.user.accountType, code, result.user)
        cookies.set('userName', user.aud,);
        cookies.set('auths', user.auths,);
        cookies.set('token', result.token,);
        cookies.set('countyLevel', result.user.accountType,);
        cookies.set('countyCode', code);
        cookies.set('info', result.user);
    }

    // 获取是否可以显示
    // function getWeatherShow(code) {
    //     return new Promise(resolve => {
    //         axios({
    //             method: 'get',
    //             url: getNationalFireAdress() + '/selectGlobalConfig',
    //             params: {
    //                 code: code
    //             },
    //             timeout: 5000,
    //         }).then((response) => {
    //             if (response.data.data['0'].value === 1) {
    //                 resolve(true)
    //             } else {
    //                 resolve(false)
    //             }
    //         }).catch((error) => {

    //         })
    //     })

    // }

    // 验证码校验
    function validateCode(rule, value) {
        if (value) {
            if (value.length === 4) {
                if (code.toLowerCase() === value.toLowerCase()) {//判断输入是否与图片验证码相符
                    return Promise.resolve();
                } else {
                    // codeRef.current.onClick()//更新验证码
                    return Promise.reject('验证码错误！');
                }
            } else {
                return Promise.reject('验证码错误！');
            }
        } else {
            return Promise.reject('验证码不能为空！');
        }
    }

    // 监听背景视频，结束则重新播放
    useEffect(() => {
        let video = document.getElementById('backVideo')
        // 暂停监听
        video.addEventListener('pause', function (e) {
            console.log('暂停播放')
            // console.log(e)
        })
        // 播放结束
        video.addEventListener('ended', function (e) {
            console.log('视频播放完了')
            // console.log(e)
        })
        // 目前的播放位置已更改时，播放时间更新
        video.addEventListener('timeupdate', function (e) {
            if (video.buffered.length > 0) {
                const end = video.buffered.end(0); // 视频结尾时间
                video.playbackRate = 0.8;//播放速度
                if (video.currentTime > end - 0.5) {
                    video.currentTime = 0;//跳转到结尾
                }
            }
        })
        // 获取公告
        // try {
        init()
        // } catch (err) {
        //     console.log(err);
        // }

        return () => video.pause()
        // eslint-disable-next-line
    }, [])

    const [ggContent, setGgContent] = useState('')//公告内容
    const [visibleGG, setGisibleGG] = useState(false)//公告是否出现
    function ImKnow() {
        localStorage.setItem('ggRead', 'true')
        setGisibleGG(false)
    }


    async function init() {
        try {
            const data = await getGG()
            // 有内容，可公告
            if (data && data.content) {
                setGgContent(data.content)
                // 公告是否已读
                const read = localStorage.getItem('ggRead')
                if (read !== 'true') {
                    setGisibleGG(true)
                }
            }
        } catch (err) {
            console.log(err);
        }

    }

    // 获取公告
    function getGG() {
        return new Promise(resolve => {
            axios({
                method: 'get',
                url: getNationalFireAdress() + '/queryNotice',

            }).then(function (res) {
                if (res.data.status === 0) {
                    resolve(res.data.data)
                } else {
                    resolve()
                }
            }).catch(function (error) {
                resolve()
            })
        })

    }

    return (
        <>
            <div className={Style.LoginForm} >
                <video src={backVideo}
                    id='backVideo'
                    className={Style.videoWrappermy}
                    autoPlay="autoplay"
                    loop={true}
                    // controls
                    muted
                >
                </video>
                <img src={Rlogo} alt='' className={Style.logoImg}></img>
                {/* 第一版 */}

                <div className={Style.loginWrapper}>
                    <div className={Style.loginTitleWrapper}>
                        <p> 全国火情播报系统</p>
                        {/* <p>星科瑞升多源卫星全国火情监测系统软件</p> */}
                    </div>
                    <div className={Style.loginTable} >
                        <Spin spinning={loading}>
                            <Form form={form} id="normalLoginFrm" onFinish={handleSubmit} >
                                <Form.Item
                                    name="userName"
                                    rules={[{ required: true, message: '请输入手机号!' }]}
                                >
                                    <Input placeholder="手机号" maxLength={16} className={Style.formInput} prefix={<UserOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: '请输入密码!' }]} >
                                    <Input.Password type="password" placeholder="密码" maxLength={16} className={Style.formInput} prefix={<LockOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                                <Form.Item
                                    name="验证码"
                                    rules={[
                                        {
                                            validator: validateCode,
                                        }
                                    ]}
                                >
                                    <Input
                                        className={Style.formInput}
                                        prefix={<SafetyCertificateOutlined className="site-form-item-icon" />}
                                        placeholder="验证码"
                                        suffix={
                                            <Vcode className={Style.codeV} ref={codeRef} onChange={value => setCode(value)} />
                                        }
                                    />
                                </Form.Item>
                                <div style={{ display: 'flex', marginBottom: '5px' }}>
                                    <div style={{ textAlign: "left", fontFamily: "楷体", width: "50%", color: "white", }}>
                                        <Link to="/RetrievePassword" style={{ color: "#ff8d35" }} className={Style.forgetPasswoed}>忘记密码</Link>
                                    </div>
                                    <div style={{ textAlign: "right", fontFamily: "楷体", width: "50%", color: "white" }}>
                                        没有账号？<Link to="/register" style={{ color: "#ff8d35" }}>现在注册</Link>
                                    </div>
                                </div>

                                <Form.Item
                                    name="submit"
                                >
                                    <Button type="primary" htmlType="submit" className={Style.loginFormBtn}> 登录 </Button>
                                </Form.Item>
                            </Form>
                        </Spin>
                    </div>
                    <div className={Style.wechatWrapperTwo} >
                        <div>
                            <p>客户服务电话</p>
                            <p>0532-86722900</p>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <img src={xiao} alt='wechat'></img>
                                </div>
                                <p>微信小程序</p>
                            </div>
                            <div>
                                <div>
                                    <img src={app1} alt='wechat'></img>
                                </div>
                                <p>app</p>
                            </div>
                            <div>
                                <div>
                                    <img src={wechat} alt='wechat'></img>
                                </div>
                                <p>公众号</p>
                            </div>
                        </div>
                    </div>
                </div>




                <div className={Style.LoginFooter}>
                    <Space>
                        <a href='https://www.star-rising.com' target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>Copyright © {new Date().getFullYear()} 青岛星科瑞升信息科技有限公司 版权所有</a>
                    </Space>
                </div>
                <Modal open={visibleGG} title='公告' footer={null} closable={false} maskClosable={false} width='40vw' wrapClassName={Style.myModal}
                // bodyStyle={{
                //     background: 'linear-gradient(180deg, rgba(0, 23, 64, 0.6) 0%, rgba(0, 14, 38, 0.8) 100%)',
                //     color: 'white'
                // }}
                >
                    <div className={Style.ggContent}>
                        {ggContent}
                    </div>
                    <div className={Style.ggBut}>
                        <Button type='primary' onClick={ImKnow}>我已知晓</Button>
                    </div>
                </Modal>
            </div>

            {/* <div className={Style.VertifyWraqpper} id='VertifyWraqpper'> */}
            {/* <Vertify
                    width={320}
                    height={160}
                    visible={visible}
                    onSuccess={() => vertifySuccess()}
                // imgUrl='https://api.dujin.org/bing/1920.php#vwid=1920&vhei=1080'
                // onFail={() => alert('fail')}
                // onRefresh={() => alert('refresh')}
                /> */}
            {/* </div> */}

            {/* 公告 */}
            {/* <div className={Style.gonggaoWra}>

            </div> */}

        </>
    );
}

