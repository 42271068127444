
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs'
import { Tabs, Button, Carousel, message, Tooltip } from 'antd'
import { CopyOutlined } from '@ant-design/icons'
import 'dayjs/locale/zh-cn';
import satelliteParameters from './satelliteParameters.json'
import jspredict from 'jspredict'
import { CloseOutlined } from '@ant-design/icons';
import Style from './FireInfoCard.module.css';

dayjs.locale('zh-cn')
const { TabPane } = Tabs;
let { getFileServerAdressNow } = window.config

export default function FireInfoCard({ firedatas, setFireClicked, closeListen }) {
    const [idx, setIdx] = useState(0)//选择的table
    const [firedata, setfiredata] = useState(firedatas)//火点信息 所有
    // const [satelliteList, setSatelliteList] = useState()//过境卫星列表
    const [nowData, setNowData] = useState({})//当前展示的火点

    // const [spinning, setSpinning] = useState(false)
    // let firedata = firedatas
    useEffect(() => {
        setfiredata(firedatas.map((item, index) => {
            item.page = index + 1
            return item
        }))
        // 默认选中火点1
        setNowData(firedatas['0'])
        setCurrent(0)//将当前的火点indx归零
        // return () => setSatelliteList()
    }, [firedatas])
    // useEffect(() => {
    //     // setfiredata(firedatas)//更新数据
    //     // 提前计算过境卫星列表
    //     initSatellite()
    // }, [])

    // 提前计算过境卫星列表
    // function initSatellite() {
    //     if (firedatas) {
    //         const data = satelliteTransit(firedatas)
    //         const firedataList = {
    //             ...firedatas,
    //             satalli: data,
    //         }
    //         setfiredata(firedataList)//更新数据
    //     }
    // }


    function copyLon(value) {
        // const range = document.createRange(); //创建range对象
        // range.selectNode(document.getElementById('copyLon')); //获取复制内容的 id 选择器
        // const selection = window.getSelection();  //创建 selection对象
        // if (selection.rangeCount > 0) selection.removeAllRanges(); //如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
        // selection.addRange(range); //将range对象添加到selection选区当中，会高亮文本块
        // document.execCommand('copy'); //复制选中的文字到剪贴板
        // message.success('复制成功')
        // selection.removeRange(range); // 移除选中的元素


        //新：只适用于https以及localhost
        // navigator.clipboard.writeText(value).then(() => {
        //     message.success('复制成功')
        // }).catch(err=>{
        //     console.log('err=>',err)
        // });

        const url = value
        let oInput = document.createElement('input')
        oInput.value = url
        document.body.appendChild(oInput)
        oInput.select() // 选择对象;
        document.execCommand('Copy') // 执行浏览器复制命令
        message.success('复制成功')
        oInput.remove()
    }
    // function copyLat(value) {
    //     const range = document.createRange(); //创建range对象
    //     range.selectNode(document.getElementById('copyLat')); //获取复制内容的 id 选择器
    //     const selection = window.getSelection();  //创建 selection对象
    //     if (selection.rangeCount > 0) selection.removeAllRanges(); //如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
    //     selection.addRange(range); //将range对象添加到selection选区当中，会高亮文本块
    //     document.execCommand('copy'); //复制选中的文字到剪贴板
    //     message.success('复制成功')
    //     selection.removeRange(range); // 移除选中的元素

    //     //新：只适用于https以及localhost
    //     // navigator.clipboard.writeText(value).then(() => {
    //     //     message.success('复制成功')
    //     // }).catch(err=>{
    //     //     console.log('err=>',err)
    //     // });
    // }

    function copyAll() {
        const range = document.createRange(); //创建range对象
        range.selectNode(document.getElementById('dada')); //获取复制内容的 id 选择器
        const selection = window.getSelection();  //创建 selection对象
        if (selection.rangeCount > 0) selection.removeAllRanges(); //如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
        selection.addRange(range); //将range对象添加到selection选区当中，会高亮文本块
        document.execCommand('copy'); //复制选中的文字到剪贴板
        message.success('复制成功')
        selection.removeRange(range); // 移除选中的元素

    }

    // 火点状态（0发现 1预警出警 2核查 3,-1结案）
    // const showTab = (idx, firedata) => {
    //     // firedata = nowData
    //     if (firedata) {
    //         switch (idx) {
    //             case 0://火点详情
    //                 return <>
    //                     {
    //                         Object.keys(firedata).length > 0 ? //对象是否为空
    //                             <div className={Style.infoWra}>
    //                                 <div className={Style.firepopDataInfo} id='dada'>
    //                                     {/* <div>
    //                                         <span>火点编码：</span>
    //                                         <span>{firedata.fireCode}</span>
    //                                     </div> */}
    //                                     <div>
    //                                         <span>地区：</span>
    //                                         <span>{firedata.proName}-{firedata.cityName}-{firedata.countyName}</span>
    //                                     </div>
    //                                     <div>
    //                                         <span>详细地址：</span>
    //                                         <span>{firedata.firePointAddress}</span>
    //                                     </div>
    //                                     <div style={{ position: 'relative' }}>
    //                                         <span>经纬度：</span>
    //                                         <span id='copyLon' className={Style.lonlatWra}>
    //                                             <span>{firedata.longitude}°{Number(firedata.longitude) > 0 ? 'E' : "W"} , {firedata.latitude}°{Number(firedata.latitude) > 0 ? 'N' : "S"}</span>
    //                                             <Tooltip title='复制'> <CopyOutlined style={{ color: '#009fff', cursor: 'pointer', margin: "3px 2px 0 0" }}
    //                                                 onClick={() => copyLon(firedata.longitude + '     ' + firedata.latitude)} />
    //                                             </Tooltip>
    //                                         </span>
    //                                     </div>
    //                                     {/* <div>
    //                                         <span>纬度：</span>
    //                                         <span id='copyLat'>{firedata.latitude}°{Number(firedata.latitude) > 0 ? 'N' : "S"}</span>
    //                                     </div> */}
    //                                     <div>
    //                                         <span>地物类型：</span>
    //                                         <span>{firedata.landType}</span>
    //                                     </div>
    //                                     <div>
    //                                         <span>置信度：</span>
    //                                         <span>{firedata.confidence === 'L' ? '低' : firedata.confidence === 'N' ? '中' : firedata.confidence === 'H' ? '高' : firedata.confidence}</span>
    //                                     </div>
    //                                     <div>
    //                                         <span>卫星时间：</span>
    //                                         <span>{firedata.satelliteTime}</span>
    //                                     </div>
    //                                     {/* <div>
    //                                         <span>添加时间：</span>
    //                                         <span>{firedata.addTime}</span>
    //                                     </div> */}
    //                                     <div>
    //                                         <span>数据源：</span>
    //                                         <span> {firedata.satelliteType}</span>
    //                                     </div>
    //                                 </div>
    //                                 <div className={Style.copySpan}>
    //                                     <div onClick={copyAll}>一键复制</div>
    //                                 </div>
    //                             </div>
    //                             : <div className={Style.firepopData}><p>暂无数据</p></div>
    //                     }
    //                 </>
    //             case 1://任务规划
    //                 return <div className={Style.statelliWrapper}>
    //                     <p className={Style.planP}>过境该区域卫星列表</p>
    //                     {/* {
    //                         satelliteList ?
    //                             satelliteList.length > 0 ?
    //                                 satelliteList.map((item, index) => <p key={index} className={Style.statelliP}>
    //                                     {item.name}&nbsp;&nbsp;&nbsp;{item.time}
    //                                 </p>) : <div className={Style.firepopData}><p>无过境该区域卫星</p></div>
    //                             : <div className={Style.jisuanWeixing}> <Button onClick={() => satelliteTransit(firedata)}>查看</Button></div>
    //                     } */}

    //                     {
    //                         firedata.satelliteList ?
    //                             firedata.satelliteList.length > 0 ?
    //                                 firedata.satelliteList.map((item, index) => <p key={index} className={Style.statelliP}>
    //                                     {item.name}&nbsp;&nbsp;&nbsp;{item.time}
    //                                 </p>) : <div className={Style.firepopData}><p>无过境该区域卫星</p></div>
    //                             : <div className={Style.jisuanWeixing}> <Button onClick={() => satelliteTransit(firedata)}>查看</Button></div>
    //                     }
    //                 </div>
    //             case 2://实时影像
    //                 return <>
    //                     <div className={Style.planWrapper}>
    //                         <Tabs defaultActiveKey="1" id='planTabs' className={Style.planTabsClass}>
    //                             <TabPane tab="原卫星数据" key="1" >
    //                                 {firedata.satelliteImage ? <img className={Style.imgTime} src={getFileServerAdressNow() + firedata.satelliteImage} alt='原卫星'></img> : <p className={Style.imgTimeP}>暂无影像</p>}
    //                             </TabPane>
    //                             <TabPane tab="高分卫星数据" key="2">
    //                                 {
    //                                     !firedata.beforeFireImage && !firedata.afterFireImage ?
    //                                         <p className={Style.imgTimeP}>暂无影像</p>
    //                                         : <div style={{ display: 'flex' }}>
    //                                             {firedata.beforeFireImage ? <div style={{ width: '50%' }}>
    //                                                 <img className={Style.imgTime2} src={getFileServerAdressNow() + firedata.beforeFireImage} alt='高分卫星'></img>
    //                                                 <p style={{ width: '100%', wordWrap: 'break-word' }}>{getTime(firedata.beforeFireImage)}</p>
    //                                             </div> : null}
    //                                             {firedata.afterFireImage ? <div style={{ width: '50%' }}>
    //                                                 <img className={Style.imgTime2} src={getFileServerAdressNow() + firedata.afterFireImage} alt='高分卫星'></img>
    //                                                 <p style={{ width: '100%', wordWrap: 'break-word' }}>{getTime(firedata.afterFireImage)}</p>
    //                                             </div> : null}
    //                                         </div>
    //                                 }

    //                             </TabPane>
    //                         </Tabs>
    //                     </div>
    //                 </>
    //             default:
    //                 break;
    //         }
    //     }
    // }

    const showTab = (idx) => {
        if (nowData) {
            switch (idx) {
                case 0://火点详情
                    return <>
                        {
                            Object.keys(nowData).length > 0 ? //对象是否为空
                                <div className={Style.infoWra}>
                                    <div className={Style.firepopDataInfo} id='dada'>
                                        <div>
                                            <span>地区：</span>
                                            <span>{nowData.countyName}-{nowData.streetName}</span>
                                        </div>
                                        <div>
                                            <span>详细地址：</span>
                                            <span>{nowData.firePointAddress}</span>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <span>经纬度：</span>
                                            <span id='copyLon' className={Style.lonlatWra}>
                                                <span>{nowData.longitude}°{Number(nowData.longitude) > 0 ? 'E' : "W"} , {nowData.latitude}°{Number(nowData.latitude) > 0 ? 'N' : "S"}</span>
                                                <Tooltip title='复制'> <CopyOutlined style={{ color: '#009fff', cursor: 'pointer', margin: "3px 2px 0 0" }}
                                                    onClick={() => copyLon(nowData.longitude + '     ' + nowData.latitude)} />
                                                </Tooltip>
                                            </span>
                                        </div>
                                        <div>
                                            <span>地物类型：</span>
                                            <span>{nowData.landType}</span>
                                        </div>
                                        <div>
                                            <span>置信度：</span>
                                            <span>{nowData.confidence === 'L' ? '低' : nowData.confidence === 'N' ? '中' : nowData.confidence === 'H' ? '高' : nowData.confidence}</span>
                                        </div>
                                        <div>
                                            <span>卫星时间：</span>
                                            <span>{nowData.satelliteTime}</span>
                                        </div>
                                        {/* <div>
                                            <span>添加时间：</span>
                                            <span>{nowData.addTime}</span>
                                        </div> */}
                                        <div>
                                            <span>数据源：</span>
                                            <span> {nowData.satelliteType}</span>
                                        </div>
                                    </div>
                                    <div className={Style.copySpan}>
                                        <div onClick={copyAll}>一键复制</div>
                                    </div>
                                </div>
                                : <div className={Style.firepopData}><p>暂无数据</p></div>
                        }
                    </>
                case 1://任务规划
                    return <div className={Style.statelliWrapper}>
                        <p className={Style.planP}>过境该区域卫星列表</p>
                        {/* {
                            satelliteList ?
                                satelliteList.length > 0 ?
                                    satelliteList.map((item, index) => <p key={index} className={Style.statelliP}>
                                        {item.name}&nbsp;&nbsp;&nbsp;{item.time}
                                    </p>) : <div className={Style.firepopData}><p>无过境该区域卫星</p></div>
                                : <div className={Style.jisuanWeixing}> <Button onClick={() => satelliteTransit(nowData)}>查看</Button></div>
                        } */}

                        {
                            nowData.satelliteList ?
                                nowData.satelliteList.length > 0 ?
                                    nowData.satelliteList.map((item, index) => <p key={index} className={Style.statelliP}>
                                        {item.name}&nbsp;&nbsp;&nbsp;{item.time}
                                    </p>) : <div className={Style.firepopData}><p>无过境该区域卫星</p></div>
                                : <div className={Style.jisuanWeixing}> <Button onClick={() => satelliteTransit(nowData)}>查看</Button></div>
                        }
                    </div>
                case 2://实时影像
                    return <>
                        <div className={Style.planWrapper}>
                            <Tabs defaultActiveKey="1" id='planTabs' className={Style.planTabsClass}>
                                <TabPane tab="原卫星数据" key="1" >
                                    {nowData.satelliteImage ? <img className={Style.imgTime} src={getFileServerAdressNow() + nowData.satelliteImage} alt='原卫星'></img> : <p className={Style.imgTimeP}>暂无影像</p>}
                                </TabPane>
                                <TabPane tab="高分卫星数据" key="2">
                                    {
                                        !nowData.beforeFireImage && !nowData.afterFireImage ?
                                            <p className={Style.imgTimeP}>暂无影像</p>
                                            : <div style={{ display: 'flex' }}>
                                                {nowData.beforeFireImage ? <div style={{ width: '50%' }}>
                                                    <img className={Style.imgTime2} src={getFileServerAdressNow() + nowData.beforeFireImage} alt='高分卫星'></img>
                                                    <p style={{ width: '100%', wordWrap: 'break-word' }}>{getTime(nowData.beforeFireImage)}</p>
                                                </div> : null}
                                                {nowData.afterFireImage ? <div style={{ width: '50%' }}>
                                                    <img className={Style.imgTime2} src={getFileServerAdressNow() + nowData.afterFireImage} alt='高分卫星'></img>
                                                    <p style={{ width: '100%', wordWrap: 'break-word' }}>{getTime(nowData.afterFireImage)}</p>
                                                </div> : null}
                                            </div>
                                    }

                                </TabPane>
                            </Tabs>
                        </div>
                    </>
                default:
                    break;
            }
        }
    }


    function getTime(path) {
        // const afterFireImage = "/group1/default/20220105/18/00/4/S2A_MSIL1C_20211222T034151_N0301_R061_T47QQF_20211222T061117_9178_3645.jpg"
        const list = path.split('_')
        let time = dayjs(list[list.length - 3]).format('YYYY-MM-DD HH:mm:ss')
        return time
    }


    async function satelliteTransit(firedataValue) {
        if (firedataValue.satelliteTime && firedataValue.longitude) {
            // jspredict.transits  （卫星参数，【纬度，经度，高度】，最小仰角，最多过境次数）
            const startTime = new Date(firedataValue.satelliteTime).getTime() - 1000 * 60 * 60 * 3;//减1小时
            const endTime = new Date(firedataValue.satelliteTime).getTime() + 1000 * 60 * 60 * 3;//加5小时
            const qth = [firedataValue.latitude, firedataValue.longitude, .1];
            const dta = []
            // satelliteParameters.forEach((item, index) => {
            //     const data = jspredict.transits(item.value, qth, startTime, endTime, 60, 4)
            //     if (data.length > 0) {
            //         dta.push({
            //             name: item.name,
            //             time: dayjs(Math.round(data['0'].start)).format('YYYY-MM-DD HH:mm:ss')
            //         })
            //     }
            // })
            for (let item of satelliteParameters) {
                // const data = await jspredict.transits(item.value, qth, startTime, endTime, 60, 4)
                const data = await jisuan(item.value, qth, startTime, endTime, 60, 4)
                if (data.length > 0) {
                    dta.push({
                        name: item.name,
                        time: dayjs(Math.round(data['0'].start)).format('YYYY-MM-DD HH:mm:ss')
                    })
                }
            }
            // setSatelliteList(dta)
            // 更新当前数据
            let data = {
                ...nowData
            }
            data.satelliteList = dta
            setNowData(data)

            // 更新所有数据
            let fData = [...firedata]
            const index = fData.findIndex(item => item.fireCode === data.fireCode)
            if (index > -1) {
                fData[index].satelliteList = dta
            }
            setfiredata(fData)

        } else {
            // setSatelliteList([])
            // 更新当前数据
            let data = {
                ...nowData
            }
            data.satelliteList = []
            setNowData(data)

            // 更新所有数据
            let fData = [...firedata]
            const index = fData.findIndex(item => item.fireCode === data.fireCode)
            if (index > -1) {
                fData[index].satelliteList = []
            }
            setfiredata(fData)
        }
    }

    function jisuan(item, qth, startTime, endTime, start, end) {
        return new Promise(resove => {
            const data = jspredict.transits(item, qth, startTime, endTime, start, end)
            resove(data)
        })
    }

    // 卫星参数
    let tabs = [
        { name: '火点详情', index: 0, isshow: true },
        { name: '实时影像', index: 2, isshow: false },
        { name: '任务规划', index: 1, isshow: true },
    ]
    const RenderTabs = (tabs, idx) => {
        return tabs.map(item => {
            if (!item.isshow) {
                return null;
            }
            return <p
                key={item.index}
                className={[Style.checkTabAll, idx === item.index ? Style.checkedTab : Style.checkTab].join(' ')}
                onClick={() => { setIdx(item.index) }}
            >
                {item.name}
            </p>
        })
    }
    const ref = useRef()
    const [current, setCurrent] = useState(0)

    function prev() {
        ref.current.prev()//上一页
        // setCurrent(v => {
        //     if (v === 0) {
        //         return firedata.length - 1
        //     } else {
        //         return v - 1
        //     }
        // })
    }
    function next() {
        ref.current.next()
        // setCurrent(v => {
        //     if (v === firedata.length - 1) {
        //         return 0
        //     } else {
        //         return v + 1
        //     }
        // })
    }
    function selectCurret(item) {
        setCurrent(item)
    }
    return (

        <div className={Style.rightSiderWrapper} id='fireInfoCard'>
            <div className={Style.cardTitle}>该地点共有{firedata.length}次火情</div>
            <div className={Style.closeButton}>
                <CloseOutlined onClick={() => { setFireClicked(false); closeListen() }} style={{ color: 'white', fontSize: '2vh' }} />
            </div>
            <div>
                <div className={Style.mypageWrapperer}>
                    {/* <Pagination defaultCurrent={1} itemRender={itemRender} total={firedata.length} current={current} defaultPageSize={1} onChange={onChange} /> */}
                    <div className={Style.mypage_left} onClick={prev}>&lsaquo;</div>
                    <div className={Style.mypage_content}>
                        <Carousel ref={ref} slidesToShow={firedata.length > 4 ? 4 : firedata.length} dots={false}>
                            {
                                firedata.map((item, index) => {
                                    return <div key={index} className={Style.CarouselList}>
                                        <div className={current === index ? Style.selectCarouselList : ''}
                                            onClick={() => {
                                                selectCurret(index);
                                                setNowData(firedata[index])
                                                // setSatelliteList()
                                            }}>
                                            火点 {index + 1}
                                        </div>
                                    </div>
                                })
                            }
                        </Carousel>
                    </div>
                    <div className={Style.mypage_right} onClick={next}>&rsaquo;</div>
                </div>
                <div>
                    <div className={Style.flexWrapper}>
                        <div className={Style.foreTitleList}>
                            {
                                RenderTabs(tabs, idx)
                            }
                        </div>
                        <div className={Style.firepopContext}>
                            {
                                showTab(idx, firedata[current])
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
}

