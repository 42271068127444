// 历史火情
import React from 'react'
import titleBack1 from '../../../common/image/titleBack2.png'
import titleBack2 from '../../../common/image/titleBack6.png'



import Style from './TitleCom.module.css'

export default function TitleCom({ title, type }) {

    return (
        <div className={Style.titleWra} style={{ backgroundImage: `url(${type === 2 ? titleBack2 : titleBack1})` }}>
            {title}
        </div>
    )
}