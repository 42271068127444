// 天气情况
import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts';

export default function Weather({ weatherData }) {
    const weatherEcharts = useRef()
    useEffect(() => {
        if (weatherData && Object.keys(weatherData).length > 0) {
            // var chartDom = document.getElementById('weatherEcharts');
            let chartDom = weatherEcharts.current
            let xAxis = []
            xAxis = weatherData.xAxis.map((item, index) => {
                return item + '\n\n' + weatherData.series[index].other.dayweather
            })
            if (chartDom) {
                //设置容器高宽
                // chartDom.style.width = 25 + 'vw';
                // chartDom.style.height = 23.87 + 'vh';
                var myChart = echarts.init(chartDom);
                const option = {
                    xAxis: {
                        // type: 'category',
                        data: xAxis,
                        show: true,

                        nameTextStyle: {//坐标轴名称的文字样式。
                            color: "rgba(255, 255, 255, 0.8)"
                        },
                        // position: 'top',//位置
                        axisLine: {//坐标轴轴线相关设置。
                            show: false,
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.8)"
                            }
                        },
                        axisLabel: {//坐标轴刻度标签的相关设置。
                            // show: false,
                            textStyle: {
                                color: "rgba(255, 255, 255, 0.8)"
                            },
                            // formatter: function (value, index) {
                            //     return value + 'kg';
                            // },
                        },
                        axisTick: {//坐标轴刻度相关设置。
                            show: false,
                        }
                    },
                    yAxis: {
                        type: 'value',
                        show: false
                    },
                    grid: {
                        top: '20%',
                        right: 0,
                        bottom: '30%',
                        lrft: 0,
                    },
                    label: {
                        show: true,
                        formatter: function (params) {
                            // {
                            //     "date": "2022-05-09",
                            //     "week": "1",
                            //     "dayweather": "小雨",
                            //     "nightweather": "多云",
                            //     "daytemp": "14",
                            //     "nighttemp": "10",
                            //     "daywind": "东北",
                            //     "nightwind": "东北",
                            //     "daypower": "≤3",
                            //     "nightpower": "≤3"
                            //   }
                            // const data = params.data.other
                            // return `${data.dayweather}\n\n${params.value}°C`
                            return `${params.value}°C`
                        }
                    },
                    series: [
                        {
                            name: '温度',
                            data: weatherData.series,
                            type: 'line',
                            smooth: true,
                            label: {
                                show: true,
                                // position: 'bottom',
                                color: "rgba(255, 255, 255, 0.8)"
                            },
                            itemStyle: {
                                color: "rgba(255, 255, 255, 0.8)"//折线的颜色
                            },
                        }
                    ]
                };
                option && myChart.setOption(option);
                window.addEventListener("resize", () => {
                    myChart.resize();
                });
            }
        }

    }, [weatherData])

    return (
        <div ref={weatherEcharts} style={{ width: '100%', height: "100%" }}></div>
    )
}