import React, { useEffect, useState } from 'react';
import { Table, Button, Space, Spin, Form, Select, DatePicker, Cascader } from 'antd';
import {
    CloseCircleOutlined
} from '@ant-design/icons';
import * as Cesium from 'cesium';
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn';
import moment from 'moment';

import Style from './NationalFirePoint.module.css';
import useTableSearch from '../../usePublic/useTableSearch'
import Axios from '../../../common/Axios'
import Map from '../Map/Map'
import usePublic from '../../usePublic/usePublic'
import zero from '../image/zero.png'

const { RangePicker } = DatePicker;

let pagination = {
    showQuickJumper: false,//跳至**页
    showTotal: ((total) => {
        return `共 ${total} 个火点`;
    }),
    showSizeChanger: false,
}
export default function NationalFirePoint() {
    const [viewer, setViewer] = useState()
    const { getColumnSearchProps } = useTableSearch()//
    const [dataSource, setDataSource] = useState([])
    const [seePointInfo, setSeePointInfo] = useState()//查看的火点信息
    const { addBoundary, deleteEntityByName, } = usePublic({ viewer })
    const [options, setOptions] = useState([]);
    const [spinning, setSpinning] = useState(false)

    // 初始化地图定位
    useEffect(() => {
        if (viewer) {
            // 定位到山东
            // addBoundary(1, '370000', true, '山东省',)
            handleHistoryFire({ code: ['370000'], time: [dayjs().subtract(7, 'day'), new Date()] })//获取山东,最近七天
        }
        // eslint-disable-next-line
    }, [viewer])

    // 初始化
    useEffect(() => {
        getProvinceList()//获取省级列表
    }, [])

    // // 添加全国矢量
    // function location() {
    //     axios({
    //         method: "get",
    //         url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Aprovince2021&maxFeatures=50&outputFormat=application%2Fjson`,
    //     }).then(res => {
    //         viewer.camera.flyTo({
    //             destination: Cesium.Rectangle.fromDegrees(res.data.bbox[0], res.data.bbox[1], res.data.bbox[2], res.data.bbox[3]),
    //             duration: 2,
    //         })
    //         res.data.features.forEach(item => {
    //             // 筛选出所在省
    //             addBoundaryHandle(item.geometry.coordinates, item.properties.pro_name)
    //         });
    //     }).catch(error => { })
    // }

    // 获取所有省份
    function getProvinceList() {
        Axios({
            method: 'get',
            url: "/getProvinceList"
        }).then(res => {
            const data = res.data.data
            data.forEach(item => {
                item.isLeaf = false
                item.level = 1
            })
            setOptions(data)
        }).catch(err => { })
    }
    const columns = [
        {
            title: '区县',
            dataIndex: 'countyName',
            key: 'countyName',
            align: "center",
            ...getColumnSearchProps('countyName', '区县'),
        },
        {
            title: '街道',
            dataIndex: 'townName',
            key: 'townName',
            align: "center",
            ...getColumnSearchProps('townName', '街道'),
        },
        {
            title: '卫星',
            dataIndex: 'satelliteType',
            key: 'satelliteType',
            align: "center",
            ...getColumnSearchProps('satelliteType', '卫星'),
        },
        {
            title: '时间',
            dataIndex: 'satelliteTime',
            key: 'satelliteTime',
            align: "center",
            sorter: (a, b) => { //排序
                const aString = a.satelliteTime;
                const bString = b.satelliteTime;
                const A = new Date(aString).getTime();
                const B = new Date(bString).getTime();
                return A - B;
            },
        },
        {
            title: '地物类型',
            dataIndex: 'landType',
            key: 'landType',
            align: "center",
            ...getColumnSearchProps('landType', '地物类型'),
        },
        {
            title: '置信度',
            dataIndex: 'confidence',
            key: 'confidence',
            align: "center",
            render: (value, _) => {
                return value === 'L' ? '低' : value === 'N' ? '中' : value === 'H' ? '高' : value
            },
            filters: [
                {
                    text: '低',
                    value: 'L',
                },
                {
                    text: '中',
                    value: 'N',
                },
                {
                    text: '高',
                    value: 'H',
                },

            ],
            onFilter: (value, record) => {
                return record.confidence.includes(value)
            },

        },
        {
            title: '火点状态',
            dataIndex: 'fireType',
            key: 'fireType',
            align: "center",
            render: (value, _) => {
                return value === '0' ? '发现' : value === '1' ? '预警' : value === '2' ? '核查' : value === '3' || value === '-1' ? '结案' : value
            }
        },
    ]

    const [nationFireForm] = Form.useForm()
    // 查询
    function handleHistoryFire(data) {
        deleteEntityByName('动态边界')
        let code = ''
        if (data.code && data.code.length > 0) {
            // 边界矢量
            addBoundary(data.code.length, [...data.code].pop(), true, '动态边界',)
            // 数据处理
            code = [...data.code].pop()
        }

        let startTime = ''
        let endTime = ''
        if (data.time) {
            startTime = dayjs(data.time['0']).format('YYYY-MM-DD HH:mm:ss')
            endTime = dayjs(data.time['1']).format('YYYY-MM-DD HH:mm:ss')
        }
        const params = {
            code: code,
            startTime: startTime,
            endTime: endTime,
            satelliteType: data.satelliteType,
            landType: data.landType
        }

        setSpinning(true)
        Axios({
            method: 'get',
            url: "/queryFirePoint",
            params: {
                ...params,
            }
        }).then(res => {
            const data = res.data.data
            data.forEach((item, index) => {
                item.key = index
            })
            setDataSource(data)//表格数据
            setSpinning(false)//关闭loading
            addPointInMap(data)//添加火点
        }).catch(err => {
            setSpinning(false)
        })

    }


    // 重置
    // function resetNation() {
    //     handleHistoryFire({ code: ['370000'], time: [dayjs().subtract(7, 'day'), new Date()] })//获取山东,最近七天
    // }

    // 在地图上添加点
    function addPointInMap(data) {
        // 移除火点
        deleteEntityByName('全国火点')
        // 添加火点
        data.forEach(item => {
            viewer.entities.add({
                name: "全国火点",
                position: Cesium.Cartesian3.fromDegrees(parseFloat(item.longitude), parseFloat(item.latitude), 0),
                billboard: {
                    image: zero,
                    show: true,
                    scale: 1.0,
                    width: 18,
                    height: 18,
                    sizeInMaters: true,
                    // scaleByDistance: new Cesium.NearFarScalar(50000, 2, 20000, 1),
                    disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    // color:new Cesium.Color.fromBytes(0,191,255,100),
                    // outlineColor: new Cesium.Color.fromBytes(0,191,255,100),
                    // outlineWidth: 20,
                },
                sInfo: item
            })
        })
    }

    // 火苗点击事件
    //   useEffect(() => {
    //     if (viewer) {
    //         var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
    //         handler.setInputAction(function (movement) {
    //             //点击实体
    //             let pick = viewer.scene.pick(movement.position);
    //             if (Cesium.defined(pick) && pick && pick.id) {
    //                 // 由于添加了聚合的原因，有点被聚合后，点击会获得多个信息，判断一下如果被聚合，则显示第一个火点的信息
    //                 if (pick.id instanceof Array) {//数组
    //                     // 找出一个是fireInfo的火点
    //                     // const index = pick.id.find(item => item._name && item._name.indexOf('fireInfo') > -1)
    //                     // getFireInfo(index)
    //                     // 筛选出是fireInfo的火点
    //                     const index = pick.id.filter(item => item._name && item._name.indexOf('fireInfo') > -1)
    //                     getFireInfo(index)
    //                 } else {
    //                     //判断当前点击是否为firePoint
    //                     if (pick.id._name && pick.id._name.indexOf('fireInfo') > -1) {
    //                         // 根据火点编码获取火点信息
    //                         getFireInfo([pick.id])
    //                     } else {
    //                         setFireClicked(false)
    //                         addListens(false)
    //                     }
    //                 }
    //             } else {
    //                 setFireClicked(false)
    //                 addListens(false)
    //             }
    //         }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    //     }
    //     return () => {
    //         !handler || handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [viewer])


    // 根据省编号获取市列表  /getCityList   @RequestParam("provinceCode")
    function getCityList(code) {
        return new Promise(resolve => {
            Axios({
                method: 'get',
                url: "/getCityList",
                params: {
                    provinceCode: code
                }
            }).then(res => {
                const data = res.data.data
                data.forEach(item => {
                    item.isLeaf = false
                    item.level = 2
                })
                resolve(data)
            }).catch(err => { })
        })
    }
    // 根据市编号获取区县列表
    function getCountyList(code) {
        return new Promise(resolve => {
            Axios({
                method: 'get',
                url: "/getCountyList",
                params: {
                    cityCode: code
                }
            }).then(res => {
                const data = res.data.data
                data.forEach(item => {
                    item.isLeaf = false
                    item.level = 3
                })
                resolve(data)
            }).catch(err => { })
        })
    }

    // 根据区县编号获取街道列表
    function getStreetList(code) {
        return new Promise(resolve => {
            Axios({
                method: 'get',
                url: "/getStreetList",
                params: {
                    countyCode: code
                }
            }).then(res => {
                const data = res.data.data
                resolve(data)
            }).catch(err => { })
        })
    }
    async function loadData(selectedOptions) {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        // targetOption.loading = true;//loading
        let chindren = []
        switch (targetOption.level) {
            case 1://获取市
                chindren = await getCityList(targetOption.code)
                break;
            case 2://获取区县
                chindren = await getCountyList(targetOption.code);
                break;
            case 3://获取街道
                chindren = await getStreetList(targetOption.code);
                break;
            default:
        }
        // targetOption.loading = false;
        targetOption.children = chindren
        setOptions([...options]);
    };

    function onChange(_, value) {
        // setCountyCodeName(value)
    }
    return (
        <div className={Style.main}>
            <Spin spinning={spinning} tip="加载中...">
                <Map setViewer={setViewer} setSeePointInfo={setSeePointInfo} />

                {/* 右侧火点 */}
                <div className={Style.rightOptionWrapper}>
                    <Form size='small'
                        className={Style.historyFireForm}
                        onFinish={handleHistoryFire}
                        form={nationFireForm}
                        initialValues={{
                            code: ["370000"],
                            time: [
                                moment().subtract(7, 'days'),//30天以前
                                moment().endOf('day')//今天
                            ]
                        }}
                    >
                        <div className={Style.rowDiv}>
                            <div>
                                {/* <Form.Item
                                    name='code'
                                >
                                    <Select placeholder='地级市' allowClear>
                                        {options.map(city => (
                                            <Select.Option key={city.code} value={city.code}>{city.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item> */}
                                <Form.Item name='code'>
                                    <Cascader className={Style.formInput} options={options} loadData={loadData} onChange={onChange} changeOnSelect
                                        fieldNames={{ label: "name", value: 'code' }} />
                                    {/* <Cascader options={options} changeOnSelect onChange={onChange} /> */}
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    name='satelliteType'
                                >
                                    <Select allowClear placeholder='卫星类型'>
                                        <Select.Option value='Suomi NPP'>Suomi NPP</Select.Option>
                                        <Select.Option value='NOAA-20'>NOAA-20</Select.Option>
                                        <Select.Option value='Himawari 8'>Himawari 8</Select.Option>
                                        <Select.Option value='MODIS'>MODIS</Select.Option>
                                        <Select.Option value='FY4A'>FY4A</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <div className={Style.rowDiv}>
                            <div>  <Form.Item name='landType'>
                                <Select allowClear placeholder='地物类型' >
                                    <Select.Option value='耕地'>耕地</Select.Option>
                                    <Select.Option value='森林'>森林</Select.Option>
                                    <Select.Option value='草地'>草地</Select.Option>
                                    <Select.Option value='灌木'>灌木</Select.Option>
                                    {/* <Select.Option value='水体'>水体</Select.Option> */}
                                    <Select.Option value='人造地表'>人造地表</Select.Option>
                                    <Select.Option value='其他'>其他</Select.Option>
                                    <Select.Option value='湿地'>湿地</Select.Option>
                                    <Select.Option value='苔原'>苔原</Select.Option>
                                    <Select.Option value='裸地'>裸地</Select.Option>
                                </Select>
                            </Form.Item></div>
                            <div>
                                <Form.Item
                                    name='time'
                                >
                                    <RangePicker size='small'
                                        format='YYYY/MM/DD'
                                    // disabledDate={disabledDate}//禁用的日期
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        <Form.Item name='submit' style={{ textAlign: 'center' }} >
                            <Space>
                                <Button htmlType='submit' ghost className={Style.formBotten}>查询</Button>
                                {/* <Button ghost className={Style.formBotten} onClick={() => {
                                    // setDataSource(historyData); historyFireForm.resetFields();
                                    resetNation()
                                }}>重置</Button> */}
                            </Space>
                        </Form.Item>
                    </Form>

                    <Table
                        id='nationFireTable'
                        columns={columns}
                        dataSource={dataSource}
                        pagination={pagination}
                        onChange={(pagination, filters, sorter, extra) => {
                            const newData = extra.currentDataSource
                            addPointInMap(newData)
                        }}
                        // rowSelection={{//复选框
                        //     // selectedRowKeys: selectedRowKeys,//指定选定的值
                        //     onChange: (_, record) => {
                        //         // setSelectedRowKeys(record)
                        //     },
                        //     selections: [
                        //         Table.SELECTION_ALL,//选择所有
                        //     ],
                        // }}
                        onRow={record => {
                            return {
                                onClick: event => {// 点击行
                                    if (viewer) {
                                        viewer.camera.flyTo({
                                            destination: Cesium.Cartesian3.fromDegrees(record.longitude, record.latitude, 5000),
                                            duration: 2,
                                        })
                                    }
                                    setSeePointInfo(record)
                                },
                                // onDoubleClick: event => { },
                                // onContextMenu: event => { },
                                // onMouseEnter: event => { }, // 鼠标移入行
                                // onMouseLeave: event => { },
                            };
                        }}
                    >
                    </Table>
                </div>

                {
                    seePointInfo ? <div className={Style.leftTopInfoWrapper}>
                        <div className={Style.leftTopHeader}>
                            <span>{seePointInfo.countyName}</span>
                            <CloseCircleOutlined onClick={() => setSeePointInfo()} />
                        </div>
                        <div className={Style.leftTopContent}>
                            <div><span>火点编码:</span><span>{seePointInfo.fireCode}</span></div>
                            <div><span>经纬度:</span><span>{seePointInfo.longitude + ' , ' + seePointInfo.latitude}</span></div>
                            <div><span>土地类型:</span><span>{seePointInfo.landType}</span></div>
                            <div><span>卫星时间:</span><span>{seePointInfo.satelliteTime}</span></div>
                            <div><span>数据源:</span><span>{seePointInfo.satelliteType}</span></div>
                            <div><span>区县:</span><span>{seePointInfo.countyName}</span></div>
                            <div><span>街道:</span><span>{seePointInfo.streetName}</span></div>
                            <div><span>详细地址:</span><span>{seePointInfo.firePointAddress}</span></div>
                            <div><span>添加时间:</span><span>{seePointInfo.addTime}</span></div>
                        </div>
                    </div> : null
                }
            </Spin>
        </div>
    )
}