import axios from "axios";
import { Modal } from 'antd'
import Cookies from 'universal-cookie';
// import UserContext from '../../common/UserContext'

let { getNationalFireAdress, jiegan } = window.config
const cookies = new Cookies();
let isShow = false//过期弹窗是否出现
// 创建新的实例，以免影响其他axios
const Axios = axios.create({
    // timeout: 5000,
    baseURL: getNationalFireAdress(),//

})
// Axios.defaults.withCredentials = true;//允许跨域携带cookie信息
// 添加请求拦截器,添加token
Axios.interceptors.request.use(function (config) {
    if (config.url == '/cloudCharts' || config.url == '/queryFirePointBroadcast' || config.url == '/queryVectorBoundary') {
        config.baseURL = jiegan()
    }
    // 在发送请求之前做些什么
    // config.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    // config.headers['Authorization'] = result;
    config.headers['Authorization'] = cookies.get('token');
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


// 响应拦截器
Axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 请求失败
    console.log('Axios封装error', error.response)
    console.log('Axios封装error', error)
    if (error && error.response) {
        switch (error.response.status) {
            case 400:
                console.log(error.response.data.error.details);
                break;
            case 401:
                console.log("401-未授权，请登录");
                break;
            case 403:
                console.log("403-请重新登录");
                // message.info('账号过期，请重新登录')
                // cookies.set('token', '')
                // localStorage.setItem('token', '')
                // window.location.href = '/'
                if (!isShow) {
                    isShow = true
                    Modal.confirm({
                        title: `账号过期是否重新登录？`,
                        // icon: <WarningOutlined />,
                        okText: '确定',
                        okType: 'danger',
                        cancelText: '取消',
                        onOk() {
                            isShow = false
                            cookies.set('userName', '');
                            cookies.set('auths', '');
                            cookies.set('token', '');
                            cookies.set('countyLevel', '');
                            localStorage.setItem('token', '')
                            window.location.href = '/'
                        },
                        onCancel() {
                            isShow = false
                        },
                    });
                }
                break;
            case 404:
                console.log("404-请求地址出错");
                break;
            case 408:
                console.log("408-请求超时");
                break;
            case 500:
                console.log("500-服务器内部错误");
                break;
            case 501:
                console.log("501-服务未实现");
                break;
            case 502:
                console.log("502-网关错误");
                break;
            case 503:
                console.log("503-服务不可用");
                break;
            case 504:
                console.log("504-网关超时");
                break;
            case 505:
                console.log("505-HTTP版本不受支持");
                break;
            default:
        }
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default Axios;
