// 历史火情
import React, { useEffect, useState, useContext } from 'react'
import moment from 'moment';
import dayjs from 'dayjs'
import { Select, Table, DatePicker, Button, Form, message, Space, Spin, Tooltip } from 'antd'
import * as Cesium from 'cesium';
import { InboxOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import MainMap from '../MainMap/MainMap'
import UserContext from '../../common/UserContext'

import ExportJsonExcel from 'js-export-excel';//导出表格依赖
import BottomSider from './BottomSider'
import TitleCom from '../usePublic/TitleCom/TitleCom'

import Style from './HistoryFire.module.css'
import usePublic from '../usePublic/usePublic'
// import CesiumContext from '../../common/CesiumContext'
import Axios from '../../common/Axios'
import useTableSearch from '../usePublic/useTableSearch'
import Cookies from 'universal-cookie';
import CesiumTool from '../CesiumTool/CesiumTool'
import close1 from '../../common/image/close1.png'
import open1 from '../../common/image/open1.png'
// import bonfire from './bonfire.png'
// import bonfire from '../../image/fireSelect.png'
// import firePng from '../../image/fire.png'
// import firePng from '../../usePublic/image/fire.png'

const { RangePicker } = DatePicker;
const cookies = new Cookies();

export default function HistoryFire({ setFireClicked, setFiredatas, historyData }) {
    const [viewer, setViewer] = useState()
    const userContext = useContext(UserContext)

    // const [fireData, setFireData] = useState([])//筛选之后火情列表
    const { getColumnSearchProps } = useTableSearch()//

    // const cesiumContext = useContext(CesiumContext);
    // let viewer = cesiumContext.viewer
    const { addFireEntitys, deleteEntityByName, addBoundary } = usePublic({ viewer })//所有的市
    const [dataSource, setDataSource] = useState([])//表格数据
    const [spinning, setSpinning] = useState(false)
    const [historyFireForm] = Form.useForm()//查询表单
    const [allCity, setallCity] = useState([])
    // 根据code获取当前地级市下的所有地级市
    useEffect(() => {
        if (viewer) {
            init()
        }
        return () => {
            if (viewer) {
                // viewer.destroy()
            }
        }
        // eslint-disable-next-line
    }, [viewer])


    function postion() {
        // const code = localStorage.getItem('countyCode')
        const code = userContext.userInfo.countyCode
        if (code) {
            // const data = localStorage.getItem('countyLevel')
            const data = userContext.userInfo.countyLevel
            switch (data) {
                case '管理员':
                    addBoundary(1, code, true, "所在省", '#FA8072')
                    break;
                case '省级':
                    addBoundary(1, code, true, "所在省", '#FA8072')
                    break;
                case '市级':
                    addBoundary(2, code, true, "所在市", '#FA8072')
                    break;
                case '县级':
                    addBoundary(3, code, true, "所在县", '#FA8072')
                    break;
                case '街道级':
                    // 截取前6位
                    // addBoundary(3, code.slice(0, 6), true, "所在街道", '#FA8072')
                    addBoundary(4, code, true, "所在街道", '#FA8072')
                    break;
                default:
            }
        }
    }


    function init() {
        getallCity()
        handleHistoryFire({
            time: [dayjs().subtract(14, 'day').format('YYYYMMDD'), dayjs()]
        })
        postion()
        // dayjs().subtract(14, 'day').format('YYYYMMDD')
    }
    function getallCity() {
        Axios({
            method: 'get',
            url: '/selectCityName',
            params: {
                // code: localStorage.getItem('countyCode')
                code: cookies.get('countyCode')
            }
        }).then(function (response) {
            if (response.data.status === 0) {
                setallCity(response.data.data)
            }
        }).catch(function (error) {
            console.log(error);
        })
    }

    function resetF() {
        // setDataSource(historyData);
        historyFireForm.resetFields();//清空表单
        // addFireEntitys(historyData)//添加查询到的火苗信息
        // cesiumContext.setBottomFireData(historyData)//传给bottom数据分析
        deleteEntityByName('selectCity')//删除选中的市
        init()
    }

    const columns = [
        // {
        //     title: 'fireCode',
        //     dataIndex: 'fireCode',
        //     key: 'fireCode',
        //     align: "center",
        // },
        // {
        //     title: 'key',
        //     dataIndex: 'key',
        //     key: 'key',
        //     align: "center",
        // },
        // {
        //     title: 'p',
        //     dataIndex: 'proName',
        //     key: 'proName',
        //     align: "center",
        //     ...getColumnSearchProps('proName', 'p'),
        // },
        {
            title: '区县',
            dataIndex: 'countyName',
            key: 'countyName',
            align: "center",
            // ...getColumnSearchProps('countyName', '区县'),
        },
        {
            title: '街道',
            dataIndex: 'streetName',
            key: 'streetName',
            align: "center",
            // ...getColumnSearchProps('streetName', '街道'),
        },
        {
            title: '卫星',
            dataIndex: 'satelliteType',
            key: 'satelliteType',
            align: "center",
            // ...getColumnSearchProps('satelliteType', '卫星'),
        },
        // {
        //     title: '数据源',
        //     dataIndex: 'satelliteType',
        //     key: 'satelliteType',
        //     align: "center",
        // },
        {
            title: '时间',
            dataIndex: 'satelliteTime',
            key: 'satelliteTime',
            align: "center",

            defaultSortOrder: 'descend',//默认排序方式
            sorter: (a, b) => { //排序
                const aString = a.satelliteTime;
                const bString = b.satelliteTime;
                const A = new Date(aString).getTime();
                const B = new Date(bString).getTime();
                return A - B;
            },
        },
        {
            title: '地物类型',
            dataIndex: 'landType',
            key: 'landType',
            align: "center",
        },
        {
            title: '置信度',
            dataIndex: 'confidence',
            key: 'confidence',
            align: "center",
            render: (value, _) => {
                return value === 'L' ? '低' : value === 'N' ? '中' : value === 'H' ? '高' : value
            }
        },
        // {
        //     title: '火点状态',
        //     dataIndex: 'fireType',
        //     key: 'fireType',
        //     align: "center",
        //     render: (value, _) => {
        //         return value === '0' ? '发现' : value === '1' ? '预警' : value === '2' ? '核查' : value === '3' || value === '-1' ? '结案' : value
        //     }
        // },
    ]

    // 动态多条件查询火点
    function handleHistoryFire(values) {
        setSpinning(true)//数据加载
        // setFireClicked(false)
        const data = { ...values }
        data.startTime = values.time ? dayjs(values.time['0']).format('YYYY-MM-DD') : ''
        data.endTime = values.time ? dayjs(values.time['1']).format('YYYY-MM-DD') : ''
        delete data.time
        delete data.submit
        Axios({
            method: 'post',
            url: '/selectFirePoint',
            data: data,
            // method: 'get',
            // url: '/queryFirePoint',
            // params: {
            //     ...data,
            //     // code: values.cityCode
            //     code: values.cityCode ? values.cityCode : userContext.userInfo.countyCode,

            // },
        }).then(function (response) {
            if (response.data.status === 0) {
                let k = 0
                const data = []
                response.data.data.forEach(item => {
                    data.push({
                        key: k++,
                        ...item
                    })
                })
                setDataSource(data)
                addFireEntitys(data)//添加查询到的火苗信息

            } else {
                setDataSource([])
                addFireEntitys()//添加查询到的火苗信息
            }
            setSpinning(false)

        }).catch(function (error) {
            console.log(error);
            setSpinning(false)
        })
    }

    // 表格行点击事件
    function rowClick(values) {
        if (viewer) {
            deleteEntityByName('biaozhi')//删除闲着没事写的标志
            // deleteEntityByName('fireInfo_' + values.fireCode)//删除这个实体，然后添加他，使他位于上层
            // addFireEntitys([values], true)//添加查询到的火苗信息
            // // 移动试图
            viewer.camera.flyTo({
                destination: Cesium.Cartesian3.fromDegrees(values.longitude, values.latitude, 10000),
            });

            //#region  这里是当时没加聚集时，多个火点重叠无法准确定位到点击的火点，进行删除再添加的操作，使火点位于最上层
            viewer.entities.add({
                name: 'biaozhi',
                position: Cesium.Cartesian3.fromDegrees(values.longitude, values.latitude),
                ellipse: {
                    semiMinorAxis: 150.0,//长轴
                    semiMajorAxis: 150.0,//短轴
                    // rotation: Cesium.Math.toRadians(-40.0),//逆时针旋转
                    outline: true,
                    outlineColor: Cesium.Color.fromCssColorString('rgba(220,20,60,1)'),
                    outlineWidth: 4,
                    stRotation: Cesium.Math.toRadians(90),
                    fill: true,
                    material: Cesium.Color.fromCssColorString('rgba(220,20,60,0.4)'),
                },
            });

            // viewer.camera.flyTo({
            //     destination: Cesium.Cartesian3.fromDegrees(values.longitude, values.latitude, 5000),
            // });

            // for (let l = 0; l < viewer.dataSources.length; l++) {
            //     if (viewer.dataSources.get(l)
            //         && viewer.dataSources.get(l).entities
            //         && viewer.dataSources.get(l).entities.values
            //         && viewer.dataSources.get(l).entities.values.length > 0
            //     ) {
            //         const entitiesArray = viewer.dataSources.get(l).entities.values
            //         // const maxHeight = Math.max(...entitiesArray.map(it => it.position._value.z))
            //         entitiesArray.forEach(item => {
            //             if (item._name.indexOf(values.fireCode) > -1) {
            //                 item.billboard = {
            //                     image: bonfire,
            //                     width: 37,
            //                     height: 37,
            //                     // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND   //设置HeightReference高度参考类型为CLAMP_TO_GROUND贴地类型
            //                 };
            //                 // item.position._value.z += 1000.0;

            //             } else {
            //                 // if (item.billboard.width._value === 27) {
            //                 //     // item.position._value.z -= 1000.0;
            //                 // }
            //                 item.billboard = {
            //                     image: firePng,
            //                     width: 25,
            //                     height: 25,
            //                     // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND   //设置HeightReference高度参考类型为CLAMP_TO_GROUND贴地类型
            //                 };
            //             }
            //         })

            //     }
            // }

            //#endregion
        }
    }

    // 地级市改变
    function citySelectChange(value) {
        if (viewer) {
            deleteEntityByName('selectCity')
            if (value) {
                deleteEntityByName('hoverBoundary')
                if (value.length === 9) { //街道
                    // addBoundary(3, value.slice(0, 6), true, "selectCity", '#1E90FF')
                    addBoundary(4, value, true, "所在街道", '#FA8072')
                } else {
                    // 判断是市还是区县
                    if (value.slice(value.length - 2, value.length) === '00') {//最后两位是00，是市
                        addBoundary(2, value, true, "selectCity", '#1E90FF')
                    } else {
                        addBoundary(3, value.slice(0, 6), true, "selectCity", '#1E90FF')
                    }
                }
            }
        }
    }

    // 时间控件限制
    // function disabledDate(current) {
    //     const start = moment().subtract(31, 'days')//30天以前
    //     const end = moment().endOf('day')//今天
    //     // 返回禁用的日期
    //     return current < start || current > end;
    // }

    let pagination = {
        showQuickJumper: false,//跳至**页
        showTotal: ((total) => {
            return `共 ${total} 个火点`;
        }),
        defaultPageSize: 15,
        // showSizeChanger: false,
    }

    // let dd = {
    //     "id": 2250229,
    //     "fireCode": "202303010151370283831062",
    //     "satelliteTime": "2023-03-01 01:51:00",
    //     "longitude": 119.73092,
    //     "latitude": 36.78918,
    //     "satelliteType": "NOAA-20",
    //     "landType": "耕地",
    //     "confidence": "N",
    //     "fireImage": null,
    //     "satelliteImage": null,
    //     "addTime": "2023-03-01 04:17:24",
    //     "firePointAddress": "山东省青岛市平度市田庄镇永胜街",
    //     "remark": null,
    //     "placeHolder": null,
    //     "proCode": "370000",
    //     "proName": "山东省",
    //     "cityCode": "370200",
    //     "cityName": "青岛市",
    //     "countyCode": "370283",
    //     "countyName": "平度市",
    //     "townCode": "370283112",
    //     "townName": "田庄镇"
    // }
    // 下载数据
    function exportExcel() {
        const data = [...dataSource]
        if (data.length <= 0) {
            message.info('请至少选择一个火点')
            return
        }
        const name = '多源卫星火情监测' + dayjs(new Date()).format('YYYY_MM_DD_HH_mm_ss')
        var option = {};
        let dataTable = [];
        if (data.length > 0) {
            for (let i in data) {
                if (data) {
                    let obj = {
                        '火点编码': data[i].fireCode,
                        '火点经度': data[i].longitude,
                        '火点纬度': data[i].latitude,
                        // '所在城市': getCityFromAddress(data[i].firePointAddress),
                        '所在城市': data[i].cityName,
                        '所在县区': data[i].countyName,
                        // '乡镇': data[i].streetName,
                        '卫星发现时间': data[i].satelliteTime,
                        '数据源名称': data[i].satelliteType,
                        '地物类型': data[i].landType,
                        '详细地址': data[i].firePointAddress,
                        // '入库时间': data[i].addTime,
                        '置信度': data[i].confidence === 'L' ? '低' : data[i].confidence === 'N' ? '中' : data[i].confidence === 'H' ? '高' : data[i].confidence,
                        // '审核状态': data[i].verifyState === '1' ? '通过' : data[i].verifyState === '2' ? '不通过' : '未审核',
                    }
                    dataTable.push(obj);
                }
            }
            option.fileName = name//文件名
            option.datas = [
                {
                    sheetData: dataTable,//数据
                    sheetName: name,//sheet名
                    // sheetFilter: Object.keys(dataTable['0']),//列过滤
                    sheetHeader: Object.keys(dataTable['0']),//第一行
                    // columnWidths: [] //列宽 需与列顺序对应
                }
            ];
            var toExcel = new ExportJsonExcel(option);
            toExcel.saveExcel();
        } else {
            message.error("请先选择数据")
        }
    }
    // function getCityFromAddress(data) {
    //     let city = ''
    //     const shengIndex = data.indexOf('山东省')
    //     if (shengIndex > -1) {
    //         const shiAddress = data.split('山东省').pop()
    //         const shiIndex = shiAddress.indexOf('市')
    //         if (shiIndex > -1) {
    //             city = shiAddress.split('市')['0'] + '市'
    //         }
    //     }
    //     return city
    // }
    const [isOpen, setIsOpen] = useState(true)//开关
    function closeBottom() {
        // setIsOpen(V => !V)
        const hisRight = document.getElementById('historyFireId')
        hisRight.style.transform = isOpen ? 'translateX(calc(100% + 20px))' : 'translateX(0)'
        let time = setInterval(() => {
            setIsOpen(V => !V)
            clearInterval(time)
        }, 900)
    }

    return (
        <>
            <div className={Style.historyFireWrapper} >
                <MainMap setViewer={setViewer} />
                <CesiumTool viewer={viewer} />
                <div className={Style.rightWra} id='historyFireId'
                    style={{
                        // transform: isOpen ? 'translateX(0)' : 'translateX(calc(100% + 20px))',
                        transition: '1s'
                    }}
                >
                    <div>
                        <TitleCom title='历史火情查询' />
                    </div>
                    <div>
                        <div className={Style.formWra}>
                            <Form layout='inline'
                                className={Style.historyFireForm}
                                onFinish={handleHistoryFire}
                                form={historyFireForm}
                                initialValues={{
                                    time: [
                                        moment(dayjs().subtract(14, 'day').format('YYYYMMDD')),
                                        moment(new Date()),
                                    ]
                                }}
                            >
                                <div className={Style.historyFireFormRow}>
                                    <div>
                                        {
                                            allCity && allCity.length > 0 ?
                                                <Form.Item
                                                    name='cityCode'
                                                >
                                                    <Select placeholder='行政区划' allowClear onChange={citySelectChange} popupClassName='popupClassNameSelect'>
                                                        {allCity.map(city => (
                                                            <Select.Option key={city.code} value={city.code}>{city.name}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item> :
                                                <Form.Item name='noCity'>
                                                    {/* <Select placeholder={localStorage.getItem('countyName')} allowClear disabled> */}
                                                    <Select placeholder={userContext.userInfo.info.countyName} allowClear disabled popupClassName='popupClassNameSelect'>
                                                    </Select>
                                                </Form.Item>
                                        }
                                    </div>
                                    <div>
                                        <Form.Item
                                            name='satelliteType'
                                        >
                                            <Select allowClear placeholder='数据源'
                                                // dropdownStyle={{
                                                //     backgroundColor: 'rgba(0, 57, 115, 0.8)',
                                                //     border: '1px solid rgba(38, 147, 255, 0.6)',
                                                //     color: 'white',
                                                // }}
                                                popupClassName='popupClassNameSelect'>
                                                <Select.Option value='Suomi NPP'>Suomi NPP</Select.Option>
                                                <Select.Option value='NOAA-20'>NOAA-20</Select.Option>
                                                <Select.Option value='Himawari 8'>Himawari 8</Select.Option>
                                                <Select.Option value='MODIS'>MODIS</Select.Option>
                                                <Select.Option value='FY4A'>FY4A</Select.Option>
                                                <Select.Option value='GK2A'>GK2A</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className={Style.historyFireFormRow}>
                                    <div>
                                        <Form.Item name='landType'>
                                            <Select allowClear placeholder='地物类型' >
                                                <Select.Option value='耕地'>耕地</Select.Option>
                                                <Select.Option value='森林'>森林</Select.Option>
                                                <Select.Option value='草地'>草地</Select.Option>
                                                <Select.Option value='灌木'>灌木</Select.Option>
                                                <Select.Option value='水体'>水体</Select.Option>
                                                <Select.Option value='人造地表'>人造地表</Select.Option>
                                                <Select.Option value='其他'>其他</Select.Option>
                                                <Select.Option value='湿地'>湿地</Select.Option>
                                                <Select.Option value='苔原'>苔原</Select.Option>
                                                <Select.Option value='裸地'>裸地</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div>
                                        <Form.Item
                                            name='time'
                                        >
                                            <RangePicker
                                                format='YYYYMMDD'
                                                // disabledDate={disabledDate}//禁用的日期
                                                popupClassName='myRangePicker'
                                                className={Style.myRangePicker}
                                            // popupStyle={{
                                            //     backgroundColor: 'rgba(0, 57, 115, 0.8)',
                                            //     border: '1px solid rgba(38, 147, 255, 0.6)',
                                            //     color: 'white',
                                            // }}
                                            />
                                        </Form.Item>
                                    </div>

                                </div>
                                <Form.Item
                                    name='submit'
                                    style={{ textAlign: "right", marginTop: '20px' }}
                                >
                                    <Space>
                                        <Button htmlType='submit' ghost className={Style.formBotten} size='small'>查询</Button>
                                        <Button ghost className={Style.formBotten} size='small' onClick={() => {
                                            resetF()
                                        }}>重置</Button>
                                    </Space>
                                </Form.Item>
                            </Form>
                        </div>
                        <div className={Style.exportWra}>
                            <div>火点列表</div>
                            <div>
                                <Button size='small' type='primary' onClick={exportExcel} icon={<CloudDownloadOutlined className={Style.exportIcon} />}>导出火点</Button>
                            </div>
                        </div>
                        <div className={Style.tableWra}>
                            <Spin spinning={spinning}>
                                <Table
                                    columns={columns}
                                    dataSource={dataSource}
                                    size='small'
                                    tableLayout='fixed'
                                    id='historyFireTableTwo'
                                    pagination={pagination}
                                    onRow={record => {
                                        return {
                                            onClick: event => { rowClick(record) }, // 点击行
                                        };
                                    }}
                                    rowClassName={(record, index) => {
                                        if (index % 2 === 1) {
                                            return Style.heiehi
                                        }

                                    }}
                                    locale={{
                                        emptyText: <div style={{ padding: '2vh' }}>
                                            <InboxOutlined style={{ color: 'white', fontSize: '64px' }} />
                                            <p style={{ color: 'white' }}>暂无火点</p>
                                        </div>,
                                    }}
                                ></Table>
                            </Spin>
                        </div>
                    </div>
                    <div className={Style.closeWrapper} onClick={closeBottom} >
                        <Tooltip title={isOpen ? '收起' : '展开'} placement="left">
                            <img src={isOpen ? close1 : open1} alt=''></img>
                        </Tooltip>
                    </div>
                </div>
                <BottomSider bottomFireData={dataSource} viewer={viewer} />
            </div>
        </>
    )
}