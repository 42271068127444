// 区域统计
import React, { useEffect, useRef, useContext } from 'react'
import * as echarts from 'echarts';
import UserContext from '../../common/UserContext'

let timeOutss;
let mouse = true//鼠标移入移除是否停止移动
export default function Region({ dataSource, hoverBoundary }) {
    const refEcharts = useRef()
    const userContext = useContext(UserContext)

    useEffect(() => {
        if (dataSource && dataSource.length > 0) {
            var chartDom = refEcharts.current
            if (chartDom) {
                const color = ['#00ffff', '#006ced', '#ffe000', '#ffa800', '#00cfff', '#ff5b00', '#ff3000']
                // 判断按什么统计，省级按市统计，依次类推
                let name = 'countyName'
                let code = 'cityCode'
                // switch (userContext.userInfo.countyLevel) {
                //     case '管理员':
                //         // code = 'proCode'
                //         // name = 'proName'
                //         code = 'cityCode'
                //         name = 'cityName'
                //         break;
                //     case '省级':
                //         code = 'cityCode'
                //         name = 'countyName'
                //         break;
                //     case '市级':
                //         code = 'cityCode'
                //         name = 'countyName'
                //         break;
                //     case '县级':
                //         code = 'townCode'
                //         name = 'townName'
                //         break;
                //     default:
                //         code = 'townCode'
                //         name = 'townName'
                //         break;
                // }
                const newchartdata = sortArr(dataSource, 'countyName')
                // const xAxis = Object.keys(newchartdata)
                // const seriesData = Object.values(newchartdata)
                const xAxis = []
                const seriesData = []
                let i = 0
                for (const key in newchartdata) {
                    // if (!key || key === 'null') {
                    // }
                    xAxis.push(key)
                    seriesData.push({
                        value: newchartdata[key].length,
                        additionalData: newchartdata[key],
                        // itemStyle: {
                        //     borderColor: color[i],
                        //     shadowColor: color[i],
                        //     color: color[i],
                        // },
                        label: {
                            // color: color[i],
                            color: 'white',
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            textStyle: { //数值样式
                                fontSize: 10
                            }
                        }
                    })

                    if (i < color.length - 1) {
                        i++
                    } else {
                        i = 0
                    }
                    // }
                }

                const option = {
                    xAxis: {
                        type: 'category',
                        data: xAxis,
                        axisLabel: {
                            show: true,
                            color: 'rgba(255, 255, 255, 0.7)',
                            interval: 0,
                            rotate: 50,
                        },
                        axisLine: {
                            lineStyle: {
                                type: 'solid',
                                color: 'rgba(255, 255, 255, 0.5)',//左边线的颜色
                                width: '1'//坐标线的宽度
                            }
                        },
                    },
                    dataZoom: [
                        // {
                        //     type: 'slider',
                        //     startValue: 0,
                        //     endValue: 10
                        // },
                        {
                            // type: "inside",
                            type: 'slider',
                            show: true,
                            height: 15,
                            startValue: 0,
                            endValue: 10,
                        },
                    ],
                    yAxis: {
                        type: 'value',
                        show: false,
                    },
                    tooltip: {
                        //将trigger设置为item
                        trigger: 'item',
                        //将在formatter中设置hover效果
                        formatter: function (params) {
                            // hoverBoundary(params.data.additionalData['0'].countyCode)
                        }
                    },
                    grid: {
                        left: '6%',
                        right: '5%',
                        top: "10%",
                        containLabel: false,//是否包含X轴
                        y2: 100,//y2表示dataZoom与X轴的距离
                    },
                    series: [{
                        data: seriesData,
                        type: 'bar',
                        barWidth: 15,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: 'rgba(37, 211, 190, 1) ' },
                                // { offset: 0.5, color: '#188df0' },
                                { offset: 1, color: ' rgba(20, 49, 68, 0.31)' }
                            ]),
                            borderRadius: [8, 8, 0, 0] //（顺时针左上，右上，右下，左下）
                        },
                        // emphasis: {
                        //     itemStyle: {
                        //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        //             { offset: 0, color: '#2378f7' },
                        //             { offset: 0.7, color: '#2378f7' },
                        //             { offset: 1, color: '#83bff6' }
                        //         ])
                        //     }
                        // },
                        label: {
                            // fontSize: 2,
                            formatter: function (params) {
                                return params.value + '处'
                            },
                        }
                    }]
                };
                const TwoChart = echarts.init(chartDom)
                TwoChart.setOption(option)
                function autoMove(boo) {
                    if (boo === true) {
                        clearInterval(timeOutss)
                        timeOutss = setInterval(() => {
                            if (mouse) {
                                if (Number(option.dataZoom[0].endValue) >= seriesData.length - 1) {
                                    option.dataZoom[0].startValue = 0;
                                    option.dataZoom[0].endValue = 10;
                                } else {
                                    option.dataZoom[0].endValue = option.dataZoom[0].endValue + 1;
                                    option.dataZoom[0].startValue = option.dataZoom[0].startValue + 1;
                                }
                                TwoChart.setOption(option)
                            }
                        }, 1000);
                    } else {
                        clearInterval(timeOutss)
                    }
                }
                // TwoChart.on('mouseover', () => clearInterval(timeOutss))//鼠标进入
                // TwoChart.on('mouseout', () => autoMove(true))//鼠标离开

                // 给echarts图表设置点击事件后，会导致出发多次请求，设置off即可解决
                TwoChart.off('click') //图表渲染前销毁点击事件,防止点击图标多次触发
                TwoChart.on("click", (param) => TwoChartClick(param, code));

                autoMove(true)
                window.addEventListener("resize", () => {
                    TwoChart.resize();
                });

            } else {
                clearInterval(timeOutss)
            }
        } else {
            clearInterval(timeOutss)
        }
        // eslint-disable-next-line
    }, [dataSource])


    // 去除数组中具有相同值的
    // 数组，相同值的key
    function sortArr(arr, str) {
        const newArray = {}
        arr.forEach(item => {
            newArray[item[str]] = newArray[item[str]] ? newArray[item[str]] : []
            newArray[item[str]].push(item)
        })
        return newArray;
    }


    // 图表点击事件
    function TwoChartClick(param, dd) {
        if (param && hoverBoundary) {
            hoverBoundary(param.data.additionalData['0'][dd], dd)
        } else {
            // message.error('没有数据')
        }
    }
    // 鼠标移入
    function onBobaoEnter() {
        // clearInterval(timeOutss)
        mouse = false
    }
    // 鼠标移出
    function onBobaoLeave() {
        // autoMove(true)
        mouse = true

    }

    return (
        <div ref={refEcharts} style={{ width: '100%', height: "100%" }} onMouseEnter={onBobaoEnter}
            onMouseLeave={onBobaoLeave}></div>
    )
}