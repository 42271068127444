import React, { useEffect, useState, useContext } from 'react'
// import * as echarts from 'echarts'
import { Tooltip, Image, Select } from 'antd';
import dayjs from 'dayjs';

import Style from './BottomSider.module.css'
// import usePublic from '../usePublic/usePublic'
// import CesiumContext from '../../common/CesiumContext'
// import noDataSvg from './image/nodata.svg'
import TitleCom from '../usePublic/TitleCom/TitleCom'
import Axios from '../../common/Axios';
import UserContext from '../../common/UserContext'
import close1 from '../../common/image/close2.png'
import open1 from '../../common/image/open2.png'
import Weather from '../Echarts/Weather'

let bobaoInterv = null
export default function BottomSider({ bottomFireData, viewer }) {
    const userContext = useContext(UserContext)

    const [bobao, setBobao] = useState([])

    useEffect(() => {
        init()
        const code = userContext.userInfo.countyCode
        if (code) {
            weatherGet(userContext.userInfo.countyCode, userContext.userInfo.countyCode)
        }

        // 获取云图
        getCloudCharts()
        return () => {
            if (bobaoInterv) {
                clearInterval(bobaoInterv)
                bobaoInterv = null
            }
        }
        // eslint-disable-next-line
    }, [])

    // 获取无权限的火点详细
    function init() {
        Axios({
            url: '/queryFirePointBroadcast',
            method: "get",
        }).then(res => {
            if (res.data.status === 0) {
                setBobao(res.data.data)
                // 全国火情滚动播报
                if (res.data.data && res.data.data.length > 0) {
                    autoGunDong()
                }
            }
        }).catch(err => { })
    }


    // 自动滚动
    function autoGunDong() {
        var parent = document.getElementById('par');
        var child1 = document.getElementById('chil');
        if (parent && child1) {
            // 设置定时器，时间即为滚动速度
            bobaoInterv = setInterval(function () {
                if (parent.scrollTop >= child1.scrollHeight - 300) {
                    parent.scrollTop = 0;
                } else {
                    parent.scrollTop = parent.scrollTop + 1;
                }
            }, 30);
        }
    }


    const [isOpen, setIsOpen] = useState(true)//开关
    // 收起
    function closeBottom() {
        // setIsOpen(V => !V)
        const hisRight = document.getElementById('BottomWrapperID')
        hisRight.style.transform = isOpen ? 'translateY(calc(100% + 20px))' : 'translateY(0)'
        let time = setInterval(() => {
            setIsOpen(V => !V)
            clearInterval(time)
        }, 900)
    }

    const [weatherData, setWeatherData] = useState({})
    // const [weatherTitle, setWeatherTitle] = useState('')//天气标题
    // 这是新的获取天气的
    function weatherGet(pro, code) {
        Axios({
            method: 'get',
            url: '/gaode/weather',
            params: {
                code: code
            },
        }).then(res => {
            if (res.data.status === 0) {
                const data = res.data.data.forecasts['0']
                // setWeatherTitle(data.city)//天气标题地市
                // 处理数据
                const weathe = data.casts
                const time = []
                const value = []
                weathe.forEach(item => {
                    time.push(dayjs(item.date).format('MM-DD'))
                    // daytemp白天温度
                    // 判断是不是今天
                    if (item.date === dayjs(new Date()).format('YYYY-MM-DD')) {
                        value.push({
                            other: { ...item },
                            value: item.daytemp,
                            itemStyle: {
                                normal: {
                                    color: "#FFF777",
                                },
                            },
                            symbol: 'pin',
                            symbolSize: 10,
                            label: {
                                color: '#FFF777'
                            }
                        })
                    } else {
                        value.push({
                            other: { ...item },
                            value: item.daytemp
                        })
                    }

                })
                setWeatherData({
                    xAxis: time,
                    series: value,
                    name: data.city
                })
            }
        }).catch(err => { })
    }

    //云图列表
    const [cloud, setCloud] = useState([])

    //当前显示的云图
    const [cloudShow, setCloudShow] = useState({})

    function cloudHandle(e, value) {
        setCloudShow(cloud[e])
    }

    // 获取云图
    function getCloudCharts() {
        Axios({
            method: 'get',
            url: '/cloudCharts'
        }).then(res => {
            if (res.data.status === 0) {
                let data = res.data.data['FY4A真彩色'].map((item, index) => {
                    return {
                        ...item,
                        key: index
                    }
                })
                setCloud(data)//更新云图列表
                // 默认选中第一个
                setCloudShow(data['0'])
            }
        })
    }
    return (
        <>
            <div className={Style.BottomWrapper} id='BottomWrapperID'
                style={{
                    // transform: isOpen ? 'translateY(0)' : 'translateY(calc(100% + 20px))',
                    transition: '1s'
                }}>

                <p className={Style.closeWrapper} onClick={closeBottom} >
                    <Tooltip title={isOpen ? '收起' : '展开'} placement="top">  <img src={isOpen ? close1 : open1} alt=''></img>
                    </Tooltip>
                </p>


                {/* 天气情况 */}
                <div>
                    <div><TitleCom title={`${weatherData && weatherData.name ? weatherData.name : ''}天气情况`} type={2} /></div>
                    <div>
                        {
                            weatherData && Object.keys(weatherData).length > 0 ?
                                <Weather weatherData={weatherData} />
                                :
                                <div className={Style.noDatap}>
                                    <p>暂无天气信息</p>
                                </div>
                        }
                    </div>
                </div>
                {/* 全国火情快讯 */}
                <div>
                    <div><TitleCom title='全国火情快讯' type={2} /></div>
                    {
                        bobao && bobao.length > 0 ?
                            <div className={Style.listWra} id='par'>
                                <div id='chil' className={Style.listWrachil}>
                                    {
                                        bobao.map((item, index) => <div key={index} className={index % 2 === 1 ? Style.listlist : Style.listlistDan} >
                                            <div>{item.firePointAddress}</div>
                                            <div>{item.satelliteTime}</div>
                                        </div>)
                                    }
                                </div>
                            </div>
                            :
                            <div className={Style.noDatap}>
                                <p >暂无火点</p>
                            </div>
                    }
                </div>
                {/* 火险等级 */}
                <div style={{ position: 'relative' }}>
                    <div><TitleCom title='云图' type={2} /></div>
                    {
                        cloudShow && cloudShow.imgUrl ?
                            <div className={Style.cloudWra}>
                                <Image src={window.config.yuntuUrl() + cloudShow.imgUrl} />
                            </div>
                            :
                            <div className={Style.noDatap}>
                                <p >暂无云图</p>
                            </div>
                    }
                    <div className={Style.cloudSelectWra}>
                        <Select onChange={cloudHandle} popupClassName='popupClassNameSelect' value={cloudShow.key}>
                            {cloud.map((item, index) => <Select.Option key={item.key} value={item.key}>{item.time}</Select.Option>)}
                        </Select>
                    </div>
                </div>


            </div>
        </>

    )
}