// 实时火情
import React, { useEffect, useState, useContext, useRef } from 'react'
// import axios from 'axios'
// import * as echarts from 'echarts';
import * as Cesium from 'cesium';
import dayjs from 'dayjs';
import { CloseOutlined, CopyOutlined } from '@ant-design/icons';
import { message, Carousel, Tooltip, } from 'antd'


// import { Checkbox } from 'antd'
import usePublic from '../usePublic/usePublic'
// import CesiumContext from '../../common/CesiumContext'
import Style from './RealTimeFire.module.css'
import MainMap from '../MainMap/MainMap'
import TitleCom from '../usePublic/TitleCom/TitleCom'
import BottomSider from './BottomSider'
// import axios from 'axios';
// import noDataSvg from '../image/nodata.svg'
import Axios from '../../common/Axios'
// import postionPng from '../../common/image/postion.png'
// import postionPng from '../../common/image/Frame99.png'
import postionPng from '../../common/image/Frame999.png'
// import todayWarn from '../../common/image/todayWarn.png'
// import todayWarnShow from '../../common/image/todayWarnShow.webp'
import UserContext from '../../common/UserContext'
// import Weather from '../Echarts/Weather'
import Region from '../Echarts/Region'

import CesiumTool from '../CesiumTool/CesiumTool'
import close1 from '../../common/image/close1.png'
import open1 from '../../common/image/open1.png'
import quan from '../../common/image/green.png'
import quanSelect from '../../common/image/green2.png'
// let { getNationalFireAdress } = window.config
let bobaoInterval//滚动定时器
let timers = null
export default function RealTimeFire({ fireBroadcast }) {
    const userContext = useContext(UserContext)

    const [viewer, setViewer] = useState()
    const { addFireEntitys, deleteEntityByName, sortArr, addBoundary } = usePublic({ viewer })//添加火点
    // 实时火点
    const [realTimeFirePoint, setRealTimeFirePoint] = useState()
    const [fireDataAll, setFireDataAll] = useState([])//全部火情列表
    const [fireData, setFireData] = useState([])//筛选之后火情列表
    const [sateType, setSateType] = useState({
        'NOAA-20': { data: [], read: true },
        'MODIS': { data: [], read: true },
        'Suomi NPP': { data: [], read: true },
        'Himawari 8': { data: [], read: true },
        'GK2A': { data: [], read: true },
        'FY4A': { data: [], read: true },
    })//卫星类型

    useEffect(() => {
        if (viewer) {
            init()
            ti()
            postion()
            // viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
            // viewer.scene.globe.depthTestAgainstTerrain = true;
            // viewer.entities.add({
            //     position: new Cesium.Cartesian3.fromDegrees(120, 36, 100000),
            //     point: new Cesium.PointGraphics({
            //         show: true,
            //         pixelSize: 15,
            //         color: Cesium.Color.fromBytes(139, 0, 0),
            //         outlineColor: new Cesium.Color(0, 0, 0, 0),
            //         outlineWidth: 0,
            //     })
            // })
        }

        return () => {
            if (bobaoInterval) {
                clearInterval(bobaoInterval)
                bobaoInterval = null
            }
            if (timers) {
                clearInterval(timers)
                timers = null
            }
            if (viewer) {
                // viewer.destroy()
            }
        }
        // eslint-disable-next-line 
    }, [viewer])

    // 地图定位，添加边界矢量
    function postion() {
        // const code = localStorage.getItem('countyCode')
        const code = userContext.userInfo.countyCode
        if (code) {
            // const data = localStorage.getItem('countyLevel')
            const data = userContext.userInfo.countyLevel
            switch (data) {
                case '管理员':
                    addBoundary(1, code, true, "所在省", '#FA8072')
                    break;
                case '省级':
                    addBoundary(1, code, true, "所在省", '#FA8072')
                    break;
                case '市级':
                    addBoundary(2, code, true, "所在市", '#FA8072')
                    break;
                case '县级':
                    addBoundary(3, code, true, "所在县", '#FA8072')
                    break;
                case '街道级':
                    // 截取前6位
                    // addBoundary(3, code.slice(0, 6), true, "所在街道", '#FA8072')
                    addBoundary(4, code, true, "所在街道", '#FA8072')
                    break;
                default:
            }
        }
    }

    // 五分钟内实时火情刷新
    async function ti() {
        timers = setInterval(async () => {
            const timeData = await queryFirePoint({
                startTime: dayjs().format('YYYY-MM-DD') + ' 00:00:00',
                // startTime: dayjs().subtract(1, 'day').format('YYYY-MM-DD') + ' 00:00:00',
                endTime: dayjs().format('YYYY-MM-DD') + ' 23:59:59'
            })
            if (timeData) {
                const realData = []
                const nowDate = Date.parse(new Date()) / 1000//现在的时间
                timeData.forEach(item => {
                    const searchData = Date.parse(new Date(item.addTime)) / 1000;//火点发现时间的时间戳
                    if (nowDate - searchData <= 1 * 60 * 5) {
                        // 五分钟内新火点
                        realData.push(item)
                    }
                })
                if (realData.length > 0) {
                    // 新火点提示
                    setRealTimeFirePoint(realData)
                }
            }

            // 五分钟更新一次数据，获取又有新火点被审核
            init()

        }, 1000 * 60 * 5)//毫秒，1秒=1000毫秒
        // }, 1000)//毫秒，1秒=1000毫秒
    }


    // 初始化查询火点
    async function init() {
        const data = await queryFirePoint({
            code: '',
            startTime: dayjs().format('YYYY-MM-DD') + ' 00:00:00',
            endTime: dayjs().format('YYYY-MM-DD') + ' 23:59:59',
            satelliteType: "",
            landType: "",
        })
        if (data) {
            setFireDataAll(data)
            setFireData(data)//更新数据
            // setFireDataAll(data)
            addFireEntitys(data)//添加地图火点
            autoGunDong()//滚动
            // 卫星统计

            const sate = sortArr(data, 'satelliteType')
            let dd = {
                ...sateType
            }
            let SateType = {
                'NOAA-20': { ...dd['NOAA-20'], data: sate['NOAA-20'] ? sate['NOAA-20'] : [] },
                'MODIS': { ...dd['MODIS'], data: sate['MODIS'] ? sate['MODIS'] : [] },
                'Suomi NPP': { ...dd['Suomi NPP'], data: sate['Suomi NPP'] ? sate['Suomi NPP'] : [] },
                'Himawari 8': { ...dd['Himawari 8'], data: sate['Himawari 8'] ? sate['Himawari 8'] : [] },
                'GK2A': { ...dd['GK2A'], data: sate['GK2A'] ? sate['GK2A'] : [] },
                'FY4A': { ...dd['FY4A'], data: sate['FY4A'] ? sate['FY4A'] : [] },
            }
            setSateType(SateType)
        }
    }


    // 动态多条件查询火点
    function queryFirePoint(values) {
        return new Promise(resolve => {
            Axios({
                method: 'get',
                url: '/queryFirePoint',
                params: {
                    ...values,
                    code: values.code ? values.code : userContext.userInfo.countyCode
                },
            }).then(function (response) {
                if (response.data.status === 0) {
                    resolve(response.data.data)
                } else {
                    resolve()
                }
            }).catch(function (error) {
                console.log(error);
            })
        })
    }

    // 鼠标移入党员列表
    function onBobaoEnter() {
        clearInterval(bobaoInterval)
    }
    // 鼠标移出党员列表
    function onBobaoLeave() {
        clearInterval(bobaoInterval)
        bobaoInterval = null
        autoGunDong()
    }
    // 自动滚动
    function autoGunDong() {
        var parent = document.getElementById('parent');
        var child1 = document.getElementById('child1');
        if (parent && child1 && !bobaoInterval) {//预防组件更新多次刷新
            // if (bobaoInterval) {
            //     clearInterval(bobaoInterval)
            //     bobaoInterval = null
            // }
            // 设置定时器，时间即为滚动速度
            bobaoInterval = setInterval(function () {
                if (parent.scrollTop >= child1.scrollHeight - 300) {
                    parent.scrollTop = 0;
                } else {
                    parent.scrollTop = parent.scrollTop + 1;
                }
            }, 30);
        }
    }


    // 实时火情点击
    function FireScrollClick(value) {
        viewer.camera.flyTo({
            destination: Cesium.Cartesian3.fromDegrees(value.longitude, value.latitude, 10000),
            duration: 2,
        })

        deleteEntityByName('biaozhi')//删除闲着没事写的标志

        // deleteEntityByName('fireInfo_' + value.fireCode)//删除这个实体，然后添加他，使他位于上层
        // addFireEntitys([value], true)//添加查询到的火苗信息
        // // 移动试图
        // viewer.camera.flyTo({
        //     destination: Cesium.Cartesian3.fromDegrees(value.longitude, value.latitude, 10000),
        // });
        viewer.entities.add({
            name: 'biaozhi',
            position: Cesium.Cartesian3.fromDegrees(value.longitude, value.latitude),
            ellipse: {
                semiMinorAxis: 150.0,//长轴
                semiMajorAxis: 150.0,//短轴
                // rotation: Cesium.Math.toRadians(-40.0),//逆时针旋转
                outline: true,
                outlineColor: Cesium.Color.fromCssColorString('rgba(220,20,60,1)'),
                outlineWidth: 4,
                stRotation: Cesium.Math.toRadians(90),
                fill: true,
                material: Cesium.Color.fromCssColorString('rgba(220,20,60,0.4)'),
            },
        });

    }

    // 今日预警情况点击
    function selectCheck(value) {
        const readState = { ...sateType }
        readState[value].read = !readState[value].read
        setSateType(readState)

        // 筛选显示的火点
        let newData = []
        for (let key in readState) {
            if (readState[key].read) {
                newData.push(...readState[key].data)
            }
        }
        setFireData(newData)
        addFireEntitys(newData)
    }


    function copyLon(value) {
        // const range = document.createRange(); //创建range对象
        // range.selectNode(document.getElementById('copyLon')); //获取复制内容的 id 选择器
        // const selection = window.getSelection();  //创建 selection对象
        // if (selection.rangeCount > 0) selection.removeAllRanges(); //如果页面已经有选取了的话，会自动删除这个选区，没有选区的话，会把这个选取加入选区
        // selection.addRange(range); //将range对象添加到selection选区当中，会高亮文本块
        // document.execCommand('copy'); //复制选中的文字到剪贴板
        // message.success('复制成功')
        // selection.removeRange(range); // 移除选中的元素


        //新：只适用于https以及localhost
        // navigator.clipboard.writeText(value).then(() => {
        //     message.success('复制成功')
        // }).catch(err=>{
        //     console.log('err=>',err)
        // });

        const url = value
        let oInput = document.createElement('input')
        oInput.value = url
        document.body.appendChild(oInput)
        oInput.select() // 选择对象;
        document.execCommand('Copy') // 执行浏览器复制命令
        message.success('复制成功')
        oInput.remove()
    }

    // 五分钟火情播报分页
    const ref = useRef()
    const [current, setCurrent] = useState(0)
    function prev() {
        ref.current.prev()//上一页
        setCurrent(v => {
            if (v === 0) {
                return realTimeFirePoint.length - 1
            } else {
                return v - 1
            }
        })
    }
    function next() {
        ref.current.next()
        setCurrent(v => {
            if (v === realTimeFirePoint.length - 1) {
                return 0
            } else {
                return v + 1
            }
        })
    }
    function selectCurret(item) {
        setCurrent(item)
    }

    // 右侧开关
    const [isOpen, setIsOpen] = useState(true)//开关
    function closeBottom() {
        // setIsOpen(V => !V)
        const hisRight = document.getElementById('hisRight')
        hisRight.style.transform = isOpen ? 'translateX(calc(100% + 20px))' : 'translateX(0)'
        let time = setInterval(() => {
            setIsOpen(V => !V)
            clearInterval(time)
        }, 900)
    }

    function hoverBoundary(code, dd) {
        if (viewer) {
            // 重新绘制区县边界
            let isTrue = false
            // 该市县的边界的是否已经存在
            var entitys = viewer.entities._entities._array;
            let length = entitys.length
            for (var f = length - 1; f >= 0; f--) {
                if (entitys[f]._name && entitys[f]._name.indexOf("hoverBoundary") > -1 && entitys[f]._name === "hoverBoundary" + code) {
                    // 已存在
                    isTrue = true;
                } else if (entitys[f]._name && entitys[f]._name.indexOf("hoverBoundary") > -1 && entitys[f]._name !== "hoverBoundary" + code) {
                    viewer.entities.remove(entitys[f]);//删除
                }
            }
            deleteEntityByName('selectCity')//删除历史火点查询select产生的实体
            // 本来有则删除，本来无则添加
            if (isTrue === false) {
                // 更新天气
                //code区县代码，取出前四位
                // const cityCode = code.substring(0, 4) + '00'
                // cesiumContext.setweatherCode(cityCode)
                // 添加边界
                // addBoundary(3, code, true, "hoverBoundary" + code, '#1E90FF')
                switch (dd) {
                    // case 'proCode':
                    //     addBoundary(1, code, true, "hoverBoundary" + code, '#1E90FF')
                    //     break;
                    case 'cityCode':
                        addBoundary(2, code, true, "hoverBoundary" + code, '#1E90FF')
                        break;
                    case 'countyCode':
                        addBoundary(3, code, true, "hoverBoundary" + code, '#1E90FF')
                        break;
                    case 'townCode':
                        addBoundary(4, code, true, "hoverBoundary" + code, '#1E90FF')
                        break;
                    default:
                }
            } else {
                // cesiumContext.setweatherCode()//天气恢复
                deleteEntityByName("hoverBoundary" + code)//删除历史火点查询select产生的实体
            }
        }
    }

    return (
        <div className={Style.mainWra}>
            <MainMap setViewer={setViewer} />
            <CesiumTool viewer={viewer} />

            <div className={Style.rightWra} id='hisRight'
                style={{
                    // transform: isOpen ? 'translateX(0)' : 'translateX(calc(100% + 20px))',
                    transition: '1s'
                }}
            >
                <div>
                    <div><TitleCom title={'今日火点个数：' + fireDataAll.length} /></div>
                    <div className={Style.todayWarnWra1} >
                        {Object.keys(sateType).map((item, index) => <div
                            key={index}
                            className={Style.todayWarnList1}
                            onClick={() => { selectCheck(item) }}
                        >
                            {/* style={{ color: sateType[item].read ? 'rgb(33, 193, 176)' : 'rgb(61, 127, 254)' }} */}
                            <div>
                                <img className={sateType[item].read ? Style.zhuan : Style.buzhuan} src={sateType[item].read ? quan : quanSelect} alt=''></img>
                                <div>{sateType[item]['data'].length}</div>
                            </div>
                            <div>
                                <div>{item}</div></div>
                        </div>)}
                    </div>
                </div>

                <div>
                    <div><TitleCom title='今日火情播报' /></div>
                    <div id='parent' onMouseEnter={onBobaoEnter}
                        onMouseLeave={onBobaoLeave} className={Style.todayFireWra}>
                        {fireData && fireData.length > 0 ?
                            <div id='child1' >
                                {fireData.map((item, index) => <div key={index} className={Style.firelistWra}>
                                    <div className={Style.firelistLeft}>
                                        <div className={Style.rowWra}><div>地点：</div><div>{item.firePointAddress}</div></div>
                                        <div className={Style.rowWraCont}>
                                            <div className={Style.rowWra}><div>时间：</div><div>{dayjs(item.satelliteTime).format('HH:mm:ss')}</div></div>
                                            <div className={Style.rowWra}><div>经纬度：</div><div>{item.longitude + ',' + item.latitude}</div></div>
                                        </div>
                                    </div>
                                    <div className={Style.firelistRight} onClick={() => FireScrollClick(item)}>
                                        <img src={postionPng} alt='' />
                                        <div>查看定位</div>
                                    </div>
                                </div>)
                                }
                            </div>
                            : <div className={Style.noDataWrapper}>
                                <p>暂无火情播报</p>
                            </div>
                        }
                    </div>
                </div>
                <div>
                    <div><TitleCom title='区域统计' /></div>
                    {
                        fireData && fireData.length > 0 ? <div>
                            <Region dataSource={fireData} hoverBoundary={hoverBoundary} />
                        </div>
                            : <div className={Style.noDatap}>
                                <p >暂无火点</p>
                            </div>
                    }

                </div>

                <div className={Style.closeWrapper} onClick={closeBottom} >
                    <Tooltip title={isOpen ? '收起' : '展开'} placement="left">
                        <img src={isOpen ? close1 : open1} alt=''></img>
                    </Tooltip>
                </div>
            </div>
            <BottomSider bottomFireData={fireData} viewer={viewer} />

            {/* 五分钟火情播报 */}
            {realTimeFirePoint && realTimeFirePoint.length > 0 ?
                <div className={Style.realTimeFirePointWrapper} id='realTimeFirePointWrapperId'>
                    <div className={Style.closeButton}>
                        <p className={Style.firepopDataPP}>近五分钟火情播报-{realTimeFirePoint.length + '条'}</p>
                        <CloseOutlined onClick={() => setRealTimeFirePoint()} style={{ color: 'white', fontSize: '2vh' }} />
                    </div>
                    {/* {
                        realTimeFirePoint && realTimeFirePoint.length > 0 ? realTimeFirePoint.map(item =>
                            <div className={Style.firepopData} key={item.id}>
                                <p>地区：{item.countyName}</p>
                                <p>详细地址：{item.firePointAddress}</p>
                                <p>经度：<span id={'copyLon' + item.id}>{item.longitude}</span> °  <CopyOutlined style={{ color: '#009fff', cursor: 'pointer' }} onClick={() => copyLon(item.id)} /></p>
                                <p>纬度：<span id={'copyLat' + item.id}>{item.latitude}</span> °  <CopyOutlined style={{ color: '#009fff', cursor: 'pointer' }} onClick={() => copyLat(item.id)} /></p>
                                <p>时间：{item.satelliteTime}</p>
                                <p>数据源：{item.satelliteType}</p>
                            </div>
                        ) : ''
                    } */}
                    <div className={Style.mypageWrapperer}>
                        {/* <Pagination defaultCurrent={1} itemRender={itemRender} total={firedata.length} current={current} defaultPageSize={1} onChange={onChange} /> */}
                        <div className={Style.mypage_left} onClick={prev}>&lsaquo;</div>
                        <div className={Style.mypage_content}>
                            <Carousel ref={ref} slidesToShow={realTimeFirePoint.length > 4 ? 4 : realTimeFirePoint.length} dots={false}>
                                {
                                    realTimeFirePoint.map((item, index) => {
                                        return <div key={index} className={Style.CarouselList}>
                                            <div className={current === index ? Style.selectCarouselList : ''}
                                                onClick={() => selectCurret(index)}>
                                                火点 {index + 1}
                                            </div>
                                        </div>
                                    })
                                }
                            </Carousel>
                        </div>
                        <div className={Style.mypage_right} onClick={next}>&rsaquo;</div>
                    </div>
                    <div className={Style.firepopData} >
                        <p>地区：{realTimeFirePoint[current].proName + '-' + realTimeFirePoint[current].cityName + '-' + realTimeFirePoint[current].countyName}</p>
                        <p>详细地址：{realTimeFirePoint[current].firePointAddress}</p>
                        <p>经纬度：
                            <span id={'copyLon' + realTimeFirePoint[current].id}>
                                {realTimeFirePoint[current].longitude}°{Number(realTimeFirePoint[current].longitude) > 0 ? 'E' : "W"} ,
                                {realTimeFirePoint[current].latitude}°{Number(realTimeFirePoint[current].latitude) > 0 ? 'N' : "S"}
                            </span>
                            <CopyOutlined style={{ color: '#009fff', cursor: 'pointer' }} onClick={() => copyLon(realTimeFirePoint[current].longitude + '     ' + realTimeFirePoint[current].latitude)} />
                        </p>


                        <p>卫星时间：{realTimeFirePoint[current].satelliteTime}</p>
                        <p>置信度：{realTimeFirePoint[current].confidence === 'L' ? '低' : realTimeFirePoint[current].confidence === 'N' ? '中' : realTimeFirePoint[current].confidence === 'H' ? '高' : realTimeFirePoint[current].confidence}</p>
                        <p>数据源：{realTimeFirePoint[current].satelliteType}</p>
                    </div>
                </div> : null}
        </div >
    )
}