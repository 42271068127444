import { useCallback } from "react"
import axios from "axios"
import SuperGif from "libgif"
import * as Cesium from 'cesium';
// import Axios from '../../common/Axios'
// import firePng from './image/fire.png'
// import firePng from '../image/fire.png'
// import firePng from './image/firefill.png'
import firePng1 from './image/fire2.png'
import firePng2 from './image/fire2.png'
import fireWork from './image/fireWork.png'

import fireDongImg from './image/fire.gif'

let { getGeoServerAdress } = window.config
// let billboardSize = 40
export default function usePublic({ viewer }) {


    function drawPoint(whetherStart) {
        return new Promise((resolve, reject) => {
            if (viewer) {
                if (whetherStart) {
                    // 清除以前的点
                    // removeEntityByName('drawPoint')
                    // 鼠标移动，绘制动态点
                    viewer.screenSpaceEventHandler.setInputAction(function (movement) {
                        const newPosition = viewer.camera.pickEllipsoid(movement.endPosition, viewer.scene.globe.ellipsoid);//当前世界坐标笛卡尔积
                        if (Cesium.defined(newPosition)) {
                            // 清除移动点
                            // removeEntityByName('movePoint')
                            // 绘制移动点
                            // viewer.entities.add({
                            //     name: 'movePoint',
                            //     position: newPosition,
                            //     point: new Cesium.PointGraphics({
                            //         show: true,
                            //         pixelSize: 15,
                            //         // heightReference: Cesium.HeightReference.NONE,
                            //         color: Cesium.Color.fromBytes(30, 144, 255),
                            //         outlineColor: new Cesium.Color(0, 0, 0, 0),
                            //         outlineWidth: 0,
                            //         disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
                            //         heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,//贴地
                            //      })
                            // });
                        }
                    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

                    // 鼠标左键绘制最终点
                    viewer.screenSpaceEventHandler.setInputAction(function (movement) {
                        const earthPosition = viewer.camera.pickEllipsoid(movement.position, viewer.scene.globe.ellipsoid);//当前世界坐标笛卡尔积
                        if (Cesium.defined(earthPosition)) {
                            // 清除移动点
                            // removeEntityByName('movePoint')
                            viewer.entities.add({
                                name: 'drawPoint',
                                position: earthPosition,
                                point: new Cesium.PointGraphics({
                                    show: true,
                                    pixelSize: 15,
                                    // heightReference: Cesium.HeightReference.NONE,
                                    color: Cesium.Color.fromBytes(139, 0, 0),
                                    outlineColor: new Cesium.Color(0, 0, 0, 0),
                                    outlineWidth: 0,
                                    // disableDepthTestDistance: Number.POSITIVE_INFINITY, //去掉地形遮挡
                                    // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,//贴地
                                })
                            });
                            // 世界坐标转换为经纬度
                            const carto_position = Cesium.Cartographic.fromCartesian(earthPosition);
                            const longitude = Cesium.Math.toDegrees(carto_position.longitude).toFixed(3);//四舍五入保留三位小数
                            const latitude = Cesium.Math.toDegrees(carto_position.latitude).toFixed(3);

                            viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);//停止监听移动事件
                            viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);//停止监听移动事件
                            const data = { longitude: longitude, latitude: latitude }
                            resolve(data)//返回经纬度
                        }
                    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

                } else {
                    // 清除之前的实体
                    // removeEntityByName('drawPoint')
                    // removeEntityByName('movePoint')
                    viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);//停止监听移动事件
                    viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);//停止监听移动事件
                }
            }
        })
    }

    // 2023年3月29日更改为 审核的火点单独一个颜色不聚合，其他火点一个颜色聚合
    // 所以在此拆分为两个函数
    function addFireEntitys(data, isDelete) {
        // let dataAuitY = []//审核的点
        // let dataAuitN = []//未审核的点
        // data.forEach(item => {
        //     console.log('item:', item)
        //     if (item.confidence === 'H') {
        //         // if (item.fireStatus === '0') {
        //         dataAuitY.push(item)
        //     } else {
        //         dataAuitN.push(item)
        //     }
        // })

        // addFireEntity(dataAuitY, isDelete)
        // addFireEntityTogether(dataAuitN, isDelete)
        addFireEntityTogether(data, isDelete)
    }

    let gif = []
    //  动图
    const addFireEntityGIF = useCallback(
        (data, isDelete) => {
            if (viewer) {
                // let entitys = viewer.entities._entities._array;
                if (data) {
                    loadGif(fireDongImg, gif)
                    // 清除之前的实体
                    if (!isDelete) {
                        deleteEntityByName('fireInfo')
                    }

                    // 添加实体
                    data.forEach(item => {
                        let fireCode = item.fireCode ? item.fireCode : ''
                        let speed = 7, i = 0;
                        viewer.entities.add({
                            name: 'fireInfo_' + fireCode,
                            position: Cesium.Cartesian3.fromDegrees(parseFloat(item.longitude), parseFloat(item.latitude)),
                            billboard: {
                                // image: firePng,
                                image: new Cesium.CallbackProperty(() => {
                                    if (gif.length) {
                                        if (i < speed * (gif.length - 1)) {
                                            i++
                                        } else {
                                            i = 0
                                        }
                                        return gif[Math.floor(i / speed)]
                                    } else {
                                        return fireDongImg//因为loadGif是异步的，在解析完成之前先使用原图
                                    }
                                }, false),
                                scale: 0.9,
                                width: 35,
                                height: 35,
                                disableDepthTestDistance: Number.POSITIVE_INFINITY,//广告牌不进行深度检测
                                // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
                                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND//设置HeightReference高度参考类型为CLAMP_TO_GROUND贴地类型
                            },
                            _fireInfo: item
                        });
                    })
                    // }
                } else {
                    deleteEntityByName('fireInfo')
                }
            }
        },
        // eslint-disable-next-line
        [viewer]
    )

    // 聚合   查询后地图添加火苗实体,data:要添加的数据，isDelete:是否删除以前的fireInfo,不传就是删
    function addFireEntityTogether(data, isDelete) {
        //#region 聚合
        if (viewer) {
            if (data) {
                if (!isDelete) {
                    removeDataSource('fireCodeData')
                }
                // 将数据处理成geojson
                const geojson = {
                    "type": "FeatureCollection",
                    "features": []
                }
                data.forEach(item => {
                    geojson.features.push({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            // "icon": firePng,
                            "coordinates": [parseFloat(item.longitude), parseFloat(item.latitude)]
                        },
                        "properties": {
                            ...item,
                            // "icon": firePng
                        }
                    })
                })

                const options = {
                    camera: viewer.scene.camera,
                    canvas: viewer.scene.canvas,
                    clampToGround: true,//贴地
                };
                // 添加geojson
                const dataSourcePromise = viewer.dataSources.add(
                    Cesium.GeoJsonDataSource.load(geojson, options)
                )

                // const img = firePng;

                dataSourcePromise.then(function (dataSource) {
                    dataSource.name = 'fireCodeData'
                    // 这里添加一下自定义图片，否则会显示默认图标
                    dataSource.entities.values.forEach(entity => {
                        const newData = {}
                        for (let key in entity._properties) {
                            if (key.indexOf('Subscription') < 0) {
                                newData[key.split('_')['1']] = entity._properties[key]._value
                            }
                        }
                        entity._fireInfo = newData
                        entity.name = 'fireInfo_' + newData.fireCode
                        // 将点拉伸一定高度，防止被地形压盖
                        // entity.position._value.z += 1.0;//高度加50，在后来历史火情点击时使用
                        entity.billboard = {
                            image: firePng1,
                            width: 25,
                            height: 25,
                            // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND   //设置HeightReference高度参考类型为CLAMP_TO_GROUND贴地类型
                            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, //设置HeightReference高度参考类型为CLAMP_TO_GROUND贴地类型
                            // disableDepthTestDistance: 99000000,
                        };
                        // 如果要加标签
                        entity.label = {
                            show: false,
                            text: '1',
                            font: 'bold 15px Microsoft YaHei',
                            // 竖直对齐方式
                            verticalOrigin: Cesium.VerticalOrigin.CENTER,
                            // 水平对齐方式
                            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                            // 偏移量
                            pixelOffset: new Cesium.Cartesian2(15, 0),
                        };

                    });

                    const pixelRange = 60;
                    const minimumClusterSize = 2;

                    //clustering 获取或设置此数据源的群集选项。此对象可以在多个数据源之间共享。
                    dataSource.clustering.enabled = true;//获取或设置是否启用群集。
                    dataSource.clustering.pixelRange = pixelRange;//pixelRange 是聚合距离，也就是小于这个距离就会被聚合,以像素为单位
                    dataSource.clustering.minimumClusterSize = minimumClusterSize;//minimumClusterSize是每个聚合点的最小聚合个数，这个值最好是设置为2，因为两个图标也可能叠压。

                    let removeListener;

                    function customStyle() {
                        if (Cesium.defined(removeListener)) {
                            removeListener();
                            removeListener = undefined;
                        } else {
                            removeListener = dataSource.clustering.clusterEvent.addEventListener(
                                function (clusteredEntities, cluster) {
                                    cluster.label.show = false;
                                    cluster.billboard.show = true;
                                    cluster.billboard.verticalOrigin =
                                        Cesium.VerticalOrigin.BOTTOM;
                                    cluster.billboard.id = cluster.label.id;
                                    cluster.billboard.width = 25;
                                    cluster.billboard.height = 42;
                                    // cluster.billboard.image = img;
                                    // 根据聚合数量的多少设置不同层级的图片以及大小
                                    // if (clusteredEntities.length >= 20) {
                                    cluster.billboard.image = combineIconAndLabel(firePng2, clusteredEntities.length, 64);
                                    // } else if (clusteredEntities.length >= 12) {
                                    //     cluster.billboard.image = combineIconAndLabel('./icons/cluster_3.png', clusteredEntities.length, 64);
                                    // } else if (clusteredEntities.length >= 8) {
                                    //     cluster.billboard.image = combineIconAndLabel('./icons/cluster_2.png', clusteredEntities.length, 64);
                                    // } else {
                                    //     cluster.billboard.image = combineIconAndLabel('./icons/cluster_1.png', clusteredEntities.length, 64);
                                    // }

                                }
                            );
                        }
                        // force a re-cluster with the new styling
                        const pixelRange = dataSource.clustering.pixelRange;
                        dataSource.clustering.pixelRange = 0;
                        dataSource.clustering.pixelRange = pixelRange;
                    }
                    customStyle();
                });
            } else {
                removeDataSource('fireCodeData')
            }
        }
        //#endregion

    }

    // 不聚合
    function addFireEntity(data, isDelete) {
        //#region 动图实体
        if (viewer) {
            // let entitys = viewer.entities._entities._array;
            if (data) {
                // 清除之前的实体
                if (!isDelete) {
                    deleteEntityByName('fireInfo')
                }

                // 添加实体
                data.forEach(item => {
                    let fireCode = item.fireCode ? item.fireCode : ''
                    viewer.entities.add({
                        name: 'fireInfo_' + fireCode,
                        position: Cesium.Cartesian3.fromDegrees(parseFloat(item.longitude), parseFloat(item.latitude)),
                        billboard: {
                            image: fireWork,
                            scale: 0.9,
                            width: 35,
                            height: 35,
                            disableDepthTestDistance: Number.POSITIVE_INFINITY,//广告牌不进行深度检测
                            // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND
                            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND//设置HeightReference高度参考类型为CLAMP_TO_GROUND贴地类型
                        },
                        _fireInfo: item
                    });
                })
                // }
            } else {
                deleteEntityByName('fireInfo')
            }
        }
        //#endregion
    }




    /**
    * @description: 将图片和文字合成新图标使用（参考Cesium源码）
    * @param {*} url：图片地址
    * @param {*} label：文字
    * @param {*} size：画布大小
    * @return {*} 返回canvas
    */
    function combineIconAndLabel(url, label, size, color) {
        // 创建画布对象
        let canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size + 40;
        let ctx = canvas.getContext("2d");

        let promise = new Cesium.Resource.fetchImage(url).then(img => {
            // 异常判断
            try {
                ctx.drawImage(img, 0, 0, img.width, img.height, 0, 40, size, size);
            } catch (e) {
                console.log(e);
            }

            ctx.beginPath();
            ctx.arc(10, 10, 10, Math.PI, Math.PI + (Math.PI / 2));
            ctx.arc(size - 10, 10, 10, Math.PI + (Math.PI / 2), Math.PI * 2);
            ctx.arc(size - 10, 30, 10, 0, Math.PI / 2);
            ctx.arc(10, 30, 10, Math.PI / 2, Math.PI);
            //   ctx.stroke();
            //   ctx.fillStyle = 'red';

            var gradient = ctx.createLinearGradient(0, 0, 0, 40);
            // Add three color stops
            gradient.addColorStop(0, 'rgb(251 82 80)');
            gradient.addColorStop(1, 'rgb(234 38 36)');

            // Set the fill style and draw a rectangle
            ctx.fillStyle = gradient;
            //   ctx.fillRect(20, 20, 200, 100);

            ctx.fill();

            // 渲染字体
            // font属性设置顺序：font-style, font-variant, font-weight, font-size, line-height, font-family
            ctx.fillStyle = Cesium.Color.WHITE.toCssColorString();
            ctx.font = '40px Microsoft YaHei';
            ctx.textAlign = "center";
            ctx.textBaseline = "bottom";
            ctx.fillText(label, size / 2, 40, size);
            //    text 要绘制的文本,x,y 文本绘制的坐标（文本左下角）,maxWidth 设置文本最大宽度，可选参数

            return canvas;
        });


        // let promise = new Cesium.Resource.fetchImage(url).then(img => {
        //     // 异常判断
        //     try {
        //         // ctx.drawImage(image, 0, 0);
        //         ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, size, size);

        //     } catch (e) {
        //         console.log(e);
        //     }

        //     // 渲染字体
        //     // font属性设置顺序：font-style, font-variant, font-weight, font-size, line-height, font-family
        //     ctx.fillStyle = Cesium.Color.WHITE.toCssColorString();
        //     ctx.font = '35px Microsoft YaHei';
        //     ctx.textAlign = "center";
        //     ctx.textBaseline = "middle";
        //     ctx.fillText(label, size / 2, (size / 2) + 10);

        //     return canvas;
        // });

        return promise;
    }

    function removeDataSource(layerName) {
        const dataSources = viewer.dataSources._dataSources
        for (let k = dataSources.length - 1; k >= 0; k--) {
            if (dataSources[k].name && dataSources[k].name === layerName) {
                viewer.dataSources.remove(dataSources[k])
            }
        }
    }

    const loadGif = (url, imageArr = []) => {
        const img = document.createElement('img');
        img.src = url
        // gif库需要img标签配置下面两个属性
        img.setAttribute('rel:animated_src', url)
        img.setAttribute('rel:auto_play', '0')
        document.body.appendChild(img)
        // 新建gif实例
        const rub = new SuperGif({ gif: img });
        return new Promise((resolve) => {
            rub.load(() => {
                for (let i = 1; i <= rub.get_length(); i++) {
                    // 遍历gif实例的每一帧
                    rub.move_to(i);
                    imageArr.push(rub.get_canvas().toDataURL())
                }
                resolve(imageArr)
                // document.body.removeChild(img)
            });
        })

    }

    // 去除数组中具有相同值的
    // 数组，相同值的key
    function sortArr(arr, str) {
        const newArray = {}
        arr.forEach(item => {
            newArray[item[str]] = newArray[item[str]] ? newArray[item[str]] : []
            newArray[item[str]].push(item)
        })
        return newArray;
    }

    // 中国省市县
    const chinaOptions = [
        {
            "value": "410000",
            "label": "河南省",
            "children": [
                {
                    "value": "410300",
                    "label": "洛阳市",
                    "children": [
                        {
                            "value": "410324",
                            "label": "栾川县",
                            "area": "2464.774"
                        },
                        {
                            "value": "410323",
                            "label": "新安县",
                            "area": "1161.59"
                        },
                        {
                            "value": "410328",
                            "label": "洛宁县",
                            "area": "2291.406"
                        },
                        {
                            "value": "410381",
                            "label": "偃师区",
                            "area": "666.342"
                        },
                        {
                            "value": "410304",
                            "label": "瀍河回族区",
                            "area": "30.788"
                        },
                        {
                            "value": "410302",
                            "label": "老城区",
                            "area": "54.782"
                        },
                        {
                            "value": "410311",
                            "label": "洛龙区",
                            "area": "523.241"
                        },
                        {
                            "value": "410327",
                            "label": "宜阳县",
                            "area": "1608.364"
                        },
                        {
                            "value": "410325",
                            "label": "嵩县",
                            "area": "3002.578"
                        },
                        {
                            "value": "410305",
                            "label": "涧西区",
                            "area": "143.819"
                        },
                        {
                            "value": "410303",
                            "label": "西工区",
                            "area": "55.657"
                        },
                        {
                            "value": "410306",
                            "label": "孟津区",
                            "area": "808.211"
                        },
                        {
                            "value": "410326",
                            "label": "汝阳县",
                            "area": "1323.128"
                        },
                        {
                            "value": "410329",
                            "label": "伊川县",
                            "area": "1064.39"
                        }
                    ]
                },
                {
                    "value": "411200",
                    "label": "三门峡市",
                    "children": [
                        {
                            "value": "411224",
                            "label": "卢氏县",
                            "area": "3661.047"
                        },
                        {
                            "value": "411221",
                            "label": "渑池县",
                            "area": "1360.333"
                        },
                        {
                            "value": "411202",
                            "label": "湖滨区",
                            "area": "202.386"
                        },
                        {
                            "value": "411281",
                            "label": "义马市",
                            "area": "100.151"
                        },
                        {
                            "value": "411203",
                            "label": "陕州区",
                            "area": "1610.603"
                        },
                        {
                            "value": "411282",
                            "label": "灵宝市",
                            "area": "2995.392"
                        }
                    ]
                },
                {
                    "value": "411100",
                    "label": "漯河市",
                    "children": [
                        {
                            "value": "411122",
                            "label": "临颍县",
                            "area": "800.947"
                        },
                        {
                            "value": "411104",
                            "label": "召陵区",
                            "area": "437.046"
                        },
                        {
                            "value": "411102",
                            "label": "源汇区",
                            "area": "230.001"
                        },
                        {
                            "value": "411121",
                            "label": "舞阳县",
                            "area": "771.802"
                        },
                        {
                            "value": "411103",
                            "label": "郾城区",
                            "area": "452.369"
                        }
                    ]
                },
                {
                    "value": "411000",
                    "label": "许昌市",
                    "children": [
                        {
                            "value": "411003",
                            "label": "建安区",
                            "area": "995.069"
                        },
                        {
                            "value": "411002",
                            "label": "魏都区",
                            "area": "88.178"
                        },
                        {
                            "value": "411024",
                            "label": "鄢陵县",
                            "area": "871.196"
                        },
                        {
                            "value": "411025",
                            "label": "襄城县",
                            "area": "922.959"
                        },
                        {
                            "value": "411082",
                            "label": "长葛市",
                            "area": "641.861"
                        },
                        {
                            "value": "411081",
                            "label": "禹州市",
                            "area": "1467.328"
                        }
                    ]
                },
                {
                    "value": "411300",
                    "label": "南阳市",
                    "children": [
                        {
                            "value": "411323",
                            "label": "西峡县",
                            "area": "3468.871"
                        },
                        {
                            "value": "411303",
                            "label": "卧龙区",
                            "area": "1013.975"
                        },
                        {
                            "value": "411321",
                            "label": "南召县",
                            "area": "2928.245"
                        },
                        {
                            "value": "411328",
                            "label": "唐河县",
                            "area": "2493.698"
                        },
                        {
                            "value": "411324",
                            "label": "镇平县",
                            "area": "1493.49"
                        },
                        {
                            "value": "411326",
                            "label": "淅川县",
                            "area": "2825.647"
                        },
                        {
                            "value": "411325",
                            "label": "内乡县",
                            "area": "2307.938"
                        },
                        {
                            "value": "411302",
                            "label": "宛城区",
                            "area": "972.661"
                        },
                        {
                            "value": "411330",
                            "label": "桐柏县",
                            "area": "1913.124"
                        },
                        {
                            "value": "411322",
                            "label": "方城县",
                            "area": "2534.5"
                        },
                        {
                            "value": "411381",
                            "label": "邓州市",
                            "area": "2359.561"
                        },
                        {
                            "value": "411329",
                            "label": "新野县",
                            "area": "1061.952"
                        },
                        {
                            "value": "411327",
                            "label": "社旗县",
                            "area": "1160.409"
                        }
                    ]
                },
                {
                    "value": "411500",
                    "label": "信阳市",
                    "children": [
                        {
                            "value": "411527",
                            "label": "淮滨县",
                            "area": "1203.361"
                        },
                        {
                            "value": "411502",
                            "label": "浉河区",
                            "area": "1786.296"
                        },
                        {
                            "value": "411521",
                            "label": "罗山县",
                            "area": "2076.56"
                        },
                        {
                            "value": "411524",
                            "label": "商城县",
                            "area": "2104.047"
                        },
                        {
                            "value": "411525",
                            "label": "固始县",
                            "area": "2945.989"
                        },
                        {
                            "value": "411503",
                            "label": "平桥区",
                            "area": "1885.971"
                        },
                        {
                            "value": "411526",
                            "label": "潢川县",
                            "area": "1641.578"
                        },
                        {
                            "value": "411523",
                            "label": "新县",
                            "area": "1552.883"
                        },
                        {
                            "value": "411528",
                            "label": "息县",
                            "area": "1879.276"
                        },
                        {
                            "value": "411522",
                            "label": "光山县",
                            "area": "1834.709"
                        }
                    ]
                },
                {
                    "value": "419000",
                    "label": "济源市",
                    "children": [
                        {
                            "value": "419001",
                            "label": "济源市",
                            "area": "1897.47"
                        }
                    ]
                },
                {
                    "value": "410900",
                    "label": "濮阳市",
                    "children": [
                        {
                            "value": "410923",
                            "label": "南乐县",
                            "area": "624.34"
                        },
                        {
                            "value": "410926",
                            "label": "范县",
                            "area": "605.935"
                        },
                        {
                            "value": "410927",
                            "label": "台前县",
                            "area": "441.637"
                        },
                        {
                            "value": "410902",
                            "label": "华龙区",
                            "area": "305.956"
                        },
                        {
                            "value": "410922",
                            "label": "清丰县",
                            "area": "831.593"
                        },
                        {
                            "value": "410928",
                            "label": "濮阳县",
                            "area": "1443.422"
                        }
                    ]
                },
                {
                    "value": "411700",
                    "label": "驻马店市",
                    "children": [
                        {
                            "value": "411721",
                            "label": "西平县",
                            "area": "1098.008"
                        },
                        {
                            "value": "411722",
                            "label": "上蔡县",
                            "area": "1511.376"
                        },
                        {
                            "value": "411729",
                            "label": "新蔡县",
                            "area": "1444.508"
                        },
                        {
                            "value": "411726",
                            "label": "泌阳县",
                            "area": "2387.333"
                        },
                        {
                            "value": "411728",
                            "label": "遂平县",
                            "area": "1066.349"
                        },
                        {
                            "value": "411723",
                            "label": "平舆县",
                            "area": "1277.028"
                        },
                        {
                            "value": "411725",
                            "label": "确山县",
                            "area": "1652.198"
                        },
                        {
                            "value": "411702",
                            "label": "驿城区",
                            "area": "1254.977"
                        },
                        {
                            "value": "411724",
                            "label": "正阳县",
                            "area": "1906.495"
                        },
                        {
                            "value": "411727",
                            "label": "汝南县",
                            "area": "1486.703"
                        }
                    ]
                },
                {
                    "value": "410600",
                    "label": "鹤壁市",
                    "children": [
                        {
                            "value": "410622",
                            "label": "淇县",
                            "area": "570.117"
                        },
                        {
                            "value": "410621",
                            "label": "浚县",
                            "area": "942.321"
                        },
                        {
                            "value": "410603",
                            "label": "山城区",
                            "area": "131.205"
                        },
                        {
                            "value": "410602",
                            "label": "鹤山区",
                            "area": "132.375"
                        },
                        {
                            "value": "410611",
                            "label": "淇滨区",
                            "area": "351.776"
                        }
                    ]
                },
                {
                    "value": "410100",
                    "label": "郑州市",
                    "children": [
                        {
                            "value": "410185",
                            "label": "登封市",
                            "area": "1215.239"
                        },
                        {
                            "value": "410102",
                            "label": "中原区",
                            "area": "196.628"
                        },
                        {
                            "value": "410183",
                            "label": "新密市",
                            "area": "996.661"
                        },
                        {
                            "value": "410184",
                            "label": "新郑市",
                            "area": "883.552"
                        },
                        {
                            "value": "410106",
                            "label": "上街区",
                            "area": "57.441"
                        },
                        {
                            "value": "410122",
                            "label": "中牟县",
                            "area": "1424.889"
                        },
                        {
                            "value": "410104",
                            "label": "管城回族区",
                            "area": "205.172"
                        },
                        {
                            "value": "410103",
                            "label": "二七区",
                            "area": "156.516"
                        },
                        {
                            "value": "410105",
                            "label": "金水区",
                            "area": "242.219"
                        },
                        {
                            "value": "410181",
                            "label": "巩义市",
                            "area": "1023.412"
                        },
                        {
                            "value": "410182",
                            "label": "荥阳市",
                            "area": "920.156"
                        },
                        {
                            "value": "410108",
                            "label": "惠济区",
                            "area": "232.561"
                        }
                    ]
                },
                {
                    "value": "410800",
                    "label": "焦作市",
                    "children": [
                        {
                            "value": "410804",
                            "label": "马村区",
                            "area": "115.26"
                        },
                        {
                            "value": "410883",
                            "label": "孟州市",
                            "area": "501.92"
                        },
                        {
                            "value": "410821",
                            "label": "修武县",
                            "area": "670.392"
                        },
                        {
                            "value": "410822",
                            "label": "博爱县",
                            "area": "431.923"
                        },
                        {
                            "value": "410882",
                            "label": "沁阳市",
                            "area": "589.15"
                        },
                        {
                            "value": "410803",
                            "label": "中站区",
                            "area": "125.993"
                        },
                        {
                            "value": "410811",
                            "label": "山阳区",
                            "area": "193.774"
                        },
                        {
                            "value": "410802",
                            "label": "解放区",
                            "area": "71.676"
                        },
                        {
                            "value": "410823",
                            "label": "武陟县",
                            "area": "816.965"
                        },
                        {
                            "value": "410825",
                            "label": "温县",
                            "area": "500.43"
                        }
                    ]
                },
                {
                    "value": "411600",
                    "label": "周口市",
                    "children": [
                        {
                            "value": "411681",
                            "label": "项城市",
                            "area": "1093.855"
                        },
                        {
                            "value": "411623",
                            "label": "商水县",
                            "area": "1268.741"
                        },
                        {
                            "value": "411602",
                            "label": "川汇区",
                            "area": "332.83"
                        },
                        {
                            "value": "411628",
                            "label": "鹿邑县",
                            "area": "1234.579"
                        },
                        {
                            "value": "411625",
                            "label": "郸城县",
                            "area": "1498.929"
                        },
                        {
                            "value": "411622",
                            "label": "西华县",
                            "area": "1205.256"
                        },
                        {
                            "value": "411603",
                            "label": "淮阳区",
                            "area": "1336.267"
                        },
                        {
                            "value": "411627",
                            "label": "太康县",
                            "area": "1761.347"
                        },
                        {
                            "value": "411621",
                            "label": "扶沟县",
                            "area": "1160.12"
                        },
                        {
                            "value": "411624",
                            "label": "沈丘县",
                            "area": "1073.836"
                        }
                    ]
                },
                {
                    "value": "410700",
                    "label": "新乡市",
                    "children": [
                        {
                            "value": "410704",
                            "label": "凤泉区",
                            "area": "118.114"
                        },
                        {
                            "value": "410783",
                            "label": "长垣市",
                            "area": "1038.962"
                        },
                        {
                            "value": "410711",
                            "label": "牧野区",
                            "area": "96.07"
                        },
                        {
                            "value": "410703",
                            "label": "卫滨区",
                            "area": "63.922"
                        },
                        {
                            "value": "410724",
                            "label": "获嘉县",
                            "area": "468.923"
                        },
                        {
                            "value": "410721",
                            "label": "新乡县",
                            "area": "385.89"
                        },
                        {
                            "value": "410727",
                            "label": "封丘县",
                            "area": "1228.852"
                        },
                        {
                            "value": "410725",
                            "label": "原阳县",
                            "area": "1281.065"
                        },
                        {
                            "value": "410702",
                            "label": "红旗区",
                            "area": "139.234"
                        },
                        {
                            "value": "410781",
                            "label": "卫辉市",
                            "area": "864.108"
                        },
                        {
                            "value": "410782",
                            "label": "辉县市",
                            "area": "1687.098"
                        },
                        {
                            "value": "410726",
                            "label": "延津县",
                            "area": "894.742"
                        }
                    ]
                },
                {
                    "value": "410500",
                    "label": "安阳市",
                    "children": [
                        {
                            "value": "410505",
                            "label": "殷都区",
                            "area": "65.017"
                        },
                        {
                            "value": "410527",
                            "label": "内黄县",
                            "area": "1147.906"
                        },
                        {
                            "value": "410502",
                            "label": "文峰区",
                            "area": "174.52"
                        },
                        {
                            "value": "410522",
                            "label": "安阳县",
                            "area": "1149.421"
                        },
                        {
                            "value": "410506",
                            "label": "龙安区",
                            "area": "239.61"
                        },
                        {
                            "value": "410503",
                            "label": "北关区",
                            "area": "95.95"
                        },
                        {
                            "value": "410581",
                            "label": "林州市",
                            "area": "2064.75"
                        },
                        {
                            "value": "410523",
                            "label": "汤阴县",
                            "area": "644.169"
                        },
                        {
                            "value": "410526",
                            "label": "滑县",
                            "area": "1778.639"
                        }
                    ]
                },
                {
                    "value": "410400",
                    "label": "平顶山市",
                    "children": [
                        {
                            "value": "410403",
                            "label": "卫东区",
                            "area": "108.117"
                        },
                        {
                            "value": "410411",
                            "label": "湛河区",
                            "area": "115.749"
                        },
                        {
                            "value": "410423",
                            "label": "鲁山县",
                            "area": "2410.481"
                        },
                        {
                            "value": "410481",
                            "label": "舞钢市",
                            "area": "626.347"
                        },
                        {
                            "value": "410422",
                            "label": "叶县",
                            "area": "1386.422"
                        },
                        {
                            "value": "410402",
                            "label": "新华区",
                            "area": "196.503"
                        },
                        {
                            "value": "410404",
                            "label": "石龙区",
                            "area": "31.53"
                        },
                        {
                            "value": "410425",
                            "label": "郏县",
                            "area": "724.342"
                        },
                        {
                            "value": "410421",
                            "label": "宝丰县",
                            "area": "731.287"
                        },
                        {
                            "value": "410482",
                            "label": "汝州市",
                            "area": "1571.9"
                        }
                    ]
                },
                {
                    "value": "410200",
                    "label": "开封市",
                    "children": [
                        {
                            "value": "410203",
                            "label": "顺河回族区",
                            "area": "72.598"
                        },
                        {
                            "value": "410202",
                            "label": "龙亭区",
                            "area": "393.924"
                        },
                        {
                            "value": "410221",
                            "label": "杞县",
                            "area": "1256.49"
                        },
                        {
                            "value": "410204",
                            "label": "鼓楼区",
                            "area": "58.167"
                        },
                        {
                            "value": "410223",
                            "label": "尉氏县",
                            "area": "1301.208"
                        },
                        {
                            "value": "410205",
                            "label": "禹王台区",
                            "area": "57.662"
                        },
                        {
                            "value": "410212",
                            "label": "祥符区",
                            "area": "1238.488"
                        },
                        {
                            "value": "410222",
                            "label": "通许县",
                            "area": "766.541"
                        },
                        {
                            "value": "410225",
                            "label": "兰考县",
                            "area": "1106.228"
                        }
                    ]
                },
                {
                    "value": "411400",
                    "label": "商丘市",
                    "children": [
                        {
                            "value": "411423",
                            "label": "宁陵县",
                            "area": "797.557"
                        },
                        {
                            "value": "411402",
                            "label": "梁园区",
                            "area": "695.642"
                        },
                        {
                            "value": "411424",
                            "label": "柘城县",
                            "area": "1041.583"
                        },
                        {
                            "value": "411481",
                            "label": "永城市",
                            "area": "2017.009"
                        },
                        {
                            "value": "411422",
                            "label": "睢县",
                            "area": "921.959"
                        },
                        {
                            "value": "411403",
                            "label": "睢阳区",
                            "area": "951.119"
                        },
                        {
                            "value": "411421",
                            "label": "民权县",
                            "area": "1242.297"
                        },
                        {
                            "value": "411425",
                            "label": "虞城县",
                            "area": "1554.172"
                        },
                        {
                            "value": "411426",
                            "label": "夏邑县",
                            "area": "1483.099"
                        }
                    ]
                }
            ]
        },
        {
            "value": "440000",
            "label": "广东省",
            "children": [
                {
                    "value": "440500",
                    "label": "汕头市",
                    "children": [
                        {
                            "value": "440513",
                            "label": "潮阳区",
                            "area": "701.992"
                        },
                        {
                            "value": "440515",
                            "label": "澄海区",
                            "area": "387.263"
                        },
                        {
                            "value": "440512",
                            "label": "濠江区",
                            "area": "227.055"
                        },
                        {
                            "value": "440514",
                            "label": "潮南区",
                            "area": "619.993"
                        },
                        {
                            "value": "440523",
                            "label": "南澳县",
                            "area": "119.25"
                        },
                        {
                            "value": "440507",
                            "label": "龙湖区",
                            "area": "141.947"
                        },
                        {
                            "value": "440511",
                            "label": "金平区",
                            "area": "149.92"
                        }
                    ]
                },
                {
                    "value": "440600",
                    "label": "佛山市",
                    "children": [
                        {
                            "value": "440607",
                            "label": "三水区",
                            "area": "827.463"
                        },
                        {
                            "value": "440608",
                            "label": "高明区",
                            "area": "937.924"
                        },
                        {
                            "value": "440606",
                            "label": "顺德区",
                            "area": "806.303"
                        },
                        {
                            "value": "440604",
                            "label": "禅城区",
                            "area": "154.454"
                        },
                        {
                            "value": "440605",
                            "label": "南海区",
                            "area": "1072.073"
                        }
                    ]
                },
                {
                    "value": "441200",
                    "label": "肇庆市",
                    "children": [
                        {
                            "value": "441225",
                            "label": "封开县",
                            "area": "2724.394"
                        },
                        {
                            "value": "441284",
                            "label": "四会市",
                            "area": "1263.428"
                        },
                        {
                            "value": "441226",
                            "label": "德庆县",
                            "area": "2003.173"
                        },
                        {
                            "value": "441224",
                            "label": "怀集县",
                            "area": "3557.881"
                        },
                        {
                            "value": "441223",
                            "label": "广宁县",
                            "area": "2455.056"
                        },
                        {
                            "value": "441202",
                            "label": "端州区",
                            "area": "154.076"
                        },
                        {
                            "value": "441203",
                            "label": "鼎湖区",
                            "area": "552.166"
                        },
                        {
                            "value": "441204",
                            "label": "高要区",
                            "area": "2185.91"
                        }
                    ]
                },
                {
                    "value": "441300",
                    "label": "惠州市",
                    "children": [
                        {
                            "value": "441324",
                            "label": "龙门县",
                            "area": "2267.607"
                        },
                        {
                            "value": "441322",
                            "label": "博罗县",
                            "area": "2855.81"
                        },
                        {
                            "value": "441323",
                            "label": "惠东县",
                            "area": "3630.975"
                        },
                        {
                            "value": "441303",
                            "label": "惠阳区",
                            "area": "1237.169"
                        },
                        {
                            "value": "441302",
                            "label": "惠城区",
                            "area": "1488.725"
                        }
                    ]
                },
                {
                    "value": "440300",
                    "label": "深圳市",
                    "children": [
                        {
                            "value": "440306",
                            "label": "宝安区",
                            "area": "408.633"
                        },
                        {
                            "value": "440305",
                            "label": "南山区",
                            "area": "204.519"
                        },
                        {
                            "value": "440304",
                            "label": "福田区",
                            "area": "79.809"
                        },
                        {
                            "value": "440303",
                            "label": "罗湖区",
                            "area": "78.984"
                        },
                        {
                            "value": "440307",
                            "label": "龙岗区",
                            "area": "778.559"
                        },
                        {
                            "value": "440311",
                            "label": "光明区",
                            "area": "155.766"
                        },
                        {
                            "value": "440309",
                            "label": "龙华区",
                            "area": "176.697"
                        },
                        {
                            "value": "440308",
                            "label": "盐田区",
                            "area": "88.87"
                        },
                        {
                            "value": "440310",
                            "label": "坪山区",
                            "area": "164.315"
                        }
                    ]
                },
                {
                    "value": "440400",
                    "label": "珠海市",
                    "children": [
                        {
                            "value": "440403",
                            "label": "斗门区",
                            "area": "642.189"
                        },
                        {
                            "value": "440404",
                            "label": "金湾区",
                            "area": "735.85"
                        },
                        {
                            "value": "440402",
                            "label": "香洲区",
                            "area": "576.769"
                        }
                    ]
                },
                {
                    "value": "440800",
                    "label": "湛江市",
                    "children": [
                        {
                            "value": "440883",
                            "label": "吴川市",
                            "area": "942.491"
                        },
                        {
                            "value": "440881",
                            "label": "廉江市",
                            "area": "2827.354"
                        },
                        {
                            "value": "440882",
                            "label": "雷州市",
                            "area": "3792.182"
                        },
                        {
                            "value": "440811",
                            "label": "麻章区",
                            "area": "926.736"
                        },
                        {
                            "value": "440825",
                            "label": "徐闻县",
                            "area": "2119.468"
                        },
                        {
                            "value": "440804",
                            "label": "坡头区",
                            "area": "565.2"
                        },
                        {
                            "value": "440803",
                            "label": "霞山区",
                            "area": "119.03"
                        },
                        {
                            "value": "440823",
                            "label": "遂溪县",
                            "area": "2147.479"
                        },
                        {
                            "value": "440802",
                            "label": "赤坎区",
                            "area": "63.416"
                        }
                    ]
                },
                {
                    "value": "445300",
                    "label": "云浮市",
                    "children": [
                        {
                            "value": "445322",
                            "label": "郁南县",
                            "area": "1962.894"
                        },
                        {
                            "value": "445381",
                            "label": "罗定市",
                            "area": "2334.671"
                        },
                        {
                            "value": "445321",
                            "label": "新兴县",
                            "area": "1521.295"
                        },
                        {
                            "value": "445302",
                            "label": "云城区",
                            "area": "778.57"
                        },
                        {
                            "value": "445303",
                            "label": "云安区",
                            "area": "1189.435"
                        }
                    ]
                },
                {
                    "value": "441700",
                    "label": "阳江市",
                    "children": [
                        {
                            "value": "441781",
                            "label": "阳春市",
                            "area": "4037.414"
                        },
                        {
                            "value": "441702",
                            "label": "江城区",
                            "area": "845.352"
                        },
                        {
                            "value": "441721",
                            "label": "阳西县",
                            "area": "1471.263"
                        },
                        {
                            "value": "441704",
                            "label": "阳东区",
                            "area": "1719.345"
                        }
                    ]
                },
                {
                    "value": "445100",
                    "label": "潮州市",
                    "children": [
                        {
                            "value": "445122",
                            "label": "饶平县",
                            "area": "1841.722"
                        },
                        {
                            "value": "445102",
                            "label": "湘桥区",
                            "area": "328.762"
                        },
                        {
                            "value": "445103",
                            "label": "潮安区",
                            "area": "1087.756"
                        }
                    ]
                },
                {
                    "value": "441400",
                    "label": "梅州市",
                    "children": [
                        {
                            "value": "441426",
                            "label": "平远县",
                            "area": "1372.866"
                        },
                        {
                            "value": "441427",
                            "label": "蕉岭县",
                            "area": "961.984"
                        },
                        {
                            "value": "441481",
                            "label": "兴宁市",
                            "area": "2073.051"
                        },
                        {
                            "value": "441424",
                            "label": "五华县",
                            "area": "3236.653"
                        },
                        {
                            "value": "441403",
                            "label": "梅县区",
                            "area": "2474.051"
                        },
                        {
                            "value": "441423",
                            "label": "丰顺县",
                            "area": "2707.717"
                        },
                        {
                            "value": "441422",
                            "label": "大埔县",
                            "area": "2459.988"
                        },
                        {
                            "value": "441402",
                            "label": "梅江区",
                            "area": "574.414"
                        }
                    ]
                },
                {
                    "value": "440700",
                    "label": "江门市",
                    "children": [
                        {
                            "value": "440784",
                            "label": "鹤山市",
                            "area": "1082.521"
                        },
                        {
                            "value": "440704",
                            "label": "江海区",
                            "area": "110.307"
                        },
                        {
                            "value": "440783",
                            "label": "开平市",
                            "area": "1656.997"
                        },
                        {
                            "value": "440781",
                            "label": "台山市",
                            "area": "3459.254"
                        },
                        {
                            "value": "440785",
                            "label": "恩平市",
                            "area": "1695.015"
                        },
                        {
                            "value": "440705",
                            "label": "新会区",
                            "area": "1368.011"
                        },
                        {
                            "value": "440703",
                            "label": "蓬江区",
                            "area": "324.773"
                        }
                    ]
                },
                {
                    "value": "441500",
                    "label": "汕尾市",
                    "children": [
                        {
                            "value": "441523",
                            "label": "陆河县",
                            "area": "987.097"
                        },
                        {
                            "value": "441521",
                            "label": "海丰县",
                            "area": "1798.69"
                        },
                        {
                            "value": "441581",
                            "label": "陆丰市",
                            "area": "1757.574"
                        },
                        {
                            "value": "441502",
                            "label": "城区",
                            "area": "795.393"
                        }
                    ]
                },
                {
                    "value": "440900",
                    "label": "茂名市",
                    "children": [
                        {
                            "value": "440981",
                            "label": "高州市",
                            "area": "3267.81"
                        },
                        {
                            "value": "440983",
                            "label": "信宜市",
                            "area": "3081.106"
                        },
                        {
                            "value": "440904",
                            "label": "电白区",
                            "area": "2141.04"
                        },
                        {
                            "value": "440982",
                            "label": "化州市",
                            "area": "2355.431"
                        },
                        {
                            "value": "440902",
                            "label": "茂南区",
                            "area": "600.028"
                        }
                    ]
                },
                {
                    "value": "445200",
                    "label": "揭阳市",
                    "children": [
                        {
                            "value": "445281",
                            "label": "普宁市",
                            "area": "1615.777"
                        },
                        {
                            "value": "445222",
                            "label": "揭西县",
                            "area": "1345.054"
                        },
                        {
                            "value": "445224",
                            "label": "惠来县",
                            "area": "1293.381"
                        },
                        {
                            "value": "445202",
                            "label": "榕城区",
                            "area": "333.667"
                        },
                        {
                            "value": "445203",
                            "label": "揭东区",
                            "area": "711.318"
                        }
                    ]
                },
                {
                    "value": "441600",
                    "label": "河源市",
                    "children": [
                        {
                            "value": "441622",
                            "label": "龙川县",
                            "area": "3083.648"
                        },
                        {
                            "value": "441623",
                            "label": "连平县",
                            "area": "2271.996"
                        },
                        {
                            "value": "441624",
                            "label": "和平县",
                            "area": "2290.915"
                        },
                        {
                            "value": "441625",
                            "label": "东源县",
                            "area": "4010.112"
                        },
                        {
                            "value": "441602",
                            "label": "源城区",
                            "area": "361.705"
                        },
                        {
                            "value": "441621",
                            "label": "紫金县",
                            "area": "3634.788"
                        }
                    ]
                },
                {
                    "value": "440100",
                    "label": "广州市",
                    "children": [
                        {
                            "value": "440117",
                            "label": "从化区",
                            "area": "1984.692"
                        },
                        {
                            "value": "440115",
                            "label": "南沙区",
                            "area": "841.092"
                        },
                        {
                            "value": "440114",
                            "label": "花都区",
                            "area": "968.638"
                        },
                        {
                            "value": "440105",
                            "label": "海珠区",
                            "area": "90.968"
                        },
                        {
                            "value": "440113",
                            "label": "番禺区",
                            "area": "519.21"
                        },
                        {
                            "value": "440103",
                            "label": "荔湾区",
                            "area": "62.731"
                        },
                        {
                            "value": "440118",
                            "label": "增城区",
                            "area": "1613.497"
                        },
                        {
                            "value": "440106",
                            "label": "天河区",
                            "area": "136.747"
                        },
                        {
                            "value": "440104",
                            "label": "越秀区",
                            "area": "33.606"
                        },
                        {
                            "value": "440112",
                            "label": "黄埔区",
                            "area": "483.596"
                        },
                        {
                            "value": "440111",
                            "label": "白云区",
                            "area": "664.199"
                        }
                    ]
                },
                {
                    "value": "440200",
                    "label": "韶关市",
                    "children": [
                        {
                            "value": "440224",
                            "label": "仁化县",
                            "area": "2221.752"
                        },
                        {
                            "value": "440232",
                            "label": "乳源瑶族自治县",
                            "area": "2298.489"
                        },
                        {
                            "value": "440282",
                            "label": "南雄市",
                            "area": "2322.946"
                        },
                        {
                            "value": "440222",
                            "label": "始兴县",
                            "area": "2130.271"
                        },
                        {
                            "value": "440204",
                            "label": "浈江区",
                            "area": "572.715"
                        },
                        {
                            "value": "440205",
                            "label": "曲江区",
                            "area": "1621.172"
                        },
                        {
                            "value": "440203",
                            "label": "武江区",
                            "area": "676.514"
                        },
                        {
                            "value": "440229",
                            "label": "翁源县",
                            "area": "2175.247"
                        },
                        {
                            "value": "440233",
                            "label": "新丰县",
                            "area": "1968.774"
                        },
                        {
                            "value": "440281",
                            "label": "乐昌市",
                            "area": "2419.653"
                        }
                    ]
                },
                {
                    "value": "441800",
                    "label": "清远市",
                    "children": [
                        {
                            "value": "441882",
                            "label": "连州市",
                            "area": "2667.708"
                        },
                        {
                            "value": "441826",
                            "label": "连南瑶族自治县",
                            "area": "1237.155"
                        },
                        {
                            "value": "441825",
                            "label": "连山壮族瑶族自治县",
                            "area": "1218.21"
                        },
                        {
                            "value": "441881",
                            "label": "英德市",
                            "area": "5632.535"
                        },
                        {
                            "value": "441821",
                            "label": "佛冈县",
                            "area": "1294.697"
                        },
                        {
                            "value": "441823",
                            "label": "阳山县",
                            "area": "3326.128"
                        },
                        {
                            "value": "441803",
                            "label": "清新区",
                            "area": "2358.299"
                        },
                        {
                            "value": "441802",
                            "label": "清城区",
                            "area": "1294.451"
                        }
                    ]
                },
                {
                    "value": "442000",
                    "label": "中山市",
                    "children": [
                        {
                            "value": "442000",
                            "label": "中山市",
                            "area": "1816.293"
                        }
                    ]
                },
                {
                    "value": "441900",
                    "label": "东莞市",
                    "children": [
                        {
                            "value": "441900",
                            "label": "东莞市",
                            "area": "2460.345"
                        }
                    ]
                }
            ]
        },
        {
            "value": "150000",
            "label": "内蒙古自治区",
            "children": [
                {
                    "value": "150700",
                    "label": "呼伦贝尔市",
                    "children": [
                        {
                            "value": "150785",
                            "label": "根河市",
                            "area": "20007.564"
                        },
                        {
                            "value": "150724",
                            "label": "鄂温克族自治旗",
                            "area": "18609.473"
                        },
                        {
                            "value": "150702",
                            "label": "海拉尔区",
                            "area": "1329.439"
                        },
                        {
                            "value": "150784",
                            "label": "额尔古纳市",
                            "area": "28960.921"
                        },
                        {
                            "value": "150782",
                            "label": "牙克石市",
                            "area": "27829.226"
                        },
                        {
                            "value": "150725",
                            "label": "陈巴尔虎旗",
                            "area": "17419.623"
                        },
                        {
                            "value": "150721",
                            "label": "阿荣旗",
                            "area": "11073.638"
                        },
                        {
                            "value": "150783",
                            "label": "扎兰屯市",
                            "area": "16771.514"
                        },
                        {
                            "value": "150703",
                            "label": "扎赉诺尔区",
                            "area": "252.847"
                        },
                        {
                            "value": "150781",
                            "label": "满洲里市",
                            "area": "504.069"
                        },
                        {
                            "value": "150727",
                            "label": "新巴尔虎右旗",
                            "area": "24877"
                        },
                        {
                            "value": "150726",
                            "label": "新巴尔虎左旗",
                            "area": "20156.112"
                        },
                        {
                            "value": "150723",
                            "label": "鄂伦春自治旗",
                            "area": "53364.768"
                        },
                        {
                            "value": "150722",
                            "label": "莫力达瓦达斡尔族自治旗",
                            "area": "10370.397"
                        }
                    ]
                },
                {
                    "value": "150300",
                    "label": "乌海市",
                    "children": [
                        {
                            "value": "150302",
                            "label": "海勃湾区",
                            "area": "485.399"
                        },
                        {
                            "value": "150303",
                            "label": "海南区",
                            "area": "968.956"
                        },
                        {
                            "value": "150304",
                            "label": "乌达区",
                            "area": "199.054"
                        }
                    ]
                },
                {
                    "value": "150200",
                    "label": "包头市",
                    "children": [
                        {
                            "value": "150223",
                            "label": "达尔罕茂明安联合旗",
                            "area": "17492.279"
                        },
                        {
                            "value": "150221",
                            "label": "土默特右旗",
                            "area": "2356.297"
                        },
                        {
                            "value": "150206",
                            "label": "白云鄂博矿区",
                            "area": "247.922"
                        },
                        {
                            "value": "150222",
                            "label": "固阳县",
                            "area": "4979.68"
                        },
                        {
                            "value": "150202",
                            "label": "东河区",
                            "area": "462.228"
                        },
                        {
                            "value": "150203",
                            "label": "昆都仑区",
                            "area": "317.046"
                        },
                        {
                            "value": "150205",
                            "label": "石拐区",
                            "area": "772.661"
                        },
                        {
                            "value": "150204",
                            "label": "青山区",
                            "area": "183.925"
                        },
                        {
                            "value": "150207",
                            "label": "九原区",
                            "area": "784.491"
                        }
                    ]
                },
                {
                    "value": "150800",
                    "label": "巴彦淖尔市",
                    "children": [
                        {
                            "value": "150824",
                            "label": "乌拉特中旗",
                            "area": "22816.822"
                        },
                        {
                            "value": "150802",
                            "label": "临河区",
                            "area": "2361.151"
                        },
                        {
                            "value": "150822",
                            "label": "磴口县",
                            "area": "3674.424"
                        },
                        {
                            "value": "150821",
                            "label": "五原县",
                            "area": "2493.147"
                        },
                        {
                            "value": "150823",
                            "label": "乌拉特前旗",
                            "area": "7505.513"
                        },
                        {
                            "value": "150825",
                            "label": "乌拉特后旗",
                            "area": "24569.395"
                        },
                        {
                            "value": "150826",
                            "label": "杭锦后旗",
                            "area": "1716.994"
                        }
                    ]
                },
                {
                    "value": "152900",
                    "label": "阿拉善盟",
                    "children": [
                        {
                            "value": "152923",
                            "label": "额济纳旗",
                            "area": "88101.103"
                        },
                        {
                            "value": "152922",
                            "label": "阿拉善右旗",
                            "area": "71511.482"
                        },
                        {
                            "value": "152921",
                            "label": "阿拉善左旗",
                            "area": "79838.511"
                        }
                    ]
                },
                {
                    "value": "150500",
                    "label": "通辽市",
                    "children": [
                        {
                            "value": "150526",
                            "label": "扎鲁特旗",
                            "area": "16493.585"
                        },
                        {
                            "value": "150581",
                            "label": "霍林郭勒市",
                            "area": "584.368"
                        },
                        {
                            "value": "150502",
                            "label": "科尔沁区",
                            "area": "3495.451"
                        },
                        {
                            "value": "150523",
                            "label": "开鲁县",
                            "area": "4378.485"
                        },
                        {
                            "value": "150521",
                            "label": "科尔沁左翼中旗",
                            "area": "9573.625"
                        },
                        {
                            "value": "150525",
                            "label": "奈曼旗",
                            "area": "8128.962"
                        },
                        {
                            "value": "150524",
                            "label": "库伦旗",
                            "area": "4712.486"
                        },
                        {
                            "value": "150522",
                            "label": "科尔沁左翼后旗",
                            "area": "11517.463"
                        }
                    ]
                },
                {
                    "value": "152200",
                    "label": "兴安盟",
                    "children": [
                        {
                            "value": "152222",
                            "label": "科尔沁右翼中旗",
                            "area": "12776.668"
                        },
                        {
                            "value": "152202",
                            "label": "阿尔山市",
                            "area": "7420.842"
                        },
                        {
                            "value": "152224",
                            "label": "突泉县",
                            "area": "4800.778"
                        },
                        {
                            "value": "152221",
                            "label": "科尔沁右翼前旗",
                            "area": "16668.512"
                        },
                        {
                            "value": "152223",
                            "label": "扎赉特旗",
                            "area": "10993.934"
                        },
                        {
                            "value": "152201",
                            "label": "乌兰浩特市",
                            "area": "2479.814"
                        }
                    ]
                },
                {
                    "value": "152500",
                    "label": "锡林郭勒盟",
                    "children": [
                        {
                            "value": "152522",
                            "label": "阿巴嘎旗",
                            "area": "27474.584"
                        },
                        {
                            "value": "152526",
                            "label": "西乌珠穆沁旗",
                            "area": "22461.931"
                        },
                        {
                            "value": "152525",
                            "label": "东乌珠穆沁旗",
                            "area": "45582.086"
                        },
                        {
                            "value": "152523",
                            "label": "苏尼特左旗",
                            "area": "34239.234"
                        },
                        {
                            "value": "152502",
                            "label": "锡林浩特市",
                            "area": "14781.725"
                        },
                        {
                            "value": "152501",
                            "label": "二连浩特市",
                            "area": "3996.231"
                        },
                        {
                            "value": "152524",
                            "label": "苏尼特右旗",
                            "area": "22503.846"
                        },
                        {
                            "value": "152528",
                            "label": "镶黄旗",
                            "area": "5140.098"
                        },
                        {
                            "value": "152530",
                            "label": "正蓝旗",
                            "area": "10213.163"
                        },
                        {
                            "value": "152529",
                            "label": "正镶白旗",
                            "area": "6250.805"
                        },
                        {
                            "value": "152531",
                            "label": "多伦县",
                            "area": "3882.137"
                        },
                        {
                            "value": "152527",
                            "label": "太仆寺旗",
                            "area": "3418.672"
                        }
                    ]
                },
                {
                    "value": "150100",
                    "label": "呼和浩特市",
                    "children": [
                        {
                            "value": "150123",
                            "label": "和林格尔县",
                            "area": "3448.976"
                        },
                        {
                            "value": "150104",
                            "label": "玉泉区",
                            "area": "206.52"
                        },
                        {
                            "value": "150103",
                            "label": "回民区",
                            "area": "194.619"
                        },
                        {
                            "value": "150125",
                            "label": "武川县",
                            "area": "4683.047"
                        },
                        {
                            "value": "150121",
                            "label": "土默特左旗",
                            "area": "2764.754"
                        },
                        {
                            "value": "150122",
                            "label": "托克托县",
                            "area": "1407.082"
                        },
                        {
                            "value": "150105",
                            "label": "赛罕区",
                            "area": "1003.612"
                        },
                        {
                            "value": "150102",
                            "label": "新城区",
                            "area": "660.669"
                        },
                        {
                            "value": "150124",
                            "label": "清水河县",
                            "area": "2806.114"
                        }
                    ]
                },
                {
                    "value": "150400",
                    "label": "赤峰市",
                    "children": [
                        {
                            "value": "150421",
                            "label": "阿鲁科尔沁旗",
                            "area": "13212.879"
                        },
                        {
                            "value": "150425",
                            "label": "克什克腾旗",
                            "area": "18914.492"
                        },
                        {
                            "value": "150422",
                            "label": "巴林左旗",
                            "area": "6465.378"
                        },
                        {
                            "value": "150424",
                            "label": "林西县",
                            "area": "3761.217"
                        },
                        {
                            "value": "150423",
                            "label": "巴林右旗",
                            "area": "9935.781"
                        },
                        {
                            "value": "150426",
                            "label": "翁牛特旗",
                            "area": "11896.932"
                        },
                        {
                            "value": "150404",
                            "label": "松山区",
                            "area": "5670.371"
                        },
                        {
                            "value": "150403",
                            "label": "元宝山区",
                            "area": "935.299"
                        },
                        {
                            "value": "150428",
                            "label": "喀喇沁旗",
                            "area": "3037.411"
                        },
                        {
                            "value": "150402",
                            "label": "红山区",
                            "area": "500.615"
                        },
                        {
                            "value": "150430",
                            "label": "敖汉旗",
                            "area": "8254.997"
                        },
                        {
                            "value": "150429",
                            "label": "宁城县",
                            "area": "4340.218"
                        }
                    ]
                },
                {
                    "value": "150900",
                    "label": "乌兰察布市",
                    "children": [
                        {
                            "value": "150929",
                            "label": "四子王旗",
                            "area": "24045.429"
                        },
                        {
                            "value": "150924",
                            "label": "兴和县",
                            "area": "3524.9"
                        },
                        {
                            "value": "150928",
                            "label": "察哈尔右翼后旗",
                            "area": "3810.111"
                        },
                        {
                            "value": "150923",
                            "label": "商都县",
                            "area": "4257.156"
                        },
                        {
                            "value": "150922",
                            "label": "化德县",
                            "area": "2529.52"
                        },
                        {
                            "value": "150927",
                            "label": "察哈尔右翼中旗",
                            "area": "4194.274"
                        },
                        {
                            "value": "150981",
                            "label": "丰镇市",
                            "area": "2676.196"
                        },
                        {
                            "value": "150921",
                            "label": "卓资县",
                            "area": "3091.199"
                        },
                        {
                            "value": "150926",
                            "label": "察哈尔右翼前旗",
                            "area": "2344.453"
                        },
                        {
                            "value": "150902",
                            "label": "集宁区",
                            "area": "521.033"
                        },
                        {
                            "value": "150925",
                            "label": "凉城县",
                            "area": "3426.2"
                        }
                    ]
                },
                {
                    "value": "150600",
                    "label": "鄂尔多斯市",
                    "children": [
                        {
                            "value": "150621",
                            "label": "达拉特旗",
                            "area": "8249.889"
                        },
                        {
                            "value": "150624",
                            "label": "鄂托克旗",
                            "area": "20369.768"
                        },
                        {
                            "value": "150625",
                            "label": "杭锦旗",
                            "area": "18790.138"
                        },
                        {
                            "value": "150626",
                            "label": "乌审旗",
                            "area": "11652.56"
                        },
                        {
                            "value": "150623",
                            "label": "鄂托克前旗",
                            "area": "12220.832"
                        },
                        {
                            "value": "150627",
                            "label": "伊金霍洛旗",
                            "area": "5486.843"
                        },
                        {
                            "value": "150603",
                            "label": "康巴什区",
                            "area": "359.304"
                        },
                        {
                            "value": "150602",
                            "label": "东胜区",
                            "area": "2164.102"
                        },
                        {
                            "value": "150622",
                            "label": "准格尔旗",
                            "area": "7546.781"
                        }
                    ]
                }
            ]
        },
        {
            "value": "230000",
            "label": "黑龙江省",
            "children": [
                {
                    "value": "230700",
                    "label": "伊春市",
                    "children": [
                        {
                            "value": "230722",
                            "label": "嘉荫县",
                            "area": "6776.756"
                        },
                        {
                            "value": "230719",
                            "label": "友好区",
                            "area": "3017.62"
                        },
                        {
                            "value": "230723",
                            "label": "汤旺县",
                            "area": "2179.759"
                        },
                        {
                            "value": "230751",
                            "label": "金林区",
                            "area": "2364.096"
                        },
                        {
                            "value": "230717",
                            "label": "伊美区",
                            "area": "2761.777"
                        },
                        {
                            "value": "230718",
                            "label": "乌翠区",
                            "area": "2396.786"
                        },
                        {
                            "value": "230724",
                            "label": "丰林县",
                            "area": "2868.978"
                        },
                        {
                            "value": "230781",
                            "label": "铁力市",
                            "area": "3839.47"
                        },
                        {
                            "value": "230726",
                            "label": "南岔县",
                            "area": "3012.698"
                        },
                        {
                            "value": "230725",
                            "label": "大箐山县",
                            "area": "3613.287"
                        }
                    ]
                },
                {
                    "value": "230400",
                    "label": "鹤岗市",
                    "children": [
                        {
                            "value": "230422",
                            "label": "绥滨县",
                            "area": "3373.999"
                        },
                        {
                            "value": "230403",
                            "label": "工农区",
                            "area": "11.74"
                        },
                        {
                            "value": "230407",
                            "label": "兴山区",
                            "area": "28.083"
                        },
                        {
                            "value": "230402",
                            "label": "向阳区",
                            "area": "10.587"
                        },
                        {
                            "value": "230404",
                            "label": "南山区",
                            "area": "28.964"
                        },
                        {
                            "value": "230421",
                            "label": "萝北县",
                            "area": "8875.644"
                        },
                        {
                            "value": "230406",
                            "label": "东山区",
                            "area": "2187.142"
                        },
                        {
                            "value": "230405",
                            "label": "兴安区",
                            "area": "201.09"
                        }
                    ]
                },
                {
                    "value": "230900",
                    "label": "七台河市",
                    "children": [
                        {
                            "value": "230903",
                            "label": "桃山区",
                            "area": "76.105"
                        },
                        {
                            "value": "230902",
                            "label": "新兴区",
                            "area": "1613.008"
                        },
                        {
                            "value": "230904",
                            "label": "茄子河区",
                            "area": "1400.388"
                        },
                        {
                            "value": "230921",
                            "label": "勃利县",
                            "area": "2391.378"
                        }
                    ]
                },
                {
                    "value": "232700",
                    "label": "大兴安岭地区",
                    "children": [
                        {
                            "value": "232701",
                            "label": "漠河市",
                            "area": "18488.447"
                        },
                        {
                            "value": "232722",
                            "label": "塔河县",
                            "area": "13999.279"
                        },
                        {
                            "value": "232721",
                            "label": "呼玛县",
                            "area": "32296.623"
                        },
                        {
                            "value": "232718",
                            "label": "加格达奇区",
                            "area": "1344.181"
                        }
                    ]
                },
                {
                    "value": "230100",
                    "label": "哈尔滨市",
                    "children": [
                        {
                            "value": "230123",
                            "label": "依兰县",
                            "area": "4606.085"
                        },
                        {
                            "value": "230124",
                            "label": "方正县",
                            "area": "2978.374"
                        },
                        {
                            "value": "230127",
                            "label": "木兰县",
                            "area": "3172.718"
                        },
                        {
                            "value": "230126",
                            "label": "巴彦县",
                            "area": "3142.742"
                        },
                        {
                            "value": "230125",
                            "label": "宾县",
                            "area": "3841.051"
                        },
                        {
                            "value": "230128",
                            "label": "通河县",
                            "area": "5655.943"
                        },
                        {
                            "value": "230183",
                            "label": "尚志市",
                            "area": "8891.053"
                        },
                        {
                            "value": "230113",
                            "label": "双城区",
                            "area": "3110.07"
                        },
                        {
                            "value": "230129",
                            "label": "延寿县",
                            "area": "3096.347"
                        },
                        {
                            "value": "230108",
                            "label": "平房区",
                            "area": "99.197"
                        },
                        {
                            "value": "230102",
                            "label": "道里区",
                            "area": "463.904"
                        },
                        {
                            "value": "230110",
                            "label": "香坊区",
                            "area": "338.86"
                        },
                        {
                            "value": "230184",
                            "label": "五常市",
                            "area": "7501.141"
                        },
                        {
                            "value": "230112",
                            "label": "阿城区",
                            "area": "2436.47"
                        },
                        {
                            "value": "230111",
                            "label": "呼兰区",
                            "area": "2220.411"
                        },
                        {
                            "value": "230109",
                            "label": "松北区",
                            "area": "721.556"
                        },
                        {
                            "value": "230103",
                            "label": "南岗区",
                            "area": "168.734"
                        },
                        {
                            "value": "230104",
                            "label": "道外区",
                            "area": "624.208"
                        }
                    ]
                },
                {
                    "value": "231200",
                    "label": "绥化市",
                    "children": [
                        {
                            "value": "231226",
                            "label": "绥棱县",
                            "area": "4312.38"
                        },
                        {
                            "value": "231283",
                            "label": "海伦市",
                            "area": "4640.226"
                        },
                        {
                            "value": "231224",
                            "label": "庆安县",
                            "area": "5464.923"
                        },
                        {
                            "value": "231221",
                            "label": "望奎县",
                            "area": "2314.515"
                        },
                        {
                            "value": "231223",
                            "label": "青冈县",
                            "area": "2680.639"
                        },
                        {
                            "value": "231202",
                            "label": "北林区",
                            "area": "2756.584"
                        },
                        {
                            "value": "231225",
                            "label": "明水县",
                            "area": "2297.51"
                        },
                        {
                            "value": "231222",
                            "label": "兰西县",
                            "area": "2484.915"
                        },
                        {
                            "value": "231282",
                            "label": "肇东市",
                            "area": "4331.364"
                        },
                        {
                            "value": "231281",
                            "label": "安达市",
                            "area": "3604.491"
                        }
                    ]
                },
                {
                    "value": "230200",
                    "label": "齐齐哈尔市",
                    "children": [
                        {
                            "value": "230281",
                            "label": "讷河市",
                            "area": "6663.786"
                        },
                        {
                            "value": "230230",
                            "label": "克东县",
                            "area": "2082.394"
                        },
                        {
                            "value": "230225",
                            "label": "甘南县",
                            "area": "4784.517"
                        },
                        {
                            "value": "230229",
                            "label": "克山县",
                            "area": "3190.341"
                        },
                        {
                            "value": "230223",
                            "label": "依安县",
                            "area": "3679.83"
                        },
                        {
                            "value": "230207",
                            "label": "碾子山区",
                            "area": "299.451"
                        },
                        {
                            "value": "230204",
                            "label": "铁锋区",
                            "area": "751.867"
                        },
                        {
                            "value": "230208",
                            "label": "梅里斯达斡尔族区",
                            "area": "2073.902"
                        },
                        {
                            "value": "230203",
                            "label": "建华区",
                            "area": "111.745"
                        },
                        {
                            "value": "230221",
                            "label": "龙江县",
                            "area": "5888.728"
                        },
                        {
                            "value": "230227",
                            "label": "富裕县",
                            "area": "4004.283"
                        },
                        {
                            "value": "230206",
                            "label": "富拉尔基区",
                            "area": "367.549"
                        },
                        {
                            "value": "230202",
                            "label": "龙沙区",
                            "area": "121.734"
                        },
                        {
                            "value": "230205",
                            "label": "昂昂溪区",
                            "area": "728.404"
                        },
                        {
                            "value": "230231",
                            "label": "拜泉县",
                            "area": "3593.127"
                        },
                        {
                            "value": "230224",
                            "label": "泰来县",
                            "area": "3909.501"
                        }
                    ]
                },
                {
                    "value": "230300",
                    "label": "鸡西市",
                    "children": [
                        {
                            "value": "230306",
                            "label": "城子河区",
                            "area": "176.998"
                        },
                        {
                            "value": "230307",
                            "label": "麻山区",
                            "area": "416.255"
                        },
                        {
                            "value": "230304",
                            "label": "滴道区",
                            "area": "501.432"
                        },
                        {
                            "value": "230302",
                            "label": "鸡冠区",
                            "area": "146.876"
                        },
                        {
                            "value": "230303",
                            "label": "恒山区",
                            "area": "547.985"
                        },
                        {
                            "value": "230305",
                            "label": "梨树区",
                            "area": "408.964"
                        },
                        {
                            "value": "230382",
                            "label": "密山市",
                            "area": "7741.47"
                        },
                        {
                            "value": "230321",
                            "label": "鸡东县",
                            "area": "3232.062"
                        },
                        {
                            "value": "230381",
                            "label": "虎林市",
                            "area": "9331.558"
                        }
                    ]
                },
                {
                    "value": "230600",
                    "label": "大庆市",
                    "children": [
                        {
                            "value": "230604",
                            "label": "让胡路区",
                            "area": "1190.55"
                        },
                        {
                            "value": "230605",
                            "label": "红岗区",
                            "area": "636.536"
                        },
                        {
                            "value": "230622",
                            "label": "肇源县",
                            "area": "4144.713"
                        },
                        {
                            "value": "230606",
                            "label": "大同区",
                            "area": "2337.015"
                        },
                        {
                            "value": "230623",
                            "label": "林甸县",
                            "area": "3494.186"
                        },
                        {
                            "value": "230624",
                            "label": "杜尔伯特蒙古族自治县",
                            "area": "6045.175"
                        },
                        {
                            "value": "230603",
                            "label": "龙凤区",
                            "area": "395.987"
                        },
                        {
                            "value": "230602",
                            "label": "萨尔图区",
                            "area": "495.242"
                        },
                        {
                            "value": "230621",
                            "label": "肇州县",
                            "area": "2460.12"
                        }
                    ]
                },
                {
                    "value": "230800",
                    "label": "佳木斯市",
                    "children": [
                        {
                            "value": "230881",
                            "label": "同江市",
                            "area": "6242.899"
                        },
                        {
                            "value": "230826",
                            "label": "桦川县",
                            "area": "2225.98"
                        },
                        {
                            "value": "230828",
                            "label": "汤原县",
                            "area": "3424.875"
                        },
                        {
                            "value": "230811",
                            "label": "郊区",
                            "area": "1769.792"
                        },
                        {
                            "value": "230805",
                            "label": "东风区",
                            "area": "55.321"
                        },
                        {
                            "value": "230804",
                            "label": "前进区",
                            "area": "30.501"
                        },
                        {
                            "value": "230803",
                            "label": "向阳区",
                            "area": "46.191"
                        },
                        {
                            "value": "230822",
                            "label": "桦南县",
                            "area": "5124.933"
                        },
                        {
                            "value": "230883",
                            "label": "抚远市",
                            "area": "5410.606"
                        },
                        {
                            "value": "230882",
                            "label": "富锦市",
                            "area": "8226.985"
                        }
                    ]
                },
                {
                    "value": "230500",
                    "label": "双鸭山市",
                    "children": [
                        {
                            "value": "230522",
                            "label": "友谊县",
                            "area": "1690.435"
                        },
                        {
                            "value": "230502",
                            "label": "尖山区",
                            "area": "118.797"
                        },
                        {
                            "value": "230506",
                            "label": "宝山区",
                            "area": "506.259"
                        },
                        {
                            "value": "230503",
                            "label": "岭东区",
                            "area": "740.736"
                        },
                        {
                            "value": "230523",
                            "label": "宝清县",
                            "area": "9992.055"
                        },
                        {
                            "value": "230524",
                            "label": "饶河县",
                            "area": "7441.705"
                        },
                        {
                            "value": "230505",
                            "label": "四方台区",
                            "area": "185.478"
                        },
                        {
                            "value": "230521",
                            "label": "集贤县",
                            "area": "2222.67"
                        }
                    ]
                },
                {
                    "value": "231100",
                    "label": "黑河市",
                    "children": [
                        {
                            "value": "231102",
                            "label": "爱辉区",
                            "area": "13915.52"
                        },
                        {
                            "value": "231181",
                            "label": "北安市",
                            "area": "7192.964"
                        },
                        {
                            "value": "231182",
                            "label": "五大连池市",
                            "area": "15087.553"
                        },
                        {
                            "value": "231124",
                            "label": "孙吴县",
                            "area": "4777.651"
                        },
                        {
                            "value": "231123",
                            "label": "逊克县",
                            "area": "10688.619"
                        },
                        {
                            "value": "231183",
                            "label": "嫩江市",
                            "area": "15215.958"
                        }
                    ]
                },
                {
                    "value": "231000",
                    "label": "牡丹江市",
                    "children": [
                        {
                            "value": "231081",
                            "label": "绥芬河市",
                            "area": "422.123"
                        },
                        {
                            "value": "231002",
                            "label": "东安区",
                            "area": "581.062"
                        },
                        {
                            "value": "231004",
                            "label": "爱民区",
                            "area": "436.78"
                        },
                        {
                            "value": "231084",
                            "label": "宁安市",
                            "area": "7226.528"
                        },
                        {
                            "value": "231003",
                            "label": "阳明区",
                            "area": "1381.108"
                        },
                        {
                            "value": "231085",
                            "label": "穆棱市",
                            "area": "6021.321"
                        },
                        {
                            "value": "231025",
                            "label": "林口县",
                            "area": "6579.955"
                        },
                        {
                            "value": "231005",
                            "label": "西安区",
                            "area": "352.565"
                        },
                        {
                            "value": "231083",
                            "label": "海林市",
                            "area": "8709.229"
                        },
                        {
                            "value": "231086",
                            "label": "东宁市",
                            "area": "7115.014"
                        }
                    ]
                }
            ]
        },
        {
            "value": "650000",
            "label": "新疆维吾尔自治区",
            "children": [
                {
                    "value": "659000",
                    "label": "北屯市",
                    "children": [
                        {
                            "value": "659005",
                            "label": "北屯市",
                            "area": "913.995"
                        },
                        {
                            "value": "659008",
                            "label": "可克达拉市",
                            "area": "1048.72"
                        },
                        {
                            "value": "659009",
                            "label": "昆玉市",
                            "area": "670.347"
                        },
                        {
                            "value": "659007",
                            "label": "双河市",
                            "area": "974.947"
                        },
                        {
                            "value": "659006",
                            "label": "铁门关市",
                            "area": "589.393"
                        },
                        {
                            "value": "659001",
                            "label": "石河子市",
                            "area": "457.456"
                        },
                        {
                            "value": "659004",
                            "label": "五家渠市",
                            "area": "743.812"
                        },
                        {
                            "value": "659002",
                            "label": "阿拉尔市",
                            "area": "3901.501"
                        },
                        {
                            "value": "659003",
                            "label": "图木舒克市",
                            "area": "1910.983"
                        },
                        {
                            "value": "659010",
                            "label": "胡杨河市",
                            "area": "1165.863"
                        }
                    ]
                },
                {
                    "value": "653200",
                    "label": "和田地区",
                    "children": [
                        {
                            "value": "653201",
                            "label": "和田市",
                            "area": "463.861"
                        },
                        {
                            "value": "653226",
                            "label": "于田县",
                            "area": "39030.942"
                        },
                        {
                            "value": "653227",
                            "label": "民丰县",
                            "area": "56690.436"
                        },
                        {
                            "value": "653224",
                            "label": "洛浦县",
                            "area": "14096.299"
                        },
                        {
                            "value": "653221",
                            "label": "和田县",
                            "area": "41086.176"
                        },
                        {
                            "value": "653223",
                            "label": "皮山县",
                            "area": "39080.923"
                        },
                        {
                            "value": "653222",
                            "label": "墨玉县",
                            "area": "25346.581"
                        },
                        {
                            "value": "653225",
                            "label": "策勒县",
                            "area": "31596.355"
                        }
                    ]
                },
                {
                    "value": "652700",
                    "label": "博尔塔拉蒙古自治州",
                    "children": [
                        {
                            "value": "652723",
                            "label": "温泉县",
                            "area": "5886.113"
                        },
                        {
                            "value": "652722",
                            "label": "精河县",
                            "area": "11171.452"
                        },
                        {
                            "value": "652701",
                            "label": "博乐市",
                            "area": "6631.595"
                        },
                        {
                            "value": "652702",
                            "label": "阿拉山口市",
                            "area": "220.515"
                        }
                    ]
                },
                {
                    "value": "654200",
                    "label": "塔城地区",
                    "children": [
                        {
                            "value": "654225",
                            "label": "裕民县",
                            "area": "6072.827"
                        },
                        {
                            "value": "654226",
                            "label": "和布克赛尔蒙古自治县",
                            "area": "28866.511"
                        },
                        {
                            "value": "654201",
                            "label": "塔城市",
                            "area": "3969.408"
                        },
                        {
                            "value": "654224",
                            "label": "托里县",
                            "area": "19976.4"
                        },
                        {
                            "value": "654221",
                            "label": "额敏县",
                            "area": "9128.522"
                        },
                        {
                            "value": "654202",
                            "label": "乌苏市",
                            "area": "14197.86"
                        },
                        {
                            "value": "654223",
                            "label": "沙湾市",
                            "area": "12450.847"
                        }
                    ]
                },
                {
                    "value": "654300",
                    "label": "阿勒泰地区",
                    "children": [
                        {
                            "value": "654321",
                            "label": "布尔津县",
                            "area": "10375.183"
                        },
                        {
                            "value": "654324",
                            "label": "哈巴河县",
                            "area": "8186.825"
                        },
                        {
                            "value": "654322",
                            "label": "富蕴县",
                            "area": "32234.844"
                        },
                        {
                            "value": "654325",
                            "label": "青河县",
                            "area": "15777.982"
                        },
                        {
                            "value": "654326",
                            "label": "吉木乃县",
                            "area": "7138.032"
                        },
                        {
                            "value": "654323",
                            "label": "福海县",
                            "area": "32379.404"
                        },
                        {
                            "value": "654301",
                            "label": "阿勒泰市",
                            "area": "10800.244"
                        }
                    ]
                },
                {
                    "value": "652800",
                    "label": "巴音郭楞蒙古自治州",
                    "children": [
                        {
                            "value": "652827",
                            "label": "和静县",
                            "area": "34968.192"
                        },
                        {
                            "value": "652828",
                            "label": "和硕县",
                            "area": "12755.519"
                        },
                        {
                            "value": "652826",
                            "label": "焉耆回族自治县",
                            "area": "2425.5"
                        },
                        {
                            "value": "652829",
                            "label": "博湖县",
                            "area": "3592.808"
                        },
                        {
                            "value": "652825",
                            "label": "且末县",
                            "area": "138663.3"
                        },
                        {
                            "value": "652824",
                            "label": "若羌县",
                            "area": "198337.089"
                        },
                        {
                            "value": "652801",
                            "label": "库尔勒市",
                            "area": "6779.158"
                        },
                        {
                            "value": "652822",
                            "label": "轮台县",
                            "area": "14188.564"
                        },
                        {
                            "value": "652823",
                            "label": "尉犁县",
                            "area": "59172.412"
                        }
                    ]
                },
                {
                    "value": "652300",
                    "label": "昌吉回族自治州",
                    "children": [
                        {
                            "value": "652302",
                            "label": "阜康市",
                            "area": "8534.92"
                        },
                        {
                            "value": "652325",
                            "label": "奇台县",
                            "area": "16664.381"
                        },
                        {
                            "value": "652324",
                            "label": "玛纳斯县",
                            "area": "9153.139"
                        },
                        {
                            "value": "652328",
                            "label": "木垒哈萨克自治县",
                            "area": "13507.696"
                        },
                        {
                            "value": "652323",
                            "label": "呼图壁县",
                            "area": "9494.682"
                        },
                        {
                            "value": "652301",
                            "label": "昌吉市",
                            "area": "7971.84"
                        },
                        {
                            "value": "652327",
                            "label": "吉木萨尔县",
                            "area": "8148.82"
                        }
                    ]
                },
                {
                    "value": "650100",
                    "label": "乌鲁木齐市",
                    "children": [
                        {
                            "value": "650107",
                            "label": "达坂城区",
                            "area": "5192.292"
                        },
                        {
                            "value": "650102",
                            "label": "天山区",
                            "area": "170.786"
                        },
                        {
                            "value": "650105",
                            "label": "水磨沟区",
                            "area": "279.551"
                        },
                        {
                            "value": "650109",
                            "label": "米东区",
                            "area": "3401.176"
                        },
                        {
                            "value": "650106",
                            "label": "头屯河区",
                            "area": "358.67"
                        },
                        {
                            "value": "650121",
                            "label": "乌鲁木齐县",
                            "area": "4122.524"
                        },
                        {
                            "value": "650104",
                            "label": "新市区",
                            "area": "262.189"
                        },
                        {
                            "value": "650103",
                            "label": "沙依巴克区",
                            "area": "420.422"
                        }
                    ]
                },
                {
                    "value": "654000",
                    "label": "伊犁哈萨克自治州",
                    "children": [
                        {
                            "value": "654003",
                            "label": "奎屯市",
                            "area": "785.135"
                        },
                        {
                            "value": "654021",
                            "label": "伊宁县",
                            "area": "4482.05"
                        },
                        {
                            "value": "654024",
                            "label": "巩留县",
                            "area": "4117.076"
                        },
                        {
                            "value": "654028",
                            "label": "尼勒克县",
                            "area": "10127.764"
                        },
                        {
                            "value": "654026",
                            "label": "昭苏县",
                            "area": "10409.459"
                        },
                        {
                            "value": "654027",
                            "label": "特克斯县",
                            "area": "8080.695"
                        },
                        {
                            "value": "654022",
                            "label": "察布查尔锡伯自治县",
                            "area": "4042.943"
                        },
                        {
                            "value": "654025",
                            "label": "新源县",
                            "area": "7581.44"
                        },
                        {
                            "value": "654023",
                            "label": "霍城县",
                            "area": "2819.699"
                        },
                        {
                            "value": "654004",
                            "label": "霍尔果斯市",
                            "area": "1945.496"
                        },
                        {
                            "value": "654002",
                            "label": "伊宁市",
                            "area": "693.269"
                        }
                    ]
                },
                {
                    "value": "653100",
                    "label": "喀什地区",
                    "children": [
                        {
                            "value": "653129",
                            "label": "伽师县",
                            "area": "6532.189"
                        },
                        {
                            "value": "653122",
                            "label": "疏勒县",
                            "area": "2160.483"
                        },
                        {
                            "value": "653131",
                            "label": "塔什库尔干塔吉克自治县",
                            "area": "23685.796"
                        },
                        {
                            "value": "653127",
                            "label": "麦盖提县",
                            "area": "10893.494"
                        },
                        {
                            "value": "653126",
                            "label": "叶城县",
                            "area": "28581.962"
                        },
                        {
                            "value": "653125",
                            "label": "莎车县",
                            "area": "8915.593"
                        },
                        {
                            "value": "653124",
                            "label": "泽普县",
                            "area": "1007.047"
                        },
                        {
                            "value": "653130",
                            "label": "巴楚县",
                            "area": "18379.986"
                        },
                        {
                            "value": "653123",
                            "label": "英吉沙县",
                            "area": "3427.069"
                        },
                        {
                            "value": "653121",
                            "label": "疏附县",
                            "area": "2712.858"
                        },
                        {
                            "value": "653101",
                            "label": "喀什市",
                            "area": "1002.559"
                        },
                        {
                            "value": "653128",
                            "label": "岳普湖县",
                            "area": "3093.068"
                        }
                    ]
                },
                {
                    "value": "652900",
                    "label": "阿克苏地区",
                    "children": [
                        {
                            "value": "652922",
                            "label": "温宿县",
                            "area": "14309.154"
                        },
                        {
                            "value": "652926",
                            "label": "拜城县",
                            "area": "15878.155"
                        },
                        {
                            "value": "652902",
                            "label": "库车市",
                            "area": "14508.2"
                        },
                        {
                            "value": "652925",
                            "label": "新和县",
                            "area": "5810.757"
                        },
                        {
                            "value": "652901",
                            "label": "阿克苏市",
                            "area": "14406.563"
                        },
                        {
                            "value": "652924",
                            "label": "沙雅县",
                            "area": "31847.68"
                        },
                        {
                            "value": "652929",
                            "label": "柯坪县",
                            "area": "8911.893"
                        },
                        {
                            "value": "652928",
                            "label": "阿瓦提县",
                            "area": "13052.521"
                        },
                        {
                            "value": "652927",
                            "label": "乌什县",
                            "area": "9036.098"
                        }
                    ]
                },
                {
                    "value": "650500",
                    "label": "哈密市",
                    "children": [
                        {
                            "value": "650521",
                            "label": "巴里坤哈萨克自治县",
                            "area": "36608.167"
                        },
                        {
                            "value": "650522",
                            "label": "伊吾县",
                            "area": "19449.66"
                        },
                        {
                            "value": "650502",
                            "label": "伊州区",
                            "area": "81163.818"
                        }
                    ]
                },
                {
                    "value": "653000",
                    "label": "克孜勒苏柯尔克孜自治州",
                    "children": [
                        {
                            "value": "653024",
                            "label": "乌恰县",
                            "area": "18753.62"
                        },
                        {
                            "value": "653001",
                            "label": "阿图什市",
                            "area": "15492.824"
                        },
                        {
                            "value": "653022",
                            "label": "阿克陶县",
                            "area": "24097.344"
                        },
                        {
                            "value": "653023",
                            "label": "阿合奇县",
                            "area": "11425.754"
                        }
                    ]
                },
                {
                    "value": "650400",
                    "label": "吐鲁番市",
                    "children": [
                        {
                            "value": "650402",
                            "label": "高昌区",
                            "area": "13649.747"
                        },
                        {
                            "value": "650421",
                            "label": "鄯善县",
                            "area": "39550.216"
                        },
                        {
                            "value": "650422",
                            "label": "托克逊县",
                            "area": "16121.768"
                        }
                    ]
                },
                {
                    "value": "650200",
                    "label": "克拉玛依市",
                    "children": [
                        {
                            "value": "650205",
                            "label": "乌尔禾区",
                            "area": "2232.594"
                        },
                        {
                            "value": "650202",
                            "label": "独山子区",
                            "area": "403.41"
                        },
                        {
                            "value": "650203",
                            "label": "克拉玛依区",
                            "area": "3121.553"
                        },
                        {
                            "value": "650204",
                            "label": "白碱滩区",
                            "area": "1386.016"
                        }
                    ]
                }
            ]
        },
        {
            "value": "420000",
            "label": "湖北省",
            "children": [
                {
                    "value": "420100",
                    "label": "武汉市",
                    "children": [
                        {
                            "value": "420116",
                            "label": "黄陂区",
                            "area": "2247.581"
                        },
                        {
                            "value": "420117",
                            "label": "新洲区",
                            "area": "1477.271"
                        },
                        {
                            "value": "420114",
                            "label": "蔡甸区",
                            "area": "1088.528"
                        },
                        {
                            "value": "420113",
                            "label": "汉南区",
                            "area": "288.845"
                        },
                        {
                            "value": "420115",
                            "label": "江夏区",
                            "area": "2012.206"
                        },
                        {
                            "value": "420106",
                            "label": "武昌区",
                            "area": "100.087"
                        },
                        {
                            "value": "420104",
                            "label": "硚口区",
                            "area": "40.336"
                        },
                        {
                            "value": "420112",
                            "label": "东西湖区",
                            "area": "491.496"
                        },
                        {
                            "value": "420105",
                            "label": "汉阳区",
                            "area": "112.675"
                        },
                        {
                            "value": "420107",
                            "label": "青山区",
                            "area": "91.704"
                        },
                        {
                            "value": "420103",
                            "label": "江汉区",
                            "area": "28.56"
                        },
                        {
                            "value": "420102",
                            "label": "江岸区",
                            "area": "80.77"
                        },
                        {
                            "value": "420111",
                            "label": "洪山区",
                            "area": "514.683"
                        }
                    ]
                },
                {
                    "value": "420300",
                    "label": "十堰市",
                    "children": [
                        {
                            "value": "420304",
                            "label": "郧阳区",
                            "area": "3819.248"
                        },
                        {
                            "value": "420303",
                            "label": "张湾区",
                            "area": "659.9"
                        },
                        {
                            "value": "420324",
                            "label": "竹溪县",
                            "area": "3305.58"
                        },
                        {
                            "value": "420323",
                            "label": "竹山县",
                            "area": "3611.583"
                        },
                        {
                            "value": "420381",
                            "label": "丹江口市",
                            "area": "3121.855"
                        },
                        {
                            "value": "420302",
                            "label": "茅箭区",
                            "area": "539.923"
                        },
                        {
                            "value": "420325",
                            "label": "房县",
                            "area": "5128.145"
                        },
                        {
                            "value": "420322",
                            "label": "郧西县",
                            "area": "3513.676"
                        }
                    ]
                },
                {
                    "value": "421100",
                    "label": "黄冈市",
                    "children": [
                        {
                            "value": "421181",
                            "label": "麻城市",
                            "area": "3602.688"
                        },
                        {
                            "value": "421102",
                            "label": "黄州区",
                            "area": "358.349"
                        },
                        {
                            "value": "421126",
                            "label": "蕲春县",
                            "area": "2405.543"
                        },
                        {
                            "value": "421125",
                            "label": "浠水县",
                            "area": "1955.416"
                        },
                        {
                            "value": "421124",
                            "label": "英山县",
                            "area": "1458.116"
                        },
                        {
                            "value": "421122",
                            "label": "红安县",
                            "area": "1782.7"
                        },
                        {
                            "value": "421182",
                            "label": "武穴市",
                            "area": "1225.381"
                        },
                        {
                            "value": "421123",
                            "label": "罗田县",
                            "area": "2130.051"
                        },
                        {
                            "value": "421127",
                            "label": "黄梅县",
                            "area": "1705.29"
                        },
                        {
                            "value": "421121",
                            "label": "团风县",
                            "area": "811.278"
                        }
                    ]
                },
                {
                    "value": "429000",
                    "label": "天门市",
                    "children": [
                        {
                            "value": "429006",
                            "label": "天门市",
                            "area": "2616.604"
                        },
                        {
                            "value": "429005",
                            "label": "潜江市",
                            "area": "1998.215"
                        },
                        {
                            "value": "429004",
                            "label": "仙桃市",
                            "area": "2519.318"
                        },
                        {
                            "value": "429021",
                            "label": "神农架林区",
                            "area": "3240.045"
                        }
                    ]
                },
                {
                    "value": "420600",
                    "label": "襄阳市",
                    "children": [
                        {
                            "value": "420625",
                            "label": "谷城县",
                            "area": "2548.059"
                        },
                        {
                            "value": "420683",
                            "label": "枣阳市",
                            "area": "3270.25"
                        },
                        {
                            "value": "420626",
                            "label": "保康县",
                            "area": "3223.594"
                        },
                        {
                            "value": "420682",
                            "label": "老河口市",
                            "area": "1061.026"
                        },
                        {
                            "value": "420684",
                            "label": "宜城市",
                            "area": "2113.996"
                        },
                        {
                            "value": "420602",
                            "label": "襄城区",
                            "area": "659.344"
                        },
                        {
                            "value": "420606",
                            "label": "樊城区",
                            "area": "549.681"
                        },
                        {
                            "value": "420624",
                            "label": "南漳县",
                            "area": "3838.932"
                        },
                        {
                            "value": "420607",
                            "label": "襄州区",
                            "area": "2455.227"
                        }
                    ]
                },
                {
                    "value": "420500",
                    "label": "宜昌市",
                    "children": [
                        {
                            "value": "420526",
                            "label": "兴山县",
                            "area": "2311.169"
                        },
                        {
                            "value": "420582",
                            "label": "当阳市",
                            "area": "2153.294"
                        },
                        {
                            "value": "420527",
                            "label": "秭归县",
                            "area": "2275.44"
                        },
                        {
                            "value": "420504",
                            "label": "点军区",
                            "area": "532.644"
                        },
                        {
                            "value": "420528",
                            "label": "长阳土家族自治县",
                            "area": "3391.608"
                        },
                        {
                            "value": "420581",
                            "label": "宜都市",
                            "area": "1348.381"
                        },
                        {
                            "value": "420503",
                            "label": "伍家岗区",
                            "area": "82.312"
                        },
                        {
                            "value": "420583",
                            "label": "枝江市",
                            "area": "1367.633"
                        },
                        {
                            "value": "420505",
                            "label": "猇亭区",
                            "area": "120.841"
                        },
                        {
                            "value": "420529",
                            "label": "五峰土家族自治县",
                            "area": "2400.796"
                        },
                        {
                            "value": "420502",
                            "label": "西陵区",
                            "area": "61.455"
                        },
                        {
                            "value": "420506",
                            "label": "夷陵区",
                            "area": "3431.143"
                        },
                        {
                            "value": "420525",
                            "label": "远安县",
                            "area": "1745.348"
                        }
                    ]
                },
                {
                    "value": "420900",
                    "label": "孝感市",
                    "children": [
                        {
                            "value": "420923",
                            "label": "云梦县",
                            "area": "609.875"
                        },
                        {
                            "value": "420984",
                            "label": "汉川市",
                            "area": "1655.671"
                        },
                        {
                            "value": "420981",
                            "label": "应城市",
                            "area": "1098.542"
                        },
                        {
                            "value": "420902",
                            "label": "孝南区",
                            "area": "1030.448"
                        },
                        {
                            "value": "420921",
                            "label": "孝昌县",
                            "area": "1182.409"
                        },
                        {
                            "value": "420982",
                            "label": "安陆市",
                            "area": "1353.899"
                        },
                        {
                            "value": "420922",
                            "label": "大悟县",
                            "area": "1981.044"
                        }
                    ]
                },
                {
                    "value": "420800",
                    "label": "荆门市",
                    "children": [
                        {
                            "value": "420802",
                            "label": "东宝区",
                            "area": "1670.628"
                        },
                        {
                            "value": "420882",
                            "label": "京山市",
                            "area": "3521.075"
                        },
                        {
                            "value": "420881",
                            "label": "钟祥市",
                            "area": "4427.088"
                        },
                        {
                            "value": "420804",
                            "label": "掇刀区",
                            "area": "579.419"
                        },
                        {
                            "value": "420822",
                            "label": "沙洋县",
                            "area": "2147.637"
                        }
                    ]
                },
                {
                    "value": "421000",
                    "label": "荆州市",
                    "children": [
                        {
                            "value": "421083",
                            "label": "洪湖市",
                            "area": "2440.077"
                        },
                        {
                            "value": "421024",
                            "label": "江陵县",
                            "area": "1043.688"
                        },
                        {
                            "value": "421023",
                            "label": "监利市",
                            "area": "3179.811"
                        },
                        {
                            "value": "421081",
                            "label": "石首市",
                            "area": "1401.124"
                        },
                        {
                            "value": "421087",
                            "label": "松滋市",
                            "area": "2179.088"
                        },
                        {
                            "value": "421002",
                            "label": "沙市区",
                            "area": "522.506"
                        },
                        {
                            "value": "421022",
                            "label": "公安县",
                            "area": "2278.117"
                        },
                        {
                            "value": "421003",
                            "label": "荆州区",
                            "area": "1042.124"
                        }
                    ]
                },
                {
                    "value": "422800",
                    "label": "恩施土家族苗族自治州",
                    "children": [
                        {
                            "value": "422822",
                            "label": "建始县",
                            "area": "2667.059"
                        },
                        {
                            "value": "422802",
                            "label": "利川市",
                            "area": "4612.333"
                        },
                        {
                            "value": "422801",
                            "label": "恩施市",
                            "area": "3957.138"
                        },
                        {
                            "value": "422828",
                            "label": "鹤峰县",
                            "area": "2899.414"
                        },
                        {
                            "value": "422827",
                            "label": "来凤县",
                            "area": "1333.68"
                        },
                        {
                            "value": "422823",
                            "label": "巴东县",
                            "area": "3345.007"
                        },
                        {
                            "value": "422825",
                            "label": "宣恩县",
                            "area": "2748.139"
                        },
                        {
                            "value": "422826",
                            "label": "咸丰县",
                            "area": "2511.259"
                        }
                    ]
                },
                {
                    "value": "421200",
                    "label": "咸宁市",
                    "children": [
                        {
                            "value": "421221",
                            "label": "嘉鱼县",
                            "area": "1024.796"
                        },
                        {
                            "value": "421224",
                            "label": "通山县",
                            "area": "2414.207"
                        },
                        {
                            "value": "421202",
                            "label": "咸安区",
                            "area": "1500.452"
                        },
                        {
                            "value": "421281",
                            "label": "赤壁市",
                            "area": "1705.287"
                        },
                        {
                            "value": "421222",
                            "label": "通城县",
                            "area": "1114.364"
                        },
                        {
                            "value": "421223",
                            "label": "崇阳县",
                            "area": "1979.854"
                        }
                    ]
                },
                {
                    "value": "421300",
                    "label": "随州市",
                    "children": [
                        {
                            "value": "421303",
                            "label": "曾都区",
                            "area": "1438.016"
                        },
                        {
                            "value": "421321",
                            "label": "随县",
                            "area": "5527.475"
                        },
                        {
                            "value": "421381",
                            "label": "广水市",
                            "area": "2646.5"
                        }
                    ]
                },
                {
                    "value": "420200",
                    "label": "黄石市",
                    "children": [
                        {
                            "value": "420205",
                            "label": "铁山区",
                            "area": "28.303"
                        },
                        {
                            "value": "420203",
                            "label": "西塞山区",
                            "area": "113.236"
                        },
                        {
                            "value": "420204",
                            "label": "下陆区",
                            "area": "61.949"
                        },
                        {
                            "value": "420222",
                            "label": "阳新县",
                            "area": "2773.232"
                        },
                        {
                            "value": "420281",
                            "label": "大冶市",
                            "area": "1555.338"
                        },
                        {
                            "value": "420202",
                            "label": "黄石港区",
                            "area": "32.219"
                        }
                    ]
                },
                {
                    "value": "420700",
                    "label": "鄂州市",
                    "children": [
                        {
                            "value": "420703",
                            "label": "华容区",
                            "area": "500.214"
                        },
                        {
                            "value": "420702",
                            "label": "梁子湖区",
                            "area": "493.938"
                        },
                        {
                            "value": "420704",
                            "label": "鄂城区",
                            "area": "594.319"
                        }
                    ]
                }
            ]
        },
        {
            "value": "210000",
            "label": "辽宁省",
            "children": [
                {
                    "value": "211400",
                    "label": "葫芦岛市",
                    "children": [
                        {
                            "value": "211422",
                            "label": "建昌县",
                            "area": "3175.29"
                        },
                        {
                            "value": "211403",
                            "label": "龙港区",
                            "area": "190.904"
                        },
                        {
                            "value": "211481",
                            "label": "兴城市",
                            "area": "2152.807"
                        },
                        {
                            "value": "211421",
                            "label": "绥中县",
                            "area": "2887.486"
                        },
                        {
                            "value": "211404",
                            "label": "南票区",
                            "area": "1005.035"
                        },
                        {
                            "value": "211402",
                            "label": "连山区",
                            "area": "1173.22"
                        }
                    ]
                },
                {
                    "value": "210200",
                    "label": "大连市",
                    "children": [
                        {
                            "value": "210281",
                            "label": "瓦房店市",
                            "area": "3891.017"
                        },
                        {
                            "value": "210202",
                            "label": "中山区",
                            "area": "56.234"
                        },
                        {
                            "value": "210212",
                            "label": "旅顺口区",
                            "area": "544.421"
                        },
                        {
                            "value": "210283",
                            "label": "庄河市",
                            "area": "3990.175"
                        },
                        {
                            "value": "210214",
                            "label": "普兰店区",
                            "area": "2617.3"
                        },
                        {
                            "value": "210224",
                            "label": "长海县",
                            "area": "144.522"
                        },
                        {
                            "value": "210213",
                            "label": "金州区",
                            "area": "1959.517"
                        },
                        {
                            "value": "210211",
                            "label": "甘井子区",
                            "area": "564.12"
                        },
                        {
                            "value": "210203",
                            "label": "西岗区",
                            "area": "28.975"
                        },
                        {
                            "value": "210204",
                            "label": "沙河口区",
                            "area": "43.188"
                        }
                    ]
                },
                {
                    "value": "210700",
                    "label": "锦州市",
                    "children": [
                        {
                            "value": "210726",
                            "label": "黑山县",
                            "area": "2514.872"
                        },
                        {
                            "value": "210711",
                            "label": "太和区",
                            "area": "931.624"
                        },
                        {
                            "value": "210727",
                            "label": "义县",
                            "area": "2486.636"
                        },
                        {
                            "value": "210781",
                            "label": "凌海市",
                            "area": "2387.51"
                        },
                        {
                            "value": "210782",
                            "label": "北镇市",
                            "area": "1674.159"
                        },
                        {
                            "value": "210702",
                            "label": "古塔区",
                            "area": "70.004"
                        },
                        {
                            "value": "210703",
                            "label": "凌河区",
                            "area": "38.917"
                        }
                    ]
                },
                {
                    "value": "211200",
                    "label": "铁岭市",
                    "children": [
                        {
                            "value": "211281",
                            "label": "调兵山市",
                            "area": "262.629"
                        },
                        {
                            "value": "211204",
                            "label": "清河区",
                            "area": "482.288"
                        },
                        {
                            "value": "211282",
                            "label": "开原市",
                            "area": "2814.116"
                        },
                        {
                            "value": "211221",
                            "label": "铁岭县",
                            "area": "2241.969"
                        },
                        {
                            "value": "211202",
                            "label": "银州区",
                            "area": "171.509"
                        },
                        {
                            "value": "211224",
                            "label": "昌图县",
                            "area": "4326.192"
                        },
                        {
                            "value": "211223",
                            "label": "西丰县",
                            "area": "2683.697"
                        }
                    ]
                },
                {
                    "value": "210600",
                    "label": "丹东市",
                    "children": [
                        {
                            "value": "210682",
                            "label": "凤城市",
                            "area": "5511.928"
                        },
                        {
                            "value": "210604",
                            "label": "振安区",
                            "area": "651.117"
                        },
                        {
                            "value": "210681",
                            "label": "东港市",
                            "area": "2215.234"
                        },
                        {
                            "value": "210603",
                            "label": "振兴区",
                            "area": "176.828"
                        },
                        {
                            "value": "210624",
                            "label": "宽甸满族自治县",
                            "area": "6131.928"
                        },
                        {
                            "value": "210602",
                            "label": "元宝区",
                            "area": "90.936"
                        }
                    ]
                },
                {
                    "value": "210900",
                    "label": "阜新市",
                    "children": [
                        {
                            "value": "210905",
                            "label": "清河门区",
                            "area": "91.004"
                        },
                        {
                            "value": "210904",
                            "label": "太平区",
                            "area": "95.161"
                        },
                        {
                            "value": "210903",
                            "label": "新邱区",
                            "area": "122.663"
                        },
                        {
                            "value": "210921",
                            "label": "阜新蒙古族自治县",
                            "area": "6215.022"
                        },
                        {
                            "value": "210922",
                            "label": "彰武县",
                            "area": "3622.267"
                        },
                        {
                            "value": "210911",
                            "label": "细河区",
                            "area": "101.569"
                        },
                        {
                            "value": "210902",
                            "label": "海州区",
                            "area": "67.862"
                        }
                    ]
                },
                {
                    "value": "210400",
                    "label": "抚顺市",
                    "children": [
                        {
                            "value": "210422",
                            "label": "新宾满族自治县",
                            "area": "4295.768"
                        },
                        {
                            "value": "210423",
                            "label": "清原满族自治县",
                            "area": "3925.248"
                        },
                        {
                            "value": "210402",
                            "label": "新抚区",
                            "area": "109.506"
                        },
                        {
                            "value": "210403",
                            "label": "东洲区",
                            "area": "603.376"
                        },
                        {
                            "value": "210411",
                            "label": "顺城区",
                            "area": "357.813"
                        },
                        {
                            "value": "210421",
                            "label": "抚顺县",
                            "area": "1670.788"
                        },
                        {
                            "value": "210404",
                            "label": "望花区",
                            "area": "307.048"
                        }
                    ]
                },
                {
                    "value": "211100",
                    "label": "盘锦市",
                    "children": [
                        {
                            "value": "211104",
                            "label": "大洼区",
                            "area": "1587.568"
                        },
                        {
                            "value": "211103",
                            "label": "兴隆台区",
                            "area": "260.259"
                        },
                        {
                            "value": "211122",
                            "label": "盘山县",
                            "area": "1904.258"
                        },
                        {
                            "value": "211102",
                            "label": "双台子区",
                            "area": "75.865"
                        }
                    ]
                },
                {
                    "value": "210100",
                    "label": "沈阳市",
                    "children": [
                        {
                            "value": "210181",
                            "label": "新民市",
                            "area": "3300.807"
                        },
                        {
                            "value": "210114",
                            "label": "于洪区",
                            "area": "499.828"
                        },
                        {
                            "value": "210105",
                            "label": "皇姑区",
                            "area": "66.203"
                        },
                        {
                            "value": "210102",
                            "label": "和平区",
                            "area": "60.013"
                        },
                        {
                            "value": "210111",
                            "label": "苏家屯区",
                            "area": "780.867"
                        },
                        {
                            "value": "210123",
                            "label": "康平县",
                            "area": "2166.947"
                        },
                        {
                            "value": "210124",
                            "label": "法库县",
                            "area": "2281.792"
                        },
                        {
                            "value": "210104",
                            "label": "大东区",
                            "area": "100.167"
                        },
                        {
                            "value": "210103",
                            "label": "沈河区",
                            "area": "59.816"
                        },
                        {
                            "value": "210106",
                            "label": "铁西区",
                            "area": "468.611"
                        },
                        {
                            "value": "210115",
                            "label": "辽中区",
                            "area": "1460.128"
                        },
                        {
                            "value": "210112",
                            "label": "浑南区",
                            "area": "808.784"
                        },
                        {
                            "value": "210113",
                            "label": "沈北新区",
                            "area": "814.102"
                        }
                    ]
                },
                {
                    "value": "210800",
                    "label": "营口市",
                    "children": [
                        {
                            "value": "210804",
                            "label": "鲅鱼圈区",
                            "area": "353.71"
                        },
                        {
                            "value": "210882",
                            "label": "大石桥市",
                            "area": "1603.345"
                        },
                        {
                            "value": "210881",
                            "label": "盖州市",
                            "area": "2991.877"
                        },
                        {
                            "value": "210803",
                            "label": "西市区",
                            "area": "268.562"
                        },
                        {
                            "value": "210811",
                            "label": "老边区",
                            "area": "249.733"
                        },
                        {
                            "value": "210802",
                            "label": "站前区",
                            "area": "39.638"
                        }
                    ]
                },
                {
                    "value": "210500",
                    "label": "本溪市",
                    "children": [
                        {
                            "value": "210522",
                            "label": "桓仁满族自治县",
                            "area": "3562.692"
                        },
                        {
                            "value": "210503",
                            "label": "溪湖区",
                            "area": "317.824"
                        },
                        {
                            "value": "210505",
                            "label": "南芬区",
                            "area": "615.373"
                        },
                        {
                            "value": "210504",
                            "label": "明山区",
                            "area": "405.29"
                        },
                        {
                            "value": "210502",
                            "label": "平山区",
                            "area": "178.247"
                        },
                        {
                            "value": "210521",
                            "label": "本溪满族自治县",
                            "area": "3347.795"
                        }
                    ]
                },
                {
                    "value": "211300",
                    "label": "朝阳市",
                    "children": [
                        {
                            "value": "211381",
                            "label": "北票市",
                            "area": "4444.242"
                        },
                        {
                            "value": "211302",
                            "label": "双塔区",
                            "area": "488.201"
                        },
                        {
                            "value": "211321",
                            "label": "朝阳县",
                            "area": "3758.243"
                        },
                        {
                            "value": "211303",
                            "label": "龙城区",
                            "area": "618.737"
                        },
                        {
                            "value": "211324",
                            "label": "喀喇沁左翼蒙古族自治县",
                            "area": "2243.963"
                        },
                        {
                            "value": "211322",
                            "label": "建平县",
                            "area": "4861.108"
                        },
                        {
                            "value": "211382",
                            "label": "凌源市",
                            "area": "3285.795"
                        }
                    ]
                },
                {
                    "value": "210300",
                    "label": "鞍山市",
                    "children": [
                        {
                            "value": "210321",
                            "label": "台安县",
                            "area": "1387.681"
                        },
                        {
                            "value": "210323",
                            "label": "岫岩满族自治县",
                            "area": "4508.224"
                        },
                        {
                            "value": "210302",
                            "label": "铁东区",
                            "area": "154.44"
                        },
                        {
                            "value": "210303",
                            "label": "铁西区",
                            "area": "142.709"
                        },
                        {
                            "value": "210304",
                            "label": "立山区",
                            "area": "169.93"
                        },
                        {
                            "value": "210311",
                            "label": "千山区",
                            "area": "328.974"
                        },
                        {
                            "value": "210381",
                            "label": "海城市",
                            "area": "2571.952"
                        }
                    ]
                },
                {
                    "value": "211000",
                    "label": "辽阳市",
                    "children": [
                        {
                            "value": "211011",
                            "label": "太子河区",
                            "area": "279.077"
                        },
                        {
                            "value": "211003",
                            "label": "文圣区",
                            "area": "287.043"
                        },
                        {
                            "value": "211005",
                            "label": "弓长岭区",
                            "area": "340.212"
                        },
                        {
                            "value": "211002",
                            "label": "白塔区",
                            "area": "29.634"
                        },
                        {
                            "value": "211081",
                            "label": "灯塔市",
                            "area": "1166.762"
                        },
                        {
                            "value": "211004",
                            "label": "宏伟区",
                            "area": "164.226"
                        },
                        {
                            "value": "211021",
                            "label": "辽阳县",
                            "area": "2445.563"
                        }
                    ]
                }
            ]
        },
        {
            "value": "370000",
            "label": "山东省",
            "children": [
                {
                    "value": "370600",
                    "label": "烟台市",
                    "children": [
                        {
                            "value": "370614",
                            "label": "蓬莱区",
                            "area": "1238.832"
                        },
                        {
                            "value": "370602",
                            "label": "芝罘区",
                            "area": "197.892"
                        },
                        {
                            "value": "370683",
                            "label": "莱州市",
                            "area": "1957.765"
                        },
                        {
                            "value": "370687",
                            "label": "海阳市",
                            "area": "1959.72"
                        },
                        {
                            "value": "370682",
                            "label": "莱阳市",
                            "area": "1724.254"
                        },
                        {
                            "value": "370681",
                            "label": "龙口市",
                            "area": "994.098"
                        },
                        {
                            "value": "370685",
                            "label": "招远市",
                            "area": "1442.521"
                        },
                        {
                            "value": "370612",
                            "label": "牟平区",
                            "area": "1554.397"
                        },
                        {
                            "value": "370613",
                            "label": "莱山区",
                            "area": "332.705"
                        },
                        {
                            "value": "370611",
                            "label": "福山区",
                            "area": "971.172"
                        },
                        {
                            "value": "370686",
                            "label": "栖霞市",
                            "area": "1791.135"
                        }
                    ]
                },
                {
                    "value": "371000",
                    "label": "威海市",
                    "children": [
                        {
                            "value": "371082",
                            "label": "荣成市",
                            "area": "1669.463"
                        },
                        {
                            "value": "371002",
                            "label": "环翠区",
                            "area": "1013.899"
                        },
                        {
                            "value": "371003",
                            "label": "文登区",
                            "area": "1591.775"
                        },
                        {
                            "value": "371083",
                            "label": "乳山市",
                            "area": "1683.408"
                        }
                    ]
                },
                {
                    "value": "371500",
                    "label": "聊城市",
                    "children": [
                        {
                            "value": "371525",
                            "label": "冠县",
                            "area": "1161.067"
                        },
                        {
                            "value": "371522",
                            "label": "莘县",
                            "area": "1383.955"
                        },
                        {
                            "value": "371503",
                            "label": "茌平区",
                            "area": "1009.331"
                        },
                        {
                            "value": "371502",
                            "label": "东昌府区",
                            "area": "1440.854"
                        },
                        {
                            "value": "371524",
                            "label": "东阿县",
                            "area": "735.19"
                        },
                        {
                            "value": "371521",
                            "label": "阳谷县",
                            "area": "1004.763"
                        },
                        {
                            "value": "371526",
                            "label": "高唐县",
                            "area": "950.827"
                        },
                        {
                            "value": "371581",
                            "label": "临清市",
                            "area": "953.628"
                        }
                    ]
                },
                {
                    "value": "371300",
                    "label": "临沂市",
                    "children": [
                        {
                            "value": "371323",
                            "label": "沂水县",
                            "area": "2403.844"
                        },
                        {
                            "value": "371328",
                            "label": "蒙阴县",
                            "area": "1612.314"
                        },
                        {
                            "value": "371326",
                            "label": "平邑县",
                            "area": "1825.341"
                        },
                        {
                            "value": "371322",
                            "label": "郯城县",
                            "area": "1194.882"
                        },
                        {
                            "value": "371302",
                            "label": "兰山区",
                            "area": "890.284"
                        },
                        {
                            "value": "371327",
                            "label": "莒南县",
                            "area": "1747.404"
                        },
                        {
                            "value": "371321",
                            "label": "沂南县",
                            "area": "1724.58"
                        },
                        {
                            "value": "371311",
                            "label": "罗庄区",
                            "area": "569.685"
                        },
                        {
                            "value": "371324",
                            "label": "兰陵县",
                            "area": "1722.91"
                        },
                        {
                            "value": "371325",
                            "label": "费县",
                            "area": "1654.88"
                        },
                        {
                            "value": "371312",
                            "label": "河东区",
                            "area": "839.044"
                        },
                        {
                            "value": "371329",
                            "label": "临沭县",
                            "area": "1016.747"
                        }
                    ]
                },
                {
                    "value": "370200",
                    "label": "青岛市",
                    "children": [
                        {
                            "value": "370285",
                            "label": "莱西市",
                            "area": "1568.322"
                        },
                        {
                            "value": "370215",
                            "label": "即墨区",
                            "area": "1888.687"
                        },
                        {
                            "value": "370212",
                            "label": "崂山区",
                            "area": "420.02"
                        },
                        {
                            "value": "370214",
                            "label": "城阳区",
                            "area": "541.026"
                        },
                        {
                            "value": "370211",
                            "label": "黄岛区",
                            "area": "2129.328"
                        },
                        {
                            "value": "370203",
                            "label": "市北区",
                            "area": "69.387"
                        },
                        {
                            "value": "370202",
                            "label": "市南区",
                            "area": "34.875"
                        },
                        {
                            "value": "370213",
                            "label": "李沧区",
                            "area": "98.67"
                        },
                        {
                            "value": "370283",
                            "label": "平度市",
                            "area": "3178.676"
                        },
                        {
                            "value": "370281",
                            "label": "胶州市",
                            "area": "1310.226"
                        }
                    ]
                },
                {
                    "value": "370300",
                    "label": "淄博市",
                    "children": [
                        {
                            "value": "370322",
                            "label": "高青县",
                            "area": "835.264"
                        },
                        {
                            "value": "370305",
                            "label": "临淄区",
                            "area": "666.886"
                        },
                        {
                            "value": "370321",
                            "label": "桓台县",
                            "area": "503.998"
                        },
                        {
                            "value": "370303",
                            "label": "张店区",
                            "area": "359.327"
                        },
                        {
                            "value": "370323",
                            "label": "沂源县",
                            "area": "1636.238"
                        },
                        {
                            "value": "370304",
                            "label": "博山区",
                            "area": "697.298"
                        },
                        {
                            "value": "370302",
                            "label": "淄川区",
                            "area": "958.936"
                        },
                        {
                            "value": "370306",
                            "label": "周村区",
                            "area": "307.439"
                        }
                    ]
                },
                {
                    "value": "371600",
                    "label": "滨州市",
                    "children": [
                        {
                            "value": "371603",
                            "label": "沾化区",
                            "area": "1784.36"
                        },
                        {
                            "value": "371602",
                            "label": "滨城区",
                            "area": "1041.292"
                        },
                        {
                            "value": "371625",
                            "label": "博兴县",
                            "area": "895.66"
                        },
                        {
                            "value": "371681",
                            "label": "邹平市",
                            "area": "1253.975"
                        },
                        {
                            "value": "371623",
                            "label": "无棣县",
                            "area": "1996.482"
                        },
                        {
                            "value": "371622",
                            "label": "阳信县",
                            "area": "793.226"
                        },
                        {
                            "value": "371621",
                            "label": "惠民县",
                            "area": "1367.659"
                        }
                    ]
                },
                {
                    "value": "370500",
                    "label": "东营市",
                    "children": [
                        {
                            "value": "370522",
                            "label": "利津县",
                            "area": "1215.794"
                        },
                        {
                            "value": "370523",
                            "label": "广饶县",
                            "area": "1174.206"
                        },
                        {
                            "value": "370502",
                            "label": "东营区",
                            "area": "1151.804"
                        },
                        {
                            "value": "370503",
                            "label": "河口区",
                            "area": "2048.057"
                        },
                        {
                            "value": "370505",
                            "label": "垦利区",
                            "area": "2263.08"
                        }
                    ]
                },
                {
                    "value": "370700",
                    "label": "潍坊市",
                    "children": [
                        {
                            "value": "370702",
                            "label": "潍城区",
                            "area": "286.675"
                        },
                        {
                            "value": "370705",
                            "label": "奎文区",
                            "area": "177.63"
                        },
                        {
                            "value": "370724",
                            "label": "临朐县",
                            "area": "1832.271"
                        },
                        {
                            "value": "370782",
                            "label": "诸城市",
                            "area": "2155.739"
                        },
                        {
                            "value": "370783",
                            "label": "寿光市",
                            "area": "1961.944"
                        },
                        {
                            "value": "370703",
                            "label": "寒亭区",
                            "area": "1310.494"
                        },
                        {
                            "value": "370781",
                            "label": "青州市",
                            "area": "1560.417"
                        },
                        {
                            "value": "370785",
                            "label": "高密市",
                            "area": "1518.943"
                        },
                        {
                            "value": "370786",
                            "label": "昌邑市",
                            "area": "1525.972"
                        },
                        {
                            "value": "370704",
                            "label": "坊子区",
                            "area": "899.864"
                        },
                        {
                            "value": "370784",
                            "label": "安丘市",
                            "area": "1707.179"
                        },
                        {
                            "value": "370725",
                            "label": "昌乐县",
                            "area": "1099.4"
                        }
                    ]
                },
                {
                    "value": "371100",
                    "label": "日照市",
                    "children": [
                        {
                            "value": "371121",
                            "label": "五莲县",
                            "area": "1493.36"
                        },
                        {
                            "value": "371102",
                            "label": "东港区",
                            "area": "1313.23"
                        },
                        {
                            "value": "371122",
                            "label": "莒县",
                            "area": "1817.143"
                        },
                        {
                            "value": "371103",
                            "label": "岚山区",
                            "area": "800.87"
                        }
                    ]
                },
                {
                    "value": "370400",
                    "label": "枣庄市",
                    "children": [
                        {
                            "value": "370406",
                            "label": "山亭区",
                            "area": "1022.927"
                        },
                        {
                            "value": "370403",
                            "label": "薛城区",
                            "area": "511.911"
                        },
                        {
                            "value": "370404",
                            "label": "峄城区",
                            "area": "635.591"
                        },
                        {
                            "value": "370402",
                            "label": "市中区",
                            "area": "373.225"
                        },
                        {
                            "value": "370481",
                            "label": "滕州市",
                            "area": "1490.261"
                        },
                        {
                            "value": "370405",
                            "label": "台儿庄区",
                            "area": "537.527"
                        }
                    ]
                },
                {
                    "value": "370100",
                    "label": "济南市",
                    "children": [
                        {
                            "value": "370114",
                            "label": "章丘区",
                            "area": "1716.153"
                        },
                        {
                            "value": "370115",
                            "label": "济阳区",
                            "area": "1092.978"
                        },
                        {
                            "value": "370124",
                            "label": "平阴县",
                            "area": "704.586"
                        },
                        {
                            "value": "370126",
                            "label": "商河县",
                            "area": "1161.563"
                        },
                        {
                            "value": "370113",
                            "label": "长清区",
                            "area": "1207.676"
                        },
                        {
                            "value": "370117",
                            "label": "钢城区",
                            "area": "515.738"
                        },
                        {
                            "value": "370116",
                            "label": "莱芜区",
                            "area": "1721.813"
                        },
                        {
                            "value": "370105",
                            "label": "天桥区",
                            "area": "258.937"
                        },
                        {
                            "value": "370102",
                            "label": "历下区",
                            "area": "100.543"
                        },
                        {
                            "value": "370112",
                            "label": "历城区",
                            "area": "1303.104"
                        },
                        {
                            "value": "370103",
                            "label": "市中区",
                            "area": "287.482"
                        },
                        {
                            "value": "370104",
                            "label": "槐荫区",
                            "area": "150.615"
                        }
                    ]
                },
                {
                    "value": "371700",
                    "label": "菏泽市",
                    "children": [
                        {
                            "value": "371726",
                            "label": "鄄城县",
                            "area": "1044.548"
                        },
                        {
                            "value": "371703",
                            "label": "定陶区",
                            "area": "834.303"
                        },
                        {
                            "value": "371725",
                            "label": "郓城县",
                            "area": "1625.728"
                        },
                        {
                            "value": "371723",
                            "label": "成武县",
                            "area": "993.666"
                        },
                        {
                            "value": "371724",
                            "label": "巨野县",
                            "area": "1301.298"
                        },
                        {
                            "value": "371721",
                            "label": "曹县",
                            "area": "1964.176"
                        },
                        {
                            "value": "371722",
                            "label": "单县",
                            "area": "1649.038"
                        },
                        {
                            "value": "371702",
                            "label": "牡丹区",
                            "area": "1433.58"
                        },
                        {
                            "value": "371728",
                            "label": "东明县",
                            "area": "1300.503"
                        }
                    ]
                },
                {
                    "value": "370900",
                    "label": "泰安市",
                    "children": [
                        {
                            "value": "370982",
                            "label": "新泰市",
                            "area": "1919.471"
                        },
                        {
                            "value": "370923",
                            "label": "东平县",
                            "area": "1338.819"
                        },
                        {
                            "value": "370902",
                            "label": "泰山区",
                            "area": "339.136"
                        },
                        {
                            "value": "370911",
                            "label": "岱岳区",
                            "area": "1751.717"
                        },
                        {
                            "value": "370983",
                            "label": "肥城市",
                            "area": "1276.887"
                        },
                        {
                            "value": "370921",
                            "label": "宁阳县",
                            "area": "1110.485"
                        }
                    ]
                },
                {
                    "value": "371400",
                    "label": "德州市",
                    "children": [
                        {
                            "value": "371481",
                            "label": "乐陵市",
                            "area": "1175.317"
                        },
                        {
                            "value": "371422",
                            "label": "宁津县",
                            "area": "833.282"
                        },
                        {
                            "value": "371402",
                            "label": "德城区",
                            "area": "539.658"
                        },
                        {
                            "value": "371428",
                            "label": "武城县",
                            "area": "752.014"
                        },
                        {
                            "value": "371423",
                            "label": "庆云县",
                            "area": "505.448"
                        },
                        {
                            "value": "371425",
                            "label": "齐河县",
                            "area": "1405.325"
                        },
                        {
                            "value": "371482",
                            "label": "禹城市",
                            "area": "994.864"
                        },
                        {
                            "value": "371424",
                            "label": "临邑县",
                            "area": "1013.914"
                        },
                        {
                            "value": "371403",
                            "label": "陵城区",
                            "area": "1218.966"
                        },
                        {
                            "value": "371426",
                            "label": "平原县",
                            "area": "1051.703"
                        },
                        {
                            "value": "371427",
                            "label": "夏津县",
                            "area": "879.069"
                        }
                    ]
                },
                {
                    "value": "370800",
                    "label": "济宁市",
                    "children": [
                        {
                            "value": "370881",
                            "label": "曲阜市",
                            "area": "829.435"
                        },
                        {
                            "value": "370830",
                            "label": "汶上县",
                            "area": "879.233"
                        },
                        {
                            "value": "370831",
                            "label": "泗水县",
                            "area": "1119.721"
                        },
                        {
                            "value": "370883",
                            "label": "邹城市",
                            "area": "1611.149"
                        },
                        {
                            "value": "370811",
                            "label": "任城区",
                            "area": "874.66"
                        },
                        {
                            "value": "370829",
                            "label": "嘉祥县",
                            "area": "983.788"
                        },
                        {
                            "value": "370832",
                            "label": "梁山县",
                            "area": "953.248"
                        },
                        {
                            "value": "370828",
                            "label": "金乡县",
                            "area": "881.645"
                        },
                        {
                            "value": "370812",
                            "label": "兖州区",
                            "area": "650.897"
                        },
                        {
                            "value": "370826",
                            "label": "微山县",
                            "area": "1768.193"
                        },
                        {
                            "value": "370827",
                            "label": "鱼台县",
                            "area": "651.431"
                        }
                    ]
                }
            ]
        },
        {
            "value": "610000",
            "label": "陕西省",
            "children": [
                {
                    "value": "610200",
                    "label": "铜川市",
                    "children": [
                        {
                            "value": "610204",
                            "label": "耀州区",
                            "area": "1610.046"
                        },
                        {
                            "value": "610222",
                            "label": "宜君县",
                            "area": "1505.151"
                        },
                        {
                            "value": "610203",
                            "label": "印台区",
                            "area": "625.787"
                        },
                        {
                            "value": "610202",
                            "label": "王益区",
                            "area": "155.276"
                        }
                    ]
                },
                {
                    "value": "611000",
                    "label": "商洛市",
                    "children": [
                        {
                            "value": "611022",
                            "label": "丹凤县",
                            "area": "2405.242"
                        },
                        {
                            "value": "611025",
                            "label": "镇安县",
                            "area": "3473.681"
                        },
                        {
                            "value": "611023",
                            "label": "商南县",
                            "area": "2310.693"
                        },
                        {
                            "value": "611021",
                            "label": "洛南县",
                            "area": "2822.659"
                        },
                        {
                            "value": "611002",
                            "label": "商州区",
                            "area": "2650.25"
                        },
                        {
                            "value": "611024",
                            "label": "山阳县",
                            "area": "3542.56"
                        },
                        {
                            "value": "611026",
                            "label": "柞水县",
                            "area": "2362.221"
                        }
                    ]
                },
                {
                    "value": "610800",
                    "label": "榆林市",
                    "children": [
                        {
                            "value": "610824",
                            "label": "靖边县",
                            "area": "4992.685"
                        },
                        {
                            "value": "610803",
                            "label": "横山区",
                            "area": "4279.258"
                        },
                        {
                            "value": "610827",
                            "label": "米脂县",
                            "area": "1171.174"
                        },
                        {
                            "value": "610822",
                            "label": "府谷县",
                            "area": "3200.499"
                        },
                        {
                            "value": "610828",
                            "label": "佳县",
                            "area": "2033.013"
                        },
                        {
                            "value": "610881",
                            "label": "神木市",
                            "area": "7493.555"
                        },
                        {
                            "value": "610831",
                            "label": "子洲县",
                            "area": "2027.264"
                        },
                        {
                            "value": "610826",
                            "label": "绥德县",
                            "area": "1850.138"
                        },
                        {
                            "value": "610802",
                            "label": "榆阳区",
                            "area": "6807.766"
                        },
                        {
                            "value": "610829",
                            "label": "吴堡县",
                            "area": "418.053"
                        },
                        {
                            "value": "610830",
                            "label": "清涧县",
                            "area": "1846.854"
                        },
                        {
                            "value": "610825",
                            "label": "定边县",
                            "area": "6810.155"
                        }
                    ]
                },
                {
                    "value": "610700",
                    "label": "汉中市",
                    "children": [
                        {
                            "value": "610729",
                            "label": "留坝县",
                            "area": "1973.975"
                        },
                        {
                            "value": "610723",
                            "label": "洋县",
                            "area": "3182.897"
                        },
                        {
                            "value": "610727",
                            "label": "略阳县",
                            "area": "2820.046"
                        },
                        {
                            "value": "610730",
                            "label": "佛坪县",
                            "area": "1274.395"
                        },
                        {
                            "value": "610724",
                            "label": "西乡县",
                            "area": "3220.955"
                        },
                        {
                            "value": "610726",
                            "label": "宁强县",
                            "area": "3235.86"
                        },
                        {
                            "value": "610725",
                            "label": "勉县",
                            "area": "2365.643"
                        },
                        {
                            "value": "610702",
                            "label": "汉台区",
                            "area": "550.79"
                        },
                        {
                            "value": "610722",
                            "label": "城固县",
                            "area": "2210.266"
                        },
                        {
                            "value": "610703",
                            "label": "南郑区",
                            "area": "2836.921"
                        },
                        {
                            "value": "610728",
                            "label": "镇巴县",
                            "area": "3395.041"
                        }
                    ]
                },
                {
                    "value": "610600",
                    "label": "延安市",
                    "children": [
                        {
                            "value": "610681",
                            "label": "子长市",
                            "area": "2392.422"
                        },
                        {
                            "value": "610626",
                            "label": "吴起县",
                            "area": "3800.204"
                        },
                        {
                            "value": "610621",
                            "label": "延长县",
                            "area": "2360.945"
                        },
                        {
                            "value": "610622",
                            "label": "延川县",
                            "area": "1988.645"
                        },
                        {
                            "value": "610627",
                            "label": "甘泉县",
                            "area": "2283.958"
                        },
                        {
                            "value": "610603",
                            "label": "安塞区",
                            "area": "2948.834"
                        },
                        {
                            "value": "610625",
                            "label": "志丹县",
                            "area": "3777.009"
                        },
                        {
                            "value": "610602",
                            "label": "宝塔区",
                            "area": "3530.207"
                        },
                        {
                            "value": "610629",
                            "label": "洛川县",
                            "area": "1802.521"
                        },
                        {
                            "value": "610630",
                            "label": "宜川县",
                            "area": "2936.039"
                        },
                        {
                            "value": "610632",
                            "label": "黄陵县",
                            "area": "2289.976"
                        },
                        {
                            "value": "610628",
                            "label": "富县",
                            "area": "4173.581"
                        },
                        {
                            "value": "610631",
                            "label": "黄龙县",
                            "area": "2755.303"
                        }
                    ]
                },
                {
                    "value": "610900",
                    "label": "安康市",
                    "children": [
                        {
                            "value": "610923",
                            "label": "宁陕县",
                            "area": "3647.799"
                        },
                        {
                            "value": "610924",
                            "label": "紫阳县",
                            "area": "2242.116"
                        },
                        {
                            "value": "610922",
                            "label": "石泉县",
                            "area": "1513.638"
                        },
                        {
                            "value": "610926",
                            "label": "平利县",
                            "area": "2646.451"
                        },
                        {
                            "value": "610927",
                            "label": "镇坪县",
                            "area": "1511.001"
                        },
                        {
                            "value": "610921",
                            "label": "汉阴县",
                            "area": "1353.417"
                        },
                        {
                            "value": "610925",
                            "label": "岚皋县",
                            "area": "1976.716"
                        },
                        {
                            "value": "610928",
                            "label": "旬阳市",
                            "area": "3546.571"
                        },
                        {
                            "value": "610929",
                            "label": "白河县",
                            "area": "1441.881"
                        },
                        {
                            "value": "610902",
                            "label": "汉滨区",
                            "area": "3633.227"
                        }
                    ]
                },
                {
                    "value": "610100",
                    "label": "西安市",
                    "children": [
                        {
                            "value": "610117",
                            "label": "高陵区",
                            "area": "287.552"
                        },
                        {
                            "value": "610115",
                            "label": "临潼区",
                            "area": "912.268"
                        },
                        {
                            "value": "610118",
                            "label": "鄠邑区",
                            "area": "1279.622"
                        },
                        {
                            "value": "610104",
                            "label": "莲湖区",
                            "area": "40.021"
                        },
                        {
                            "value": "610111",
                            "label": "灞桥区",
                            "area": "325.756"
                        },
                        {
                            "value": "610102",
                            "label": "新城区",
                            "area": "29.326"
                        },
                        {
                            "value": "610114",
                            "label": "阎良区",
                            "area": "244.184"
                        },
                        {
                            "value": "610112",
                            "label": "未央区",
                            "area": "264.162"
                        },
                        {
                            "value": "610116",
                            "label": "长安区",
                            "area": "1589.528"
                        },
                        {
                            "value": "610124",
                            "label": "周至县",
                            "area": "2945.562"
                        },
                        {
                            "value": "610122",
                            "label": "蓝田县",
                            "area": "2004.874"
                        },
                        {
                            "value": "610113",
                            "label": "雁塔区",
                            "area": "151.75"
                        },
                        {
                            "value": "610103",
                            "label": "碑林区",
                            "area": "23.719"
                        }
                    ]
                },
                {
                    "value": "610400",
                    "label": "咸阳市",
                    "children": [
                        {
                            "value": "610426",
                            "label": "永寿县",
                            "area": "889.157"
                        },
                        {
                            "value": "610424",
                            "label": "乾县",
                            "area": "1010.584"
                        },
                        {
                            "value": "610404",
                            "label": "渭城区",
                            "area": "269.991"
                        },
                        {
                            "value": "610423",
                            "label": "泾阳县",
                            "area": "776.817"
                        },
                        {
                            "value": "610428",
                            "label": "长武县",
                            "area": "570.951"
                        },
                        {
                            "value": "610422",
                            "label": "三原县",
                            "area": "573.443"
                        },
                        {
                            "value": "610425",
                            "label": "礼泉县",
                            "area": "1012.262"
                        },
                        {
                            "value": "610402",
                            "label": "秦都区",
                            "area": "262.386"
                        },
                        {
                            "value": "610430",
                            "label": "淳化县",
                            "area": "978.492"
                        },
                        {
                            "value": "610403",
                            "label": "杨陵区",
                            "area": "134.516"
                        },
                        {
                            "value": "610481",
                            "label": "兴平市",
                            "area": "509.739"
                        },
                        {
                            "value": "610431",
                            "label": "武功县",
                            "area": "390.408"
                        },
                        {
                            "value": "610429",
                            "label": "旬邑县",
                            "area": "1776.679"
                        },
                        {
                            "value": "610482",
                            "label": "彬州市",
                            "area": "1173.682"
                        }
                    ]
                },
                {
                    "value": "610300",
                    "label": "宝鸡市",
                    "children": [
                        {
                            "value": "610327",
                            "label": "陇县",
                            "area": "2280.198"
                        },
                        {
                            "value": "610323",
                            "label": "岐山县",
                            "area": "847.874"
                        },
                        {
                            "value": "610329",
                            "label": "麟游县",
                            "area": "1706.408"
                        },
                        {
                            "value": "610328",
                            "label": "千阳县",
                            "area": "1000.889"
                        },
                        {
                            "value": "610303",
                            "label": "金台区",
                            "area": "310.258"
                        },
                        {
                            "value": "610302",
                            "label": "渭滨区",
                            "area": "845.862"
                        },
                        {
                            "value": "610330",
                            "label": "凤县",
                            "area": "3130.907"
                        },
                        {
                            "value": "610331",
                            "label": "太白县",
                            "area": "2717.092"
                        },
                        {
                            "value": "610304",
                            "label": "陈仓区",
                            "area": "2467.47"
                        },
                        {
                            "value": "610322",
                            "label": "凤翔区",
                            "area": "1229.199"
                        },
                        {
                            "value": "610326",
                            "label": "眉县",
                            "area": "858.821"
                        },
                        {
                            "value": "610324",
                            "label": "扶风县",
                            "area": "701.929"
                        }
                    ]
                },
                {
                    "value": "610500",
                    "label": "渭南市",
                    "children": [
                        {
                            "value": "610581",
                            "label": "韩城市",
                            "area": "1546.03"
                        },
                        {
                            "value": "610527",
                            "label": "白水县",
                            "area": "976.153"
                        },
                        {
                            "value": "610522",
                            "label": "潼关县",
                            "area": "410.003"
                        },
                        {
                            "value": "610525",
                            "label": "澄城县",
                            "area": "1117.769"
                        },
                        {
                            "value": "610526",
                            "label": "蒲城县",
                            "area": "1580.086"
                        },
                        {
                            "value": "610524",
                            "label": "合阳县",
                            "area": "1316.253"
                        },
                        {
                            "value": "610528",
                            "label": "富平县",
                            "area": "1243.865"
                        },
                        {
                            "value": "610582",
                            "label": "华阴市",
                            "area": "672.485"
                        },
                        {
                            "value": "610503",
                            "label": "华州区",
                            "area": "1135.504"
                        },
                        {
                            "value": "610523",
                            "label": "大荔县",
                            "area": "1705.645"
                        },
                        {
                            "value": "610502",
                            "label": "临渭区",
                            "area": "1259.535"
                        }
                    ]
                }
            ]
        },
        {
            "value": "310000",
            "label": "上海市",
            "children": [
                {
                    "value": "310100",
                    "label": "上海城区",
                    "children": [
                        {
                            "value": "310116",
                            "label": "金山区",
                            "area": "667.022"
                        },
                        {
                            "value": "310151",
                            "label": "崇明区",
                            "area": "2102.989"
                        },
                        {
                            "value": "310115",
                            "label": "浦东新区",
                            "area": "1697.11"
                        },
                        {
                            "value": "310120",
                            "label": "奉贤区",
                            "area": "805.685"
                        },
                        {
                            "value": "310114",
                            "label": "嘉定区",
                            "area": "462.415"
                        },
                        {
                            "value": "310107",
                            "label": "普陀区",
                            "area": "55.57"
                        },
                        {
                            "value": "310113",
                            "label": "宝山区",
                            "area": "315.986"
                        },
                        {
                            "value": "310101",
                            "label": "黄浦区",
                            "area": "20.454"
                        },
                        {
                            "value": "310110",
                            "label": "杨浦区",
                            "area": "60.698"
                        },
                        {
                            "value": "310117",
                            "label": "松江区",
                            "area": "606.404"
                        },
                        {
                            "value": "310109",
                            "label": "虹口区",
                            "area": "23.465"
                        },
                        {
                            "value": "310105",
                            "label": "长宁区",
                            "area": "37.216"
                        },
                        {
                            "value": "310106",
                            "label": "静安区",
                            "area": "36.78"
                        },
                        {
                            "value": "310112",
                            "label": "闵行区",
                            "area": "372.789"
                        },
                        {
                            "value": "310104",
                            "label": "徐汇区",
                            "area": "55.109"
                        },
                        {
                            "value": "310118",
                            "label": "青浦区",
                            "area": "667.048"
                        }
                    ]
                }
            ]
        },
        {
            "value": "520000",
            "label": "贵州省",
            "children": [
                {
                    "value": "520300",
                    "label": "遵义市",
                    "children": [
                        {
                            "value": "520322",
                            "label": "桐梓县",
                            "area": "3207.484"
                        },
                        {
                            "value": "520323",
                            "label": "绥阳县",
                            "area": "2555.884"
                        },
                        {
                            "value": "520328",
                            "label": "湄潭县",
                            "area": "1864.545"
                        },
                        {
                            "value": "520329",
                            "label": "余庆县",
                            "area": "1620.479"
                        },
                        {
                            "value": "520382",
                            "label": "仁怀市",
                            "area": "1789.486"
                        },
                        {
                            "value": "520304",
                            "label": "播州区",
                            "area": "2472.638"
                        },
                        {
                            "value": "520381",
                            "label": "赤水市",
                            "area": "1868.293"
                        },
                        {
                            "value": "520330",
                            "label": "习水县",
                            "area": "3060.733"
                        },
                        {
                            "value": "520325",
                            "label": "道真仡佬族苗族自治县",
                            "area": "2152.037"
                        },
                        {
                            "value": "520324",
                            "label": "正安县",
                            "area": "2584.174"
                        },
                        {
                            "value": "520326",
                            "label": "务川仡佬族苗族自治县",
                            "area": "2785.414"
                        },
                        {
                            "value": "520327",
                            "label": "凤冈县",
                            "area": "1893.986"
                        },
                        {
                            "value": "520303",
                            "label": "汇川区",
                            "area": "1511.569"
                        },
                        {
                            "value": "520302",
                            "label": "红花岗区",
                            "area": "1399.064"
                        }
                    ]
                },
                {
                    "value": "522700",
                    "label": "黔南布依族苗族自治州",
                    "children": [
                        {
                            "value": "522726",
                            "label": "独山县",
                            "area": "2435.373"
                        },
                        {
                            "value": "522722",
                            "label": "荔波县",
                            "area": "2411.389"
                        },
                        {
                            "value": "522731",
                            "label": "惠水县",
                            "area": "2478.081"
                        },
                        {
                            "value": "522727",
                            "label": "平塘县",
                            "area": "2789.685"
                        },
                        {
                            "value": "522729",
                            "label": "长顺县",
                            "area": "1543.032"
                        },
                        {
                            "value": "522728",
                            "label": "罗甸县",
                            "area": "3010.208"
                        },
                        {
                            "value": "522725",
                            "label": "瓮安县",
                            "area": "1957.072"
                        },
                        {
                            "value": "522730",
                            "label": "龙里县",
                            "area": "1529.665"
                        },
                        {
                            "value": "522702",
                            "label": "福泉市",
                            "area": "1696.645"
                        },
                        {
                            "value": "522732",
                            "label": "三都水族自治县",
                            "area": "2382.817"
                        },
                        {
                            "value": "522723",
                            "label": "贵定县",
                            "area": "1622.443"
                        },
                        {
                            "value": "522701",
                            "label": "都匀市",
                            "area": "2300.461"
                        }
                    ]
                },
                {
                    "value": "522300",
                    "label": "黔西南布依族苗族自治州",
                    "children": [
                        {
                            "value": "522327",
                            "label": "册亨县",
                            "area": "2607.959"
                        },
                        {
                            "value": "522301",
                            "label": "兴义市",
                            "area": "2913.281"
                        },
                        {
                            "value": "522302",
                            "label": "兴仁市",
                            "area": "1783.135"
                        },
                        {
                            "value": "522328",
                            "label": "安龙县",
                            "area": "2243.772"
                        },
                        {
                            "value": "522324",
                            "label": "晴隆县",
                            "area": "1300.702"
                        },
                        {
                            "value": "522326",
                            "label": "望谟县",
                            "area": "3020.602"
                        },
                        {
                            "value": "522323",
                            "label": "普安县",
                            "area": "1449.385"
                        },
                        {
                            "value": "522325",
                            "label": "贞丰县",
                            "area": "1501.412"
                        }
                    ]
                },
                {
                    "value": "520400",
                    "label": "安顺市",
                    "children": [
                        {
                            "value": "520402",
                            "label": "西秀区",
                            "area": "1723.617"
                        },
                        {
                            "value": "520423",
                            "label": "镇宁布依族苗族自治县",
                            "area": "1703.886"
                        },
                        {
                            "value": "520424",
                            "label": "关岭布依族苗族自治县",
                            "area": "1471.552"
                        },
                        {
                            "value": "520425",
                            "label": "紫云苗族布依族自治县",
                            "area": "2270.703"
                        },
                        {
                            "value": "520403",
                            "label": "平坝区",
                            "area": "997.469"
                        },
                        {
                            "value": "520422",
                            "label": "普定县",
                            "area": "1084.945"
                        }
                    ]
                },
                {
                    "value": "520200",
                    "label": "六盘水市",
                    "children": [
                        {
                            "value": "520201",
                            "label": "钟山区",
                            "area": "1067.662"
                        },
                        {
                            "value": "520203",
                            "label": "六枝特区",
                            "area": "1798.764"
                        },
                        {
                            "value": "520281",
                            "label": "盘州市",
                            "area": "4032.481"
                        },
                        {
                            "value": "520221",
                            "label": "水城区",
                            "area": "3009.786"
                        }
                    ]
                },
                {
                    "value": "520600",
                    "label": "铜仁市",
                    "children": [
                        {
                            "value": "520625",
                            "label": "印江土家族苗族自治县",
                            "area": "1978.268"
                        },
                        {
                            "value": "520623",
                            "label": "石阡县",
                            "area": "2160.68"
                        },
                        {
                            "value": "520602",
                            "label": "碧江区",
                            "area": "1032.321"
                        },
                        {
                            "value": "520626",
                            "label": "德江县",
                            "area": "2071.912"
                        },
                        {
                            "value": "520603",
                            "label": "万山区",
                            "area": "827.231"
                        },
                        {
                            "value": "520624",
                            "label": "思南县",
                            "area": "2205.621"
                        },
                        {
                            "value": "520622",
                            "label": "玉屏侗族自治县",
                            "area": "527.551"
                        },
                        {
                            "value": "520628",
                            "label": "松桃苗族自治县",
                            "area": "2864.473"
                        },
                        {
                            "value": "520621",
                            "label": "江口县",
                            "area": "1874.901"
                        },
                        {
                            "value": "520627",
                            "label": "沿河土家族自治县",
                            "area": "2471.888"
                        }
                    ]
                },
                {
                    "value": "522600",
                    "label": "黔东南苗族侗族自治州",
                    "children": [
                        {
                            "value": "522632",
                            "label": "榕江县",
                            "area": "3311.241"
                        },
                        {
                            "value": "522634",
                            "label": "雷山县",
                            "area": "1190.151"
                        },
                        {
                            "value": "522625",
                            "label": "镇远县",
                            "area": "1892.276"
                        },
                        {
                            "value": "522627",
                            "label": "天柱县",
                            "area": "2206.973"
                        },
                        {
                            "value": "522623",
                            "label": "施秉县",
                            "area": "1545.864"
                        },
                        {
                            "value": "522601",
                            "label": "凯里市",
                            "area": "1564.902"
                        },
                        {
                            "value": "522636",
                            "label": "丹寨县",
                            "area": "943.03"
                        },
                        {
                            "value": "522633",
                            "label": "从江县",
                            "area": "3226.325"
                        },
                        {
                            "value": "522631",
                            "label": "黎平县",
                            "area": "4414.396"
                        },
                        {
                            "value": "522628",
                            "label": "锦屏县",
                            "area": "1615.129"
                        },
                        {
                            "value": "522630",
                            "label": "台江县",
                            "area": "1081.769"
                        },
                        {
                            "value": "522622",
                            "label": "黄平县",
                            "area": "1655.344"
                        },
                        {
                            "value": "522626",
                            "label": "岑巩县",
                            "area": "1472.744"
                        },
                        {
                            "value": "522635",
                            "label": "麻江县",
                            "area": "954.347"
                        },
                        {
                            "value": "522629",
                            "label": "剑河县",
                            "area": "2158.363"
                        },
                        {
                            "value": "522624",
                            "label": "三穗县",
                            "area": "1029.521"
                        }
                    ]
                },
                {
                    "value": "520500",
                    "label": "毕节市",
                    "children": [
                        {
                            "value": "520502",
                            "label": "七星关区",
                            "area": "3406.317"
                        },
                        {
                            "value": "520522",
                            "label": "黔西市",
                            "area": "2563.531"
                        },
                        {
                            "value": "520523",
                            "label": "金沙县",
                            "area": "2510.445"
                        },
                        {
                            "value": "520524",
                            "label": "织金县",
                            "area": "2873.261"
                        },
                        {
                            "value": "520526",
                            "label": "威宁彝族回族苗族自治县",
                            "area": "6299.852"
                        },
                        {
                            "value": "520527",
                            "label": "赫章县",
                            "area": "3246.023"
                        },
                        {
                            "value": "520525",
                            "label": "纳雍县",
                            "area": "2444.427"
                        },
                        {
                            "value": "520521",
                            "label": "大方县",
                            "area": "3501.467"
                        }
                    ]
                },
                {
                    "value": "520100",
                    "label": "贵阳市",
                    "children": [
                        {
                            "value": "520121",
                            "label": "开阳县",
                            "area": "2030.298"
                        },
                        {
                            "value": "520113",
                            "label": "白云区",
                            "area": "268.99"
                        },
                        {
                            "value": "520122",
                            "label": "息烽县",
                            "area": "1027.701"
                        },
                        {
                            "value": "520123",
                            "label": "修文县",
                            "area": "1083.915"
                        },
                        {
                            "value": "520181",
                            "label": "清镇市",
                            "area": "1385.15"
                        },
                        {
                            "value": "520111",
                            "label": "花溪区",
                            "area": "961.548"
                        },
                        {
                            "value": "520102",
                            "label": "南明区",
                            "area": "205.165"
                        },
                        {
                            "value": "520115",
                            "label": "观山湖区",
                            "area": "307.607"
                        },
                        {
                            "value": "520103",
                            "label": "云岩区",
                            "area": "91.612"
                        },
                        {
                            "value": "520112",
                            "label": "乌当区",
                            "area": "683.738"
                        }
                    ]
                }
            ]
        },
        {
            "value": "500000",
            "label": "重庆市",
            "children": [
                {
                    "value": "500200",
                    "label": "重庆郊县",
                    "children": [
                        {
                            "value": "500238",
                            "label": "巫溪县",
                            "area": "4025.229"
                        },
                        {
                            "value": "500229",
                            "label": "城口县",
                            "area": "3293.853"
                        },
                        {
                            "value": "500236",
                            "label": "奉节县",
                            "area": "4097.89"
                        },
                        {
                            "value": "500241",
                            "label": "秀山土家族苗族自治县",
                            "area": "2453.247"
                        },
                        {
                            "value": "500243",
                            "label": "彭水苗族土家族自治县",
                            "area": "3897.567"
                        },
                        {
                            "value": "500231",
                            "label": "垫江县",
                            "area": "1517.977"
                        },
                        {
                            "value": "500235",
                            "label": "云阳县",
                            "area": "3635.352"
                        },
                        {
                            "value": "500240",
                            "label": "石柱土家族自治县",
                            "area": "3012.261"
                        },
                        {
                            "value": "500233",
                            "label": "忠县",
                            "area": "2182.167"
                        },
                        {
                            "value": "500230",
                            "label": "丰都县",
                            "area": "2898.453"
                        },
                        {
                            "value": "500242",
                            "label": "酉阳土家族苗族自治县",
                            "area": "5170.257"
                        },
                        {
                            "value": "500237",
                            "label": "巫山县",
                            "area": "2948.527"
                        }
                    ]
                },
                {
                    "value": "500100",
                    "label": "重庆城区",
                    "children": [
                        {
                            "value": "500151",
                            "label": "铜梁区",
                            "area": "1338.057"
                        },
                        {
                            "value": "500152",
                            "label": "潼南区",
                            "area": "1587.103"
                        },
                        {
                            "value": "500117",
                            "label": "合川区",
                            "area": "2344.607"
                        },
                        {
                            "value": "500115",
                            "label": "长寿区",
                            "area": "1420.757"
                        },
                        {
                            "value": "500120",
                            "label": "璧山区",
                            "area": "914.424"
                        },
                        {
                            "value": "500153",
                            "label": "荣昌区",
                            "area": "1075.865"
                        },
                        {
                            "value": "500103",
                            "label": "渝中区",
                            "area": "22.893"
                        },
                        {
                            "value": "500111",
                            "label": "大足区",
                            "area": "1434.534"
                        },
                        {
                            "value": "500104",
                            "label": "大渡口区",
                            "area": "103.184"
                        },
                        {
                            "value": "500119",
                            "label": "南川区",
                            "area": "2589.447"
                        },
                        {
                            "value": "500107",
                            "label": "九龙坡区",
                            "area": "431.737"
                        },
                        {
                            "value": "500118",
                            "label": "永川区",
                            "area": "1577.406"
                        },
                        {
                            "value": "500155",
                            "label": "梁平区",
                            "area": "1896.299"
                        },
                        {
                            "value": "500102",
                            "label": "涪陵区",
                            "area": "2945.44"
                        },
                        {
                            "value": "500101",
                            "label": "万州区",
                            "area": "3453.39"
                        },
                        {
                            "value": "500110",
                            "label": "綦江区",
                            "area": "2749.767"
                        },
                        {
                            "value": "500154",
                            "label": "开州区",
                            "area": "3960.969"
                        },
                        {
                            "value": "500116",
                            "label": "江津区",
                            "area": "3215.088"
                        },
                        {
                            "value": "500114",
                            "label": "黔江区",
                            "area": "2394.519"
                        },
                        {
                            "value": "500108",
                            "label": "南岸区",
                            "area": "262.443"
                        },
                        {
                            "value": "500113",
                            "label": "巴南区",
                            "area": "1820.017"
                        },
                        {
                            "value": "500109",
                            "label": "北碚区",
                            "area": "750.565"
                        },
                        {
                            "value": "500156",
                            "label": "武隆区",
                            "area": "2887.9"
                        },
                        {
                            "value": "500106",
                            "label": "沙坪坝区",
                            "area": "396.908"
                        },
                        {
                            "value": "500105",
                            "label": "江北区",
                            "area": "222.227"
                        },
                        {
                            "value": "500112",
                            "label": "渝北区",
                            "area": "1457.67"
                        }
                    ]
                }
            ]
        },
        {
            "value": "540000",
            "label": "西藏自治区",
            "children": [
                {
                    "value": "540200",
                    "label": "日喀则市",
                    "children": [
                        {
                            "value": "540232",
                            "label": "仲巴县",
                            "area": "43516.068"
                        },
                        {
                            "value": "540226",
                            "label": "昂仁县",
                            "area": "27486.996"
                        },
                        {
                            "value": "540227",
                            "label": "谢通门县",
                            "area": "14004.035"
                        },
                        {
                            "value": "540236",
                            "label": "萨嘎县",
                            "area": "12418.951"
                        },
                        {
                            "value": "540221",
                            "label": "南木林县",
                            "area": "8120.413"
                        },
                        {
                            "value": "540225",
                            "label": "拉孜县",
                            "area": "4494.776"
                        },
                        {
                            "value": "540229",
                            "label": "仁布县",
                            "area": "2120.375"
                        },
                        {
                            "value": "540202",
                            "label": "桑珠孜区",
                            "area": "3671.898"
                        },
                        {
                            "value": "540228",
                            "label": "白朗县",
                            "area": "2800.723"
                        },
                        {
                            "value": "540224",
                            "label": "萨迦县",
                            "area": "5758.604"
                        },
                        {
                            "value": "540234",
                            "label": "吉隆县",
                            "area": "9040.934"
                        },
                        {
                            "value": "540223",
                            "label": "定日县",
                            "area": "13863.578"
                        },
                        {
                            "value": "540235",
                            "label": "聂拉木县",
                            "area": "7860.115"
                        },
                        {
                            "value": "540222",
                            "label": "江孜县",
                            "area": "3837.662"
                        },
                        {
                            "value": "540231",
                            "label": "定结县",
                            "area": "5827.828"
                        },
                        {
                            "value": "540233",
                            "label": "亚东县",
                            "area": "4274.482"
                        },
                        {
                            "value": "540230",
                            "label": "康马县",
                            "area": "6130.732"
                        },
                        {
                            "value": "540237",
                            "label": "岗巴县",
                            "area": "3938.919"
                        }
                    ]
                },
                {
                    "value": "540100",
                    "label": "拉萨市",
                    "children": [
                        {
                            "value": "540122",
                            "label": "当雄县",
                            "area": "10257.08"
                        },
                        {
                            "value": "540121",
                            "label": "林周县",
                            "area": "4491.795"
                        },
                        {
                            "value": "540127",
                            "label": "墨竹工卡县",
                            "area": "5503.21"
                        },
                        {
                            "value": "540123",
                            "label": "尼木县",
                            "area": "3243.361"
                        },
                        {
                            "value": "540102",
                            "label": "城关区",
                            "area": "512.965"
                        },
                        {
                            "value": "540103",
                            "label": "堆龙德庆区",
                            "area": "2664.977"
                        },
                        {
                            "value": "540104",
                            "label": "达孜区",
                            "area": "1364.337"
                        },
                        {
                            "value": "540124",
                            "label": "曲水县",
                            "area": "1639.847"
                        }
                    ]
                },
                {
                    "value": "542500",
                    "label": "阿里地区",
                    "children": [
                        {
                            "value": "542526",
                            "label": "改则县",
                            "area": "135569.022"
                        },
                        {
                            "value": "542522",
                            "label": "札达县",
                            "area": "24594.887"
                        },
                        {
                            "value": "542527",
                            "label": "措勤县",
                            "area": "22879.092"
                        },
                        {
                            "value": "542521",
                            "label": "普兰县",
                            "area": "13183.089"
                        },
                        {
                            "value": "542523",
                            "label": "噶尔县",
                            "area": "18032.224"
                        },
                        {
                            "value": "542524",
                            "label": "日土县",
                            "area": "77117.341"
                        },
                        {
                            "value": "542525",
                            "label": "革吉县",
                            "area": "45683.424"
                        }
                    ]
                },
                {
                    "value": "540400",
                    "label": "林芝市",
                    "children": [
                        {
                            "value": "540421",
                            "label": "工布江达县",
                            "area": "12998.891"
                        },
                        {
                            "value": "540424",
                            "label": "波密县",
                            "area": "16667.442"
                        },
                        {
                            "value": "540423",
                            "label": "墨脱县",
                            "area": "31527.691"
                        },
                        {
                            "value": "540402",
                            "label": "巴宜区",
                            "area": "8544.73"
                        },
                        {
                            "value": "540422",
                            "label": "米林县",
                            "area": "9501.358"
                        },
                        {
                            "value": "540425",
                            "label": "察隅县",
                            "area": "31356.076"
                        },
                        {
                            "value": "540426",
                            "label": "朗县",
                            "area": "4097.055"
                        }
                    ]
                },
                {
                    "value": "540300",
                    "label": "昌都市",
                    "children": [
                        {
                            "value": "540323",
                            "label": "类乌齐县",
                            "area": "6338.881"
                        },
                        {
                            "value": "540324",
                            "label": "丁青县",
                            "area": "12343.707"
                        },
                        {
                            "value": "540302",
                            "label": "卡若区",
                            "area": "10791.36"
                        },
                        {
                            "value": "540321",
                            "label": "江达县",
                            "area": "13137.97"
                        },
                        {
                            "value": "540322",
                            "label": "贡觉县",
                            "area": "6326.254"
                        },
                        {
                            "value": "540330",
                            "label": "边坝县",
                            "area": "8802.75"
                        },
                        {
                            "value": "540329",
                            "label": "洛隆县",
                            "area": "8027.343"
                        },
                        {
                            "value": "540325",
                            "label": "察雅县",
                            "area": "8259.875"
                        },
                        {
                            "value": "540328",
                            "label": "芒康县",
                            "area": "11557.268"
                        },
                        {
                            "value": "540326",
                            "label": "八宿县",
                            "area": "12344.548"
                        },
                        {
                            "value": "540327",
                            "label": "左贡县",
                            "area": "11867.628"
                        }
                    ]
                },
                {
                    "value": "540600",
                    "label": "那曲市",
                    "children": [
                        {
                            "value": "540624",
                            "label": "安多县",
                            "area": "43527.251"
                        },
                        {
                            "value": "540623",
                            "label": "聂荣县",
                            "area": "8987.535"
                        },
                        {
                            "value": "540622",
                            "label": "比如县",
                            "area": "11700.519"
                        },
                        {
                            "value": "540628",
                            "label": "巴青县",
                            "area": "9812.671"
                        },
                        {
                            "value": "540625",
                            "label": "申扎县",
                            "area": "25627.591"
                        },
                        {
                            "value": "540626",
                            "label": "索县",
                            "area": "5867.499"
                        },
                        {
                            "value": "540627",
                            "label": "班戈县",
                            "area": "28396.882"
                        },
                        {
                            "value": "540602",
                            "label": "色尼区",
                            "area": "16199.838"
                        },
                        {
                            "value": "540621",
                            "label": "嘉黎县",
                            "area": "13100.149"
                        },
                        {
                            "value": "540629",
                            "label": "尼玛县",
                            "area": "72091.228"
                        },
                        {
                            "value": "540630",
                            "label": "双湖县",
                            "area": "117700.669"
                        }
                    ]
                },
                {
                    "value": "540500",
                    "label": "山南市",
                    "children": [
                        {
                            "value": "540523",
                            "label": "桑日县",
                            "area": "2642.257"
                        },
                        {
                            "value": "540528",
                            "label": "加查县",
                            "area": "4389.743"
                        },
                        {
                            "value": "540522",
                            "label": "贡嘎县",
                            "area": "2383.702"
                        },
                        {
                            "value": "540521",
                            "label": "扎囊县",
                            "area": "2155.749"
                        },
                        {
                            "value": "540502",
                            "label": "乃东区",
                            "area": "2184.102"
                        },
                        {
                            "value": "540531",
                            "label": "浪卡子县",
                            "area": "7971.576"
                        },
                        {
                            "value": "540525",
                            "label": "曲松县",
                            "area": "2068.163"
                        },
                        {
                            "value": "540524",
                            "label": "琼结县",
                            "area": "1046.067"
                        },
                        {
                            "value": "540526",
                            "label": "措美县",
                            "area": "4192.573"
                        },
                        {
                            "value": "540530",
                            "label": "错那县",
                            "area": "35001.973"
                        },
                        {
                            "value": "540527",
                            "label": "洛扎县",
                            "area": "4826.918"
                        },
                        {
                            "value": "540529",
                            "label": "隆子县",
                            "area": "10022.796"
                        }
                    ]
                }
            ]
        },
        {
            "value": "340000",
            "label": "安徽省",
            "children": [
                {
                    "value": "340500",
                    "label": "马鞍山市",
                    "children": [
                        {
                            "value": "340506",
                            "label": "博望区",
                            "area": "366.49"
                        },
                        {
                            "value": "340503",
                            "label": "花山区",
                            "area": "178.859"
                        },
                        {
                            "value": "340504",
                            "label": "雨山区",
                            "area": "173.971"
                        },
                        {
                            "value": "340523",
                            "label": "和县",
                            "area": "1317.562"
                        },
                        {
                            "value": "340522",
                            "label": "含山县",
                            "area": "1033.779"
                        },
                        {
                            "value": "340521",
                            "label": "当涂县",
                            "area": "977.541"
                        }
                    ]
                },
                {
                    "value": "341200",
                    "label": "阜阳市",
                    "children": [
                        {
                            "value": "341203",
                            "label": "颍东区",
                            "area": "677.443"
                        },
                        {
                            "value": "341222",
                            "label": "太和县",
                            "area": "1860.572"
                        },
                        {
                            "value": "341204",
                            "label": "颍泉区",
                            "area": "652.073"
                        },
                        {
                            "value": "341221",
                            "label": "临泉县",
                            "area": "1837.455"
                        },
                        {
                            "value": "341202",
                            "label": "颍州区",
                            "area": "613.611"
                        },
                        {
                            "value": "341225",
                            "label": "阜南县",
                            "area": "1811.81"
                        },
                        {
                            "value": "341226",
                            "label": "颍上县",
                            "area": "2003.047"
                        },
                        {
                            "value": "341282",
                            "label": "界首市",
                            "area": "663.447"
                        }
                    ]
                },
                {
                    "value": "340600",
                    "label": "淮北市",
                    "children": [
                        {
                            "value": "340602",
                            "label": "杜集区",
                            "area": "238.252"
                        },
                        {
                            "value": "340604",
                            "label": "烈山区",
                            "area": "387.874"
                        },
                        {
                            "value": "340603",
                            "label": "相山区",
                            "area": "141.561"
                        },
                        {
                            "value": "340621",
                            "label": "濉溪县",
                            "area": "1979.532"
                        }
                    ]
                },
                {
                    "value": "340700",
                    "label": "铜陵市",
                    "children": [
                        {
                            "value": "340711",
                            "label": "郊区",
                            "area": "554.371"
                        },
                        {
                            "value": "340706",
                            "label": "义安区",
                            "area": "805.785"
                        },
                        {
                            "value": "340705",
                            "label": "铜官区",
                            "area": "132.592"
                        },
                        {
                            "value": "340722",
                            "label": "枞阳县",
                            "area": "1489.41"
                        }
                    ]
                },
                {
                    "value": "341000",
                    "label": "黄山市",
                    "children": [
                        {
                            "value": "341003",
                            "label": "黄山区",
                            "area": "1751.235"
                        },
                        {
                            "value": "341023",
                            "label": "黟县",
                            "area": "855.821"
                        },
                        {
                            "value": "341024",
                            "label": "祁门县",
                            "area": "2213.634"
                        },
                        {
                            "value": "341004",
                            "label": "徽州区",
                            "area": "426.404"
                        },
                        {
                            "value": "341002",
                            "label": "屯溪区",
                            "area": "194.115"
                        },
                        {
                            "value": "341021",
                            "label": "歙县",
                            "area": "2118.022"
                        },
                        {
                            "value": "341022",
                            "label": "休宁县",
                            "area": "2113.954"
                        }
                    ]
                },
                {
                    "value": "341700",
                    "label": "池州市",
                    "children": [
                        {
                            "value": "341721",
                            "label": "东至县",
                            "area": "3260.201"
                        },
                        {
                            "value": "341702",
                            "label": "贵池区",
                            "area": "2514.234"
                        },
                        {
                            "value": "341722",
                            "label": "石台县",
                            "area": "1429.934"
                        },
                        {
                            "value": "341723",
                            "label": "青阳县",
                            "area": "1193.802"
                        }
                    ]
                },
                {
                    "value": "340800",
                    "label": "安庆市",
                    "children": [
                        {
                            "value": "340825",
                            "label": "太湖县",
                            "area": "2039.789"
                        },
                        {
                            "value": "340811",
                            "label": "宜秀区",
                            "area": "418.27"
                        },
                        {
                            "value": "340828",
                            "label": "岳西县",
                            "area": "2368.917"
                        },
                        {
                            "value": "340882",
                            "label": "潜山市",
                            "area": "1687.448"
                        },
                        {
                            "value": "340881",
                            "label": "桐城市",
                            "area": "1537.497"
                        },
                        {
                            "value": "340802",
                            "label": "迎江区",
                            "area": "206.921"
                        },
                        {
                            "value": "340826",
                            "label": "宿松县",
                            "area": "2376.451"
                        },
                        {
                            "value": "340827",
                            "label": "望江县",
                            "area": "1321.193"
                        },
                        {
                            "value": "340803",
                            "label": "大观区",
                            "area": "274.375"
                        },
                        {
                            "value": "340822",
                            "label": "怀宁县",
                            "area": "1257.909"
                        }
                    ]
                },
                {
                    "value": "340300",
                    "label": "蚌埠市",
                    "children": [
                        {
                            "value": "340311",
                            "label": "淮上区",
                            "area": "408.469"
                        },
                        {
                            "value": "340322",
                            "label": "五河县",
                            "area": "1422.181"
                        },
                        {
                            "value": "340304",
                            "label": "禹会区",
                            "area": "321.547"
                        },
                        {
                            "value": "340321",
                            "label": "怀远县",
                            "area": "2209.579"
                        },
                        {
                            "value": "340303",
                            "label": "蚌山区",
                            "area": "89.855"
                        },
                        {
                            "value": "340302",
                            "label": "龙子湖区",
                            "area": "148.853"
                        },
                        {
                            "value": "340323",
                            "label": "固镇县",
                            "area": "1367.917"
                        }
                    ]
                },
                {
                    "value": "341100",
                    "label": "滁州市",
                    "children": [
                        {
                            "value": "341182",
                            "label": "明光市",
                            "area": "2359.918"
                        },
                        {
                            "value": "341181",
                            "label": "天长市",
                            "area": "1752.446"
                        },
                        {
                            "value": "341126",
                            "label": "凤阳县",
                            "area": "1921.934"
                        },
                        {
                            "value": "341122",
                            "label": "来安县",
                            "area": "1503.08"
                        },
                        {
                            "value": "341125",
                            "label": "定远县",
                            "area": "3006.791"
                        },
                        {
                            "value": "341102",
                            "label": "琅琊区",
                            "area": "267.833"
                        },
                        {
                            "value": "341103",
                            "label": "南谯区",
                            "area": "1128.354"
                        },
                        {
                            "value": "341124",
                            "label": "全椒县",
                            "area": "1575.971"
                        }
                    ]
                },
                {
                    "value": "341800",
                    "label": "宣城市",
                    "children": [
                        {
                            "value": "341882",
                            "label": "广德市",
                            "area": "2110.657"
                        },
                        {
                            "value": "341825",
                            "label": "旌德县",
                            "area": "903.749"
                        },
                        {
                            "value": "341802",
                            "label": "宣州区",
                            "area": "2629.482"
                        },
                        {
                            "value": "341821",
                            "label": "郎溪县",
                            "area": "1100.856"
                        },
                        {
                            "value": "341823",
                            "label": "泾县",
                            "area": "2036.817"
                        },
                        {
                            "value": "341824",
                            "label": "绩溪县",
                            "area": "1112.383"
                        },
                        {
                            "value": "341881",
                            "label": "宁国市",
                            "area": "2425.432"
                        }
                    ]
                },
                {
                    "value": "341300",
                    "label": "宿州市",
                    "children": [
                        {
                            "value": "341324",
                            "label": "泗县",
                            "area": "1849.326"
                        },
                        {
                            "value": "341302",
                            "label": "埇桥区",
                            "area": "2901.078"
                        },
                        {
                            "value": "341322",
                            "label": "萧县",
                            "area": "1845.183"
                        },
                        {
                            "value": "341323",
                            "label": "灵璧县",
                            "area": "2132.115"
                        },
                        {
                            "value": "341321",
                            "label": "砀山县",
                            "area": "1198.929"
                        }
                    ]
                },
                {
                    "value": "340400",
                    "label": "淮南市",
                    "children": [
                        {
                            "value": "340421",
                            "label": "凤台县",
                            "area": "1086.335"
                        },
                        {
                            "value": "340406",
                            "label": "潘集区",
                            "area": "599.357"
                        },
                        {
                            "value": "340405",
                            "label": "八公山区",
                            "area": "67.328"
                        },
                        {
                            "value": "340402",
                            "label": "大通区",
                            "area": "311.152"
                        },
                        {
                            "value": "340403",
                            "label": "田家庵区",
                            "area": "252.329"
                        },
                        {
                            "value": "340404",
                            "label": "谢家集区",
                            "area": "270.241"
                        },
                        {
                            "value": "340422",
                            "label": "寿县",
                            "area": "2942.799"
                        }
                    ]
                },
                {
                    "value": "340200",
                    "label": "芜湖市",
                    "children": [
                        {
                            "value": "340211",
                            "label": "繁昌区",
                            "area": "571.099"
                        },
                        {
                            "value": "340202",
                            "label": "镜湖区",
                            "area": "116.086"
                        },
                        {
                            "value": "340281",
                            "label": "无为市",
                            "area": "2005.84"
                        },
                        {
                            "value": "340207",
                            "label": "鸠江区",
                            "area": "884.213"
                        },
                        {
                            "value": "340209",
                            "label": "弋江区",
                            "area": "516.857"
                        },
                        {
                            "value": "340223",
                            "label": "南陵县",
                            "area": "1267.264"
                        },
                        {
                            "value": "340210",
                            "label": "湾沚区",
                            "area": "647.166"
                        }
                    ]
                },
                {
                    "value": "341600",
                    "label": "亳州市",
                    "children": [
                        {
                            "value": "341623",
                            "label": "利辛县",
                            "area": "2009.558"
                        },
                        {
                            "value": "341602",
                            "label": "谯城区",
                            "area": "2264.83"
                        },
                        {
                            "value": "341622",
                            "label": "蒙城县",
                            "area": "2141.56"
                        },
                        {
                            "value": "341621",
                            "label": "涡阳县",
                            "area": "2108.66"
                        }
                    ]
                },
                {
                    "value": "340100",
                    "label": "合肥市",
                    "children": [
                        {
                            "value": "340102",
                            "label": "瑶海区",
                            "area": "222.776"
                        },
                        {
                            "value": "340122",
                            "label": "肥东县",
                            "area": "2195.34"
                        },
                        {
                            "value": "340181",
                            "label": "巢湖市",
                            "area": "2068.79"
                        },
                        {
                            "value": "340103",
                            "label": "庐阳区",
                            "area": "136.631"
                        },
                        {
                            "value": "340104",
                            "label": "蜀山区",
                            "area": "648.308"
                        },
                        {
                            "value": "340111",
                            "label": "包河区",
                            "area": "311.738"
                        },
                        {
                            "value": "340121",
                            "label": "长丰县",
                            "area": "1835.625"
                        },
                        {
                            "value": "340124",
                            "label": "庐江县",
                            "area": "2354.589"
                        },
                        {
                            "value": "340123",
                            "label": "肥西县",
                            "area": "1698.447"
                        }
                    ]
                },
                {
                    "value": "341500",
                    "label": "六安市",
                    "children": [
                        {
                            "value": "341525",
                            "label": "霍山县",
                            "area": "2045.951"
                        },
                        {
                            "value": "341503",
                            "label": "裕安区",
                            "area": "1905.942"
                        },
                        {
                            "value": "341524",
                            "label": "金寨县",
                            "area": "3918.924"
                        },
                        {
                            "value": "341502",
                            "label": "金安区",
                            "area": "1655.468"
                        },
                        {
                            "value": "341522",
                            "label": "霍邱县",
                            "area": "3226.774"
                        },
                        {
                            "value": "341504",
                            "label": "叶集区",
                            "area": "566.503"
                        },
                        {
                            "value": "341523",
                            "label": "舒城县",
                            "area": "2118.763"
                        }
                    ]
                }
            ]
        },
        {
            "value": "350000",
            "label": "福建省",
            "children": [
                {
                    "value": "350300",
                    "label": "莆田市",
                    "children": [
                        {
                            "value": "350304",
                            "label": "荔城区",
                            "area": "288.254"
                        },
                        {
                            "value": "350305",
                            "label": "秀屿区",
                            "area": "666.502"
                        },
                        {
                            "value": "350303",
                            "label": "涵江区",
                            "area": "774.4"
                        },
                        {
                            "value": "350322",
                            "label": "仙游县",
                            "area": "1840.523"
                        },
                        {
                            "value": "350302",
                            "label": "城厢区",
                            "area": "492.518"
                        }
                    ]
                },
                {
                    "value": "350200",
                    "label": "厦门市",
                    "children": [
                        {
                            "value": "350203",
                            "label": "思明区",
                            "area": "85.424"
                        },
                        {
                            "value": "350206",
                            "label": "湖里区",
                            "area": "76.342"
                        },
                        {
                            "value": "350213",
                            "label": "翔安区",
                            "area": "445.171"
                        },
                        {
                            "value": "350211",
                            "label": "集美区",
                            "area": "277.719"
                        },
                        {
                            "value": "350205",
                            "label": "海沧区",
                            "area": "215.802"
                        },
                        {
                            "value": "350212",
                            "label": "同安区",
                            "area": "665.86"
                        }
                    ]
                },
                {
                    "value": "350600",
                    "label": "漳州市",
                    "children": [
                        {
                            "value": "350629",
                            "label": "华安县",
                            "area": "1274.775"
                        },
                        {
                            "value": "350626",
                            "label": "东山县",
                            "area": "448.727"
                        },
                        {
                            "value": "350681",
                            "label": "龙海区",
                            "area": "1392.168"
                        },
                        {
                            "value": "350623",
                            "label": "漳浦县",
                            "area": "2420.407"
                        },
                        {
                            "value": "350603",
                            "label": "龙文区",
                            "area": "117.399"
                        },
                        {
                            "value": "350602",
                            "label": "芗城区",
                            "area": "259.136"
                        },
                        {
                            "value": "350625",
                            "label": "长泰区",
                            "area": "904.894"
                        },
                        {
                            "value": "350624",
                            "label": "诏安县",
                            "area": "1389.48"
                        },
                        {
                            "value": "350627",
                            "label": "南靖县",
                            "area": "1970.885"
                        },
                        {
                            "value": "350622",
                            "label": "云霄县",
                            "area": "1108.687"
                        },
                        {
                            "value": "350628",
                            "label": "平和县",
                            "area": "2299.791"
                        }
                    ]
                },
                {
                    "value": "350900",
                    "label": "宁德市",
                    "children": [
                        {
                            "value": "350924",
                            "label": "寿宁县",
                            "area": "1434.213"
                        },
                        {
                            "value": "350922",
                            "label": "古田县",
                            "area": "2360.308"
                        },
                        {
                            "value": "350981",
                            "label": "福安市",
                            "area": "1874.428"
                        },
                        {
                            "value": "350982",
                            "label": "福鼎市",
                            "area": "1611.215"
                        },
                        {
                            "value": "350902",
                            "label": "蕉城区",
                            "area": "1478.939"
                        },
                        {
                            "value": "350921",
                            "label": "霞浦县",
                            "area": "1693.427"
                        },
                        {
                            "value": "350926",
                            "label": "柘荣县",
                            "area": "546.245"
                        },
                        {
                            "value": "350923",
                            "label": "屏南县",
                            "area": "1500.781"
                        },
                        {
                            "value": "350925",
                            "label": "周宁县",
                            "area": "1034.867"
                        }
                    ]
                },
                {
                    "value": "350100",
                    "label": "福州市",
                    "children": [
                        {
                            "value": "350123",
                            "label": "罗源县",
                            "area": "1167.82"
                        },
                        {
                            "value": "350122",
                            "label": "连江县",
                            "area": "1298.245"
                        },
                        {
                            "value": "350124",
                            "label": "闽清县",
                            "area": "1492.875"
                        },
                        {
                            "value": "350112",
                            "label": "长乐区",
                            "area": "740.491"
                        },
                        {
                            "value": "350125",
                            "label": "永泰县",
                            "area": "2227.874"
                        },
                        {
                            "value": "350181",
                            "label": "福清市",
                            "area": "1776.421"
                        },
                        {
                            "value": "350128",
                            "label": "平潭县",
                            "area": "375.756"
                        },
                        {
                            "value": "350103",
                            "label": "台江区",
                            "area": "17.592"
                        },
                        {
                            "value": "350105",
                            "label": "马尾区",
                            "area": "282.892"
                        },
                        {
                            "value": "350104",
                            "label": "仓山区",
                            "area": "153.014"
                        },
                        {
                            "value": "350121",
                            "label": "闽侯县",
                            "area": "2124.775"
                        },
                        {
                            "value": "350102",
                            "label": "鼓楼区",
                            "area": "35.753"
                        },
                        {
                            "value": "350111",
                            "label": "晋安区",
                            "area": "552.064"
                        }
                    ]
                },
                {
                    "value": "350500",
                    "label": "泉州市",
                    "children": [
                        {
                            "value": "350526",
                            "label": "德化县",
                            "area": "2203.27"
                        },
                        {
                            "value": "350504",
                            "label": "洛江区",
                            "area": "373.79"
                        },
                        {
                            "value": "350525",
                            "label": "永春县",
                            "area": "1455.312"
                        },
                        {
                            "value": "350581",
                            "label": "石狮市",
                            "area": "178.183"
                        },
                        {
                            "value": "350521",
                            "label": "惠安县",
                            "area": "793.088"
                        },
                        {
                            "value": "350582",
                            "label": "晋江市",
                            "area": "712.839"
                        },
                        {
                            "value": "350527",
                            "label": "金门县",
                            "area": "169.057"
                        },
                        {
                            "value": "350505",
                            "label": "泉港区",
                            "area": "311.807"
                        },
                        {
                            "value": "350583",
                            "label": "南安市",
                            "area": "2018.562"
                        },
                        {
                            "value": "350524",
                            "label": "安溪县",
                            "area": "2991.42"
                        },
                        {
                            "value": "350502",
                            "label": "鲤城区",
                            "area": "57.434"
                        },
                        {
                            "value": "350503",
                            "label": "丰泽区",
                            "area": "125.498"
                        }
                    ]
                },
                {
                    "value": "350700",
                    "label": "南平市",
                    "children": [
                        {
                            "value": "350782",
                            "label": "武夷山市",
                            "area": "2806.094"
                        },
                        {
                            "value": "350723",
                            "label": "光泽县",
                            "area": "2242.496"
                        },
                        {
                            "value": "350722",
                            "label": "浦城县",
                            "area": "3379.293"
                        },
                        {
                            "value": "350703",
                            "label": "建阳区",
                            "area": "3381.646"
                        },
                        {
                            "value": "350724",
                            "label": "松溪县",
                            "area": "1042.008"
                        },
                        {
                            "value": "350781",
                            "label": "邵武市",
                            "area": "2856.437"
                        },
                        {
                            "value": "350783",
                            "label": "建瓯市",
                            "area": "4196.3"
                        },
                        {
                            "value": "350725",
                            "label": "政和县",
                            "area": "1743.238"
                        },
                        {
                            "value": "350721",
                            "label": "顺昌县",
                            "area": "1977.014"
                        },
                        {
                            "value": "350702",
                            "label": "延平区",
                            "area": "2653.408"
                        }
                    ]
                },
                {
                    "value": "350800",
                    "label": "龙岩市",
                    "children": [
                        {
                            "value": "350881",
                            "label": "漳平市",
                            "area": "2948.606"
                        },
                        {
                            "value": "350824",
                            "label": "武平县",
                            "area": "2640.205"
                        },
                        {
                            "value": "350823",
                            "label": "上杭县",
                            "area": "2840.021"
                        },
                        {
                            "value": "350802",
                            "label": "新罗区",
                            "area": "2666.081"
                        },
                        {
                            "value": "350821",
                            "label": "长汀县",
                            "area": "3106.743"
                        },
                        {
                            "value": "350803",
                            "label": "永定区",
                            "area": "2243.421"
                        },
                        {
                            "value": "350825",
                            "label": "连城县",
                            "area": "2590.107"
                        }
                    ]
                },
                {
                    "value": "350400",
                    "label": "三明市",
                    "children": [
                        {
                            "value": "350429",
                            "label": "泰宁县",
                            "area": "1536.462"
                        },
                        {
                            "value": "350425",
                            "label": "大田县",
                            "area": "2214.548"
                        },
                        {
                            "value": "350430",
                            "label": "建宁县",
                            "area": "1728.662"
                        },
                        {
                            "value": "350426",
                            "label": "尤溪县",
                            "area": "3433.076"
                        },
                        {
                            "value": "350427",
                            "label": "沙县区",
                            "area": "1798.114"
                        },
                        {
                            "value": "350428",
                            "label": "将乐县",
                            "area": "2248.466"
                        },
                        {
                            "value": "350421",
                            "label": "明溪县",
                            "area": "1721.544"
                        },
                        {
                            "value": "350424",
                            "label": "宁化县",
                            "area": "2408.566"
                        },
                        {
                            "value": "350403",
                            "label": "三元区",
                            "area": "1151.717"
                        },
                        {
                            "value": "350481",
                            "label": "永安市",
                            "area": "2923.687"
                        },
                        {
                            "value": "350423",
                            "label": "清流县",
                            "area": "1804.397"
                        }
                    ]
                }
            ]
        },
        {
            "value": "430000",
            "label": "湖南省",
            "children": [
                {
                    "value": "431200",
                    "label": "怀化市",
                    "children": [
                        {
                            "value": "431228",
                            "label": "芷江侗族自治县",
                            "area": "2090.901"
                        },
                        {
                            "value": "431281",
                            "label": "洪江市",
                            "area": "2291.846"
                        },
                        {
                            "value": "431230",
                            "label": "通道侗族自治县",
                            "area": "2214.776"
                        },
                        {
                            "value": "431222",
                            "label": "沅陵县",
                            "area": "5805.775"
                        },
                        {
                            "value": "431223",
                            "label": "辰溪县",
                            "area": "1991.607"
                        },
                        {
                            "value": "431224",
                            "label": "溆浦县",
                            "area": "3426.503"
                        },
                        {
                            "value": "431229",
                            "label": "靖州苗族侗族自治县",
                            "area": "2208.071"
                        },
                        {
                            "value": "431225",
                            "label": "会同县",
                            "area": "2258.563"
                        },
                        {
                            "value": "431227",
                            "label": "新晃侗族自治县",
                            "area": "1493.593"
                        },
                        {
                            "value": "431226",
                            "label": "麻阳苗族自治县",
                            "area": "1569.43"
                        },
                        {
                            "value": "431202",
                            "label": "鹤城区",
                            "area": "673.251"
                        },
                        {
                            "value": "431221",
                            "label": "中方县",
                            "area": "1515.846"
                        }
                    ]
                },
                {
                    "value": "433100",
                    "label": "湘西土家族苗族自治州",
                    "children": [
                        {
                            "value": "433125",
                            "label": "保靖县",
                            "area": "1752.119"
                        },
                        {
                            "value": "433127",
                            "label": "永顺县",
                            "area": "3820.774"
                        },
                        {
                            "value": "433101",
                            "label": "吉首市",
                            "area": "1078.74"
                        },
                        {
                            "value": "433124",
                            "label": "花垣县",
                            "area": "1109.094"
                        },
                        {
                            "value": "433126",
                            "label": "古丈县",
                            "area": "1297.091"
                        },
                        {
                            "value": "433122",
                            "label": "泸溪县",
                            "area": "1565.737"
                        },
                        {
                            "value": "433130",
                            "label": "龙山县",
                            "area": "3134.262"
                        },
                        {
                            "value": "433123",
                            "label": "凤凰县",
                            "area": "1728.393"
                        }
                    ]
                },
                {
                    "value": "430800",
                    "label": "张家界市",
                    "children": [
                        {
                            "value": "430821",
                            "label": "慈利县",
                            "area": "3481.554"
                        },
                        {
                            "value": "430811",
                            "label": "武陵源区",
                            "area": "393.676"
                        },
                        {
                            "value": "430802",
                            "label": "永定区",
                            "area": "2186.043"
                        },
                        {
                            "value": "430822",
                            "label": "桑植县",
                            "area": "3463.79"
                        }
                    ]
                },
                {
                    "value": "430600",
                    "label": "岳阳市",
                    "children": [
                        {
                            "value": "430623",
                            "label": "华容县",
                            "area": "1601.721"
                        },
                        {
                            "value": "430611",
                            "label": "君山区",
                            "area": "625.074"
                        },
                        {
                            "value": "430681",
                            "label": "汨罗市",
                            "area": "1665.552"
                        },
                        {
                            "value": "430602",
                            "label": "岳阳楼区",
                            "area": "407.685"
                        },
                        {
                            "value": "430603",
                            "label": "云溪区",
                            "area": "380.87"
                        },
                        {
                            "value": "430624",
                            "label": "湘阴县",
                            "area": "1535.51"
                        },
                        {
                            "value": "430621",
                            "label": "岳阳县",
                            "area": "2812.086"
                        },
                        {
                            "value": "430682",
                            "label": "临湘市",
                            "area": "1721.075"
                        },
                        {
                            "value": "430626",
                            "label": "平江县",
                            "area": "4114.786"
                        }
                    ]
                },
                {
                    "value": "430400",
                    "label": "衡阳市",
                    "children": [
                        {
                            "value": "430423",
                            "label": "衡山县",
                            "area": "931.255"
                        },
                        {
                            "value": "430424",
                            "label": "衡东县",
                            "area": "1926.248"
                        },
                        {
                            "value": "430421",
                            "label": "衡阳县",
                            "area": "2562.021"
                        },
                        {
                            "value": "430412",
                            "label": "南岳区",
                            "area": "181.708"
                        },
                        {
                            "value": "430426",
                            "label": "祁东县",
                            "area": "1873.7"
                        },
                        {
                            "value": "430406",
                            "label": "雁峰区",
                            "area": "82.442"
                        },
                        {
                            "value": "430405",
                            "label": "珠晖区",
                            "area": "219.123"
                        },
                        {
                            "value": "430407",
                            "label": "石鼓区",
                            "area": "105.217"
                        },
                        {
                            "value": "430422",
                            "label": "衡南县",
                            "area": "2607.415"
                        },
                        {
                            "value": "430481",
                            "label": "耒阳市",
                            "area": "2651.673"
                        },
                        {
                            "value": "430408",
                            "label": "蒸湘区",
                            "area": "112.549"
                        },
                        {
                            "value": "430482",
                            "label": "常宁市",
                            "area": "2047.74"
                        }
                    ]
                },
                {
                    "value": "430100",
                    "label": "长沙市",
                    "children": [
                        {
                            "value": "430105",
                            "label": "开福区",
                            "area": "189.086"
                        },
                        {
                            "value": "430104",
                            "label": "岳麓区",
                            "area": "544.168"
                        },
                        {
                            "value": "430181",
                            "label": "浏阳市",
                            "area": "4998.816"
                        },
                        {
                            "value": "430121",
                            "label": "长沙县",
                            "area": "1755.537"
                        },
                        {
                            "value": "430182",
                            "label": "宁乡市",
                            "area": "2908.697"
                        },
                        {
                            "value": "430111",
                            "label": "雨花区",
                            "area": "290.129"
                        },
                        {
                            "value": "430102",
                            "label": "芙蓉区",
                            "area": "44.16"
                        },
                        {
                            "value": "430103",
                            "label": "天心区",
                            "area": "137.846"
                        },
                        {
                            "value": "430112",
                            "label": "望城区",
                            "area": "950.568"
                        }
                    ]
                },
                {
                    "value": "430700",
                    "label": "常德市",
                    "children": [
                        {
                            "value": "430723",
                            "label": "澧县",
                            "area": "2071.517"
                        },
                        {
                            "value": "430726",
                            "label": "石门县",
                            "area": "3971.041"
                        },
                        {
                            "value": "430724",
                            "label": "临澧县",
                            "area": "1204.271"
                        },
                        {
                            "value": "430781",
                            "label": "津市市",
                            "area": "556.786"
                        },
                        {
                            "value": "430703",
                            "label": "鼎城区",
                            "area": "2450.323"
                        },
                        {
                            "value": "430702",
                            "label": "武陵区",
                            "area": "298.07"
                        },
                        {
                            "value": "430722",
                            "label": "汉寿县",
                            "area": "2092.537"
                        },
                        {
                            "value": "430725",
                            "label": "桃源县",
                            "area": "4442.024"
                        },
                        {
                            "value": "430721",
                            "label": "安乡县",
                            "area": "1100.65"
                        }
                    ]
                },
                {
                    "value": "430300",
                    "label": "湘潭市",
                    "children": [
                        {
                            "value": "430304",
                            "label": "岳塘区",
                            "area": "207.142"
                        },
                        {
                            "value": "430321",
                            "label": "湘潭县",
                            "area": "2139.918"
                        },
                        {
                            "value": "430302",
                            "label": "雨湖区",
                            "area": "455.409"
                        },
                        {
                            "value": "430382",
                            "label": "韶山市",
                            "area": "248.942"
                        },
                        {
                            "value": "430381",
                            "label": "湘乡市",
                            "area": "1964.085"
                        }
                    ]
                },
                {
                    "value": "430200",
                    "label": "株洲市",
                    "children": [
                        {
                            "value": "430202",
                            "label": "荷塘区",
                            "area": "153.315"
                        },
                        {
                            "value": "430223",
                            "label": "攸县",
                            "area": "2662.613"
                        },
                        {
                            "value": "430224",
                            "label": "茶陵县",
                            "area": "2507.608"
                        },
                        {
                            "value": "430225",
                            "label": "炎陵县",
                            "area": "2039.53"
                        },
                        {
                            "value": "430203",
                            "label": "芦淞区",
                            "area": "220.685"
                        },
                        {
                            "value": "430211",
                            "label": "天元区",
                            "area": "323.262"
                        },
                        {
                            "value": "430212",
                            "label": "渌口区",
                            "area": "1056.787"
                        },
                        {
                            "value": "430204",
                            "label": "石峰区",
                            "area": "165.623"
                        },
                        {
                            "value": "430281",
                            "label": "醴陵市",
                            "area": "2141.9"
                        }
                    ]
                },
                {
                    "value": "430500",
                    "label": "邵阳市",
                    "children": [
                        {
                            "value": "430524",
                            "label": "隆回县",
                            "area": "2859.345"
                        },
                        {
                            "value": "430527",
                            "label": "绥宁县",
                            "area": "2920.915"
                        },
                        {
                            "value": "430511",
                            "label": "北塔区",
                            "area": "84.388"
                        },
                        {
                            "value": "430502",
                            "label": "双清区",
                            "area": "134.026"
                        },
                        {
                            "value": "430503",
                            "label": "大祥区",
                            "area": "213.259"
                        },
                        {
                            "value": "430523",
                            "label": "邵阳县",
                            "area": "1998.823"
                        },
                        {
                            "value": "430529",
                            "label": "城步苗族自治县",
                            "area": "2607.168"
                        },
                        {
                            "value": "430582",
                            "label": "邵东市",
                            "area": "1778.944"
                        },
                        {
                            "value": "430522",
                            "label": "新邵县",
                            "area": "1766.815"
                        },
                        {
                            "value": "430528",
                            "label": "新宁县",
                            "area": "2749.609"
                        },
                        {
                            "value": "430581",
                            "label": "武冈市",
                            "area": "1532.308"
                        },
                        {
                            "value": "430525",
                            "label": "洞口县",
                            "area": "2168.376"
                        }
                    ]
                },
                {
                    "value": "431000",
                    "label": "郴州市",
                    "children": [
                        {
                            "value": "431023",
                            "label": "永兴县",
                            "area": "1977.41"
                        },
                        {
                            "value": "431028",
                            "label": "安仁县",
                            "area": "1467.378"
                        },
                        {
                            "value": "431027",
                            "label": "桂东县",
                            "area": "1435.36"
                        },
                        {
                            "value": "431026",
                            "label": "汝城县",
                            "area": "2403.293"
                        },
                        {
                            "value": "431002",
                            "label": "北湖区",
                            "area": "819.218"
                        },
                        {
                            "value": "431003",
                            "label": "苏仙区",
                            "area": "1339.701"
                        },
                        {
                            "value": "431081",
                            "label": "资兴市",
                            "area": "2730.091"
                        },
                        {
                            "value": "431022",
                            "label": "宜章县",
                            "area": "2130.947"
                        },
                        {
                            "value": "431021",
                            "label": "桂阳县",
                            "area": "2963.198"
                        },
                        {
                            "value": "431025",
                            "label": "临武县",
                            "area": "1378.17"
                        },
                        {
                            "value": "431024",
                            "label": "嘉禾县",
                            "area": "691.661"
                        }
                    ]
                },
                {
                    "value": "431100",
                    "label": "永州市",
                    "children": [
                        {
                            "value": "431103",
                            "label": "冷水滩区",
                            "area": "1215.707"
                        },
                        {
                            "value": "431121",
                            "label": "祁阳市",
                            "area": "2542.251"
                        },
                        {
                            "value": "431122",
                            "label": "东安县",
                            "area": "2212.008"
                        },
                        {
                            "value": "431128",
                            "label": "新田县",
                            "area": "1003.302"
                        },
                        {
                            "value": "431102",
                            "label": "零陵区",
                            "area": "1973.708"
                        },
                        {
                            "value": "431126",
                            "label": "宁远县",
                            "area": "2487.976"
                        },
                        {
                            "value": "431123",
                            "label": "双牌县",
                            "area": "1723.211"
                        },
                        {
                            "value": "431127",
                            "label": "蓝山县",
                            "area": "1805.613"
                        },
                        {
                            "value": "431124",
                            "label": "道县",
                            "area": "2454.569"
                        },
                        {
                            "value": "431125",
                            "label": "江永县",
                            "area": "1623.95"
                        },
                        {
                            "value": "431129",
                            "label": "江华瑶族自治县",
                            "area": "3259.715"
                        }
                    ]
                },
                {
                    "value": "430900",
                    "label": "益阳市",
                    "children": [
                        {
                            "value": "430922",
                            "label": "桃江县",
                            "area": "2066.601"
                        },
                        {
                            "value": "430902",
                            "label": "资阳区",
                            "area": "581.071"
                        },
                        {
                            "value": "430903",
                            "label": "赫山区",
                            "area": "1271.811"
                        },
                        {
                            "value": "430923",
                            "label": "安化县",
                            "area": "4950.194"
                        },
                        {
                            "value": "430921",
                            "label": "南县",
                            "area": "1326.726"
                        },
                        {
                            "value": "430981",
                            "label": "沅江市",
                            "area": "2131.971"
                        }
                    ]
                },
                {
                    "value": "431300",
                    "label": "娄底市",
                    "children": [
                        {
                            "value": "431381",
                            "label": "冷水江市",
                            "area": "432.796"
                        },
                        {
                            "value": "431322",
                            "label": "新化县",
                            "area": "3642.895"
                        },
                        {
                            "value": "431382",
                            "label": "涟源市",
                            "area": "1805.981"
                        },
                        {
                            "value": "431321",
                            "label": "双峰县",
                            "area": "1598.95"
                        },
                        {
                            "value": "431302",
                            "label": "娄星区",
                            "area": "627.575"
                        }
                    ]
                }
            ]
        },
        {
            "value": "460000",
            "label": "海南省",
            "children": [
                {
                    "value": "469000",
                    "label": "昌江黎族自治县",
                    "children": [
                        {
                            "value": "469026",
                            "label": "昌江黎族自治县",
                            "area": "1671.786"
                        },
                        {
                            "value": "469024",
                            "label": "临高县",
                            "area": "1393.483"
                        },
                        {
                            "value": "469022",
                            "label": "屯昌县",
                            "area": "1226.646"
                        },
                        {
                            "value": "469007",
                            "label": "东方市",
                            "area": "2572.514"
                        },
                        {
                            "value": "469002",
                            "label": "琼海市",
                            "area": "1806.671"
                        },
                        {
                            "value": "469025",
                            "label": "白沙黎族自治县",
                            "area": "2120.397"
                        },
                        {
                            "value": "469021",
                            "label": "定安县",
                            "area": "1188.654"
                        },
                        {
                            "value": "469006",
                            "label": "万宁市",
                            "area": "2352.835"
                        },
                        {
                            "value": "469030",
                            "label": "琼中黎族苗族自治县",
                            "area": "2691.263"
                        },
                        {
                            "value": "469028",
                            "label": "陵水黎族自治县",
                            "area": "1279.83"
                        },
                        {
                            "value": "469027",
                            "label": "乐东黎族自治县",
                            "area": "3023.468"
                        },
                        {
                            "value": "469023",
                            "label": "澄迈县",
                            "area": "2156.096"
                        },
                        {
                            "value": "469005",
                            "label": "文昌市",
                            "area": "2886.389"
                        },
                        {
                            "value": "469001",
                            "label": "五指山市",
                            "area": "1144.345"
                        },
                        {
                            "value": "469029",
                            "label": "保亭黎族苗族自治县",
                            "area": "1152.472"
                        }
                    ]
                },
                {
                    "value": "460400",
                    "label": "儋州市",
                    "children": [
                        {
                            "value": "460400",
                            "label": "儋州市",
                            "area": "3565.898"
                        }
                    ]
                },
                {
                    "value": "460200",
                    "label": "三亚市",
                    "children": [
                        {
                            "value": "460202",
                            "label": "海棠区",
                            "area": "326.882"
                        },
                        {
                            "value": "460205",
                            "label": "崖州区",
                            "area": "479.209"
                        },
                        {
                            "value": "460203",
                            "label": "吉阳区",
                            "area": "553.382"
                        },
                        {
                            "value": "460204",
                            "label": "天涯区",
                            "area": "1110.874"
                        }
                    ]
                },
                {
                    "value": "460300",
                    "label": "三沙市",
                    "children": [
                        {
                            "value": "460301",
                            "label": "西沙区",
                            "area": "1098.937"
                        },
                        {
                            "value": "460302",
                            "label": "南沙区",
                            "area": "5275.208"
                        }
                    ]
                },
                {
                    "value": "460100",
                    "label": "海口市",
                    "children": [
                        {
                            "value": "460108",
                            "label": "美兰区",
                            "area": "695.956"
                        },
                        {
                            "value": "460107",
                            "label": "琼山区",
                            "area": "928.667"
                        },
                        {
                            "value": "460106",
                            "label": "龙华区",
                            "area": "307.552"
                        },
                        {
                            "value": "460105",
                            "label": "秀英区",
                            "area": "558.504"
                        }
                    ]
                }
            ]
        },
        {
            "value": "320000",
            "label": "江苏省",
            "children": [
                {
                    "value": "320700",
                    "label": "连云港市",
                    "children": [
                        {
                            "value": "320703",
                            "label": "连云区",
                            "area": "771.61"
                        },
                        {
                            "value": "320722",
                            "label": "东海县",
                            "area": "2033.262"
                        },
                        {
                            "value": "320724",
                            "label": "灌南县",
                            "area": "1037.34"
                        },
                        {
                            "value": "320706",
                            "label": "海州区",
                            "area": "730.438"
                        },
                        {
                            "value": "320707",
                            "label": "赣榆区",
                            "area": "1463.429"
                        },
                        {
                            "value": "320723",
                            "label": "灌云县",
                            "area": "1541.185"
                        }
                    ]
                },
                {
                    "value": "320600",
                    "label": "南通市",
                    "children": [
                        {
                            "value": "320685",
                            "label": "海安市",
                            "area": "1163.833"
                        },
                        {
                            "value": "320623",
                            "label": "如东县",
                            "area": "2279.254"
                        },
                        {
                            "value": "320684",
                            "label": "海门区",
                            "area": "1135.837"
                        },
                        {
                            "value": "320681",
                            "label": "启东市",
                            "area": "1685.374"
                        },
                        {
                            "value": "320612",
                            "label": "通州区",
                            "area": "1452.675"
                        },
                        {
                            "value": "320602",
                            "label": "崇川区",
                            "area": "582.912"
                        },
                        {
                            "value": "320682",
                            "label": "如皋市",
                            "area": "1566.99"
                        }
                    ]
                },
                {
                    "value": "320800",
                    "label": "淮安市",
                    "children": [
                        {
                            "value": "320831",
                            "label": "金湖县",
                            "area": "1393.638"
                        },
                        {
                            "value": "320804",
                            "label": "淮阴区",
                            "area": "1303.307"
                        },
                        {
                            "value": "320830",
                            "label": "盱眙县",
                            "area": "2497.875"
                        },
                        {
                            "value": "320813",
                            "label": "洪泽区",
                            "area": "1286.214"
                        },
                        {
                            "value": "320826",
                            "label": "涟水县",
                            "area": "1674.851"
                        },
                        {
                            "value": "320803",
                            "label": "淮安区",
                            "area": "1468.858"
                        },
                        {
                            "value": "320812",
                            "label": "清江浦区",
                            "area": "434.113"
                        }
                    ]
                },
                {
                    "value": "320900",
                    "label": "盐城市",
                    "children": [
                        {
                            "value": "320922",
                            "label": "滨海县",
                            "area": "1965.556"
                        },
                        {
                            "value": "320924",
                            "label": "射阳县",
                            "area": "2577.915"
                        },
                        {
                            "value": "320923",
                            "label": "阜宁县",
                            "area": "1440.054"
                        },
                        {
                            "value": "320925",
                            "label": "建湖县",
                            "area": "1151.268"
                        },
                        {
                            "value": "320903",
                            "label": "盐都区",
                            "area": "1049.623"
                        },
                        {
                            "value": "320981",
                            "label": "东台市",
                            "area": "2544.577"
                        },
                        {
                            "value": "320921",
                            "label": "响水县",
                            "area": "1409.529"
                        },
                        {
                            "value": "320902",
                            "label": "亭湖区",
                            "area": "1002.736"
                        },
                        {
                            "value": "320904",
                            "label": "大丰区",
                            "area": "2878.749"
                        }
                    ]
                },
                {
                    "value": "321000",
                    "label": "扬州市",
                    "children": [
                        {
                            "value": "321002",
                            "label": "广陵区",
                            "area": "342.031"
                        },
                        {
                            "value": "321023",
                            "label": "宝应县",
                            "area": "1472.131"
                        },
                        {
                            "value": "321012",
                            "label": "江都区",
                            "area": "1322.834"
                        },
                        {
                            "value": "321084",
                            "label": "高邮市",
                            "area": "1892.238"
                        },
                        {
                            "value": "321003",
                            "label": "邗江区",
                            "area": "640.992"
                        },
                        {
                            "value": "321081",
                            "label": "仪征市",
                            "area": "902.153"
                        }
                    ]
                },
                {
                    "value": "321300",
                    "label": "宿迁市",
                    "children": [
                        {
                            "value": "321323",
                            "label": "泗阳县",
                            "area": "1378.326"
                        },
                        {
                            "value": "321324",
                            "label": "泗洪县",
                            "area": "2700.903"
                        },
                        {
                            "value": "321311",
                            "label": "宿豫区",
                            "area": "1125.906"
                        },
                        {
                            "value": "321302",
                            "label": "宿城区",
                            "area": "1020.565"
                        },
                        {
                            "value": "321322",
                            "label": "沭阳县",
                            "area": "2302.712"
                        }
                    ]
                },
                {
                    "value": "321100",
                    "label": "镇江市",
                    "children": [
                        {
                            "value": "321182",
                            "label": "扬中市",
                            "area": "321.006"
                        },
                        {
                            "value": "321111",
                            "label": "润州区",
                            "area": "123.391"
                        },
                        {
                            "value": "321112",
                            "label": "丹徒区",
                            "area": "606.403"
                        },
                        {
                            "value": "321102",
                            "label": "京口区",
                            "area": "345.494"
                        },
                        {
                            "value": "321181",
                            "label": "丹阳市",
                            "area": "1050.087"
                        },
                        {
                            "value": "321183",
                            "label": "句容市",
                            "area": "1382.785"
                        }
                    ]
                },
                {
                    "value": "320100",
                    "label": "南京市",
                    "children": [
                        {
                            "value": "320114",
                            "label": "雨花台区",
                            "area": "130.818"
                        },
                        {
                            "value": "320111",
                            "label": "浦口区",
                            "area": "908.897"
                        },
                        {
                            "value": "320115",
                            "label": "江宁区",
                            "area": "1564.907"
                        },
                        {
                            "value": "320118",
                            "label": "高淳区",
                            "area": "792.223"
                        },
                        {
                            "value": "320116",
                            "label": "六合区",
                            "area": "1477.157"
                        },
                        {
                            "value": "320117",
                            "label": "溧水区",
                            "area": "1063.674"
                        },
                        {
                            "value": "320105",
                            "label": "建邺区",
                            "area": "79.162"
                        },
                        {
                            "value": "320104",
                            "label": "秦淮区",
                            "area": "49.067"
                        },
                        {
                            "value": "320102",
                            "label": "玄武区",
                            "area": "75.603"
                        },
                        {
                            "value": "320113",
                            "label": "栖霞区",
                            "area": "390.536"
                        },
                        {
                            "value": "320106",
                            "label": "鼓楼区",
                            "area": "55.154"
                        }
                    ]
                },
                {
                    "value": "321200",
                    "label": "泰州市",
                    "children": [
                        {
                            "value": "321281",
                            "label": "兴化市",
                            "area": "2394.142"
                        },
                        {
                            "value": "321204",
                            "label": "姜堰区",
                            "area": "861.316"
                        },
                        {
                            "value": "321202",
                            "label": "海陵区",
                            "area": "388.1"
                        },
                        {
                            "value": "321282",
                            "label": "靖江市",
                            "area": "651.676"
                        },
                        {
                            "value": "321283",
                            "label": "泰兴市",
                            "area": "1175.868"
                        },
                        {
                            "value": "321203",
                            "label": "高港区",
                            "area": "322.755"
                        }
                    ]
                },
                {
                    "value": "320400",
                    "label": "常州市",
                    "children": [
                        {
                            "value": "320481",
                            "label": "溧阳市",
                            "area": "1535.134"
                        },
                        {
                            "value": "320411",
                            "label": "新北区",
                            "area": "506.93"
                        },
                        {
                            "value": "320402",
                            "label": "天宁区",
                            "area": "153.937"
                        },
                        {
                            "value": "320404",
                            "label": "钟楼区",
                            "area": "133.934"
                        },
                        {
                            "value": "320413",
                            "label": "金坛区",
                            "area": "976.222"
                        },
                        {
                            "value": "320412",
                            "label": "武进区",
                            "area": "1062.531"
                        }
                    ]
                },
                {
                    "value": "320200",
                    "label": "无锡市",
                    "children": [
                        {
                            "value": "320213",
                            "label": "梁溪区",
                            "area": "73.57"
                        },
                        {
                            "value": "320214",
                            "label": "新吴区",
                            "area": "233.176"
                        },
                        {
                            "value": "320211",
                            "label": "滨湖区",
                            "area": "628.938"
                        },
                        {
                            "value": "320282",
                            "label": "宜兴市",
                            "area": "2035.258"
                        },
                        {
                            "value": "320205",
                            "label": "锡山区",
                            "area": "397.298"
                        },
                        {
                            "value": "320206",
                            "label": "惠山区",
                            "area": "320.829"
                        },
                        {
                            "value": "320281",
                            "label": "江阴市",
                            "area": "993.506"
                        }
                    ]
                },
                {
                    "value": "320500",
                    "label": "苏州市",
                    "children": [
                        {
                            "value": "320508",
                            "label": "姑苏区",
                            "area": "81.785"
                        },
                        {
                            "value": "320585",
                            "label": "太仓市",
                            "area": "821.273"
                        },
                        {
                            "value": "320509",
                            "label": "吴江区",
                            "area": "1227.179"
                        },
                        {
                            "value": "320507",
                            "label": "相城区",
                            "area": "485.608"
                        },
                        {
                            "value": "320581",
                            "label": "常熟市",
                            "area": "1262.954"
                        },
                        {
                            "value": "320583",
                            "label": "昆山市",
                            "area": "929.23"
                        },
                        {
                            "value": "320582",
                            "label": "张家港市",
                            "area": "1007.668"
                        },
                        {
                            "value": "320505",
                            "label": "虎丘区",
                            "area": "338.355"
                        },
                        {
                            "value": "320506",
                            "label": "吴中区",
                            "area": "2475.123"
                        }
                    ]
                },
                {
                    "value": "320300",
                    "label": "徐州市",
                    "children": [
                        {
                            "value": "320311",
                            "label": "泉山区",
                            "area": "102.277"
                        },
                        {
                            "value": "320312",
                            "label": "铜山区",
                            "area": "1938.816"
                        },
                        {
                            "value": "320302",
                            "label": "鼓楼区",
                            "area": "92.24"
                        },
                        {
                            "value": "320321",
                            "label": "丰县",
                            "area": "1450.76"
                        },
                        {
                            "value": "320381",
                            "label": "新沂市",
                            "area": "1590.441"
                        },
                        {
                            "value": "320303",
                            "label": "云龙区",
                            "area": "113.483"
                        },
                        {
                            "value": "320382",
                            "label": "邳州市",
                            "area": "2076.789"
                        },
                        {
                            "value": "320305",
                            "label": "贾汪区",
                            "area": "749.282"
                        },
                        {
                            "value": "320324",
                            "label": "睢宁县",
                            "area": "1770.27"
                        },
                        {
                            "value": "320322",
                            "label": "沛县",
                            "area": "1248.892"
                        }
                    ]
                }
            ]
        },
        {
            "value": "630000",
            "label": "青海省",
            "children": [
                {
                    "value": "630200",
                    "label": "海东市",
                    "children": [
                        {
                            "value": "630222",
                            "label": "民和回族土族自治县",
                            "area": "1907.768"
                        },
                        {
                            "value": "630202",
                            "label": "乐都区",
                            "area": "2477.903"
                        },
                        {
                            "value": "630224",
                            "label": "化隆回族自治县",
                            "area": "2699.428"
                        },
                        {
                            "value": "630225",
                            "label": "循化撒拉族自治县",
                            "area": "1823.971"
                        },
                        {
                            "value": "630203",
                            "label": "平安区",
                            "area": "737.21"
                        },
                        {
                            "value": "630223",
                            "label": "互助土族自治县",
                            "area": "3355.421"
                        }
                    ]
                },
                {
                    "value": "632500",
                    "label": "海南藏族自治州",
                    "children": [
                        {
                            "value": "632521",
                            "label": "共和县",
                            "area": "16611.394"
                        },
                        {
                            "value": "632523",
                            "label": "贵德县",
                            "area": "3514.801"
                        },
                        {
                            "value": "632522",
                            "label": "同德县",
                            "area": "4690.51"
                        },
                        {
                            "value": "632525",
                            "label": "贵南县",
                            "area": "6648.087"
                        },
                        {
                            "value": "632524",
                            "label": "兴海县",
                            "area": "12139.844"
                        }
                    ]
                },
                {
                    "value": "632800",
                    "label": "海西蒙古族藏族自治州",
                    "children": [
                        {
                            "value": "632801",
                            "label": "格尔木市",
                            "area": "119162.232"
                        },
                        {
                            "value": "632823",
                            "label": "天峻县",
                            "area": "25601.012"
                        },
                        {
                            "value": "632802",
                            "label": "德令哈市",
                            "area": "27780.201"
                        },
                        {
                            "value": "632822",
                            "label": "都兰县",
                            "area": "45226.638"
                        },
                        {
                            "value": "632821",
                            "label": "乌兰县",
                            "area": "12269.506"
                        },
                        {
                            "value": "632825",
                            "label": "海西蒙古族藏族自治州直辖",
                            "area": "22098.699"
                        },
                        {
                            "value": "632803",
                            "label": "茫崖市",
                            "area": "48655.3"
                        }
                    ]
                },
                {
                    "value": "632300",
                    "label": "黄南藏族自治州",
                    "children": [
                        {
                            "value": "632322",
                            "label": "尖扎县",
                            "area": "1555.333"
                        },
                        {
                            "value": "632301",
                            "label": "同仁市",
                            "area": "3215.305"
                        },
                        {
                            "value": "632323",
                            "label": "泽库县",
                            "area": "6558.974"
                        },
                        {
                            "value": "632324",
                            "label": "河南蒙古族自治县",
                            "area": "6703.617"
                        }
                    ]
                },
                {
                    "value": "632700",
                    "label": "玉树藏族自治州",
                    "children": [
                        {
                            "value": "632724",
                            "label": "治多县",
                            "area": "80682.818"
                        },
                        {
                            "value": "632723",
                            "label": "称多县",
                            "area": "14638.304"
                        },
                        {
                            "value": "632726",
                            "label": "曲麻莱县",
                            "area": "46578.633"
                        },
                        {
                            "value": "632725",
                            "label": "囊谦县",
                            "area": "12078.293"
                        },
                        {
                            "value": "632722",
                            "label": "杂多县",
                            "area": "35569.489"
                        },
                        {
                            "value": "632701",
                            "label": "玉树市",
                            "area": "15388.964"
                        }
                    ]
                },
                {
                    "value": "632600",
                    "label": "果洛藏族自治州",
                    "children": [
                        {
                            "value": "632621",
                            "label": "玛沁县",
                            "area": "13473.323"
                        },
                        {
                            "value": "632622",
                            "label": "班玛县",
                            "area": "6399.456"
                        },
                        {
                            "value": "632624",
                            "label": "达日县",
                            "area": "14535.339"
                        },
                        {
                            "value": "632625",
                            "label": "久治县",
                            "area": "8266.284"
                        },
                        {
                            "value": "632623",
                            "label": "甘德县",
                            "area": "7123.57"
                        },
                        {
                            "value": "632626",
                            "label": "玛多县",
                            "area": "24478.183"
                        }
                    ]
                },
                {
                    "value": "630100",
                    "label": "西宁市",
                    "children": [
                        {
                            "value": "630123",
                            "label": "湟源县",
                            "area": "1540.576"
                        },
                        {
                            "value": "630102",
                            "label": "城东区",
                            "area": "117.063"
                        },
                        {
                            "value": "630121",
                            "label": "大通回族土族自治县",
                            "area": "3158.425"
                        },
                        {
                            "value": "630104",
                            "label": "城西区",
                            "area": "57.522"
                        },
                        {
                            "value": "630103",
                            "label": "城中区",
                            "area": "154.878"
                        },
                        {
                            "value": "630106",
                            "label": "湟中区",
                            "area": "2438.39"
                        },
                        {
                            "value": "630105",
                            "label": "城北区",
                            "area": "129.462"
                        }
                    ]
                },
                {
                    "value": "632200",
                    "label": "海北藏族自治州",
                    "children": [
                        {
                            "value": "632223",
                            "label": "海晏县",
                            "area": "4458.792"
                        },
                        {
                            "value": "632221",
                            "label": "门源回族自治县",
                            "area": "6376.604"
                        },
                        {
                            "value": "632224",
                            "label": "刚察县",
                            "area": "9660.333"
                        },
                        {
                            "value": "632222",
                            "label": "祁连县",
                            "area": "13900.013"
                        }
                    ]
                }
            ]
        },
        {
            "value": "450000",
            "label": "广西壮族自治区",
            "children": [
                {
                    "value": "450500",
                    "label": "北海市",
                    "children": [
                        {
                            "value": "450512",
                            "label": "铁山港区",
                            "area": "501.773"
                        },
                        {
                            "value": "450503",
                            "label": "银海区",
                            "area": "518.078"
                        },
                        {
                            "value": "450502",
                            "label": "海城区",
                            "area": "156.512"
                        },
                        {
                            "value": "450521",
                            "label": "合浦县",
                            "area": "2633.191"
                        }
                    ]
                },
                {
                    "value": "450100",
                    "label": "南宁市",
                    "children": [
                        {
                            "value": "450124",
                            "label": "马山县",
                            "area": "2340.506"
                        },
                        {
                            "value": "450125",
                            "label": "上林县",
                            "area": "1870.874"
                        },
                        {
                            "value": "450110",
                            "label": "武鸣区",
                            "area": "3390.233"
                        },
                        {
                            "value": "450126",
                            "label": "宾阳县",
                            "area": "2298.249"
                        },
                        {
                            "value": "450127",
                            "label": "横州市",
                            "area": "3446.558"
                        },
                        {
                            "value": "450108",
                            "label": "良庆区",
                            "area": "1370.592"
                        },
                        {
                            "value": "450105",
                            "label": "江南区",
                            "area": "1178.725"
                        },
                        {
                            "value": "450109",
                            "label": "邕宁区",
                            "area": "1229.976"
                        },
                        {
                            "value": "450107",
                            "label": "西乡塘区",
                            "area": "1075.883"
                        },
                        {
                            "value": "450103",
                            "label": "青秀区",
                            "area": "863.62"
                        },
                        {
                            "value": "450102",
                            "label": "兴宁区",
                            "area": "722.162"
                        },
                        {
                            "value": "450123",
                            "label": "隆安县",
                            "area": "2295.408"
                        }
                    ]
                },
                {
                    "value": "450700",
                    "label": "钦州市",
                    "children": [
                        {
                            "value": "450702",
                            "label": "钦南区",
                            "area": "2615.131"
                        },
                        {
                            "value": "450703",
                            "label": "钦北区",
                            "area": "2239.287"
                        },
                        {
                            "value": "450721",
                            "label": "灵山县",
                            "area": "3551.994"
                        },
                        {
                            "value": "450722",
                            "label": "浦北县",
                            "area": "2537.708"
                        }
                    ]
                },
                {
                    "value": "450200",
                    "label": "柳州市",
                    "children": [
                        {
                            "value": "450225",
                            "label": "融水苗族自治县",
                            "area": "4656.031"
                        },
                        {
                            "value": "450226",
                            "label": "三江侗族自治县",
                            "area": "2396.701"
                        },
                        {
                            "value": "450224",
                            "label": "融安县",
                            "area": "2909.312"
                        },
                        {
                            "value": "450205",
                            "label": "柳北区",
                            "area": "301.262"
                        },
                        {
                            "value": "450202",
                            "label": "城中区",
                            "area": "77.824"
                        },
                        {
                            "value": "450222",
                            "label": "柳城县",
                            "area": "2119.325"
                        },
                        {
                            "value": "450223",
                            "label": "鹿寨县",
                            "area": "2988.82"
                        },
                        {
                            "value": "450203",
                            "label": "鱼峰区",
                            "area": "843.963"
                        },
                        {
                            "value": "450204",
                            "label": "柳南区",
                            "area": "529.473"
                        },
                        {
                            "value": "450206",
                            "label": "柳江区",
                            "area": "1775.794"
                        }
                    ]
                },
                {
                    "value": "451000",
                    "label": "百色市",
                    "children": [
                        {
                            "value": "451030",
                            "label": "西林县",
                            "area": "2977.663"
                        },
                        {
                            "value": "451027",
                            "label": "凌云县",
                            "area": "2052.53"
                        },
                        {
                            "value": "451028",
                            "label": "乐业县",
                            "area": "2627.195"
                        },
                        {
                            "value": "451031",
                            "label": "隆林各族自治县",
                            "area": "3524.136"
                        },
                        {
                            "value": "451002",
                            "label": "右江区",
                            "area": "3733.924"
                        },
                        {
                            "value": "451081",
                            "label": "靖西市",
                            "area": "3336.001"
                        },
                        {
                            "value": "451082",
                            "label": "平果市",
                            "area": "2479.127"
                        },
                        {
                            "value": "451026",
                            "label": "那坡县",
                            "area": "2235.379"
                        },
                        {
                            "value": "451029",
                            "label": "田林县",
                            "area": "5512.322"
                        },
                        {
                            "value": "451024",
                            "label": "德保县",
                            "area": "2572.026"
                        },
                        {
                            "value": "451022",
                            "label": "田东县",
                            "area": "2812.732"
                        },
                        {
                            "value": "451003",
                            "label": "田阳区",
                            "area": "2364.434"
                        }
                    ]
                },
                {
                    "value": "450400",
                    "label": "梧州市",
                    "children": [
                        {
                            "value": "450423",
                            "label": "蒙山县",
                            "area": "1272.595"
                        },
                        {
                            "value": "450405",
                            "label": "长洲区",
                            "area": "373.422"
                        },
                        {
                            "value": "450406",
                            "label": "龙圩区",
                            "area": "963.52"
                        },
                        {
                            "value": "450481",
                            "label": "岑溪市",
                            "area": "2768.909"
                        },
                        {
                            "value": "450403",
                            "label": "万秀区",
                            "area": "436.327"
                        },
                        {
                            "value": "450421",
                            "label": "苍梧县",
                            "area": "2789.018"
                        },
                        {
                            "value": "450422",
                            "label": "藤县",
                            "area": "3950.089"
                        }
                    ]
                },
                {
                    "value": "450300",
                    "label": "桂林市",
                    "children": [
                        {
                            "value": "450312",
                            "label": "临桂区",
                            "area": "2252.233"
                        },
                        {
                            "value": "450305",
                            "label": "七星区",
                            "area": "71.366"
                        },
                        {
                            "value": "450311",
                            "label": "雁山区",
                            "area": "302.122"
                        },
                        {
                            "value": "450326",
                            "label": "永福县",
                            "area": "2799.008"
                        },
                        {
                            "value": "450321",
                            "label": "阳朔县",
                            "area": "1429.093"
                        },
                        {
                            "value": "450381",
                            "label": "荔浦市",
                            "area": "1761.72"
                        },
                        {
                            "value": "450328",
                            "label": "龙胜各族自治县",
                            "area": "2438.91"
                        },
                        {
                            "value": "450304",
                            "label": "象山区",
                            "area": "91.868"
                        },
                        {
                            "value": "450302",
                            "label": "秀峰区",
                            "area": "43.033"
                        },
                        {
                            "value": "450327",
                            "label": "灌阳县",
                            "area": "1846.581"
                        },
                        {
                            "value": "450303",
                            "label": "叠彩区",
                            "area": "51.843"
                        },
                        {
                            "value": "450323",
                            "label": "灵川县",
                            "area": "2295.822"
                        },
                        {
                            "value": "450329",
                            "label": "资源县",
                            "area": "1944.012"
                        },
                        {
                            "value": "450330",
                            "label": "平乐县",
                            "area": "1880.855"
                        },
                        {
                            "value": "450332",
                            "label": "恭城瑶族自治县",
                            "area": "2129.747"
                        },
                        {
                            "value": "450325",
                            "label": "兴安县",
                            "area": "2330.442"
                        },
                        {
                            "value": "450324",
                            "label": "全州县",
                            "area": "3982.583"
                        }
                    ]
                },
                {
                    "value": "451100",
                    "label": "贺州市",
                    "children": [
                        {
                            "value": "451123",
                            "label": "富川瑶族自治县",
                            "area": "1543.895"
                        },
                        {
                            "value": "451121",
                            "label": "昭平县",
                            "area": "3216.421"
                        },
                        {
                            "value": "451122",
                            "label": "钟山县",
                            "area": "1475.816"
                        },
                        {
                            "value": "451103",
                            "label": "平桂区",
                            "area": "1866.878"
                        },
                        {
                            "value": "451102",
                            "label": "八步区",
                            "area": "3649.95"
                        }
                    ]
                },
                {
                    "value": "451300",
                    "label": "来宾市",
                    "children": [
                        {
                            "value": "451321",
                            "label": "忻城县",
                            "area": "2517.569"
                        },
                        {
                            "value": "451381",
                            "label": "合山市",
                            "area": "364.934"
                        },
                        {
                            "value": "451322",
                            "label": "象州县",
                            "area": "1915.697"
                        },
                        {
                            "value": "451302",
                            "label": "兴宾区",
                            "area": "4403.414"
                        },
                        {
                            "value": "451324",
                            "label": "金秀瑶族自治县",
                            "area": "2464.027"
                        },
                        {
                            "value": "451323",
                            "label": "武宣县",
                            "area": "1708.504"
                        }
                    ]
                },
                {
                    "value": "450800",
                    "label": "贵港市",
                    "children": [
                        {
                            "value": "450802",
                            "label": "港北区",
                            "area": "1095.725"
                        },
                        {
                            "value": "450804",
                            "label": "覃塘区",
                            "area": "1350.356"
                        },
                        {
                            "value": "450803",
                            "label": "港南区",
                            "area": "1101.016"
                        },
                        {
                            "value": "450821",
                            "label": "平南县",
                            "area": "2990.821"
                        },
                        {
                            "value": "450881",
                            "label": "桂平市",
                            "area": "4070.703"
                        }
                    ]
                },
                {
                    "value": "451200",
                    "label": "河池市",
                    "children": [
                        {
                            "value": "451225",
                            "label": "罗城仫佬族自治县",
                            "area": "2643.771"
                        },
                        {
                            "value": "451222",
                            "label": "天峨县",
                            "area": "3170.637"
                        },
                        {
                            "value": "451221",
                            "label": "南丹县",
                            "area": "3921.229"
                        },
                        {
                            "value": "451226",
                            "label": "环江毛南族自治县",
                            "area": "4572.115"
                        },
                        {
                            "value": "451203",
                            "label": "宜州区",
                            "area": "3840.578"
                        },
                        {
                            "value": "451223",
                            "label": "凤山县",
                            "area": "1732.338"
                        },
                        {
                            "value": "451202",
                            "label": "金城江区",
                            "area": "2331.824"
                        },
                        {
                            "value": "451228",
                            "label": "都安瑶族自治县",
                            "area": "4087.476"
                        },
                        {
                            "value": "451229",
                            "label": "大化瑶族自治县",
                            "area": "2792.393"
                        },
                        {
                            "value": "451224",
                            "label": "东兰县",
                            "area": "2403.914"
                        },
                        {
                            "value": "451227",
                            "label": "巴马瑶族自治县",
                            "area": "1978.561"
                        }
                    ]
                },
                {
                    "value": "450600",
                    "label": "防城港市",
                    "children": [
                        {
                            "value": "450602",
                            "label": "港口区",
                            "area": "439.577"
                        },
                        {
                            "value": "450603",
                            "label": "防城区",
                            "area": "2428.925"
                        },
                        {
                            "value": "450681",
                            "label": "东兴市",
                            "area": "541.864"
                        },
                        {
                            "value": "450621",
                            "label": "上思县",
                            "area": "2822.04"
                        }
                    ]
                },
                {
                    "value": "451400",
                    "label": "崇左市",
                    "children": [
                        {
                            "value": "451421",
                            "label": "扶绥县",
                            "area": "2832.717"
                        },
                        {
                            "value": "451402",
                            "label": "江州区",
                            "area": "2921.446"
                        },
                        {
                            "value": "451425",
                            "label": "天等县",
                            "area": "2166.336"
                        },
                        {
                            "value": "451423",
                            "label": "龙州县",
                            "area": "2305.22"
                        },
                        {
                            "value": "451481",
                            "label": "凭祥市",
                            "area": "635.507"
                        },
                        {
                            "value": "451422",
                            "label": "宁明县",
                            "area": "3728.835"
                        },
                        {
                            "value": "451424",
                            "label": "大新县",
                            "area": "2755.725"
                        }
                    ]
                },
                {
                    "value": "450900",
                    "label": "玉林市",
                    "children": [
                        {
                            "value": "450903",
                            "label": "福绵区",
                            "area": "829.015"
                        },
                        {
                            "value": "450923",
                            "label": "博白县",
                            "area": "3821.713"
                        },
                        {
                            "value": "450922",
                            "label": "陆川县",
                            "area": "1554.748"
                        },
                        {
                            "value": "450902",
                            "label": "玉州区",
                            "area": "437.459"
                        },
                        {
                            "value": "450981",
                            "label": "北流市",
                            "area": "2465.713"
                        },
                        {
                            "value": "450921",
                            "label": "容县",
                            "area": "2257.226"
                        },
                        {
                            "value": "450924",
                            "label": "兴业县",
                            "area": "1467.811"
                        }
                    ]
                }
            ]
        },
        {
            "value": "640000",
            "label": "宁夏回族自治区",
            "children": [
                {
                    "value": "640400",
                    "label": "固原市",
                    "children": [
                        {
                            "value": "640422",
                            "label": "西吉县",
                            "area": "3116.126"
                        },
                        {
                            "value": "640425",
                            "label": "彭阳县",
                            "area": "2534.564"
                        },
                        {
                            "value": "640424",
                            "label": "泾源县",
                            "area": "1111.159"
                        },
                        {
                            "value": "640423",
                            "label": "隆德县",
                            "area": "1004.142"
                        },
                        {
                            "value": "640402",
                            "label": "原州区",
                            "area": "2760.794"
                        }
                    ]
                },
                {
                    "value": "640500",
                    "label": "中卫市",
                    "children": [
                        {
                            "value": "640521",
                            "label": "中宁县",
                            "area": "3277.566"
                        },
                        {
                            "value": "640502",
                            "label": "沙坡头区",
                            "area": "5367.615"
                        },
                        {
                            "value": "640522",
                            "label": "海原县",
                            "area": "4995.222"
                        }
                    ]
                },
                {
                    "value": "640200",
                    "label": "石嘴山市",
                    "children": [
                        {
                            "value": "640221",
                            "label": "平罗县",
                            "area": "2065.733"
                        },
                        {
                            "value": "640202",
                            "label": "大武口区",
                            "area": "948.322"
                        },
                        {
                            "value": "640205",
                            "label": "惠农区",
                            "area": "1065.654"
                        }
                    ]
                },
                {
                    "value": "640300",
                    "label": "吴忠市",
                    "children": [
                        {
                            "value": "640381",
                            "label": "青铜峡市",
                            "area": "1908.675"
                        },
                        {
                            "value": "640324",
                            "label": "同心县",
                            "area": "4423.915"
                        },
                        {
                            "value": "640323",
                            "label": "盐池县",
                            "area": "6550.332"
                        },
                        {
                            "value": "640303",
                            "label": "红寺堡区",
                            "area": "2501.275"
                        },
                        {
                            "value": "640302",
                            "label": "利通区",
                            "area": "1421.731"
                        }
                    ]
                },
                {
                    "value": "640100",
                    "label": "银川市",
                    "children": [
                        {
                            "value": "640104",
                            "label": "兴庆区",
                            "area": "645.055"
                        },
                        {
                            "value": "640105",
                            "label": "西夏区",
                            "area": "885.009"
                        },
                        {
                            "value": "640121",
                            "label": "永宁县",
                            "area": "923.384"
                        },
                        {
                            "value": "640181",
                            "label": "灵武市",
                            "area": "2956.363"
                        },
                        {
                            "value": "640122",
                            "label": "贺兰县",
                            "area": "1196.398"
                        },
                        {
                            "value": "640106",
                            "label": "金凤区",
                            "area": "280.346"
                        }
                    ]
                }
            ]
        },
        {
            "value": "360000",
            "label": "江西省",
            "children": [
                {
                    "value": "360400",
                    "label": "九江市",
                    "children": [
                        {
                            "value": "360429",
                            "label": "湖口县",
                            "area": "667.502"
                        },
                        {
                            "value": "360430",
                            "label": "彭泽县",
                            "area": "1548.779"
                        },
                        {
                            "value": "360423",
                            "label": "武宁县",
                            "area": "3523.893"
                        },
                        {
                            "value": "360404",
                            "label": "柴桑区",
                            "area": "888.573"
                        },
                        {
                            "value": "360428",
                            "label": "都昌县",
                            "area": "2226.312"
                        },
                        {
                            "value": "360424",
                            "label": "修水县",
                            "area": "4497.503"
                        },
                        {
                            "value": "360483",
                            "label": "庐山市",
                            "area": "768.45"
                        },
                        {
                            "value": "360402",
                            "label": "濂溪区",
                            "area": "400.086"
                        },
                        {
                            "value": "360425",
                            "label": "永修县",
                            "area": "1945.679"
                        },
                        {
                            "value": "360482",
                            "label": "共青城市",
                            "area": "304.911"
                        },
                        {
                            "value": "360481",
                            "label": "瑞昌市",
                            "area": "1429.242"
                        },
                        {
                            "value": "360426",
                            "label": "德安县",
                            "area": "853.606"
                        },
                        {
                            "value": "360403",
                            "label": "浔阳区",
                            "area": "47.955"
                        }
                    ]
                },
                {
                    "value": "360700",
                    "label": "赣州市",
                    "children": [
                        {
                            "value": "360732",
                            "label": "兴国县",
                            "area": "3215.981"
                        },
                        {
                            "value": "360730",
                            "label": "宁都县",
                            "area": "4050.832"
                        },
                        {
                            "value": "360733",
                            "label": "会昌县",
                            "area": "2707.094"
                        },
                        {
                            "value": "360724",
                            "label": "上犹县",
                            "area": "1539.43"
                        },
                        {
                            "value": "360726",
                            "label": "安远县",
                            "area": "2365.331"
                        },
                        {
                            "value": "360735",
                            "label": "石城县",
                            "area": "1568.825"
                        },
                        {
                            "value": "360731",
                            "label": "于都县",
                            "area": "2893.825"
                        },
                        {
                            "value": "360725",
                            "label": "崇义县",
                            "area": "2206.799"
                        },
                        {
                            "value": "360723",
                            "label": "大余县",
                            "area": "1346.109"
                        },
                        {
                            "value": "360781",
                            "label": "瑞金市",
                            "area": "2441.064"
                        },
                        {
                            "value": "360722",
                            "label": "信丰县",
                            "area": "2865.606"
                        },
                        {
                            "value": "360734",
                            "label": "寻乌县",
                            "area": "2337.175"
                        },
                        {
                            "value": "360704",
                            "label": "赣县区",
                            "area": "2987.575"
                        },
                        {
                            "value": "360728",
                            "label": "定南县",
                            "area": "1319.883"
                        },
                        {
                            "value": "360702",
                            "label": "章贡区",
                            "area": "591.814"
                        },
                        {
                            "value": "360729",
                            "label": "全南县",
                            "area": "1535.121"
                        },
                        {
                            "value": "360703",
                            "label": "南康区",
                            "area": "1740.505"
                        },
                        {
                            "value": "360783",
                            "label": "龙南市",
                            "area": "1645.05"
                        }
                    ]
                },
                {
                    "value": "361000",
                    "label": "抚州市",
                    "children": [
                        {
                            "value": "361003",
                            "label": "东乡区",
                            "area": "1261.12"
                        },
                        {
                            "value": "361002",
                            "label": "临川区",
                            "area": "2122.388"
                        },
                        {
                            "value": "361028",
                            "label": "资溪县",
                            "area": "1252.933"
                        },
                        {
                            "value": "361025",
                            "label": "乐安县",
                            "area": "2410.021"
                        },
                        {
                            "value": "361022",
                            "label": "黎川县",
                            "area": "1706.621"
                        },
                        {
                            "value": "361021",
                            "label": "南城县",
                            "area": "1709.252"
                        },
                        {
                            "value": "361027",
                            "label": "金溪县",
                            "area": "1356.707"
                        },
                        {
                            "value": "361026",
                            "label": "宜黄县",
                            "area": "1940.766"
                        },
                        {
                            "value": "361024",
                            "label": "崇仁县",
                            "area": "1513.816"
                        },
                        {
                            "value": "361023",
                            "label": "南丰县",
                            "area": "1921.836"
                        },
                        {
                            "value": "361030",
                            "label": "广昌县",
                            "area": "1603.611"
                        }
                    ]
                },
                {
                    "value": "360200",
                    "label": "景德镇市",
                    "children": [
                        {
                            "value": "360202",
                            "label": "昌江区",
                            "area": "324.542"
                        },
                        {
                            "value": "360222",
                            "label": "浮梁县",
                            "area": "2830.645"
                        },
                        {
                            "value": "360281",
                            "label": "乐平市",
                            "area": "1980.508"
                        },
                        {
                            "value": "360203",
                            "label": "珠山区",
                            "area": "113.485"
                        }
                    ]
                },
                {
                    "value": "360300",
                    "label": "萍乡市",
                    "children": [
                        {
                            "value": "360302",
                            "label": "安源区",
                            "area": "212.757"
                        },
                        {
                            "value": "360322",
                            "label": "上栗县",
                            "area": "729.056"
                        },
                        {
                            "value": "360321",
                            "label": "莲花县",
                            "area": "1065.195"
                        },
                        {
                            "value": "360323",
                            "label": "芦溪县",
                            "area": "958.646"
                        },
                        {
                            "value": "360313",
                            "label": "湘东区",
                            "area": "856.792"
                        }
                    ]
                },
                {
                    "value": "360100",
                    "label": "南昌市",
                    "children": [
                        {
                            "value": "360124",
                            "label": "进贤县",
                            "area": "1937.711"
                        },
                        {
                            "value": "360104",
                            "label": "青云谱区",
                            "area": "36.895"
                        },
                        {
                            "value": "360121",
                            "label": "南昌县",
                            "area": "1807.043"
                        },
                        {
                            "value": "360103",
                            "label": "西湖区",
                            "area": "34.698"
                        },
                        {
                            "value": "360111",
                            "label": "青山湖区",
                            "area": "262.69"
                        },
                        {
                            "value": "360102",
                            "label": "东湖区",
                            "area": "55.311"
                        },
                        {
                            "value": "360123",
                            "label": "安义县",
                            "area": "665.037"
                        },
                        {
                            "value": "360112",
                            "label": "新建区",
                            "area": "2196.327"
                        },
                        {
                            "value": "360113",
                            "label": "红谷滩区",
                            "area": "195.844"
                        }
                    ]
                },
                {
                    "value": "360900",
                    "label": "宜春市",
                    "children": [
                        {
                            "value": "360924",
                            "label": "宜丰县",
                            "area": "1930.561"
                        },
                        {
                            "value": "360921",
                            "label": "奉新县",
                            "area": "1644.867"
                        },
                        {
                            "value": "360983",
                            "label": "高安市",
                            "area": "2442.35"
                        },
                        {
                            "value": "360981",
                            "label": "丰城市",
                            "area": "2829.214"
                        },
                        {
                            "value": "360923",
                            "label": "上高县",
                            "area": "1349.408"
                        },
                        {
                            "value": "360926",
                            "label": "铜鼓县",
                            "area": "1555.309"
                        },
                        {
                            "value": "360922",
                            "label": "万载县",
                            "area": "1712.219"
                        },
                        {
                            "value": "360902",
                            "label": "袁州区",
                            "area": "2533.336"
                        },
                        {
                            "value": "360982",
                            "label": "樟树市",
                            "area": "1286.117"
                        },
                        {
                            "value": "360925",
                            "label": "靖安县",
                            "area": "1377.231"
                        }
                    ]
                },
                {
                    "value": "360500",
                    "label": "新余市",
                    "children": [
                        {
                            "value": "360521",
                            "label": "分宜县",
                            "area": "1372.596"
                        },
                        {
                            "value": "360502",
                            "label": "渝水区",
                            "area": "1792.122"
                        }
                    ]
                },
                {
                    "value": "360800",
                    "label": "吉安市",
                    "children": [
                        {
                            "value": "360821",
                            "label": "吉安县",
                            "area": "2123.363"
                        },
                        {
                            "value": "360822",
                            "label": "吉水县",
                            "area": "2508.934"
                        },
                        {
                            "value": "360830",
                            "label": "永新县",
                            "area": "2181.809"
                        },
                        {
                            "value": "360828",
                            "label": "万安县",
                            "area": "2039.066"
                        },
                        {
                            "value": "360825",
                            "label": "永丰县",
                            "area": "2700.683"
                        },
                        {
                            "value": "360827",
                            "label": "遂川县",
                            "area": "3105.951"
                        },
                        {
                            "value": "360802",
                            "label": "吉州区",
                            "area": "428.626"
                        },
                        {
                            "value": "360803",
                            "label": "青原区",
                            "area": "913.803"
                        },
                        {
                            "value": "360824",
                            "label": "新干县",
                            "area": "1244.704"
                        },
                        {
                            "value": "360829",
                            "label": "安福县",
                            "area": "2788.685"
                        },
                        {
                            "value": "360823",
                            "label": "峡江县",
                            "area": "1296.663"
                        },
                        {
                            "value": "360826",
                            "label": "泰和县",
                            "area": "2502.584"
                        },
                        {
                            "value": "360881",
                            "label": "井冈山市",
                            "area": "1443.915"
                        }
                    ]
                },
                {
                    "value": "360600",
                    "label": "鹰潭市",
                    "children": [
                        {
                            "value": "360602",
                            "label": "月湖区",
                            "area": "136.999"
                        },
                        {
                            "value": "360681",
                            "label": "贵溪市",
                            "area": "2477.364"
                        },
                        {
                            "value": "360603",
                            "label": "余江区",
                            "area": "929.582"
                        }
                    ]
                },
                {
                    "value": "361100",
                    "label": "上饶市",
                    "children": [
                        {
                            "value": "361130",
                            "label": "婺源县",
                            "area": "2968.551"
                        },
                        {
                            "value": "361125",
                            "label": "横峰县",
                            "area": "650.448"
                        },
                        {
                            "value": "361126",
                            "label": "弋阳县",
                            "area": "1585.022"
                        },
                        {
                            "value": "361181",
                            "label": "德兴市",
                            "area": "2079.654"
                        },
                        {
                            "value": "361124",
                            "label": "铅山县",
                            "area": "2182.611"
                        },
                        {
                            "value": "361103",
                            "label": "广丰区",
                            "area": "1375.241"
                        },
                        {
                            "value": "361123",
                            "label": "玉山县",
                            "area": "1734.866"
                        },
                        {
                            "value": "361104",
                            "label": "广信区",
                            "area": "2234.254"
                        },
                        {
                            "value": "361102",
                            "label": "信州区",
                            "area": "315.818"
                        },
                        {
                            "value": "361127",
                            "label": "余干县",
                            "area": "2351.791"
                        },
                        {
                            "value": "361129",
                            "label": "万年县",
                            "area": "1151.718"
                        },
                        {
                            "value": "361128",
                            "label": "鄱阳县",
                            "area": "4127.475"
                        }
                    ]
                }
            ]
        },
        {
            "value": "330000",
            "label": "浙江省",
            "children": [
                {
                    "value": "330900",
                    "label": "舟山市",
                    "children": [
                        {
                            "value": "330921",
                            "label": "岱山县",
                            "area": "348.904"
                        },
                        {
                            "value": "330903",
                            "label": "普陀区",
                            "area": "501.883"
                        },
                        {
                            "value": "330902",
                            "label": "定海区",
                            "area": "614.524"
                        },
                        {
                            "value": "330922",
                            "label": "嵊泗县",
                            "area": "156.306"
                        }
                    ]
                },
                {
                    "value": "331000",
                    "label": "台州市",
                    "children": [
                        {
                            "value": "331004",
                            "label": "路桥区",
                            "area": "360.587"
                        },
                        {
                            "value": "331082",
                            "label": "临海市",
                            "area": "2264.025"
                        },
                        {
                            "value": "331002",
                            "label": "椒江区",
                            "area": "366.164"
                        },
                        {
                            "value": "331022",
                            "label": "三门县",
                            "area": "1230.039"
                        },
                        {
                            "value": "331081",
                            "label": "温岭市",
                            "area": "1097.898"
                        },
                        {
                            "value": "331083",
                            "label": "玉环市",
                            "area": "638.524"
                        },
                        {
                            "value": "331023",
                            "label": "天台县",
                            "area": "1421.581"
                        },
                        {
                            "value": "331024",
                            "label": "仙居县",
                            "area": "1999.677"
                        },
                        {
                            "value": "331003",
                            "label": "黄岩区",
                            "area": "988.421"
                        }
                    ]
                },
                {
                    "value": "330400",
                    "label": "嘉兴市",
                    "children": [
                        {
                            "value": "330482",
                            "label": "平湖市",
                            "area": "581.038"
                        },
                        {
                            "value": "330402",
                            "label": "南湖区",
                            "area": "435.753"
                        },
                        {
                            "value": "330421",
                            "label": "嘉善县",
                            "area": "505.648"
                        },
                        {
                            "value": "330424",
                            "label": "海盐县",
                            "area": "941.814"
                        },
                        {
                            "value": "330411",
                            "label": "秀洲区",
                            "area": "548.65"
                        },
                        {
                            "value": "330481",
                            "label": "海宁市",
                            "area": "863.204"
                        },
                        {
                            "value": "330483",
                            "label": "桐乡市",
                            "area": "728.825"
                        }
                    ]
                },
                {
                    "value": "330200",
                    "label": "宁波市",
                    "children": [
                        {
                            "value": "330225",
                            "label": "象山县",
                            "area": "1512.083"
                        },
                        {
                            "value": "330206",
                            "label": "北仑区",
                            "area": "671.331"
                        },
                        {
                            "value": "330211",
                            "label": "镇海区",
                            "area": "271.537"
                        },
                        {
                            "value": "330205",
                            "label": "江北区",
                            "area": "208.727"
                        },
                        {
                            "value": "330226",
                            "label": "宁海县",
                            "area": "1902.648"
                        },
                        {
                            "value": "330212",
                            "label": "鄞州区",
                            "area": "832.377"
                        },
                        {
                            "value": "330213",
                            "label": "奉化区",
                            "area": "1342.758"
                        },
                        {
                            "value": "330203",
                            "label": "海曙区",
                            "area": "592.951"
                        },
                        {
                            "value": "330282",
                            "label": "慈溪市",
                            "area": "1487.4"
                        },
                        {
                            "value": "330281",
                            "label": "余姚市",
                            "area": "1536.371"
                        }
                    ]
                },
                {
                    "value": "330300",
                    "label": "温州市",
                    "children": [
                        {
                            "value": "330381",
                            "label": "瑞安市",
                            "area": "1304.291"
                        },
                        {
                            "value": "330305",
                            "label": "洞头区",
                            "area": "356.219"
                        },
                        {
                            "value": "330326",
                            "label": "平阳县",
                            "area": "982.027"
                        },
                        {
                            "value": "330327",
                            "label": "苍南县",
                            "area": "1231.711"
                        },
                        {
                            "value": "330383",
                            "label": "龙港市",
                            "area": "157.86"
                        },
                        {
                            "value": "330328",
                            "label": "文成县",
                            "area": "1294.182"
                        },
                        {
                            "value": "330329",
                            "label": "泰顺县",
                            "area": "1768.546"
                        },
                        {
                            "value": "330382",
                            "label": "乐清市",
                            "area": "1437.07"
                        },
                        {
                            "value": "330324",
                            "label": "永嘉县",
                            "area": "2677.354"
                        },
                        {
                            "value": "330303",
                            "label": "龙湾区",
                            "area": "322.195"
                        },
                        {
                            "value": "330304",
                            "label": "瓯海区",
                            "area": "465.439"
                        },
                        {
                            "value": "330302",
                            "label": "鹿城区",
                            "area": "291.971"
                        }
                    ]
                },
                {
                    "value": "331100",
                    "label": "丽水市",
                    "children": [
                        {
                            "value": "331102",
                            "label": "莲都区",
                            "area": "1503.832"
                        },
                        {
                            "value": "331181",
                            "label": "龙泉市",
                            "area": "3035.162"
                        },
                        {
                            "value": "331124",
                            "label": "松阳县",
                            "area": "1403.471"
                        },
                        {
                            "value": "331125",
                            "label": "云和县",
                            "area": "977.853"
                        },
                        {
                            "value": "331127",
                            "label": "景宁畲族自治县",
                            "area": "1938.799"
                        },
                        {
                            "value": "331123",
                            "label": "遂昌县",
                            "area": "2531.739"
                        },
                        {
                            "value": "331121",
                            "label": "青田县",
                            "area": "2474.99"
                        },
                        {
                            "value": "331126",
                            "label": "庆元县",
                            "area": "1894.812"
                        },
                        {
                            "value": "331122",
                            "label": "缙云县",
                            "area": "1498.463"
                        }
                    ]
                },
                {
                    "value": "330800",
                    "label": "衢州市",
                    "children": [
                        {
                            "value": "330802",
                            "label": "柯城区",
                            "area": "604.206"
                        },
                        {
                            "value": "330881",
                            "label": "江山市",
                            "area": "2017.917"
                        },
                        {
                            "value": "330822",
                            "label": "常山县",
                            "area": "1097.806"
                        },
                        {
                            "value": "330824",
                            "label": "开化县",
                            "area": "2232.329"
                        },
                        {
                            "value": "330803",
                            "label": "衢江区",
                            "area": "1749.354"
                        },
                        {
                            "value": "330825",
                            "label": "龙游县",
                            "area": "1143.225"
                        }
                    ]
                },
                {
                    "value": "330700",
                    "label": "金华市",
                    "children": [
                        {
                            "value": "330784",
                            "label": "永康市",
                            "area": "1041.581"
                        },
                        {
                            "value": "330727",
                            "label": "磐安县",
                            "area": "1184.774"
                        },
                        {
                            "value": "330783",
                            "label": "东阳市",
                            "area": "1755.692"
                        },
                        {
                            "value": "330723",
                            "label": "武义县",
                            "area": "1581.355"
                        },
                        {
                            "value": "330703",
                            "label": "金东区",
                            "area": "664.382"
                        },
                        {
                            "value": "330782",
                            "label": "义乌市",
                            "area": "1107.067"
                        },
                        {
                            "value": "330702",
                            "label": "婺城区",
                            "area": "1389.722"
                        },
                        {
                            "value": "330726",
                            "label": "浦江县",
                            "area": "891.622"
                        },
                        {
                            "value": "330781",
                            "label": "兰溪市",
                            "area": "1305.013"
                        }
                    ]
                },
                {
                    "value": "330600",
                    "label": "绍兴市",
                    "children": [
                        {
                            "value": "330683",
                            "label": "嵊州市",
                            "area": "1780.68"
                        },
                        {
                            "value": "330624",
                            "label": "新昌县",
                            "area": "1219.554"
                        },
                        {
                            "value": "330602",
                            "label": "越城区",
                            "area": "658.511"
                        },
                        {
                            "value": "330604",
                            "label": "上虞区",
                            "area": "1234.891"
                        },
                        {
                            "value": "330681",
                            "label": "诸暨市",
                            "area": "2314.907"
                        },
                        {
                            "value": "330603",
                            "label": "柯桥区",
                            "area": "1070.65"
                        }
                    ]
                },
                {
                    "value": "330500",
                    "label": "湖州市",
                    "children": [
                        {
                            "value": "330522",
                            "label": "长兴县",
                            "area": "1433.561"
                        },
                        {
                            "value": "330503",
                            "label": "南浔区",
                            "area": "702.554"
                        },
                        {
                            "value": "330502",
                            "label": "吴兴区",
                            "area": "861.908"
                        },
                        {
                            "value": "330523",
                            "label": "安吉县",
                            "area": "1886.766"
                        },
                        {
                            "value": "330521",
                            "label": "德清县",
                            "area": "939.56"
                        }
                    ]
                },
                {
                    "value": "330100",
                    "label": "杭州市",
                    "children": [
                        {
                            "value": "330108",
                            "label": "滨江区",
                            "area": "71.302"
                        },
                        {
                            "value": "330182",
                            "label": "建德市",
                            "area": "2325.925"
                        },
                        {
                            "value": "330114",
                            "label": "临平区",
                            "area": "282.666"
                        },
                        {
                            "value": "330122",
                            "label": "桐庐县",
                            "area": "1837.667"
                        },
                        {
                            "value": "330105",
                            "label": "拱墅区",
                            "area": "98.827"
                        },
                        {
                            "value": "330111",
                            "label": "富阳区",
                            "area": "1827.137"
                        },
                        {
                            "value": "330113",
                            "label": "钱塘区",
                            "area": "522.556"
                        },
                        {
                            "value": "330127",
                            "label": "淳安县",
                            "area": "4420.468"
                        },
                        {
                            "value": "330102",
                            "label": "上城区",
                            "area": "120.455"
                        },
                        {
                            "value": "330106",
                            "label": "西湖区",
                            "area": "311.292"
                        },
                        {
                            "value": "330110",
                            "label": "余杭区",
                            "area": "948.498"
                        },
                        {
                            "value": "330112",
                            "label": "临安区",
                            "area": "3110.443"
                        },
                        {
                            "value": "330109",
                            "label": "萧山区",
                            "area": "999.056"
                        }
                    ]
                }
            ]
        },
        {
            "value": "130000",
            "label": "河北省",
            "children": [
                {
                    "value": "130200",
                    "label": "唐山市",
                    "children": [
                        {
                            "value": "130284",
                            "label": "滦州市",
                            "area": "1015.975"
                        },
                        {
                            "value": "130283",
                            "label": "迁安市",
                            "area": "1230.362"
                        },
                        {
                            "value": "130227",
                            "label": "迁西县",
                            "area": "1450.522"
                        },
                        {
                            "value": "130281",
                            "label": "遵化市",
                            "area": "1508.896"
                        },
                        {
                            "value": "130209",
                            "label": "曹妃甸区",
                            "area": "1480.789"
                        },
                        {
                            "value": "130224",
                            "label": "滦南县",
                            "area": "1249.599"
                        },
                        {
                            "value": "130225",
                            "label": "乐亭县",
                            "area": "1501.947"
                        },
                        {
                            "value": "130229",
                            "label": "玉田县",
                            "area": "1169.611"
                        },
                        {
                            "value": "130202",
                            "label": "路南区",
                            "area": "243.977"
                        },
                        {
                            "value": "130204",
                            "label": "古冶区",
                            "area": "251.799"
                        },
                        {
                            "value": "130205",
                            "label": "开平区",
                            "area": "240.12"
                        },
                        {
                            "value": "130208",
                            "label": "丰润区",
                            "area": "1216.82"
                        },
                        {
                            "value": "130203",
                            "label": "路北区",
                            "area": "169.593"
                        },
                        {
                            "value": "130207",
                            "label": "丰南区",
                            "area": "1401.804"
                        }
                    ]
                },
                {
                    "value": "131000",
                    "label": "廊坊市",
                    "children": [
                        {
                            "value": "131028",
                            "label": "大厂回族自治县",
                            "area": "175.833"
                        },
                        {
                            "value": "131025",
                            "label": "大城县",
                            "area": "896.305"
                        },
                        {
                            "value": "131023",
                            "label": "永清县",
                            "area": "767.109"
                        },
                        {
                            "value": "131022",
                            "label": "固安县",
                            "area": "697.73"
                        },
                        {
                            "value": "131024",
                            "label": "香河县",
                            "area": "448.317"
                        },
                        {
                            "value": "131026",
                            "label": "文安县",
                            "area": "1033.209"
                        },
                        {
                            "value": "131003",
                            "label": "广阳区",
                            "area": "382.427"
                        },
                        {
                            "value": "131082",
                            "label": "三河市",
                            "area": "634.691"
                        },
                        {
                            "value": "131002",
                            "label": "安次区",
                            "area": "581.467"
                        },
                        {
                            "value": "131081",
                            "label": "霸州市",
                            "area": "795.426"
                        }
                    ]
                },
                {
                    "value": "130300",
                    "label": "秦皇岛市",
                    "children": [
                        {
                            "value": "130303",
                            "label": "山海关区",
                            "area": "208.124"
                        },
                        {
                            "value": "130321",
                            "label": "青龙满族自治县",
                            "area": "3508.484"
                        },
                        {
                            "value": "130324",
                            "label": "卢龙县",
                            "area": "958.903"
                        },
                        {
                            "value": "130302",
                            "label": "海港区",
                            "area": "754.389"
                        },
                        {
                            "value": "130304",
                            "label": "北戴河区",
                            "area": "158.016"
                        },
                        {
                            "value": "130306",
                            "label": "抚宁区",
                            "area": "1039.475"
                        },
                        {
                            "value": "130322",
                            "label": "昌黎县",
                            "area": "1232.28"
                        }
                    ]
                },
                {
                    "value": "130700",
                    "label": "张家口市",
                    "children": [
                        {
                            "value": "130723",
                            "label": "康保县",
                            "area": "3364.06"
                        },
                        {
                            "value": "130725",
                            "label": "尚义县",
                            "area": "2633.126"
                        },
                        {
                            "value": "130722",
                            "label": "张北县",
                            "area": "4195.382"
                        },
                        {
                            "value": "130708",
                            "label": "万全区",
                            "area": "1127.299"
                        },
                        {
                            "value": "130724",
                            "label": "沽源县",
                            "area": "3583.298"
                        },
                        {
                            "value": "130709",
                            "label": "崇礼区",
                            "area": "2350.528"
                        },
                        {
                            "value": "130732",
                            "label": "赤城县",
                            "area": "5263.743"
                        },
                        {
                            "value": "130706",
                            "label": "下花园区",
                            "area": "315.663"
                        },
                        {
                            "value": "130731",
                            "label": "涿鹿县",
                            "area": "2796.707"
                        },
                        {
                            "value": "130730",
                            "label": "怀来县",
                            "area": "1777.705"
                        },
                        {
                            "value": "130703",
                            "label": "桥西区",
                            "area": "212.095"
                        },
                        {
                            "value": "130705",
                            "label": "宣化区",
                            "area": "2018.353"
                        },
                        {
                            "value": "130702",
                            "label": "桥东区",
                            "area": "403.233"
                        },
                        {
                            "value": "130728",
                            "label": "怀安县",
                            "area": "1677.995"
                        },
                        {
                            "value": "130727",
                            "label": "阳原县",
                            "area": "1851.445"
                        },
                        {
                            "value": "130726",
                            "label": "蔚县",
                            "area": "3193.877"
                        }
                    ]
                },
                {
                    "value": "130400",
                    "label": "邯郸市",
                    "children": [
                        {
                            "value": "130426",
                            "label": "涉县",
                            "area": "1498.855"
                        },
                        {
                            "value": "130481",
                            "label": "武安市",
                            "area": "1800.826"
                        },
                        {
                            "value": "130434",
                            "label": "魏县",
                            "area": "863.454"
                        },
                        {
                            "value": "130407",
                            "label": "肥乡区",
                            "area": "501.668"
                        },
                        {
                            "value": "130406",
                            "label": "峰峰矿区",
                            "area": "330.675"
                        },
                        {
                            "value": "130423",
                            "label": "临漳县",
                            "area": "747.755"
                        },
                        {
                            "value": "130435",
                            "label": "曲周县",
                            "area": "675.55"
                        },
                        {
                            "value": "130431",
                            "label": "鸡泽县",
                            "area": "336.545"
                        },
                        {
                            "value": "130403",
                            "label": "丛台区",
                            "area": "402.518"
                        },
                        {
                            "value": "130408",
                            "label": "永年区",
                            "area": "752.664"
                        },
                        {
                            "value": "130427",
                            "label": "磁县",
                            "area": "702.966"
                        },
                        {
                            "value": "130404",
                            "label": "复兴区",
                            "area": "258.25"
                        },
                        {
                            "value": "130402",
                            "label": "邯山区",
                            "area": "429.202"
                        },
                        {
                            "value": "130424",
                            "label": "成安县",
                            "area": "475.554"
                        },
                        {
                            "value": "130433",
                            "label": "馆陶县",
                            "area": "452.65"
                        },
                        {
                            "value": "130430",
                            "label": "邱县",
                            "area": "444.674"
                        },
                        {
                            "value": "130432",
                            "label": "广平县",
                            "area": "318.84"
                        },
                        {
                            "value": "130425",
                            "label": "大名县",
                            "area": "1057.717"
                        }
                    ]
                },
                {
                    "value": "130100",
                    "label": "石家庄市",
                    "children": [
                        {
                            "value": "130130",
                            "label": "无极县",
                            "area": "498.037"
                        },
                        {
                            "value": "130131",
                            "label": "平山县",
                            "area": "2642.079"
                        },
                        {
                            "value": "130109",
                            "label": "藁城区",
                            "area": "814.141"
                        },
                        {
                            "value": "130123",
                            "label": "正定县",
                            "area": "481.703"
                        },
                        {
                            "value": "130126",
                            "label": "灵寿县",
                            "area": "1066.869"
                        },
                        {
                            "value": "130110",
                            "label": "鹿泉区",
                            "area": "614.325"
                        },
                        {
                            "value": "130108",
                            "label": "裕华区",
                            "area": "106.926"
                        },
                        {
                            "value": "130129",
                            "label": "赞皇县",
                            "area": "849.632"
                        },
                        {
                            "value": "130183",
                            "label": "晋州市",
                            "area": "604.887"
                        },
                        {
                            "value": "130132",
                            "label": "元氏县",
                            "area": "674.391"
                        },
                        {
                            "value": "130127",
                            "label": "高邑县",
                            "area": "220.736"
                        },
                        {
                            "value": "130105",
                            "label": "新华区",
                            "area": "90.763"
                        },
                        {
                            "value": "130184",
                            "label": "新乐市",
                            "area": "520.857"
                        },
                        {
                            "value": "130181",
                            "label": "辛集市",
                            "area": "951.515"
                        },
                        {
                            "value": "130104",
                            "label": "桥西区",
                            "area": "67.139"
                        },
                        {
                            "value": "130121",
                            "label": "井陉县",
                            "area": "1380.296"
                        },
                        {
                            "value": "130102",
                            "label": "长安区",
                            "area": "140.791"
                        },
                        {
                            "value": "130107",
                            "label": "井陉矿区",
                            "area": "70.892"
                        },
                        {
                            "value": "130128",
                            "label": "深泽县",
                            "area": "297.674"
                        },
                        {
                            "value": "130111",
                            "label": "栾城区",
                            "area": "351.425"
                        },
                        {
                            "value": "130125",
                            "label": "行唐县",
                            "area": "955.384"
                        },
                        {
                            "value": "130133",
                            "label": "赵县",
                            "area": "670.192"
                        }
                    ]
                },
                {
                    "value": "130600",
                    "label": "保定市",
                    "children": [
                        {
                            "value": "130630",
                            "label": "涞源县",
                            "area": "2447.23"
                        },
                        {
                            "value": "130628",
                            "label": "高阳县",
                            "area": "493.891"
                        },
                        {
                            "value": "130636",
                            "label": "顺平县",
                            "area": "713.39"
                        },
                        {
                            "value": "130632",
                            "label": "安新县",
                            "area": "727.651"
                        },
                        {
                            "value": "130633",
                            "label": "易县",
                            "area": "2536.841"
                        },
                        {
                            "value": "130609",
                            "label": "徐水区",
                            "area": "730.047"
                        },
                        {
                            "value": "130602",
                            "label": "竞秀区",
                            "area": "174.508"
                        },
                        {
                            "value": "130683",
                            "label": "安国市",
                            "area": "486.423"
                        },
                        {
                            "value": "130682",
                            "label": "定州市",
                            "area": "1274.958"
                        },
                        {
                            "value": "130606",
                            "label": "莲池区",
                            "area": "177.306"
                        },
                        {
                            "value": "130623",
                            "label": "涞水县",
                            "area": "1648.888"
                        },
                        {
                            "value": "130681",
                            "label": "涿州市",
                            "area": "748.97"
                        },
                        {
                            "value": "130635",
                            "label": "蠡县",
                            "area": "652.926"
                        },
                        {
                            "value": "130637",
                            "label": "博野县",
                            "area": "330.462"
                        },
                        {
                            "value": "130624",
                            "label": "阜平县",
                            "area": "2479.06"
                        },
                        {
                            "value": "130631",
                            "label": "望都县",
                            "area": "368.84"
                        },
                        {
                            "value": "130627",
                            "label": "唐县",
                            "area": "1418.389"
                        },
                        {
                            "value": "130634",
                            "label": "曲阳县",
                            "area": "1074.84"
                        },
                        {
                            "value": "130684",
                            "label": "高碑店市",
                            "area": "679.082"
                        },
                        {
                            "value": "130629",
                            "label": "容城县",
                            "area": "313.788"
                        },
                        {
                            "value": "130626",
                            "label": "定兴县",
                            "area": "709.652"
                        },
                        {
                            "value": "130638",
                            "label": "雄县",
                            "area": "516.481"
                        },
                        {
                            "value": "130608",
                            "label": "清苑区",
                            "area": "861.123"
                        },
                        {
                            "value": "130607",
                            "label": "满城区",
                            "area": "632.245"
                        }
                    ]
                },
                {
                    "value": "130800",
                    "label": "承德市",
                    "children": [
                        {
                            "value": "130828",
                            "label": "围场满族蒙古族自治县",
                            "area": "9017.831"
                        },
                        {
                            "value": "130826",
                            "label": "丰宁满族自治县",
                            "area": "8730.921"
                        },
                        {
                            "value": "130825",
                            "label": "隆化县",
                            "area": "5443.976"
                        },
                        {
                            "value": "130827",
                            "label": "宽城满族自治县",
                            "area": "1931.589"
                        },
                        {
                            "value": "130804",
                            "label": "鹰手营子矿区",
                            "area": "150.608"
                        },
                        {
                            "value": "130822",
                            "label": "兴隆县",
                            "area": "3121.991"
                        },
                        {
                            "value": "130881",
                            "label": "平泉市",
                            "area": "3281.267"
                        },
                        {
                            "value": "130824",
                            "label": "滦平县",
                            "area": "2995.872"
                        },
                        {
                            "value": "130803",
                            "label": "双滦区",
                            "area": "456.86"
                        },
                        {
                            "value": "130802",
                            "label": "双桥区",
                            "area": "628.724"
                        },
                        {
                            "value": "130821",
                            "label": "承德县",
                            "area": "3694.9"
                        }
                    ]
                },
                {
                    "value": "130500",
                    "label": "邢台市",
                    "children": [
                        {
                            "value": "130524",
                            "label": "柏乡县",
                            "area": "262.533"
                        },
                        {
                            "value": "130581",
                            "label": "南宫市",
                            "area": "854.784"
                        },
                        {
                            "value": "130522",
                            "label": "临城县",
                            "area": "778.452"
                        },
                        {
                            "value": "130530",
                            "label": "新河县",
                            "area": "362.491"
                        },
                        {
                            "value": "130506",
                            "label": "南和区",
                            "area": "407.179"
                        },
                        {
                            "value": "130523",
                            "label": "内丘县",
                            "area": "781.62"
                        },
                        {
                            "value": "130534",
                            "label": "清河县",
                            "area": "503.097"
                        },
                        {
                            "value": "130531",
                            "label": "广宗县",
                            "area": "507.83"
                        },
                        {
                            "value": "130582",
                            "label": "沙河市",
                            "area": "971.055"
                        },
                        {
                            "value": "130532",
                            "label": "平乡县",
                            "area": "401.881"
                        },
                        {
                            "value": "130528",
                            "label": "宁晋县",
                            "area": "1113.76"
                        },
                        {
                            "value": "130505",
                            "label": "任泽区",
                            "area": "427.454"
                        },
                        {
                            "value": "130529",
                            "label": "巨鹿县",
                            "area": "637.748"
                        },
                        {
                            "value": "130525",
                            "label": "隆尧县",
                            "area": "749.11"
                        },
                        {
                            "value": "130502",
                            "label": "襄都区",
                            "area": "224.883"
                        },
                        {
                            "value": "130503",
                            "label": "信都区",
                            "area": "1892.753"
                        },
                        {
                            "value": "130533",
                            "label": "威县",
                            "area": "1011.829"
                        },
                        {
                            "value": "130535",
                            "label": "临西县",
                            "area": "542.837"
                        }
                    ]
                },
                {
                    "value": "131100",
                    "label": "衡水市",
                    "children": [
                        {
                            "value": "131121",
                            "label": "枣强县",
                            "area": "898.007"
                        },
                        {
                            "value": "131102",
                            "label": "桃城区",
                            "area": "604.441"
                        },
                        {
                            "value": "131182",
                            "label": "深州市",
                            "area": "1248.42"
                        },
                        {
                            "value": "131128",
                            "label": "阜城县",
                            "area": "692.114"
                        },
                        {
                            "value": "131124",
                            "label": "饶阳县",
                            "area": "573.003"
                        },
                        {
                            "value": "131125",
                            "label": "安平县",
                            "area": "495.952"
                        },
                        {
                            "value": "131103",
                            "label": "冀州区",
                            "area": "929.342"
                        },
                        {
                            "value": "131127",
                            "label": "景县",
                            "area": "1187.948"
                        },
                        {
                            "value": "131126",
                            "label": "故城县",
                            "area": "936.803"
                        },
                        {
                            "value": "131122",
                            "label": "武邑县",
                            "area": "829.729"
                        },
                        {
                            "value": "131123",
                            "label": "武强县",
                            "area": "439.058"
                        }
                    ]
                },
                {
                    "value": "130900",
                    "label": "沧州市",
                    "children": [
                        {
                            "value": "130982",
                            "label": "任丘市",
                            "area": "1035.81"
                        },
                        {
                            "value": "130923",
                            "label": "东光县",
                            "area": "707.365"
                        },
                        {
                            "value": "130903",
                            "label": "运河区",
                            "area": "119.131"
                        },
                        {
                            "value": "130924",
                            "label": "海兴县",
                            "area": "866.179"
                        },
                        {
                            "value": "130927",
                            "label": "南皮县",
                            "area": "792.868"
                        },
                        {
                            "value": "130929",
                            "label": "献县",
                            "area": "1171.365"
                        },
                        {
                            "value": "130928",
                            "label": "吴桥县",
                            "area": "581.886"
                        },
                        {
                            "value": "130983",
                            "label": "黄骅市",
                            "area": "2403.529"
                        },
                        {
                            "value": "130922",
                            "label": "青县",
                            "area": "988.014"
                        },
                        {
                            "value": "130984",
                            "label": "河间市",
                            "area": "1328.533"
                        },
                        {
                            "value": "130926",
                            "label": "肃宁县",
                            "area": "515.665"
                        },
                        {
                            "value": "130925",
                            "label": "盐山县",
                            "area": "796.632"
                        },
                        {
                            "value": "130930",
                            "label": "孟村回族自治县",
                            "area": "386.929"
                        },
                        {
                            "value": "130921",
                            "label": "沧县",
                            "area": "1540.545"
                        },
                        {
                            "value": "130902",
                            "label": "新华区",
                            "area": "74.046"
                        },
                        {
                            "value": "130981",
                            "label": "泊头市",
                            "area": "1014.983"
                        }
                    ]
                }
            ]
        },
        {
            "value": "810000",
            "label": "香港特别行政区",
            "children": [
                {
                    "value": "810000",
                    "label": "北区",
                    "children": [
                        {
                            "value": "810013",
                            "label": "北区",
                            "area": "147.912"
                        },
                        {
                            "value": "810014",
                            "label": "大埔区",
                            "area": "172.341"
                        },
                        {
                            "value": "810015",
                            "label": "西贡区",
                            "area": "154.402"
                        },
                        {
                            "value": "810016",
                            "label": "沙田区",
                            "area": "76.664"
                        },
                        {
                            "value": "810006",
                            "label": "深水埗区",
                            "area": "11.698"
                        },
                        {
                            "value": "810007",
                            "label": "九龙城区",
                            "area": "14.743"
                        },
                        {
                            "value": "810005",
                            "label": "油尖旺区",
                            "area": "12.599"
                        },
                        {
                            "value": "810008",
                            "label": "黄大仙区",
                            "area": "9.736"
                        },
                        {
                            "value": "810003",
                            "label": "东区",
                            "area": "24.354"
                        },
                        {
                            "value": "810011",
                            "label": "屯门区",
                            "area": "86.665"
                        },
                        {
                            "value": "810009",
                            "label": "观塘区",
                            "area": "14.688"
                        },
                        {
                            "value": "810018",
                            "label": "离岛区",
                            "area": "218.145"
                        },
                        {
                            "value": "810001",
                            "label": "中西区",
                            "area": "17.223"
                        },
                        {
                            "value": "810012",
                            "label": "元朗区",
                            "area": "151.573"
                        },
                        {
                            "value": "810004",
                            "label": "南区",
                            "area": "48.61"
                        },
                        {
                            "value": "810002",
                            "label": "湾仔区",
                            "area": "11.421"
                        },
                        {
                            "value": "810017",
                            "label": "葵青区",
                            "area": "35.834"
                        },
                        {
                            "value": "810010",
                            "label": "荃湾区",
                            "area": "79.983"
                        }
                    ]
                }
            ]
        },
        {
            "value": "820000",
            "label": "澳门特别行政区",
            "children": [
                {
                    "value": "820000",
                    "label": "风顺堂区",
                    "children": [
                        {
                            "value": "820005",
                            "label": "风顺堂区",
                            "area": "1.206"
                        },
                        {
                            "value": "820007",
                            "label": "路凼填海区",
                            "area": "6.697"
                        },
                        {
                            "value": "820006",
                            "label": "嘉模堂区",
                            "area": "12.185"
                        },
                        {
                            "value": "820004",
                            "label": "大堂区",
                            "area": "11.87"
                        },
                        {
                            "value": "820001",
                            "label": "花地玛堂区",
                            "area": "6.749"
                        },
                        {
                            "value": "820002",
                            "label": "花王堂区",
                            "area": "1.305"
                        },
                        {
                            "value": "820003",
                            "label": "望德堂区",
                            "area": "0.671"
                        },
                        {
                            "value": "820008",
                            "label": "圣方济各堂区",
                            "area": "12.103"
                        }
                    ]
                }
            ]
        },
        {
            "value": "710000",
            "label": "台湾省",
            "children": [
                {
                    "value": "710000",
                    "label": "台湾省",
                    "children": [
                        {
                            "value": "710000",
                            "label": "台湾省",
                            "area": "38331.879"
                        }
                    ]
                }
            ]
        },
        {
            "value": "620000",
            "label": "甘肃省",
            "children": [
                {
                    "value": "620300",
                    "label": "金昌市",
                    "children": [
                        {
                            "value": "620321",
                            "label": "永昌县",
                            "area": "4538.379"
                        },
                        {
                            "value": "620302",
                            "label": "金川区",
                            "area": "3086.62"
                        }
                    ]
                },
                {
                    "value": "620200",
                    "label": "嘉峪关市",
                    "children": [
                        {
                            "value": "620202",
                            "label": "嘉峪关市",
                            "area": "1224.687"
                        }
                    ]
                },
                {
                    "value": "620100",
                    "label": "兰州市",
                    "children": [
                        {
                            "value": "620111",
                            "label": "红古区",
                            "area": "529.43"
                        },
                        {
                            "value": "620104",
                            "label": "西固区",
                            "area": "358.615"
                        },
                        {
                            "value": "620103",
                            "label": "七里河区",
                            "area": "397.704"
                        },
                        {
                            "value": "620122",
                            "label": "皋兰县",
                            "area": "2476.62"
                        },
                        {
                            "value": "620102",
                            "label": "城关区",
                            "area": "208.093"
                        },
                        {
                            "value": "620105",
                            "label": "安宁区",
                            "area": "82.152"
                        },
                        {
                            "value": "620123",
                            "label": "榆中县",
                            "area": "3299.558"
                        },
                        {
                            "value": "620121",
                            "label": "永登县",
                            "area": "5775.523"
                        }
                    ]
                },
                {
                    "value": "620900",
                    "label": "酒泉市",
                    "children": [
                        {
                            "value": "620982",
                            "label": "敦煌市",
                            "area": "26737.733"
                        },
                        {
                            "value": "620981",
                            "label": "玉门市",
                            "area": "13318.51"
                        },
                        {
                            "value": "620902",
                            "label": "肃州区",
                            "area": "3363.538"
                        },
                        {
                            "value": "620923",
                            "label": "肃北蒙古族自治县",
                            "area": "55241.673"
                        },
                        {
                            "value": "620921",
                            "label": "金塔县",
                            "area": "16248.895"
                        },
                        {
                            "value": "620924",
                            "label": "阿克塞哈萨克族自治县",
                            "area": "29197.259"
                        },
                        {
                            "value": "620922",
                            "label": "瓜州县",
                            "area": "23582.341"
                        }
                    ]
                },
                {
                    "value": "620800",
                    "label": "平凉市",
                    "children": [
                        {
                            "value": "620822",
                            "label": "灵台县",
                            "area": "1966.127"
                        },
                        {
                            "value": "620802",
                            "label": "崆峒区",
                            "area": "1936.056"
                        },
                        {
                            "value": "620825",
                            "label": "庄浪县",
                            "area": "1507.683"
                        },
                        {
                            "value": "620823",
                            "label": "崇信县",
                            "area": "854.82"
                        },
                        {
                            "value": "620881",
                            "label": "华亭市",
                            "area": "1196.555"
                        },
                        {
                            "value": "620826",
                            "label": "静宁县",
                            "area": "2185.824"
                        },
                        {
                            "value": "620821",
                            "label": "泾川县",
                            "area": "1468.443"
                        }
                    ]
                },
                {
                    "value": "620400",
                    "label": "白银市",
                    "children": [
                        {
                            "value": "620421",
                            "label": "靖远县",
                            "area": "5621.693"
                        },
                        {
                            "value": "620403",
                            "label": "平川区",
                            "area": "1976.798"
                        },
                        {
                            "value": "620402",
                            "label": "白银区",
                            "area": "1351.21"
                        },
                        {
                            "value": "620422",
                            "label": "会宁县",
                            "area": "5664.299"
                        },
                        {
                            "value": "620423",
                            "label": "景泰县",
                            "area": "5484.806"
                        }
                    ]
                },
                {
                    "value": "621200",
                    "label": "陇南市",
                    "children": [
                        {
                            "value": "621226",
                            "label": "礼县",
                            "area": "4268.815"
                        },
                        {
                            "value": "621223",
                            "label": "宕昌县",
                            "area": "3320.582"
                        },
                        {
                            "value": "621228",
                            "label": "两当县",
                            "area": "1426.999"
                        },
                        {
                            "value": "621202",
                            "label": "武都区",
                            "area": "4653.331"
                        },
                        {
                            "value": "621227",
                            "label": "徽县",
                            "area": "2698.106"
                        },
                        {
                            "value": "621222",
                            "label": "文县",
                            "area": "5005.476"
                        },
                        {
                            "value": "621225",
                            "label": "西和县",
                            "area": "1847.511"
                        },
                        {
                            "value": "621221",
                            "label": "成县",
                            "area": "1689.748"
                        },
                        {
                            "value": "621224",
                            "label": "康县",
                            "area": "2973.392"
                        }
                    ]
                },
                {
                    "value": "620700",
                    "label": "张掖市",
                    "children": [
                        {
                            "value": "620724",
                            "label": "高台县",
                            "area": "4359.936"
                        },
                        {
                            "value": "620721",
                            "label": "肃南裕固族自治县",
                            "area": "20556.405"
                        },
                        {
                            "value": "620725",
                            "label": "山丹县",
                            "area": "4693.988"
                        },
                        {
                            "value": "620722",
                            "label": "民乐县",
                            "area": "2446.323"
                        },
                        {
                            "value": "620702",
                            "label": "甘州区",
                            "area": "3693.545"
                        },
                        {
                            "value": "620723",
                            "label": "临泽县",
                            "area": "2716.391"
                        }
                    ]
                },
                {
                    "value": "623000",
                    "label": "甘南藏族自治州",
                    "children": [
                        {
                            "value": "623021",
                            "label": "临潭县",
                            "area": "1385.195"
                        },
                        {
                            "value": "623022",
                            "label": "卓尼县",
                            "area": "5151.991"
                        },
                        {
                            "value": "623023",
                            "label": "舟曲县",
                            "area": "3018.432"
                        },
                        {
                            "value": "623001",
                            "label": "合作市",
                            "area": "2152.192"
                        },
                        {
                            "value": "623025",
                            "label": "玛曲县",
                            "area": "9650.399"
                        },
                        {
                            "value": "623027",
                            "label": "夏河县",
                            "area": "6273.195"
                        },
                        {
                            "value": "623024",
                            "label": "迭部县",
                            "area": "4715.223"
                        },
                        {
                            "value": "623026",
                            "label": "碌曲县",
                            "area": "4315.151"
                        }
                    ]
                },
                {
                    "value": "622900",
                    "label": "临夏回族自治州",
                    "children": [
                        {
                            "value": "622926",
                            "label": "东乡族自治县",
                            "area": "1507.161"
                        },
                        {
                            "value": "622921",
                            "label": "临夏县",
                            "area": "1149.483"
                        },
                        {
                            "value": "622901",
                            "label": "临夏市",
                            "area": "99.202"
                        },
                        {
                            "value": "622927",
                            "label": "积石山保安族东乡族撒拉族自治县",
                            "area": "912.931"
                        },
                        {
                            "value": "622923",
                            "label": "永靖县",
                            "area": "1895.815"
                        },
                        {
                            "value": "622925",
                            "label": "和政县",
                            "area": "1155.41"
                        },
                        {
                            "value": "622924",
                            "label": "广河县",
                            "area": "524.877"
                        },
                        {
                            "value": "622922",
                            "label": "康乐县",
                            "area": "887.488"
                        }
                    ]
                },
                {
                    "value": "621100",
                    "label": "定西市",
                    "children": [
                        {
                            "value": "621126",
                            "label": "岷县",
                            "area": "3581.358"
                        },
                        {
                            "value": "621124",
                            "label": "临洮县",
                            "area": "2851.162"
                        },
                        {
                            "value": "621123",
                            "label": "渭源县",
                            "area": "2047.153"
                        },
                        {
                            "value": "621125",
                            "label": "漳县",
                            "area": "2171.139"
                        },
                        {
                            "value": "621102",
                            "label": "安定区",
                            "area": "3643.638"
                        },
                        {
                            "value": "621122",
                            "label": "陇西县",
                            "area": "2424.048"
                        },
                        {
                            "value": "621121",
                            "label": "通渭县",
                            "area": "2903.428"
                        }
                    ]
                },
                {
                    "value": "620500",
                    "label": "天水市",
                    "children": [
                        {
                            "value": "620522",
                            "label": "秦安县",
                            "area": "1606.699"
                        },
                        {
                            "value": "620523",
                            "label": "甘谷县",
                            "area": "1575.943"
                        },
                        {
                            "value": "620502",
                            "label": "秦州区",
                            "area": "2379.603"
                        },
                        {
                            "value": "620503",
                            "label": "麦积区",
                            "area": "3487.745"
                        },
                        {
                            "value": "620521",
                            "label": "清水县",
                            "area": "1984.52"
                        },
                        {
                            "value": "620524",
                            "label": "武山县",
                            "area": "1991.942"
                        },
                        {
                            "value": "620525",
                            "label": "张家川回族自治县",
                            "area": "1249.201"
                        }
                    ]
                },
                {
                    "value": "621000",
                    "label": "庆阳市",
                    "children": [
                        {
                            "value": "621025",
                            "label": "正宁县",
                            "area": "1338.369"
                        },
                        {
                            "value": "621022",
                            "label": "环县",
                            "area": "9213.937"
                        },
                        {
                            "value": "621023",
                            "label": "华池县",
                            "area": "3802.068"
                        },
                        {
                            "value": "621027",
                            "label": "镇原县",
                            "area": "3495.984"
                        },
                        {
                            "value": "621026",
                            "label": "宁县",
                            "area": "2650.736"
                        },
                        {
                            "value": "621021",
                            "label": "庆城县",
                            "area": "2692.491"
                        },
                        {
                            "value": "621024",
                            "label": "合水县",
                            "area": "2924.323"
                        },
                        {
                            "value": "621002",
                            "label": "西峰区",
                            "area": "996.846"
                        }
                    ]
                },
                {
                    "value": "620600",
                    "label": "武威市",
                    "children": [
                        {
                            "value": "620602",
                            "label": "凉州区",
                            "area": "4976.282"
                        },
                        {
                            "value": "620622",
                            "label": "古浪县",
                            "area": "5055.679"
                        },
                        {
                            "value": "620621",
                            "label": "民勤县",
                            "area": "15815.687"
                        },
                        {
                            "value": "620623",
                            "label": "天祝藏族自治县",
                            "area": "6625.567"
                        }
                    ]
                }
            ]
        },
        {
            "value": "510000",
            "label": "四川省",
            "children": [
                {
                    "value": "511300",
                    "label": "南充市",
                    "children": [
                        {
                            "value": "511304",
                            "label": "嘉陵区",
                            "area": "1171.88"
                        },
                        {
                            "value": "511323",
                            "label": "蓬安县",
                            "area": "1331.104"
                        },
                        {
                            "value": "511322",
                            "label": "营山县",
                            "area": "1633.235"
                        },
                        {
                            "value": "511325",
                            "label": "西充县",
                            "area": "1107.794"
                        },
                        {
                            "value": "511321",
                            "label": "南部县",
                            "area": "2212.922"
                        },
                        {
                            "value": "511324",
                            "label": "仪陇县",
                            "area": "1792.388"
                        },
                        {
                            "value": "511302",
                            "label": "顺庆区",
                            "area": "539.872"
                        },
                        {
                            "value": "511303",
                            "label": "高坪区",
                            "area": "814.565"
                        },
                        {
                            "value": "511381",
                            "label": "阆中市",
                            "area": "1877.222"
                        }
                    ]
                },
                {
                    "value": "510700",
                    "label": "绵阳市",
                    "children": [
                        {
                            "value": "510781",
                            "label": "江油市",
                            "area": "2720.483"
                        },
                        {
                            "value": "510725",
                            "label": "梓潼县",
                            "area": "1445.761"
                        },
                        {
                            "value": "510704",
                            "label": "游仙区",
                            "area": "1014.278"
                        },
                        {
                            "value": "510722",
                            "label": "三台县",
                            "area": "2658.696"
                        },
                        {
                            "value": "510723",
                            "label": "盐亭县",
                            "area": "1644.78"
                        },
                        {
                            "value": "510703",
                            "label": "涪城区",
                            "area": "556.305"
                        },
                        {
                            "value": "510727",
                            "label": "平武县",
                            "area": "5956.071"
                        },
                        {
                            "value": "510705",
                            "label": "安州区",
                            "area": "1187.455"
                        },
                        {
                            "value": "510726",
                            "label": "北川羌族自治县",
                            "area": "3076.687"
                        }
                    ]
                },
                {
                    "value": "510600",
                    "label": "德阳市",
                    "children": [
                        {
                            "value": "510623",
                            "label": "中江县",
                            "area": "2197.789"
                        },
                        {
                            "value": "510681",
                            "label": "广汉市",
                            "area": "547.694"
                        },
                        {
                            "value": "510603",
                            "label": "旌阳区",
                            "area": "649.867"
                        },
                        {
                            "value": "510682",
                            "label": "什邡市",
                            "area": "820.96"
                        },
                        {
                            "value": "510604",
                            "label": "罗江区",
                            "area": "446.02"
                        },
                        {
                            "value": "510683",
                            "label": "绵竹市",
                            "area": "1242.933"
                        }
                    ]
                },
                {
                    "value": "511900",
                    "label": "巴中市",
                    "children": [
                        {
                            "value": "511902",
                            "label": "巴州区",
                            "area": "1399.61"
                        },
                        {
                            "value": "511922",
                            "label": "南江县",
                            "area": "3394.253"
                        },
                        {
                            "value": "511923",
                            "label": "平昌县",
                            "area": "2227.112"
                        },
                        {
                            "value": "511921",
                            "label": "通江县",
                            "area": "4104.627"
                        },
                        {
                            "value": "511903",
                            "label": "恩阳区",
                            "area": "1167.619"
                        }
                    ]
                },
                {
                    "value": "510800",
                    "label": "广元市",
                    "children": [
                        {
                            "value": "510811",
                            "label": "昭化区",
                            "area": "1427.832"
                        },
                        {
                            "value": "510812",
                            "label": "朝天区",
                            "area": "1613.482"
                        },
                        {
                            "value": "510824",
                            "label": "苍溪县",
                            "area": "2331.825"
                        },
                        {
                            "value": "510823",
                            "label": "剑阁县",
                            "area": "3203.168"
                        },
                        {
                            "value": "510821",
                            "label": "旺苍县",
                            "area": "2995.036"
                        },
                        {
                            "value": "510822",
                            "label": "青川县",
                            "area": "3214.909"
                        },
                        {
                            "value": "510802",
                            "label": "利州区",
                            "area": "1532.312"
                        }
                    ]
                },
                {
                    "value": "510100",
                    "label": "成都市",
                    "children": [
                        {
                            "value": "510182",
                            "label": "彭州市",
                            "area": "1420.911"
                        },
                        {
                            "value": "510181",
                            "label": "都江堰市",
                            "area": "1203.686"
                        },
                        {
                            "value": "510184",
                            "label": "崇州市",
                            "area": "1090.688"
                        },
                        {
                            "value": "510131",
                            "label": "蒲江县",
                            "area": "581.843"
                        },
                        {
                            "value": "510113",
                            "label": "青白江区",
                            "area": "370.716"
                        },
                        {
                            "value": "510129",
                            "label": "大邑县",
                            "area": "1279.594"
                        },
                        {
                            "value": "510185",
                            "label": "简阳市",
                            "area": "2215.045"
                        },
                        {
                            "value": "510118",
                            "label": "新津区",
                            "area": "330.882"
                        },
                        {
                            "value": "510183",
                            "label": "邛崃市",
                            "area": "1377.463"
                        },
                        {
                            "value": "510121",
                            "label": "金堂县",
                            "area": "1158.156"
                        },
                        {
                            "value": "510115",
                            "label": "温江区",
                            "area": "276.984"
                        },
                        {
                            "value": "510107",
                            "label": "武侯区",
                            "area": "127.232"
                        },
                        {
                            "value": "510116",
                            "label": "双流区",
                            "area": "1063.684"
                        },
                        {
                            "value": "510117",
                            "label": "郫都区",
                            "area": "433.579"
                        },
                        {
                            "value": "510106",
                            "label": "金牛区",
                            "area": "109.612"
                        },
                        {
                            "value": "510112",
                            "label": "龙泉驿区",
                            "area": "559.343"
                        },
                        {
                            "value": "510114",
                            "label": "新都区",
                            "area": "499.286"
                        },
                        {
                            "value": "510108",
                            "label": "成华区",
                            "area": "108.283"
                        },
                        {
                            "value": "510104",
                            "label": "锦江区",
                            "area": "61.016"
                        },
                        {
                            "value": "510105",
                            "label": "青羊区",
                            "area": "66.258"
                        }
                    ]
                },
                {
                    "value": "511600",
                    "label": "广安市",
                    "children": [
                        {
                            "value": "511622",
                            "label": "武胜县",
                            "area": "956.236"
                        },
                        {
                            "value": "511623",
                            "label": "邻水县",
                            "area": "1911.458"
                        },
                        {
                            "value": "511602",
                            "label": "广安区",
                            "area": "1026.242"
                        },
                        {
                            "value": "511681",
                            "label": "华蓥市",
                            "area": "465.421"
                        },
                        {
                            "value": "511603",
                            "label": "前锋区",
                            "area": "503.224"
                        },
                        {
                            "value": "511621",
                            "label": "岳池县",
                            "area": "1478.898"
                        }
                    ]
                },
                {
                    "value": "511700",
                    "label": "达州市",
                    "children": [
                        {
                            "value": "511724",
                            "label": "大竹县",
                            "area": "2084.542"
                        },
                        {
                            "value": "511725",
                            "label": "渠县",
                            "area": "2014.594"
                        },
                        {
                            "value": "511722",
                            "label": "宣汉县",
                            "area": "4271.085"
                        },
                        {
                            "value": "511723",
                            "label": "开江县",
                            "area": "1030.979"
                        },
                        {
                            "value": "511781",
                            "label": "万源市",
                            "area": "4057.784"
                        },
                        {
                            "value": "511703",
                            "label": "达川区",
                            "area": "2250.963"
                        },
                        {
                            "value": "511702",
                            "label": "通川区",
                            "area": "878.337"
                        }
                    ]
                },
                {
                    "value": "511100",
                    "label": "乐山市",
                    "children": [
                        {
                            "value": "511112",
                            "label": "五通桥区",
                            "area": "464.423"
                        },
                        {
                            "value": "511111",
                            "label": "沙湾区",
                            "area": "603.504"
                        },
                        {
                            "value": "511126",
                            "label": "夹江县",
                            "area": "740.496"
                        },
                        {
                            "value": "511124",
                            "label": "井研县",
                            "area": "842.165"
                        },
                        {
                            "value": "511113",
                            "label": "金口河区",
                            "area": "601.093"
                        },
                        {
                            "value": "511123",
                            "label": "犍为县",
                            "area": "1370.113"
                        },
                        {
                            "value": "511132",
                            "label": "峨边彝族自治县",
                            "area": "2376.982"
                        },
                        {
                            "value": "511129",
                            "label": "沐川县",
                            "area": "1415.568"
                        },
                        {
                            "value": "511133",
                            "label": "马边彝族自治县",
                            "area": "2295.891"
                        },
                        {
                            "value": "511102",
                            "label": "市中区",
                            "area": "840.19"
                        },
                        {
                            "value": "511181",
                            "label": "峨眉山市",
                            "area": "1189.475"
                        }
                    ]
                },
                {
                    "value": "510900",
                    "label": "遂宁市",
                    "children": [
                        {
                            "value": "510904",
                            "label": "安居区",
                            "area": "1269.568"
                        },
                        {
                            "value": "510921",
                            "label": "蓬溪县",
                            "area": "1253.722"
                        },
                        {
                            "value": "510923",
                            "label": "大英县",
                            "area": "701.21"
                        },
                        {
                            "value": "510903",
                            "label": "船山区",
                            "area": "605.251"
                        },
                        {
                            "value": "510981",
                            "label": "射洪市",
                            "area": "1497.528"
                        }
                    ]
                },
                {
                    "value": "512000",
                    "label": "资阳市",
                    "children": [
                        {
                            "value": "512002",
                            "label": "雁江区",
                            "area": "1634.859"
                        },
                        {
                            "value": "512022",
                            "label": "乐至县",
                            "area": "1424.418"
                        },
                        {
                            "value": "512021",
                            "label": "安岳县",
                            "area": "2691.036"
                        }
                    ]
                },
                {
                    "value": "510300",
                    "label": "自贡市",
                    "children": [
                        {
                            "value": "510321",
                            "label": "荣县",
                            "area": "1604.242"
                        },
                        {
                            "value": "510304",
                            "label": "大安区",
                            "area": "399.781"
                        },
                        {
                            "value": "510311",
                            "label": "沿滩区",
                            "area": "468.192"
                        },
                        {
                            "value": "510302",
                            "label": "自流井区",
                            "area": "154.47"
                        },
                        {
                            "value": "510322",
                            "label": "富顺县",
                            "area": "1333.529"
                        },
                        {
                            "value": "510303",
                            "label": "贡井区",
                            "area": "406.954"
                        }
                    ]
                },
                {
                    "value": "511400",
                    "label": "眉山市",
                    "children": [
                        {
                            "value": "511421",
                            "label": "仁寿县",
                            "area": "2612.044"
                        },
                        {
                            "value": "511424",
                            "label": "丹棱县",
                            "area": "459.943"
                        },
                        {
                            "value": "511423",
                            "label": "洪雅县",
                            "area": "1940.691"
                        },
                        {
                            "value": "511403",
                            "label": "彭山区",
                            "area": "462.489"
                        },
                        {
                            "value": "511425",
                            "label": "青神县",
                            "area": "387.373"
                        },
                        {
                            "value": "511402",
                            "label": "东坡区",
                            "area": "1330.569"
                        }
                    ]
                },
                {
                    "value": "513400",
                    "label": "凉山彝族自治州",
                    "children": [
                        {
                            "value": "513434",
                            "label": "越西县",
                            "area": "2270.655"
                        },
                        {
                            "value": "513423",
                            "label": "盐源县",
                            "area": "8416.785"
                        },
                        {
                            "value": "513437",
                            "label": "雷波县",
                            "area": "2833.103"
                        },
                        {
                            "value": "513422",
                            "label": "木里藏族自治县",
                            "area": "13199.658"
                        },
                        {
                            "value": "513432",
                            "label": "喜德县",
                            "area": "2101.181"
                        },
                        {
                            "value": "513425",
                            "label": "会理市",
                            "area": "4542.609"
                        },
                        {
                            "value": "513435",
                            "label": "甘洛县",
                            "area": "2140.159"
                        },
                        {
                            "value": "513428",
                            "label": "普格县",
                            "area": "1902.412"
                        },
                        {
                            "value": "513426",
                            "label": "会东县",
                            "area": "3227.151"
                        },
                        {
                            "value": "513436",
                            "label": "美姑县",
                            "area": "2512.239"
                        },
                        {
                            "value": "513433",
                            "label": "冕宁县",
                            "area": "4395.003"
                        },
                        {
                            "value": "513430",
                            "label": "金阳县",
                            "area": "1586.759"
                        },
                        {
                            "value": "513429",
                            "label": "布拖县",
                            "area": "1691.489"
                        },
                        {
                            "value": "513424",
                            "label": "德昌县",
                            "area": "2285.021"
                        },
                        {
                            "value": "513427",
                            "label": "宁南县",
                            "area": "1681.566"
                        },
                        {
                            "value": "513401",
                            "label": "西昌市",
                            "area": "2896.978"
                        },
                        {
                            "value": "513431",
                            "label": "昭觉县",
                            "area": "2554.611"
                        }
                    ]
                },
                {
                    "value": "510500",
                    "label": "泸州市",
                    "children": [
                        {
                            "value": "510503",
                            "label": "纳溪区",
                            "area": "1152.921"
                        },
                        {
                            "value": "510521",
                            "label": "泸县",
                            "area": "1527.309"
                        },
                        {
                            "value": "510504",
                            "label": "龙马潭区",
                            "area": "332.723"
                        },
                        {
                            "value": "510524",
                            "label": "叙永县",
                            "area": "2971.579"
                        },
                        {
                            "value": "510525",
                            "label": "古蔺县",
                            "area": "3186.586"
                        },
                        {
                            "value": "510502",
                            "label": "江阳区",
                            "area": "648.637"
                        },
                        {
                            "value": "510522",
                            "label": "合江县",
                            "area": "2416.069"
                        }
                    ]
                },
                {
                    "value": "511000",
                    "label": "内江市",
                    "children": [
                        {
                            "value": "511011",
                            "label": "东兴区",
                            "area": "1181.404"
                        },
                        {
                            "value": "511025",
                            "label": "资中县",
                            "area": "1734.573"
                        },
                        {
                            "value": "511024",
                            "label": "威远县",
                            "area": "1288.684"
                        },
                        {
                            "value": "511083",
                            "label": "隆昌市",
                            "area": "794.667"
                        },
                        {
                            "value": "511002",
                            "label": "市中区",
                            "area": "384.45"
                        }
                    ]
                },
                {
                    "value": "511500",
                    "label": "宜宾市",
                    "children": [
                        {
                            "value": "511503",
                            "label": "南溪区",
                            "area": "674.524"
                        },
                        {
                            "value": "511524",
                            "label": "长宁县",
                            "area": "942.963"
                        },
                        {
                            "value": "511525",
                            "label": "高县",
                            "area": "1323.426"
                        },
                        {
                            "value": "511502",
                            "label": "翠屏区",
                            "area": "1521.469"
                        },
                        {
                            "value": "511526",
                            "label": "珙县",
                            "area": "1147.309"
                        },
                        {
                            "value": "511523",
                            "label": "江安县",
                            "area": "947.018"
                        },
                        {
                            "value": "511527",
                            "label": "筠连县",
                            "area": "1253.683"
                        },
                        {
                            "value": "511528",
                            "label": "兴文县",
                            "area": "1377.177"
                        },
                        {
                            "value": "511529",
                            "label": "屏山县",
                            "area": "1508.392"
                        },
                        {
                            "value": "511504",
                            "label": "叙州区",
                            "area": "2573.803"
                        }
                    ]
                },
                {
                    "value": "510400",
                    "label": "攀枝花市",
                    "children": [
                        {
                            "value": "510421",
                            "label": "米易县",
                            "area": "2108.495"
                        },
                        {
                            "value": "510403",
                            "label": "西区",
                            "area": "133.123"
                        },
                        {
                            "value": "510422",
                            "label": "盐边县",
                            "area": "3269.036"
                        },
                        {
                            "value": "510411",
                            "label": "仁和区",
                            "area": "1724.009"
                        },
                        {
                            "value": "510402",
                            "label": "东区",
                            "area": "165.517"
                        }
                    ]
                },
                {
                    "value": "513200",
                    "label": "阿坝藏族羌族自治州",
                    "children": [
                        {
                            "value": "513226",
                            "label": "金川县",
                            "area": "5370.883"
                        },
                        {
                            "value": "513233",
                            "label": "红原县",
                            "area": "8340.942"
                        },
                        {
                            "value": "513228",
                            "label": "黑水县",
                            "area": "4120.19"
                        },
                        {
                            "value": "513231",
                            "label": "阿坝县",
                            "area": "10120.629"
                        },
                        {
                            "value": "513201",
                            "label": "马尔康市",
                            "area": "6614.713"
                        },
                        {
                            "value": "513232",
                            "label": "若尔盖县",
                            "area": "10332.532"
                        },
                        {
                            "value": "513221",
                            "label": "汶川县",
                            "area": "4079.799"
                        },
                        {
                            "value": "513225",
                            "label": "九寨沟县",
                            "area": "5273.624"
                        },
                        {
                            "value": "513222",
                            "label": "理县",
                            "area": "4340.996"
                        },
                        {
                            "value": "513224",
                            "label": "松潘县",
                            "area": "8320.606"
                        },
                        {
                            "value": "513223",
                            "label": "茂县",
                            "area": "3879.831"
                        },
                        {
                            "value": "513227",
                            "label": "小金县",
                            "area": "5557.316"
                        },
                        {
                            "value": "513230",
                            "label": "壤塘县",
                            "area": "6638.109"
                        }
                    ]
                },
                {
                    "value": "511800",
                    "label": "雅安市",
                    "children": [
                        {
                            "value": "511827",
                            "label": "宝兴县",
                            "area": "3120.446"
                        },
                        {
                            "value": "511826",
                            "label": "芦山县",
                            "area": "1194.716"
                        },
                        {
                            "value": "511825",
                            "label": "天全县",
                            "area": "2379.9"
                        },
                        {
                            "value": "511802",
                            "label": "雨城区",
                            "area": "1062.186"
                        },
                        {
                            "value": "511823",
                            "label": "汉源县",
                            "area": "2180.467"
                        },
                        {
                            "value": "511824",
                            "label": "石棉县",
                            "area": "2673.266"
                        },
                        {
                            "value": "511822",
                            "label": "荥经县",
                            "area": "1773.743"
                        },
                        {
                            "value": "511803",
                            "label": "名山区",
                            "area": "611.935"
                        }
                    ]
                },
                {
                    "value": "513300",
                    "label": "甘孜藏族自治州",
                    "children": [
                        {
                            "value": "513330",
                            "label": "德格县",
                            "area": "11428.05"
                        },
                        {
                            "value": "513328",
                            "label": "甘孜县",
                            "area": "6832.751"
                        },
                        {
                            "value": "513332",
                            "label": "石渠县",
                            "area": "22385.392"
                        },
                        {
                            "value": "513331",
                            "label": "白玉县",
                            "area": "10243.567"
                        },
                        {
                            "value": "513327",
                            "label": "炉霍县",
                            "area": "4480.197"
                        },
                        {
                            "value": "513329",
                            "label": "新龙县",
                            "area": "9386.214"
                        },
                        {
                            "value": "513334",
                            "label": "理塘县",
                            "area": "14030.906"
                        },
                        {
                            "value": "513301",
                            "label": "康定市",
                            "area": "11593.727"
                        },
                        {
                            "value": "513326",
                            "label": "道孚县",
                            "area": "7030.549"
                        },
                        {
                            "value": "513335",
                            "label": "巴塘县",
                            "area": "7705.329"
                        },
                        {
                            "value": "513337",
                            "label": "稻城县",
                            "area": "7080.553"
                        },
                        {
                            "value": "513325",
                            "label": "雅江县",
                            "area": "7440.121"
                        },
                        {
                            "value": "513336",
                            "label": "乡城县",
                            "area": "4901.855"
                        },
                        {
                            "value": "513324",
                            "label": "九龙县",
                            "area": "6777.111"
                        },
                        {
                            "value": "513338",
                            "label": "得荣县",
                            "area": "2917.139"
                        },
                        {
                            "value": "513323",
                            "label": "丹巴县",
                            "area": "4499.111"
                        },
                        {
                            "value": "513333",
                            "label": "色达县",
                            "area": "8804.19"
                        },
                        {
                            "value": "513322",
                            "label": "泸定县",
                            "area": "2159.476"
                        }
                    ]
                }
            ]
        },
        {
            "value": "120000",
            "label": "天津市",
            "children": [
                {
                    "value": "120100",
                    "label": "天津城区",
                    "children": [
                        {
                            "value": "120110",
                            "label": "东丽区",
                            "area": "475.863"
                        },
                        {
                            "value": "120115",
                            "label": "宝坻区",
                            "area": "1509.553"
                        },
                        {
                            "value": "120116",
                            "label": "滨海新区",
                            "area": "2696.128"
                        },
                        {
                            "value": "120105",
                            "label": "河北区",
                            "area": "29.521"
                        },
                        {
                            "value": "120118",
                            "label": "静海区",
                            "area": "1475.819"
                        },
                        {
                            "value": "120102",
                            "label": "河东区",
                            "area": "42.074"
                        },
                        {
                            "value": "120101",
                            "label": "和平区",
                            "area": "10.003"
                        },
                        {
                            "value": "120119",
                            "label": "蓟州区",
                            "area": "1590.01"
                        },
                        {
                            "value": "120114",
                            "label": "武清区",
                            "area": "1573.952"
                        },
                        {
                            "value": "120104",
                            "label": "南开区",
                            "area": "41.423"
                        },
                        {
                            "value": "120117",
                            "label": "宁河区",
                            "area": "1165.89"
                        },
                        {
                            "value": "120106",
                            "label": "红桥区",
                            "area": "22.531"
                        },
                        {
                            "value": "120113",
                            "label": "北辰区",
                            "area": "473.59"
                        },
                        {
                            "value": "120112",
                            "label": "津南区",
                            "area": "380.463"
                        },
                        {
                            "value": "120103",
                            "label": "河西区",
                            "area": "48.204"
                        },
                        {
                            "value": "120111",
                            "label": "西青区",
                            "area": "559.343"
                        }
                    ]
                }
            ]
        },
        {
            "value": "530000",
            "label": "云南省",
            "children": [
                {
                    "value": "530300",
                    "label": "曲靖市",
                    "children": [
                        {
                            "value": "530326",
                            "label": "会泽县",
                            "area": "5884.949"
                        },
                        {
                            "value": "530304",
                            "label": "马龙区",
                            "area": "1593.532"
                        },
                        {
                            "value": "530324",
                            "label": "罗平县",
                            "area": "3009.351"
                        },
                        {
                            "value": "530322",
                            "label": "陆良县",
                            "area": "1992.064"
                        },
                        {
                            "value": "530323",
                            "label": "师宗县",
                            "area": "2768.813"
                        },
                        {
                            "value": "530381",
                            "label": "宣威市",
                            "area": "6054.326"
                        },
                        {
                            "value": "530325",
                            "label": "富源县",
                            "area": "3251.371"
                        },
                        {
                            "value": "530303",
                            "label": "沾益区",
                            "area": "2812.36"
                        },
                        {
                            "value": "530302",
                            "label": "麒麟区",
                            "area": "1545.864"
                        }
                    ]
                },
                {
                    "value": "530600",
                    "label": "昭通市",
                    "children": [
                        {
                            "value": "530625",
                            "label": "永善县",
                            "area": "2778.526"
                        },
                        {
                            "value": "530626",
                            "label": "绥江县",
                            "area": "750.11"
                        },
                        {
                            "value": "530629",
                            "label": "威信县",
                            "area": "1392.096"
                        },
                        {
                            "value": "530681",
                            "label": "水富市",
                            "area": "438.415"
                        },
                        {
                            "value": "530624",
                            "label": "大关县",
                            "area": "1716.512"
                        },
                        {
                            "value": "530622",
                            "label": "巧家县",
                            "area": "3197.371"
                        },
                        {
                            "value": "530621",
                            "label": "鲁甸县",
                            "area": "1485.463"
                        },
                        {
                            "value": "530602",
                            "label": "昭阳区",
                            "area": "2163.361"
                        },
                        {
                            "value": "530627",
                            "label": "镇雄县",
                            "area": "3696.447"
                        },
                        {
                            "value": "530628",
                            "label": "彝良县",
                            "area": "2800.929"
                        },
                        {
                            "value": "530623",
                            "label": "盐津县",
                            "area": "2021.127"
                        }
                    ]
                },
                {
                    "value": "532500",
                    "label": "红河哈尼族彝族自治州",
                    "children": [
                        {
                            "value": "532527",
                            "label": "泸西县",
                            "area": "1647.156"
                        },
                        {
                            "value": "532504",
                            "label": "弥勒市",
                            "area": "3912.945"
                        },
                        {
                            "value": "532525",
                            "label": "石屏县",
                            "area": "3047.29"
                        },
                        {
                            "value": "532524",
                            "label": "建水县",
                            "area": "3776.74"
                        },
                        {
                            "value": "532502",
                            "label": "开远市",
                            "area": "1937.29"
                        },
                        {
                            "value": "532503",
                            "label": "蒙自市",
                            "area": "2162.841"
                        },
                        {
                            "value": "532501",
                            "label": "个旧市",
                            "area": "1564.194"
                        },
                        {
                            "value": "532529",
                            "label": "红河县",
                            "area": "2033.417"
                        },
                        {
                            "value": "532530",
                            "label": "金平苗族瑶族傣族自治县",
                            "area": "3609.351"
                        },
                        {
                            "value": "532523",
                            "label": "屏边苗族自治县",
                            "area": "1846.12"
                        },
                        {
                            "value": "532528",
                            "label": "元阳县",
                            "area": "2210.428"
                        },
                        {
                            "value": "532531",
                            "label": "绿春县",
                            "area": "3088.55"
                        },
                        {
                            "value": "532532",
                            "label": "河口瑶族自治县",
                            "area": "1331.579"
                        }
                    ]
                },
                {
                    "value": "530700",
                    "label": "丽江市",
                    "children": [
                        {
                            "value": "530721",
                            "label": "玉龙纳西族自治县",
                            "area": "6194.768"
                        },
                        {
                            "value": "530724",
                            "label": "宁蒗彝族自治县",
                            "area": "6026.693"
                        },
                        {
                            "value": "530722",
                            "label": "永胜县",
                            "area": "4928.208"
                        },
                        {
                            "value": "530723",
                            "label": "华坪县",
                            "area": "2142.33"
                        },
                        {
                            "value": "530702",
                            "label": "古城区",
                            "area": "1266.656"
                        }
                    ]
                },
                {
                    "value": "533300",
                    "label": "怒江傈僳族自治州",
                    "children": [
                        {
                            "value": "533324",
                            "label": "贡山独龙族怒族自治县",
                            "area": "4380.851"
                        },
                        {
                            "value": "533325",
                            "label": "兰坪白族普米族自治县",
                            "area": "4368.358"
                        },
                        {
                            "value": "533323",
                            "label": "福贡县",
                            "area": "2749.844"
                        },
                        {
                            "value": "533301",
                            "label": "泸水市",
                            "area": "3097.48"
                        }
                    ]
                },
                {
                    "value": "532800",
                    "label": "西双版纳傣族自治州",
                    "children": [
                        {
                            "value": "532801",
                            "label": "景洪市",
                            "area": "6865.304"
                        },
                        {
                            "value": "532823",
                            "label": "勐腊县",
                            "area": "6829.148"
                        },
                        {
                            "value": "532822",
                            "label": "勐海县",
                            "area": "5369.842"
                        }
                    ]
                },
                {
                    "value": "532900",
                    "label": "大理白族自治州",
                    "children": [
                        {
                            "value": "532931",
                            "label": "剑川县",
                            "area": "2237.614"
                        },
                        {
                            "value": "532932",
                            "label": "鹤庆县",
                            "area": "2363.017"
                        },
                        {
                            "value": "532930",
                            "label": "洱源县",
                            "area": "2530.017"
                        },
                        {
                            "value": "532929",
                            "label": "云龙县",
                            "area": "4368.423"
                        },
                        {
                            "value": "532924",
                            "label": "宾川县",
                            "area": "2531.732"
                        },
                        {
                            "value": "532923",
                            "label": "祥云县",
                            "area": "2431.854"
                        },
                        {
                            "value": "532922",
                            "label": "漾濞彝族自治县",
                            "area": "1860.849"
                        },
                        {
                            "value": "532901",
                            "label": "大理市",
                            "area": "1739.548"
                        },
                        {
                            "value": "532928",
                            "label": "永平县",
                            "area": "2790.285"
                        },
                        {
                            "value": "532925",
                            "label": "弥渡县",
                            "area": "1526.661"
                        },
                        {
                            "value": "532927",
                            "label": "巍山彝族回族自治县",
                            "area": "2180.629"
                        },
                        {
                            "value": "532926",
                            "label": "南涧彝族自治县",
                            "area": "1739.231"
                        }
                    ]
                },
                {
                    "value": "530400",
                    "label": "玉溪市",
                    "children": [
                        {
                            "value": "530425",
                            "label": "易门县",
                            "area": "1526.931"
                        },
                        {
                            "value": "530402",
                            "label": "红塔区",
                            "area": "946.525"
                        },
                        {
                            "value": "530403",
                            "label": "江川区",
                            "area": "808.395"
                        },
                        {
                            "value": "530426",
                            "label": "峨山彝族自治县",
                            "area": "1932.038"
                        },
                        {
                            "value": "530424",
                            "label": "华宁县",
                            "area": "1247.834"
                        },
                        {
                            "value": "530423",
                            "label": "通海县",
                            "area": "740.339"
                        },
                        {
                            "value": "530427",
                            "label": "新平彝族傣族自治县",
                            "area": "4265.19"
                        },
                        {
                            "value": "530428",
                            "label": "元江哈尼族彝族傣族自治县",
                            "area": "2716.463"
                        },
                        {
                            "value": "530481",
                            "label": "澄江市",
                            "area": "754.502"
                        }
                    ]
                },
                {
                    "value": "530500",
                    "label": "保山市",
                    "children": [
                        {
                            "value": "530581",
                            "label": "腾冲市",
                            "area": "5710.01"
                        },
                        {
                            "value": "530502",
                            "label": "隆阳区",
                            "area": "4826.698"
                        },
                        {
                            "value": "530524",
                            "label": "昌宁县",
                            "area": "3774.371"
                        },
                        {
                            "value": "530521",
                            "label": "施甸县",
                            "area": "1936.076"
                        },
                        {
                            "value": "530523",
                            "label": "龙陵县",
                            "area": "2815.794"
                        }
                    ]
                },
                {
                    "value": "532600",
                    "label": "文山壮族苗族自治州",
                    "children": [
                        {
                            "value": "532627",
                            "label": "广南县",
                            "area": "7756.281"
                        },
                        {
                            "value": "532626",
                            "label": "丘北县",
                            "area": "5048.576"
                        },
                        {
                            "value": "532622",
                            "label": "砚山县",
                            "area": "3858.462"
                        },
                        {
                            "value": "532624",
                            "label": "麻栗坡县",
                            "area": "2359.775"
                        },
                        {
                            "value": "532623",
                            "label": "西畴县",
                            "area": "1501.65"
                        },
                        {
                            "value": "532601",
                            "label": "文山市",
                            "area": "2956.885"
                        },
                        {
                            "value": "532625",
                            "label": "马关县",
                            "area": "2669.67"
                        },
                        {
                            "value": "532628",
                            "label": "富宁县",
                            "area": "5267.74"
                        }
                    ]
                },
                {
                    "value": "533400",
                    "label": "迪庆藏族自治州",
                    "children": [
                        {
                            "value": "533422",
                            "label": "德钦县",
                            "area": "7293.748"
                        },
                        {
                            "value": "533401",
                            "label": "香格里拉市",
                            "area": "11425.44"
                        },
                        {
                            "value": "533423",
                            "label": "维西傈僳族自治县",
                            "area": "4473.424"
                        }
                    ]
                },
                {
                    "value": "530800",
                    "label": "普洱市",
                    "children": [
                        {
                            "value": "530823",
                            "label": "景东彝族自治县",
                            "area": "4446.06"
                        },
                        {
                            "value": "530822",
                            "label": "墨江哈尼族自治县",
                            "area": "5262.861"
                        },
                        {
                            "value": "530825",
                            "label": "镇沅彝族哈尼族拉祜族自治县",
                            "area": "4137.484"
                        },
                        {
                            "value": "530824",
                            "label": "景谷傣族彝族自治县",
                            "area": "7525.444"
                        },
                        {
                            "value": "530821",
                            "label": "宁洱哈尼族彝族自治县",
                            "area": "3676.237"
                        },
                        {
                            "value": "530828",
                            "label": "澜沧拉祜族自治县",
                            "area": "8737.851"
                        },
                        {
                            "value": "530802",
                            "label": "思茅区",
                            "area": "3860.738"
                        },
                        {
                            "value": "530829",
                            "label": "西盟佤族自治县",
                            "area": "1259.198"
                        },
                        {
                            "value": "530827",
                            "label": "孟连傣族拉祜族佤族自治县",
                            "area": "1895.912"
                        },
                        {
                            "value": "530826",
                            "label": "江城哈尼族彝族自治县",
                            "area": "3442.366"
                        }
                    ]
                },
                {
                    "value": "532300",
                    "label": "楚雄彝族自治州",
                    "children": [
                        {
                            "value": "532328",
                            "label": "元谋县",
                            "area": "2029.059"
                        },
                        {
                            "value": "532326",
                            "label": "大姚县",
                            "area": "4036.579"
                        },
                        {
                            "value": "532323",
                            "label": "牟定县",
                            "area": "1448.503"
                        },
                        {
                            "value": "532325",
                            "label": "姚安县",
                            "area": "1696.728"
                        },
                        {
                            "value": "532331",
                            "label": "禄丰市",
                            "area": "3537.913"
                        },
                        {
                            "value": "532324",
                            "label": "南华县",
                            "area": "2263.904"
                        },
                        {
                            "value": "532301",
                            "label": "楚雄市",
                            "area": "4433.96"
                        },
                        {
                            "value": "532322",
                            "label": "双柏县",
                            "area": "3887.152"
                        },
                        {
                            "value": "532327",
                            "label": "永仁县",
                            "area": "2143.15"
                        },
                        {
                            "value": "532329",
                            "label": "武定县",
                            "area": "2940.049"
                        }
                    ]
                },
                {
                    "value": "530900",
                    "label": "临沧市",
                    "children": [
                        {
                            "value": "530921",
                            "label": "凤庆县",
                            "area": "3306.98"
                        },
                        {
                            "value": "530922",
                            "label": "云县",
                            "area": "3686.265"
                        },
                        {
                            "value": "530923",
                            "label": "永德县",
                            "area": "3225.94"
                        },
                        {
                            "value": "530924",
                            "label": "镇康县",
                            "area": "2524.859"
                        },
                        {
                            "value": "530926",
                            "label": "耿马傣族佤族自治县",
                            "area": "3701.526"
                        },
                        {
                            "value": "530902",
                            "label": "临翔区",
                            "area": "2561.413"
                        },
                        {
                            "value": "530925",
                            "label": "双江拉祜族佤族布朗族傣族自治县",
                            "area": "2164.86"
                        },
                        {
                            "value": "530927",
                            "label": "沧源佤族自治县",
                            "area": "2490.207"
                        }
                    ]
                },
                {
                    "value": "533100",
                    "label": "德宏傣族景颇族自治州",
                    "children": [
                        {
                            "value": "533123",
                            "label": "盈江县",
                            "area": "4318.829"
                        },
                        {
                            "value": "533122",
                            "label": "梁河县",
                            "area": "1135.978"
                        },
                        {
                            "value": "533124",
                            "label": "陇川县",
                            "area": "1872.731"
                        },
                        {
                            "value": "533103",
                            "label": "芒市",
                            "area": "2898.355"
                        },
                        {
                            "value": "533102",
                            "label": "瑞丽市",
                            "area": "945.02"
                        }
                    ]
                },
                {
                    "value": "530100",
                    "label": "昆明市",
                    "children": [
                        {
                            "value": "530113",
                            "label": "东川区",
                            "area": "1864.539"
                        },
                        {
                            "value": "530129",
                            "label": "寻甸回族彝族自治县",
                            "area": "3596.627"
                        },
                        {
                            "value": "530102",
                            "label": "五华区",
                            "area": "396.277"
                        },
                        {
                            "value": "530125",
                            "label": "宜良县",
                            "area": "1912.944"
                        },
                        {
                            "value": "530112",
                            "label": "西山区",
                            "area": "896.959"
                        },
                        {
                            "value": "530126",
                            "label": "石林彝族自治县",
                            "area": "1681.376"
                        },
                        {
                            "value": "530115",
                            "label": "晋宁区",
                            "area": "1337.653"
                        },
                        {
                            "value": "530181",
                            "label": "安宁市",
                            "area": "1299.49"
                        },
                        {
                            "value": "530124",
                            "label": "富民县",
                            "area": "994.444"
                        },
                        {
                            "value": "530128",
                            "label": "禄劝彝族苗族自治县",
                            "area": "4228.511"
                        },
                        {
                            "value": "530127",
                            "label": "嵩明县",
                            "area": "827.329"
                        },
                        {
                            "value": "530103",
                            "label": "盘龙区",
                            "area": "844.913"
                        },
                        {
                            "value": "530111",
                            "label": "官渡区",
                            "area": "637.001"
                        },
                        {
                            "value": "530114",
                            "label": "呈贡区",
                            "area": "499.095"
                        }
                    ]
                }
            ]
        },
        {
            "value": "110000",
            "label": "北京市",
            "children": [
                {
                    "value": "110100",
                    "label": "北京城区",
                    "children": [
                        {
                            "value": "110116",
                            "label": "怀柔区",
                            "area": "2122.328"
                        },
                        {
                            "value": "110119",
                            "label": "延庆区",
                            "area": "1995.052"
                        },
                        {
                            "value": "110109",
                            "label": "门头沟区",
                            "area": "1447.931"
                        },
                        {
                            "value": "110113",
                            "label": "顺义区",
                            "area": "1009.386"
                        },
                        {
                            "value": "110117",
                            "label": "平谷区",
                            "area": "948.138"
                        },
                        {
                            "value": "110105",
                            "label": "朝阳区",
                            "area": "464.409"
                        },
                        {
                            "value": "110101",
                            "label": "东城区",
                            "area": "42.038"
                        },
                        {
                            "value": "110107",
                            "label": "石景山区",
                            "area": "84.445"
                        },
                        {
                            "value": "110115",
                            "label": "大兴区",
                            "area": "1034.33"
                        },
                        {
                            "value": "110112",
                            "label": "通州区",
                            "area": "905.2"
                        },
                        {
                            "value": "110118",
                            "label": "密云区",
                            "area": "2227.065"
                        },
                        {
                            "value": "110114",
                            "label": "昌平区",
                            "area": "1342.641"
                        },
                        {
                            "value": "110111",
                            "label": "房山区",
                            "area": "1998.222"
                        },
                        {
                            "value": "110102",
                            "label": "西城区",
                            "area": "50.345"
                        },
                        {
                            "value": "110106",
                            "label": "丰台区",
                            "area": "304.036"
                        },
                        {
                            "value": "110108",
                            "label": "海淀区",
                            "area": "430.879"
                        }
                    ]
                }
            ]
        },
        {
            "value": "220000",
            "label": "吉林省",
            "children": [
                {
                    "value": "220200",
                    "label": "吉林市",
                    "children": [
                        {
                            "value": "220211",
                            "label": "丰满区",
                            "area": "1060.571"
                        },
                        {
                            "value": "220282",
                            "label": "桦甸市",
                            "area": "6523.443"
                        },
                        {
                            "value": "220283",
                            "label": "舒兰市",
                            "area": "4555.318"
                        },
                        {
                            "value": "220203",
                            "label": "龙潭区",
                            "area": "1173.103"
                        },
                        {
                            "value": "220281",
                            "label": "蛟河市",
                            "area": "6367.446"
                        },
                        {
                            "value": "220221",
                            "label": "永吉县",
                            "area": "2629.04"
                        },
                        {
                            "value": "220284",
                            "label": "磐石市",
                            "area": "3861.176"
                        },
                        {
                            "value": "220204",
                            "label": "船营区",
                            "area": "685.073"
                        },
                        {
                            "value": "220202",
                            "label": "昌邑区",
                            "area": "857.185"
                        }
                    ]
                },
                {
                    "value": "220100",
                    "label": "长春市",
                    "children": [
                        {
                            "value": "220182",
                            "label": "榆树市",
                            "area": "4719.933"
                        },
                        {
                            "value": "220183",
                            "label": "德惠市",
                            "area": "3011.932"
                        },
                        {
                            "value": "220112",
                            "label": "双阳区",
                            "area": "1675.098"
                        },
                        {
                            "value": "220122",
                            "label": "农安县",
                            "area": "5191.688"
                        },
                        {
                            "value": "220103",
                            "label": "宽城区",
                            "area": "975.473"
                        },
                        {
                            "value": "220113",
                            "label": "九台区",
                            "area": "3368.182"
                        },
                        {
                            "value": "220104",
                            "label": "朝阳区",
                            "area": "284.524"
                        },
                        {
                            "value": "220106",
                            "label": "绿园区",
                            "area": "320.023"
                        },
                        {
                            "value": "220184",
                            "label": "公主岭市",
                            "area": "4144.995"
                        },
                        {
                            "value": "220105",
                            "label": "二道区",
                            "area": "505.995"
                        },
                        {
                            "value": "220102",
                            "label": "南关区",
                            "area": "531.949"
                        }
                    ]
                },
                {
                    "value": "220800",
                    "label": "白城市",
                    "children": [
                        {
                            "value": "220882",
                            "label": "大安市",
                            "area": "4879.45"
                        },
                        {
                            "value": "220881",
                            "label": "洮南市",
                            "area": "5105.143"
                        },
                        {
                            "value": "220822",
                            "label": "通榆县",
                            "area": "8475.675"
                        },
                        {
                            "value": "220802",
                            "label": "洮北区",
                            "area": "2576.628"
                        },
                        {
                            "value": "220821",
                            "label": "镇赉县",
                            "area": "4722.953"
                        }
                    ]
                },
                {
                    "value": "220700",
                    "label": "松原市",
                    "children": [
                        {
                            "value": "220781",
                            "label": "扶余市",
                            "area": "4656.558"
                        },
                        {
                            "value": "220702",
                            "label": "宁江区",
                            "area": "1247.597"
                        },
                        {
                            "value": "220721",
                            "label": "前郭尔罗斯蒙古族自治县",
                            "area": "6021.576"
                        },
                        {
                            "value": "220723",
                            "label": "乾安县",
                            "area": "3518.614"
                        },
                        {
                            "value": "220722",
                            "label": "长岭县",
                            "area": "5735.972"
                        }
                    ]
                },
                {
                    "value": "220400",
                    "label": "辽源市",
                    "children": [
                        {
                            "value": "220403",
                            "label": "西安区",
                            "area": "71.863"
                        },
                        {
                            "value": "220421",
                            "label": "东丰县",
                            "area": "2522.332"
                        },
                        {
                            "value": "220402",
                            "label": "龙山区",
                            "area": "259.664"
                        },
                        {
                            "value": "220422",
                            "label": "东辽县",
                            "area": "2283.662"
                        }
                    ]
                },
                {
                    "value": "220600",
                    "label": "白山市",
                    "children": [
                        {
                            "value": "220622",
                            "label": "靖宇县",
                            "area": "3085.318"
                        },
                        {
                            "value": "220605",
                            "label": "江源区",
                            "area": "1346.851"
                        },
                        {
                            "value": "220623",
                            "label": "长白朝鲜族自治县",
                            "area": "2489.537"
                        },
                        {
                            "value": "220621",
                            "label": "抚松县",
                            "area": "6155.438"
                        },
                        {
                            "value": "220602",
                            "label": "浑江区",
                            "area": "1355.999"
                        },
                        {
                            "value": "220681",
                            "label": "临江市",
                            "area": "2983.691"
                        }
                    ]
                },
                {
                    "value": "220500",
                    "label": "通化市",
                    "children": [
                        {
                            "value": "220502",
                            "label": "东昌区",
                            "area": "374.874"
                        },
                        {
                            "value": "220503",
                            "label": "二道江区",
                            "area": "371.05"
                        },
                        {
                            "value": "220521",
                            "label": "通化县",
                            "area": "3720.729"
                        },
                        {
                            "value": "220524",
                            "label": "柳河县",
                            "area": "3346.174"
                        },
                        {
                            "value": "220523",
                            "label": "辉南县",
                            "area": "2274.929"
                        },
                        {
                            "value": "220581",
                            "label": "梅河口市",
                            "area": "2178.564"
                        },
                        {
                            "value": "220582",
                            "label": "集安市",
                            "area": "3276.806"
                        }
                    ]
                },
                {
                    "value": "220300",
                    "label": "四平市",
                    "children": [
                        {
                            "value": "220303",
                            "label": "铁东区",
                            "area": "922.295"
                        },
                        {
                            "value": "220382",
                            "label": "双辽市",
                            "area": "3097.2"
                        },
                        {
                            "value": "220323",
                            "label": "伊通满族自治县",
                            "area": "2527.235"
                        },
                        {
                            "value": "220322",
                            "label": "梨树县",
                            "area": "3508.306"
                        },
                        {
                            "value": "220302",
                            "label": "铁西区",
                            "area": "184.569"
                        }
                    ]
                },
                {
                    "value": "222400",
                    "label": "延边朝鲜族自治州",
                    "children": [
                        {
                            "value": "222402",
                            "label": "图们市",
                            "area": "1135.55"
                        },
                        {
                            "value": "222404",
                            "label": "珲春市",
                            "area": "5108.437"
                        },
                        {
                            "value": "222406",
                            "label": "和龙市",
                            "area": "5064.865"
                        },
                        {
                            "value": "222424",
                            "label": "汪清县",
                            "area": "8825.735"
                        },
                        {
                            "value": "222401",
                            "label": "延吉市",
                            "area": "1731.963"
                        },
                        {
                            "value": "222426",
                            "label": "安图县",
                            "area": "7435.837"
                        },
                        {
                            "value": "222403",
                            "label": "敦化市",
                            "area": "11788.448"
                        },
                        {
                            "value": "222405",
                            "label": "龙井市",
                            "area": "2194.807"
                        }
                    ]
                }
            ]
        },
        {
            "value": "140000",
            "label": "山西省",
            "children": [
                {
                    "value": "141000",
                    "label": "临汾市",
                    "children": [
                        {
                            "value": "141031",
                            "label": "隰县",
                            "area": "1415.392"
                        },
                        {
                            "value": "141028",
                            "label": "吉县",
                            "area": "1783.082"
                        },
                        {
                            "value": "141030",
                            "label": "大宁县",
                            "area": "955.245"
                        },
                        {
                            "value": "141025",
                            "label": "古县",
                            "area": "1193.853"
                        },
                        {
                            "value": "141026",
                            "label": "安泽县",
                            "area": "1963.849"
                        },
                        {
                            "value": "141034",
                            "label": "汾西县",
                            "area": "859.078"
                        },
                        {
                            "value": "141082",
                            "label": "霍州市",
                            "area": "762.951"
                        },
                        {
                            "value": "141021",
                            "label": "曲沃县",
                            "area": "434.428"
                        },
                        {
                            "value": "141032",
                            "label": "永和县",
                            "area": "1215.498"
                        },
                        {
                            "value": "141029",
                            "label": "乡宁县",
                            "area": "2021.825"
                        },
                        {
                            "value": "141081",
                            "label": "侯马市",
                            "area": "221.66"
                        },
                        {
                            "value": "141033",
                            "label": "蒲县",
                            "area": "1525.924"
                        },
                        {
                            "value": "141024",
                            "label": "洪洞县",
                            "area": "1489.527"
                        },
                        {
                            "value": "141023",
                            "label": "襄汾县",
                            "area": "1032.516"
                        },
                        {
                            "value": "141002",
                            "label": "尧都区",
                            "area": "1316.481"
                        },
                        {
                            "value": "141027",
                            "label": "浮山县",
                            "area": "942.317"
                        },
                        {
                            "value": "141022",
                            "label": "翼城县",
                            "area": "1159.321"
                        }
                    ]
                },
                {
                    "value": "140100",
                    "label": "太原市",
                    "children": [
                        {
                            "value": "140123",
                            "label": "娄烦县",
                            "area": "1288.61"
                        },
                        {
                            "value": "140108",
                            "label": "尖草坪区",
                            "area": "294.929"
                        },
                        {
                            "value": "140107",
                            "label": "杏花岭区",
                            "area": "145.777"
                        },
                        {
                            "value": "140122",
                            "label": "阳曲县",
                            "area": "2082.855"
                        },
                        {
                            "value": "140181",
                            "label": "古交市",
                            "area": "1510.993"
                        },
                        {
                            "value": "140121",
                            "label": "清徐县",
                            "area": "605.674"
                        },
                        {
                            "value": "140109",
                            "label": "万柏林区",
                            "area": "288.478"
                        },
                        {
                            "value": "140110",
                            "label": "晋源区",
                            "area": "291.514"
                        },
                        {
                            "value": "140106",
                            "label": "迎泽区",
                            "area": "105.051"
                        },
                        {
                            "value": "140105",
                            "label": "小店区",
                            "area": "290.161"
                        }
                    ]
                },
                {
                    "value": "140300",
                    "label": "阳泉市",
                    "children": [
                        {
                            "value": "140321",
                            "label": "平定县",
                            "area": "1403.05"
                        },
                        {
                            "value": "140302",
                            "label": "城区",
                            "area": "15.048"
                        },
                        {
                            "value": "140311",
                            "label": "郊区",
                            "area": "623.545"
                        },
                        {
                            "value": "140322",
                            "label": "盂县",
                            "area": "2508.155"
                        },
                        {
                            "value": "140303",
                            "label": "矿区",
                            "area": "12.284"
                        }
                    ]
                },
                {
                    "value": "140600",
                    "label": "朔州市",
                    "children": [
                        {
                            "value": "140603",
                            "label": "平鲁区",
                            "area": "2319.5"
                        },
                        {
                            "value": "140623",
                            "label": "右玉县",
                            "area": "1987.388"
                        },
                        {
                            "value": "140681",
                            "label": "怀仁市",
                            "area": "1237.291"
                        },
                        {
                            "value": "140602",
                            "label": "朔城区",
                            "area": "1768.24"
                        },
                        {
                            "value": "140621",
                            "label": "山阴县",
                            "area": "1628.975"
                        },
                        {
                            "value": "140622",
                            "label": "应县",
                            "area": "1687.613"
                        }
                    ]
                },
                {
                    "value": "141100",
                    "label": "吕梁市",
                    "children": [
                        {
                            "value": "141124",
                            "label": "临县",
                            "area": "2976.699"
                        },
                        {
                            "value": "141127",
                            "label": "岚县",
                            "area": "1510.748"
                        },
                        {
                            "value": "141102",
                            "label": "离石区",
                            "area": "1315.128"
                        },
                        {
                            "value": "141125",
                            "label": "柳林县",
                            "area": "1289.178"
                        },
                        {
                            "value": "141128",
                            "label": "方山县",
                            "area": "1439.952"
                        },
                        {
                            "value": "141129",
                            "label": "中阳县",
                            "area": "1434.607"
                        },
                        {
                            "value": "141130",
                            "label": "交口县",
                            "area": "1259.016"
                        },
                        {
                            "value": "141123",
                            "label": "兴县",
                            "area": "3165.271"
                        },
                        {
                            "value": "141126",
                            "label": "石楼县",
                            "area": "1734.754"
                        },
                        {
                            "value": "141122",
                            "label": "交城县",
                            "area": "1830.585"
                        },
                        {
                            "value": "141121",
                            "label": "文水县",
                            "area": "1069.327"
                        },
                        {
                            "value": "141181",
                            "label": "孝义市",
                            "area": "934.278"
                        },
                        {
                            "value": "141182",
                            "label": "汾阳市",
                            "area": "1172.563"
                        }
                    ]
                },
                {
                    "value": "140200",
                    "label": "大同市",
                    "children": [
                        {
                            "value": "140223",
                            "label": "广灵县",
                            "area": "1210.145"
                        },
                        {
                            "value": "140225",
                            "label": "浑源县",
                            "area": "1938.58"
                        },
                        {
                            "value": "140224",
                            "label": "灵丘县",
                            "area": "2733.581"
                        },
                        {
                            "value": "140221",
                            "label": "阳高县",
                            "area": "1609.895"
                        },
                        {
                            "value": "140222",
                            "label": "天镇县",
                            "area": "1690.122"
                        },
                        {
                            "value": "140212",
                            "label": "新荣区",
                            "area": "1104.146"
                        },
                        {
                            "value": "140215",
                            "label": "云州区",
                            "area": "1483.49"
                        },
                        {
                            "value": "140226",
                            "label": "左云县",
                            "area": "1324.698"
                        },
                        {
                            "value": "140213",
                            "label": "平城区",
                            "area": "228.201"
                        },
                        {
                            "value": "140214",
                            "label": "云冈区",
                            "area": "751.931"
                        }
                    ]
                },
                {
                    "value": "140700",
                    "label": "晋中市",
                    "children": [
                        {
                            "value": "140724",
                            "label": "昔阳县",
                            "area": "1939.975"
                        },
                        {
                            "value": "140723",
                            "label": "和顺县",
                            "area": "2200.126"
                        },
                        {
                            "value": "140725",
                            "label": "寿阳县",
                            "area": "2127.454"
                        },
                        {
                            "value": "140721",
                            "label": "榆社县",
                            "area": "1689.448"
                        },
                        {
                            "value": "140781",
                            "label": "介休市",
                            "area": "724.639"
                        },
                        {
                            "value": "140722",
                            "label": "左权县",
                            "area": "2023.807"
                        },
                        {
                            "value": "140729",
                            "label": "灵石县",
                            "area": "1215.013"
                        },
                        {
                            "value": "140702",
                            "label": "榆次区",
                            "area": "1318.094"
                        },
                        {
                            "value": "140703",
                            "label": "太谷区",
                            "area": "1037.426"
                        },
                        {
                            "value": "140727",
                            "label": "祁县",
                            "area": "855.729"
                        },
                        {
                            "value": "140728",
                            "label": "平遥县",
                            "area": "1254.369"
                        }
                    ]
                },
                {
                    "value": "140400",
                    "label": "长治市",
                    "children": [
                        {
                            "value": "140426",
                            "label": "黎城县",
                            "area": "1119.582"
                        },
                        {
                            "value": "140405",
                            "label": "屯留区",
                            "area": "1179.064"
                        },
                        {
                            "value": "140406",
                            "label": "潞城区",
                            "area": "610.036"
                        },
                        {
                            "value": "140428",
                            "label": "长子县",
                            "area": "1031.647"
                        },
                        {
                            "value": "140425",
                            "label": "平顺县",
                            "area": "1520.238"
                        },
                        {
                            "value": "140423",
                            "label": "襄垣县",
                            "area": "1169.53"
                        },
                        {
                            "value": "140403",
                            "label": "潞州区",
                            "area": "347.16"
                        },
                        {
                            "value": "140430",
                            "label": "沁县",
                            "area": "1321.41"
                        },
                        {
                            "value": "140429",
                            "label": "武乡县",
                            "area": "1617.515"
                        },
                        {
                            "value": "140427",
                            "label": "壶关县",
                            "area": "1010.609"
                        },
                        {
                            "value": "140431",
                            "label": "沁源县",
                            "area": "2574.171"
                        },
                        {
                            "value": "140404",
                            "label": "上党区",
                            "area": "477.13"
                        }
                    ]
                },
                {
                    "value": "140900",
                    "label": "忻州市",
                    "children": [
                        {
                            "value": "140932",
                            "label": "偏关县",
                            "area": "1669.917"
                        },
                        {
                            "value": "140923",
                            "label": "代县",
                            "area": "1747.899"
                        },
                        {
                            "value": "140921",
                            "label": "定襄县",
                            "area": "846.651"
                        },
                        {
                            "value": "140926",
                            "label": "静乐县",
                            "area": "2043.272"
                        },
                        {
                            "value": "140902",
                            "label": "忻府区",
                            "area": "1984.378"
                        },
                        {
                            "value": "140928",
                            "label": "五寨县",
                            "area": "1382.611"
                        },
                        {
                            "value": "140981",
                            "label": "原平市",
                            "area": "2527.328"
                        },
                        {
                            "value": "140925",
                            "label": "宁武县",
                            "area": "1948.535"
                        },
                        {
                            "value": "140922",
                            "label": "五台县",
                            "area": "2869.674"
                        },
                        {
                            "value": "140929",
                            "label": "岢岚县",
                            "area": "1996.358"
                        },
                        {
                            "value": "140924",
                            "label": "繁峙县",
                            "area": "2388.528"
                        },
                        {
                            "value": "140927",
                            "label": "神池县",
                            "area": "1477.37"
                        },
                        {
                            "value": "140931",
                            "label": "保德县",
                            "area": "990.84"
                        },
                        {
                            "value": "140930",
                            "label": "河曲县",
                            "area": "1311.247"
                        }
                    ]
                },
                {
                    "value": "140500",
                    "label": "晋城市",
                    "children": [
                        {
                            "value": "140502",
                            "label": "城区",
                            "area": "147.425"
                        },
                        {
                            "value": "140525",
                            "label": "泽州县",
                            "area": "2031.146"
                        },
                        {
                            "value": "140521",
                            "label": "沁水县",
                            "area": "2625.874"
                        },
                        {
                            "value": "140522",
                            "label": "阳城县",
                            "area": "1935.698"
                        },
                        {
                            "value": "140524",
                            "label": "陵川县",
                            "area": "1684.348"
                        },
                        {
                            "value": "140581",
                            "label": "高平市",
                            "area": "992.835"
                        }
                    ]
                },
                {
                    "value": "140800",
                    "label": "运城市",
                    "children": [
                        {
                            "value": "140829",
                            "label": "平陆县",
                            "area": "1175.154"
                        },
                        {
                            "value": "140822",
                            "label": "万荣县",
                            "area": "1104.954"
                        },
                        {
                            "value": "140802",
                            "label": "盐湖区",
                            "area": "1205.045"
                        },
                        {
                            "value": "140828",
                            "label": "夏县",
                            "area": "1351.523"
                        },
                        {
                            "value": "140823",
                            "label": "闻喜县",
                            "area": "1167.258"
                        },
                        {
                            "value": "140821",
                            "label": "临猗县",
                            "area": "1370.158"
                        },
                        {
                            "value": "140827",
                            "label": "垣曲县",
                            "area": "1614.842"
                        },
                        {
                            "value": "140826",
                            "label": "绛县",
                            "area": "989.975"
                        },
                        {
                            "value": "140825",
                            "label": "新绛县",
                            "area": "594.447"
                        },
                        {
                            "value": "140882",
                            "label": "河津市",
                            "area": "600.55"
                        },
                        {
                            "value": "140824",
                            "label": "稷山县",
                            "area": "682.963"
                        },
                        {
                            "value": "140830",
                            "label": "芮城县",
                            "area": "1170.275"
                        },
                        {
                            "value": "140881",
                            "label": "永济市",
                            "area": "1218.311"
                        }
                    ]
                }
            ]
        }
    ]


    /*
        添加边界
        level:{//级别
            1：省，
            2：市，
            3：县，
        };
        code :行政区划code
        add :是否添加边界，为false时，只移动视角
        boundaryName :边界实体名称
        color ：边界颜色
     */
    function addBoundary(level, code, add, boundaryName, color) {
        // 山东省特殊处理，使用没有海岸线的矢量
        if (code) {
            const co = code.slice(0, 2)
            if (co === '37') {
                switch (level) {
                    case 1://省
                        axios({
                            method: "get",
                            url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Ashandong_boundary_noCoastline&maxFeatures=100&outputFormat=application%2Fjson`,
                            // url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Aprovince2021&maxFeatures=100&outputFormat=application%2Fjson&TYPENAME=cite:province2021&cql_filter=pro_code='${code}'`,
                        }).then(res => {
                            res.data.features.forEach(item => {
                                // 筛选出所在省
                                axios({
                                    method: "get",
                                    url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Aprovince2021&maxFeatures=100&outputFormat=application%2Fjson&TYPENAME=cite:province2021&cql_filter=pro_code='${code}'`,
                                }).then(res => {
                                    res.data.features.forEach(item => {
                                        // 筛选出所在省
                                        if (item.properties && item.properties.pro_code === code) {
                                            // viewer.camera.flyTo({
                                            //     destination: Cesium.Rectangle.fromDegrees(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]),
                                            //     duration: 2,
                                            // })
                                            // reBoundingBox(Cesium.Rectangle.fromDegrees(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]))
                                            reBoundingBox(Cesium.Rectangle.fromRadians(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]))
                                        }
                                    });
                                }).catch(error => { })
                                if (add) {
                                    addBoundaryHandleSD(item.geometry.coordinates, boundaryName ? boundaryName : '所在省', color)
                                }
                            });
                        }).catch(error => { })
                        break;
                    case 2://市
                        // let url = getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3AshandongCity2023_noCoastline&maxFeatures=100&outputFormat=application%2Fjson&cql_filter=city_code=${code.slice(0, 4)}`
                        axios({
                            method: "get",
                            url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3AshandongCity2023_noCoastline&maxFeatures=100&outputFormat=application%2Fjson&cql_filter=city_code=${code.slice(0, 4)}`,
                            // url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Acity2021&maxFeatures=395&outputFormat=application%2Fjson&TYPENAME=cite:city2021&cql_filter=city_code=${code}`
                        }).then(res => {
                            let maxbox = []
                            res.data.features.forEach((item, index) => {
                                // 筛选出所在省
                                if (item.properties && item.properties.city_code === code.slice(0, 4)) {
                                    // 最大范围的box
                                    // maxbox.push(new Cesium.Cartographic(item.bbox[0], item.bbox[1], 0))
                                    // maxbox.push(new Cesium.Cartographic(item.bbox[2], item.bbox[3], 0))
                                    maxbox.push(new Cesium.Cartographic(item.bbox[0], item.bbox[3], 0))
                                    maxbox.push(new Cesium.Cartographic(item.bbox[2], item.bbox[1], 0))

                                    if (add) {
                                        addBoundaryHandleSD(item.geometry.coordinates, boundaryName ? boundaryName : '所在市', color)
                                    }
                                }
                            });
                            // 计算出最大范围的box (得到的是经纬度)
                            const bound = new Cesium.Rectangle.fromCartographicArray(maxbox)
                            reBoundingBox(bound)
                            // viewer.camera.flyTo({
                            //     destination: Cesium.Rectangle.fromDegrees(bound.west, bound.south, bound.east, bound.north),
                            //     duration: 2,
                            // })

                        }).catch(error => { })
                        break;
                    case 3://县
                        // 去掉海岸线的
                        // axios({
                        //     method: "get",
                        //     url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3AshandongCounty2023_noCoastline&maxFeatures=100&outputFormat=application%2Fjson&cql_filter=cou_code=${code.slice(0, 6)}`,
                        //     // url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Adistrict2021&maxFeatures=2875&outputFormat=application%2Fjson&TYPENAME=cite:district2021&cql_filter=town_code=${code}`
                        // }).then(res => {
                        //     let maxbox = []
                        //     res.data.features.forEach(item => {
                        //         // 筛选出所在省
                        //         if (item.properties && item.properties.cou_code === code.slice(0, 6)) {
                        //             // 最大范围的box
                        //             maxbox.push(new Cesium.Cartographic(item.bbox[0], item.bbox[3], 0))
                        //             maxbox.push(new Cesium.Cartographic(item.bbox[2], item.bbox[1], 0))
                        //             // viewer.camera.flyTo({
                        //             //     destination: Cesium.Rectangle.fromDegrees(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]),
                        //             //     duration: 2,
                        //             // })
                        //             if (add) {
                        //                 addBoundaryHandleSD(item.geometry.coordinates, boundaryName ? boundaryName : '所在县', color)
                        //             }
                        //         }
                        //     });
                        //     // 计算出最大范围的box
                        //     const bound = new Cesium.Rectangle.fromCartographicArray(maxbox)
                        //     viewer.camera.flyTo({
                        //         destination: Cesium.Rectangle.fromDegrees(bound.west, bound.south, bound.east, bound.north),
                        //         duration: 2,
                        //     })
                        // }).catch(error => { })

                        // 没去海岸线的
                        axios({
                            method: "get",
                            url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Adistrict2021&maxFeatures=2875&outputFormat=application%2Fjson&TYPENAME=cite:district2021&cql_filter=town_code=${code}`
                        }).then(res => {
                            res.data.features.forEach(item => {
                                // 筛选出所在省
                                if (item.properties && item.properties.town_code === code) {
                                    // viewer.camera.flyTo({
                                    //     destination: Cesium.Rectangle.fromDegrees(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]),
                                    //     duration: 2,
                                    // })
                                    reBoundingBox(Cesium.Rectangle.fromRadians(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]))

                                    // boxArray = [item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]]
                                    if (add) {
                                        addBoundaryHandle(item.geometry.coordinates, boundaryName ? boundaryName : '所在县', color)
                                    }
                                }
                            });
                        }).catch(error => { })
                        break;
                    case 4://街道
                        // 跟省外一样的
                        // 判断只有山东省的才加载街道
                        if (Number(code.slice(0, 2)) === 37) {
                            axios({
                                method: "get",
                                // url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Adistrict2021&maxFeatures=2875&outputFormat=application%2Fjson&TYPENAME=cite:district2021&cql_filter=town_code=${code}`
                                url: getGeoServerAdress() + `/geoserver/common_service/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=common_service%3AshandongTown2022&maxFeatures=100&outputFormat=application%2Fjson&cql_filter=town_code=${code}`
                            }).then(res => {
                                res.data.features.forEach(item => {
                                    // 筛选出所在省
                                    if (item.properties && item.properties.town_code === code) {
                                        // viewer.camera.flyTo({
                                        //     destination: Cesium.Rectangle.fromDegrees(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]),
                                        //     duration: 2,
                                        // })
                                        reBoundingBox(Cesium.Rectangle.fromRadians(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]))
                                        // boxArray = [item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]]
                                        if (add) {
                                            addBoundaryHandle(item.geometry.coordinates, boundaryName ? boundaryName : '所在街道', color)
                                        }
                                    }
                                });
                            }).catch(error => { })
                        } else {
                            addBoundary(3, code.slice(0, 6), add, boundaryName, color)
                        }
                        break;
                    default:
                }
            } else {
                switch (level) {
                    case 1://省
                        axios({
                            method: "get",
                            // http://118.24.27.47:9080/geoserver/dms/wms?service=WMS&version=1.1.0&request=GetMap&layers=dms%3Aprovince2021&bbox=73.50235500048308%2C3.837030000434175%2C135.09566999987067%2C53.56362400064718&width=768&height=620&srs=EPSG%3A4326&styles=&format=application/openlayers
                            // http://118.24.27.47:9080/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Aprovince2021&maxFeatures=50&outputFormat=application%2Fjson
                            url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Aprovince2021&maxFeatures=100&outputFormat=application%2Fjson&TYPENAME=cite:province2021&cql_filter=pro_code='${code}'`,
                        }).then(res => {
                            res.data.features.forEach(item => {
                                // 筛选出所在省
                                if (item.properties && item.properties.pro_code === code) {
                                    // viewer.camera.flyTo({
                                    //     destination: Cesium.Rectangle.fromDegrees(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]),
                                    //     duration: 2,
                                    // })
                                    reBoundingBox(Cesium.Rectangle.fromRadians(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]))
                                    if (add) {
                                        addBoundaryHandle(item.geometry.coordinates, boundaryName ? boundaryName : '所在省', color)
                                    }
                                }
                            });
                        }).catch(error => { })

                        break;
                    case 2://市
                        axios({
                            method: "get",
                            url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Acity2021&maxFeatures=395&outputFormat=application%2Fjson&TYPENAME=cite:city2021&cql_filter=city_code=${code}`
                        }).then(res => {
                            res.data.features.forEach(item => {
                                // 筛选出所在省
                                if (item.properties && item.properties.city_code === code) {
                                    // viewer.camera.flyTo({
                                    //     destination: Cesium.Rectangle.fromDegrees(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]),
                                    //     duration: 2,
                                    // })
                                    reBoundingBox(Cesium.Rectangle.fromRadians(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]))
                                    if (add) {
                                        addBoundaryHandle(item.geometry.coordinates, boundaryName ? boundaryName : '所在市', color)
                                    }
                                }
                            });
                        }).catch(error => { })
                        break;
                    case 3://县
                        axios({
                            method: "get",
                            url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Adistrict2021&maxFeatures=2875&outputFormat=application%2Fjson&TYPENAME=cite:district2021&cql_filter=town_code=${code}`
                        }).then(res => {
                            res.data.features.forEach(item => {
                                // 筛选出所在省
                                if (item.properties && item.properties.town_code === code) {
                                    // viewer.camera.flyTo({
                                    //     destination: Cesium.Rectangle.fromDegrees(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]),
                                    //     duration: 2,
                                    // })
                                    reBoundingBox(Cesium.Rectangle.fromRadians(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]))
                                    // boxArray = [item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]]
                                    if (add) {
                                        addBoundaryHandle(item.geometry.coordinates, boundaryName ? boundaryName : '所在县', color)
                                    }
                                }
                            });
                        }).catch(error => { })
                        break;
                    case 4://街道
                        // 判断只有山东省的才加载街道
                        if (Number(code.slice(0, 2)) === 37) {
                            axios({
                                method: "get",
                                // url: getGeoServerAdress() + `/geoserver/dms/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=dms%3Adistrict2021&maxFeatures=2875&outputFormat=application%2Fjson&TYPENAME=cite:district2021&cql_filter=town_code=${code}`
                                url: getGeoServerAdress() + `/geoserver/common_service/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=common_service%3AshandongTown2022&maxFeatures=100&outputFormat=application%2Fjson&cql_filter=town_code=${code}`
                            }).then(res => {
                                res.data.features.forEach(item => {
                                    // 筛选出所在省
                                    if (item.properties && item.properties.town_code === code) {
                                        // viewer.camera.flyTo({
                                        //     destination: Cesium.Rectangle.fromDegrees(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]),
                                        //     duration: 2,
                                        // })
                                        reBoundingBox(Cesium.Rectangle.fromRadians(item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]))

                                        // boxArray = [item.bbox[0], item.bbox[1], item.bbox[2], item.bbox[3]]
                                        if (add) {
                                            addBoundaryHandle(item.geometry.coordinates, boundaryName ? boundaryName : '所在街道', color)
                                        }
                                    }
                                });
                            }).catch(error => { })
                        } else {
                            addBoundary(3, code.slice(0, 6), add, boundaryName, color)
                        }
                        break;
                    default:
                }
            }
        }
    }



    // boundingBox 矩形经纬度四至,需为经纬度
    /*
    boundingBox  //矩形经纬度四至,需为经纬度 nort;east;sout;west;
    screenWidth  //cesium屏幕宽度
    screenHeight  //cesium屏幕高度
    paddingStart  //左侧边距宽度
    paddingTop  //上侧宽度
    paddingEnd  //屏幕右侧宽度
    paddingBottom  //屏幕下侧宽度
    */
    function reBoundingBox(boundingBox, screenWidth, screenHeight, paddingStart = 10, paddingTop = 70, paddingEnd, paddingBottom) {
        screenWidth = document.body.offsetWidth // 屏幕宽度
        screenHeight = document.body.offsetHeight // 屏幕高度 
        paddingStart = 10 //屏幕左侧宽度
        paddingTop = 70//屏幕上侧宽度
        paddingEnd = document.body.offsetWidth * 0.25//屏幕右侧宽度
        paddingBottom = document.body.offsetHeight * 0.3//屏幕下侧宽度
        // 矩形四至
        let latNorth = boundingBox.north;
        let lonEast = boundingBox.east;
        let latSouth = boundingBox.south;
        let lonWest = boundingBox.west;
        // 计算后的四至
        let newLatSouth = (screenHeight * latSouth * paddingTop + screenHeight * latNorth * paddingBottom - screenHeight * latSouth * screenHeight) / (screenHeight * paddingTop + screenHeight * paddingBottom - screenHeight * screenHeight);
        let newLatNorth = (screenHeight * latNorth - paddingTop * newLatSouth) / (screenHeight - paddingTop);
        let newLonEast = (screenWidth * lonEast * paddingStart - screenWidth * lonEast * screenWidth + screenWidth * lonWest * paddingEnd) / (screenWidth * paddingStart + screenWidth * paddingEnd - screenWidth * screenWidth);
        let newLonWest = (screenWidth * lonWest - paddingStart * newLonEast) / (screenWidth - paddingStart);
        //  新的矩形
        let Rectangle = Cesium.Rectangle.fromDegrees(newLonWest, newLatSouth, newLonEast, newLatNorth)
        viewer.camera.flyTo({
            destination: Rectangle,
            duration: 2,
        })
    }



    // 添加边界实体
    function addBoundaryHandle(data, name, color) {
        data.forEach(item => {
            let arr = [];
            item[0].forEach(mem => {
                arr.push(mem[0]);
                arr.push(mem[1])
            })
            viewer.entities.add({
                name: name,
                polyline: {
                    positions: Cesium.Cartesian3.fromDegreesArray(arr),
                    width: 6,
                    // material: new Cesium.PolylineDashMaterialProperty({//虚线
                    material: new Cesium.PolylineGlowMaterialProperty({//光晕
                        glowPower: 0.2,
                        color: color ? Cesium.Color.fromCssColorString(color) : Cesium.Color.fromBytes(250, 128, 114),//#FA8072
                    }),
                    // followSurface: false, //是否贴着地表
                    clampToGround: true,//贴地
                },
            })
        })
    }

    // 去除海岸线后，山东省数据不一样，需特殊处理
    function addBoundaryHandleSD(data, name, color) {
        data.forEach(item => {
            let arr = [];
            item.forEach(mem => {
                arr.push(mem[0]);
                arr.push(mem[1])
            })
            viewer.entities.add({
                name: name,
                polyline: {
                    positions: Cesium.Cartesian3.fromDegreesArray(arr),
                    width: 6,
                    // material: new Cesium.PolylineDashMaterialProperty({//虚线
                    material: new Cesium.PolylineGlowMaterialProperty({//光晕
                        glowPower: 0.2,
                        color: color ? Cesium.Color.fromCssColorString(color) : Cesium.Color.fromBytes(250, 128, 114),//#FA8072
                    }),
                    // followSurface: false, //是否贴着地表
                    clampToGround: true,//贴地
                },
            })
        })
    }
    // 根据实体名字删除实体(模糊删除)
    function deleteEntityByName(name) {
        if (viewer) {
            let entitys = viewer.entities._entities._array;
            for (var na = entitys.length - 1; na >= 0; na--) {
                if (entitys[na]._name && entitys[na]._name.indexOf(name) > -1) {
                    viewer.entities.remove(entitys[na]);
                }
            }
        }

    }


    // 清除测距测面
    const deleteRangeArea = () => {
        let RangeArea = viewer.entities._entities._array
        for (let i = 0; i < RangeArea.length; i++) {
            if (RangeArea[i]._name === "_range") {
                viewer.entities.remove(RangeArea[i]);
                i--;
            }
        }
    }

    // 测距
    function range(isHui) {
        if (viewer && isHui) {
            return new Promise((resolve, reject) => {
                deleteRangeArea()
                // 取消双击事件-追踪该位置
                viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

                // var handler = new Cesium.ScreenSpaceEventHandler(viewer.scene._imageryLayerCollection);
                var positions = [];
                var poly = null;
                var distance = 0;
                var cartesian = null;
                var labelPt;

                //鼠标移动事件
                viewer.screenSpaceEventHandler.setInputAction(function (movement) {
                    let ray = viewer.camera.getPickRay(movement.endPosition);
                    cartesian = viewer.scene.globe.pick(ray, viewer.scene);
                    // const cartesian = viewer.camera.pickEllipsoid(movement.endPosition, viewer.scene.globe.ellipsoid);//当前世界坐标笛卡尔积
                    if (!Cesium.defined(cartesian)) //跳出地球时异常
                        return;
                    if (positions.length >= 2) {
                        if (!Cesium.defined(poly)) {
                            //移动时路径绘制
                            poly = new PolyLinePrimitive(positions);
                        } else {
                            positions.pop();
                            positions.push(cartesian);
                        }
                    }
                }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

                //鼠标左键点击事件
                viewer.screenSpaceEventHandler.setInputAction(function (movement) {
                    let ray = viewer.camera.getPickRay(movement.position);
                    cartesian = viewer.scene.globe.pick(ray, viewer.scene);
                    // const cartesian = viewer.camera.pickEllipsoid(movement.position, viewer.scene.globe.ellipsoid);//当前世界坐标笛卡尔积
                    if (!Cesium.defined(cartesian)) //跳出地球时异常
                        return;
                    if (positions.length === 0) {
                        positions.push(cartesian.clone());
                    }
                    positions.push(cartesian);
                    //记录鼠标单击时的节点位置，异步计算贴地距离
                    labelPt = positions[positions.length - 1];
                    if (positions.length > 2) {
                        getSpaceDistance(positions);
                    } else if (positions.length === 2) {
                        //在三维场景中添加Label
                        viewer.entities.add({
                            name: '_range',
                            id: "range",
                            position: labelPt,
                            point: {
                                pixelSize: 5,
                                color: Cesium.Color.RED,
                                outlineColor: Cesium.Color.WHITE,
                                outlineWidth: 2,
                            },
                            label: {
                                text: '起 点',
                                font: 'normal 8px SimHei',
                                fillColor: Cesium.Color.BLUE,
                                backgroundColor: Cesium.Color.WHITE,    //背景颜色  
                                //showBackground:true,
                                style: Cesium.LabelStyle.FILL,     //label样式 FILL、OUTLINE、FILL_AND_OUTLINE
                                outlineWidth: 2,
                                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,//水平位置
                                pixelOffset: new Cesium.Cartesian2(0, -10),
                            }
                        });
                    }
                }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

                //鼠标左键双击事件
                viewer.screenSpaceEventHandler.setInputAction(function (movement) {
                    let ray = viewer.camera.getPickRay(movement.position);
                    cartesian = viewer.scene.globe.pick(ray, viewer.scene);
                    // const cartesian = viewer.camera.pickEllipsoid(movement.position, viewer.scene.globe.ellipsoid);//当前世界坐标笛卡尔积
                    if (!Cesium.defined(cartesian)) //跳出地球时异常
                        return;
                    if (positions.length === 0) {
                        positions.push(cartesian.clone());
                    }
                    positions.push(cartesian);
                    //记录鼠标单击时的节点位置，异步计算贴地距离
                    labelPt = positions[positions.length - 1];
                    if (positions.length > 2) {
                        getSpaceDistance(positions, true);
                    } else if (positions.length === 2) {
                        //在三维场景中添加Label
                        viewer.entities.add({
                            name: '_range',
                            //id:"LEFT_DOUBLE_CLICK",
                            position: labelPt,
                            point: {
                                pixelSize: 5,
                                color: Cesium.Color.RED,
                                outlineColor: Cesium.Color.WHITE,
                                outlineWidth: 2,
                            }
                        });
                    }
                    // handler.destroy(); //关闭事件句柄
                    // handler = undefined;
                    viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);//停止监听移动事件
                    viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);//停止监听移动事件
                }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

                var PolyLinePrimitive = (function () {
                    function _(positions) {
                        this.options = {
                            name: '_range',
                            polyline: {
                                show: true,
                                positions: [],
                                material: Cesium.Color.ORANGERED,
                                width: 3,
                                clampToGround: true
                            }
                        };
                        this.positions = positions;
                        this._init();
                    }

                    _.prototype._init = function () {
                        var _self = this;
                        var _update = function () {
                            return _self.positions;
                        };
                        //实时更新polyline.positions
                        this.options.polyline.positions = new Cesium.CallbackProperty(_update, false);
                        viewer.entities.add(this.options);
                    };

                    return _;
                })();

                //空间两点距离计算函数
                function getSpaceDistance(positions, ifLast) {
                    //只计算最后一截，与前面累加
                    //因move和鼠标左击事件，最后两个点坐标重复
                    var i = positions.length - 3;
                    var point1cartographic = Cesium.Cartographic.fromCartesian(positions[i]);
                    var point2cartographic = Cesium.Cartographic.fromCartesian(positions[i + 1]);
                    getTerrainDistance(point1cartographic, point2cartographic, ifLast);
                }

                //贴地距离计算函数
                function getTerrainDistance(point1cartographic, point2cartographic, ifLast) {
                    var geodesic = new Cesium.EllipsoidGeodesic();
                    geodesic.setEndPoints(point1cartographic, point2cartographic);
                    var s = geodesic.surfaceDistance;
                    var cartoPts = [point1cartographic];
                    for (var jj = 1000; jj < s; jj += 1000) {　　//分段采样计算距离
                        var cartoPt = geodesic.interpolateUsingSurfaceDistance(jj);
                        cartoPts.push(cartoPt);
                    }
                    cartoPts.push(point2cartographic);
                    //返回两点之间的距离
                    var promise = Cesium.sampleTerrain(viewer.terrainProvider, 2, cartoPts);
                    Cesium.when(promise, function (updatedPositions) {
                        // positions height have been updated.
                        // updatedPositions is just a reference to positions.
                        for (var jj = 0; jj < updatedPositions.length - 1; jj++) {
                            var geoD = new Cesium.EllipsoidGeodesic();
                            geoD.setEndPoints(updatedPositions[jj], updatedPositions[jj + 1]);
                            var innerS = geoD.surfaceDistance;
                            innerS = Math.sqrt(Math.pow(innerS, 2) + Math.pow(updatedPositions[jj + 1].height - updatedPositions[jj].height, 2));
                            distance += innerS;
                        }
                        //在三维场景中添加Label
                        var textDisance = distance.toFixed(2) + "m";
                        if (distance > 1000)
                            textDisance = (distance / 1000.0).toFixed(2) + "公里";
                        if (ifLast) {//最后一个点则返回距离
                            resolve(textDisance)
                        }
                        viewer.entities.add({
                            name: '_range',
                            position: labelPt,
                            point: {
                                pixelSize: 4,
                                color: Cesium.Color.RED,
                                outlineColor: Cesium.Color.WHITE,
                                outlineWidth: 2,
                            },
                            label: {
                                text: textDisance,
                                font: '18px sans-serif',
                                fillColor: Cesium.Color.WHITE,
                                // backgroundColor:Cesium.Color.WHITE,    //背景颜色
                                // style: Cesium.LabelStyle.FILL,
                                outlineWidth: 2,
                                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                                horizontalOrigin: Cesium.HorizontalOrigin.LEFT,//水平位置
                                pixelOffset: new Cesium.Cartesian2(0, -10),
                            }
                        });
                    });
                }
            })
        } else {
            deleteRangeArea()
            viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);//停止监听移动事件
            viewer.screenSpaceEventHandler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);//停止监听移动事件
        }

    }

    return {
        addFireEntitys,
        addFireEntityTogether,
        addFireEntityGIF,
        addFireEntity,
        sortArr,
        chinaOptions,
        deleteEntityByName,
        addBoundary,
        range,
        addBoundaryHandle,
        drawPoint,
    }
};
