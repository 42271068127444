// 卫星影像数据管理地图
import React, { useEffect } from 'react';
// import Style from './Map.module.css'
import * as Cesium from 'cesium';
// import CesiumContext from '../../common/CesiumContext'
// import usePublic from '../usePublic/usePublic'

let viewer;
export default function DataMap({ setViewer, setSeePointInfo }) {
    // const cesiumContext = useContext(CesiumContext);
    // const { addBoundary } = usePublic({ viewer })
    let basemap = new Cesium.ArcGisMapServerImageryProvider({
        url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer',
        tileMatrixSetID: "GoogleMapsCompatible",
        style: "default",
        layer: "tdtImgAnnoLayer",
    });
    basemap.baseLayerName = 'baseLayerNation'
    // 初始化地图
    useEffect(
        () => {
            Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJiYzcwZmJmNi02MzQ5LTQ1MGEtODgzMy0yZTZiZGExY2MzMGMiLCJpZCI6MTAxOTgsInNjb3BlcyI6WyJhc2wiLCJhc3IiLCJnYyJdLCJpYXQiOjE1ODEzMDgxNTR9.NeJU4yfUi-SnLrNDOBYsQgmiSh3B4axCTi1m84Qw99c';
            viewer = new Cesium.Viewer('cesiumContainer', {
                baseLayerPicker: false,
                shouldAnimate: true,//自动播放
                infoBox: false,//信息框
                animation: false,//“动画”窗口小部件提供用于播放，暂停和倒退的按钮，以及当前时间和日期，并由“飞梭环”包围，用于控制动画的速度。cesium示例地图左下角那个
                timeline: false,//时间轴是用于显示和控制当前场景时间的小部件。
                geocoder: false,//查找，用于查找地址和地标，以及将照相机悬挂到它们。使用铯离子进行地址解析。,cesium示例地图右上角的放大镜
                homeButton: false,//主页按钮
                navigationHelpButton: false,//导航帮助按钮
                selectionIndicator: false,//原生选择绿框
                fullscreenButton: false,//右下角的全屏按钮
                terrainProvider: Cesium.createWorldTerrain(),
                // terrainProvider: new Cesium.EllipsoidTerrainProvider({}),//关闭地形

                sceneModePicker: false, //是否显示投影方式控件
                contextOptions: {
                    webgl: {
                        alpha: true,
                    }
                },
                imageryProvider: basemap

            });
            // viewer.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
            //     url: "http://t0.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=5956e6519f2bb0ae8e57bc834298c9f1",
            //     layer: "tdtImgBasicLayer",
            //     style: "default",
            //     format: "image/jpeg",
            //     tileMatrixSetID: "GoogleMapsCompatible",
            //     show: false
            // }));//注记图层

            setViewer(viewer)
            // 关闭双击entity，该事件会放大视图，entity居中显示，且鼠标左键失去移动功能，鼠标滚轮失去作用
            viewer.screenSpaceEventHandler.setInputAction(function () { }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

            // 高程遮挡属性，设置该属性为true之后，标绘将位于地形的顶部；如果设为false（默认值），那么标绘将位于平面上。
            // 缺陷：开启该属性有可能在切换图层时会引发标绘消失的bug。
            viewer.scene.globe.depthTestAgainstTerrain = false;

            // 解决天地图过暗
            viewer.scene.highDynamicRange = false;
            // 去除版权信息
            viewer.cesiumWidget.creditContainer.style.display = "none";

            // 最小缩放高度 米
            viewer.scene.screenSpaceCameraController.minimumZoomDistance = 600;
            // viewer.scene.screenSpaceCameraController.minimumZoomDistance = 150;//相机的高度的最小值
            // viewer.scene.screenSpaceCameraController.maximumZoomDistance = 6000000; //相机高度的最大值
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene._imageryLayerCollection);

            lisenClick()
            // eslint-disable-next-line
        }, []
    )

    // 监听地图点击
    function lisenClick() {
        // 鼠标左键绘制最终点
        viewer.screenSpaceEventHandler.setInputAction(function (movement) {
            const earthPosition = viewer.camera.pickEllipsoid(movement.position, viewer.scene.globe.ellipsoid);//当前世界坐标笛卡尔积
            if (Cesium.defined(earthPosition)) {
                let pick = viewer.scene.pick(movement.position);
                if (pick) {
                    if (pick.id && pick.id.sInfo && pick.id._name) {//已经确认的点位
                        //     const data = pick.id.sInfo
                        setSeePointInfo(pick.id.sInfo)
                    }
                }
            }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    }
    return (
        <div style={{ width: '100vw', height: '95vh' }} id='mainCesium'>
            <div id="cesiumContainer" style={{ height: '100%', width: '100%' }}></div>
        </div>
    );
}

