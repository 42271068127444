import React, { useEffect, useState, useContext } from 'react';
import dayjs from 'dayjs'
import { Popover, Select } from 'antd'
import Cookies from 'universal-cookie';
import { useHistory, Link } from "react-router-dom";
// import { BrowserRouter as Router, Switch, Route, NavLink,  } from "react-router-dom";

import Style from './Header.module.css';
import logo from './image/logo.png'
import UserContext from '../../common/UserContext'
// import signout from './image/signout.png'
import myself from '../../common/image/myicon.png'
import Axios from '../../common/Axios'

const cookies = new Cookies();

export default function Header(props) {
    const userContext = useContext(UserContext)

    const history = useHistory()
    const [nowLink, setNowLink] = useState('/')
    // 右上角时间
    const [timeSurface, setTimeSurface] = useState(dayjs(new Date()).format('YYYY-MM-DD hh:mm:ss'))
    useEffect(() => {
        /* 定时器 间隔 */
        let time = setInterval(() => {
            setTimeSurface(dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'))
        }, 1000);//1S请求一次
        const link = window.location
        if (link.pathname) {
            setNowLink(link.pathname)
        }
        // 如果是管理员
        if (userContext.userInfo.countyLevel === '管理员') {
            getProvinceList()//获取所有省份
        }
        return () => clearInterval(time)
        // eslint-disable-next-line
    }, [])
    const content = <div>
        <p className={Style.headerUserTitle1}>地区：{userContext.userInfo.info ? userContext.userInfo.info.countyName : ''}</p>
        <span className={Style.headerUserTitle1}>账号有效期:{userContext.userInfo.info ? userContext.userInfo.info.overTime : ''}</span>
    </div>

    function goback() {
        history.push('/admin')
        window.location.href = '/admin'
    }

    let link = [
        { name: '实时火情', link: '/', show: true },
        // 账号过期后，不可查看历史火情
        {
            name: '历史火情', link: '/HistoryFire',
            show: userContext.userInfo.info && userContext.userInfo.info.overTime && dayjs(userContext.userInfo.info.overTime) - dayjs() < 0 ? false : true
        }
    ]

    function linkChange(value) {
        setNowLink(value.link)
    }

    const [proAll, setProAll] = useState([])//所有省份
    // 获取所有省份
    function getProvinceList() {
        Axios({
            method: 'get',
            url: "/getProvinceList"
        }).then(res => {
            const data = res.data.data
            // data.forEach(item => {
            //     item.isLeaf = false
            //     item.level = 1
            // })
            setProAll(data)
        }).catch(err => { })
    }

    // 管理员省份更改
    function onChange(value, option) {
        const data = { ...userContext.userInfo }
        let newData = {
            ...data,
            countyCode: value,
            info: {
                ...data.info,
                countyCode: value,
                countyName: option.children
            },
        }
        userContext.setUserInfo(newData)
        cookies.set('countyCode', value)
        cookies.set('info', newData.info)

        // 判断当前在哪个页面
        const path = window.location.pathname

        window.location.href = path
    }


    return (
        <div className={Style.headerWrapper}>
            <div className={Style.headerTitleWrapper}>
                <img src={logo} alt='' />  <span className={Style.headerTitle}> 全国火情播报系统</span>
            </div>
            <div className={Style.linkWra}>
                {link.map((item, index) => item.show ?
                    <Link onClick={() => linkChange(item)} className={nowLink === item.link ? Style.linkCSelect : Style.linkC} to={item.link} key={index}>{item.name}</Link>
                    : null
                )}
            </div>
            <div className={Style.headerRight}>
                <span className={Style.headerTime}>{timeSurface}</span>
                {
                    proAll && proAll.length > 0 ? <Select className={Style.maragnSelect} defaultValue={userContext.userInfo.countyCode} onChange={onChange}>
                        {proAll.map((item, index) => <Select.Option key={index} value={item.code}>{item.name}</Select.Option>)}
                    </Select> : null
                }
                <div style={{ display: "flex" }}>
                    <img alt='' src={myself} className={Style.userIcon} />
                    <Popover placement="bottom" title={null} content={content} trigger="hover"  >
                        <span className={Style.headerUserTitle}>{userContext.userInfo.userName}</span>
                    </Popover>
                </div>
                <div className={Style.gobackWra} style={{ display: userContext.userInfo.auths === 'auth_administor' ? '' : 'none' }} onClick={() => { goback() }}>去后台</div>
                <div className={Style.signoutWra} onClick={() => {
                    cookies.set('userName', '',);
                    cookies.set('auths', '',);
                    cookies.set('token', '',);
                    cookies.set('countyLevel', '',);
                    history.push('/')
                    userContext.setUserInfo({})
                }}>
                    <div>退出登录</div>
                    <div></div>
                </div>

            </div>
        </div>
    )
}